import { useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import { useFieldArray, useFormContext } from "react-hook-form";

import { AutoCompleteNew } from "../../../shared/Form/Fields/AutoComplete";
import DependsOn from "../../../shared/HOC/DependsOn";
import { useFetch } from "../../../shared/services/useFetch";

interface IAgenteCausador {
  CODIGO: number;
  CLICODIGO: number | null;
  CODTABELA: string;
  DESCRICAO: string;
  ASSINA: string | null;
  TABELA: "ESOCSITGERADORADOENCA" | "ESOCAGENTECAUSADORACIDENTE";
}

export default function Step() {
  const { data: agentes } = useFetch<IAgenteCausador[]>(
    "/cadastro/agentes-causadores-acidentes"
  );

  const { control, setValue, errors, register } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: "AGENTES",
  });

  useEffect(() => {
    if (!fields.length) append({});
  }, []);

  return (
    <DependsOn
      dependencies={[agentes]}
      Component={
        <>
          {fields.map((field, idx) => {
            return (
              <Paper
                style={{
                  padding: "25px",
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "8px",
                }}
                elevation={5}
              >
                <input
                  type="hidden"
                  {...(register(`AGENTES[${idx}].ACATABELA`) as any)}
                />
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <AutoCompleteNew
                      key={field.id}
                      setValue={setValue}
                      control={control}
                      label="Agente causador"
                      name={`AGENTES[${idx}].ACACODIGO`}
                      onChange={(data) =>
                        setValue(`AGENTES[${idx}].ACATABELA`, data?.code2)
                      }
                      valeuDef={field["ACACODIGO"]}
                      rules={{
                        required: "Campo Obrigatório",
                      }}
                      options={agentes?.map((agente) => ({
                        label: agente.DESCRICAO,
                        code: agente.CODIGO,
                        code2: agente.TABELA,
                      }))}
                      error={
                        errors["AGENTES"]
                          ? errors["AGENTES"][idx]?.ACACODIGO
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </>
      }
    />
  );
}
