import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ContainedLoadingButton } from "../Button/LoadingButton";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { Backdrop, Button, Fade, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        gap: "21px",
        width: "535px",
        outline: "none",
        display: "flex",
        padding: "21px",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
    },
}));

export type formCPF = { FUNCPF: string; TIPOEXAME: number }

export const ModalInformation = () => {
    let history = useHistory();

    const classes = useStyles();

    const { validCPF, changeValidCPF } = useStatePersistent();

    if (
        validCPF?.MENSAGEM_EXIBICAO?.length === 0
    ) {
        return null;
    }

    return (
      <Modal
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        aria-labelledby="transition-modal-title"
        open={validCPF?.MENSAGEM_EXIBICAO?.length > 0}
        aria-describedby="transition-modal-description"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={validCPF?.MENSAGEM_EXIBICAO?.length > 0}>
          <div className={classes.paper}>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              style={{
                marginTop: "24px",
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: "100px",
                }}
                htmlColor="#C9B116"
              />
            </Grid>
            <Typography align="center" variant="h6">
              {validCPF?.MENSAGEM_EXIBICAO}
            </Typography>
            <Grid container justifyContent="flex-end">
              <Grid lg={3}>
                <Button onClick={() => {
                     changeValidCPF(true);
                     history.push("/pages")
                }}>Cancelar</Button>
              </Grid>
              <Grid lg={3}>
                <ContainedLoadingButton
                  onClick={() => {
                    changeValidCPF(true);
                  }}
                >
                  Continuar
                </ContainedLoadingButton>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    );
}
