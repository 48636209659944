import { useEffect, useCallback, useState } from "react";

import LoadingCircular from "../../shared/LoadingCircular";
import StepperForm from "../../shared/StepperForm";
import { useCat } from "./controller";
import DadosCAT from "./steps/dados";
import AgenteCAT from "./steps/agente";
import LocalCAT from "./steps/local";
import ParteCAT from "./steps/parte";
import HeaderWithBack from "../../shared/Item/HeaderWithBack";
import { useHistory, useParams } from "react-router-dom";
import { format, isValid } from "date-fns";

const isEmpty = (obj: Object | Array<Record<string, unknown>>): boolean => {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj.length === 0;
    }
    return Object.keys(obj).length === 0;
  }
};

export default function CatItem() {
  const history = useHistory();
  const { id, idCat } = useParams<{ id: string; idCat: string }>();
  const linkBack = `/pages/editarFuncionario/${id}`;
  const { loading, ...crudProps } = useCat(id, linkBack, idCat);
  const [cache, setCache] = useState<any>({});

  useEffect(() => {
    setCache(crudProps?.content);
  }, [crudProps?.content]);

  const getStepContent = (index: number) => {
    switch (index) {
      case 0:
        return <DadosCAT id={id} />;
      case 1:
        return <LocalCAT />;
      case 2:
        return <ParteCAT />;
      case 3:
        return <AgenteCAT />;
    }
  };

  const getSteps = useCallback(
    () => [
      "Comunicação de acidente de trabalho",
      "Local do acidente",
      "Partes atingidas",
      "Agente causador",
    ],
    []
  );

  const onChangeStep = (
    data: any,
    resetForm: (data: unknown) => void,
    activeStep?: number
  ) => {
    if (activeStep === 2) {
      if (isEmpty(data)) data.PARTES = [];
    }
    if (activeStep === 3) {
      if (isEmpty(data)) data.AGENTES = [];
    }
    const newData = { ...cache, ...data };
    localStorage.setItem("@ProSESMT:Form", JSON.stringify(newData));
    resetForm(newData);
    setCache(newData);
  };

  if (loading) {
    return <LoadingCircular />;
  }

  return (
    <>
      <HeaderWithBack
        linkBack={`/pages/editarFuncionario/${id}`}
        title={`${idCat === "0" ? "Criar" : "Manutenção"} CAT`}
      />
      <StepperForm
        {...crudProps}
        type={idCat === "0" ? "create" : "edit"}
        content={cache}
        getStepContent={getStepContent}
        getSteps={getSteps}
        orientation="horizontal"
        handleCancel={() => history.push(`/pages/editarFuncionario/${id}`)}
        onClickDelete={crudProps?.destroy}
        onSubmit={(data: any) => {
          localStorage.removeItem("@ProSESMT:Form");
          if (isEmpty(data) && cache) {
            cache.AGENTES = [];
          }

          const newData = { ...cache, ...data };

          if (isValid(new Date(newData.CATDATA))) {
            newData.CATDATA = format(new Date(newData.CATDATA), "yyyy-MM-dd");
          }

          if (newData.CATDATAOBITO && isValid(new Date(newData.CATDATAOBITO))) {
            newData.CATDATAOBITO = format(
              new Date(newData.CATDATAOBITO),
              "yyyy-MM-dd"
            );
          }

          if (isValid(new Date(newData.CATULTDIATRABALHADO))) {
            newData.CATULTDIATRABALHADO = format(
              new Date(newData.CATULTDIATRABALHADO),
              "yyyy-MM-dd"
            );
          }

          if (idCat !== "0") return crudProps.edit({ ...cache, ...data });
          crudProps.create({ ...cache, ...data }, false);
        }}
        onChangeStep={onChangeStep}
      />
    </>
  );
}
