import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Form } from "../../../shared";
import { api } from "../../../shared/services/api";
import { Loading } from "../components";
import RadioButtons from "../../../shared/Form/Fields/RadioButtons";
import InputDate from "../../../shared/Form/Fields/Date";

type Params = { empCodigo: string; anaCodigo: string; plCodigo: string };

export default function EditarDados() {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { anaCodigo: idanalise, plCodigo: idplanoacao } = useParams<Params>();

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(
          `/analiserisco/${idanalise}/cronogramas/${idplanoacao}`
        );
        setData(res.data);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box className="no-print" style={{ margin: 0, padding: 0 }}>
        <Typography
          className="no-print"
          align="left"
          style={{
            marginBottom: "35px",
            marginLeft: "10px",
            fontWeight: "bold",
            fontSize: "15pt",
          }}
          color="primary"
        >
          Dados
        </Typography>
        <Form
          defaultValues={data}
          spacing={"8px 8px"}
          onSubmit={(data) => {}}
          buttons={() => {}}
          schema={[
            {
              content: [
                {
                  lg: 12,
                  type: "custom",
                  name: "CROTIPO",
                  customComponent: ({ control, error, name }) => (
                    <Box style={{ maxWidth: "250px" }}>
                      <RadioButtons
                        schama={[
                          { label: "Segurança", value: 1 },
                          { label: "Medicina", value: 2 },
                        ]}
                        control={control}
                        error={error}
                        label="Tipo"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        inputPropsAdditional={{ disabled: true }}
                      />
                    </Box>
                  ),
                },
                {
                  lg: 12,
                  name: "CROPRIORIDADE",
                  label: "Prioridade",
                  disabled: true,
                },
                {
                  lg: 4,
                  name: "CRODTINICIAL",
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Inicial"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 4,
                  name: "CRODTPREVISTA",
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Prevista"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 4,
                  name: "CRODTFINAL",
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Executada"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 12,
                  name: "CRORECOMENDACAO",
                  label: "Medida Proposta",
                  rules: { required: "Campo Obrigatório" },
                  inputPropsAdittional: { multiline: true, rows: 3 },
                  disabled: true,
                },
                {
                  lg: 12,
                  name: "CROESTRATEGIA",
                  label: "Estratégia e Metodologia de Ação",
                  rules: { required: "Campo Obrigatório" },
                  inputPropsAdittional: { multiline: true, rows: 3 },
                  disabled: true,
                },
                {
                  lg: 12,
                  name: "CROREFERENCIA",
                  label: "Referência Legal",
                  inputPropsAdittional: { multiline: true, rows: 3 },
                  disabled: true,
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  );
}
