import { useEffect, useState } from "react";
import { api } from "../../shared/services/api";

export const useFetch = (url) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(url);
        setData(res.data);
      } catch (err) {
        return { error: err }
      }
    }
    fetch();
  }, []);

  return { data };
}

export const useFetchSelect = (url, label, value, formatFun?) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(url);
        const opt = res.data.map(
          (item) => {
            return {
              label: formatFun ? formatFun(item) : item[label],
              ...(
                Array.isArray(value)
                  ? {
                    code: item[value[0]],
                    code2: item[value[1]]
                  } : { code: item[value] })
            }
          }
        );
        setOptions(opt);
      } catch (err) {
        return { error: err };
      }
    }
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { options };
}