import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

export default function InputDef({
  name,
  errors,
  TextFieldProps = {},
  inputPropsAdittional = {},
  control,
  rules,
  label,
  ...rest
}: any) {
  if (control) {
    return (
      <Controller
        render={({ onChange, value }) => (
          <TextField
            {...rest}
            onChange={onChange}
            value={value}
            label={label}
            name={name}
            error={errors}
            helperText={errors && <span>{errors.message}</span>}
            inputProps={TextFieldProps}
          />
        )}

        rules={rules}
        control={control}
        name={name}
        placeholder={label}
      />
    )
  }

  return (
    <TextField
      {...rest}
      name={name}
      label={label}
      error={errors}
      InputProps={{
        ...TextFieldProps
      }}
      helperText={errors && <span>{errors.message}</span>}
    />
  );
}
