import { useEffect } from "react";
import { Control, useFieldArray, useFormContext } from "react-hook-form";
import { Grid, Paper } from "@material-ui/core";

import { AutoCompleteNew } from "../../../shared/Form/Fields/AutoComplete";
import DependsOn from "../../../shared/HOC/DependsOn";
import { useFetch } from "../../../shared/services/useFetch";
import { SelectForm } from "../../../shared/Form/Fields";

interface ILateralidade {
  LATCODIGO: number;
  LATDESCRICAO: string | null;
  LATCODTABELA: number | null;
  ASSINA: string | null;
}

interface IParteAtingida {
  PCACODIGO: number;
  CLICODIGO: number | null;
  PCACODTABELA: string | null;
  PCADESCRICAO: string | null;
  ASSINA: string | null;
}

interface FormItemArrayProps {
  field: any;
  control: Control;
  errors: any;
  options: any;
  selectOptions: any;
  prefix: string;
  order: number;
  onRemove: () => void;
  setValue: any;
}

function FormItemArray({
  field,
  control,
  errors,
  options,
  selectOptions,
  prefix,
  order,
  onRemove,
  setValue,
}: FormItemArrayProps) {
  return (
    <Paper style={{ padding: "25px", margin: "8px 0px" }} elevation={5}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={8}>
          <AutoCompleteNew
            key={field.id}
            setValue={setValue}
            control={control}
            label="Parte do corpo atingida"
            name={`${prefix}.PCACODIGO`}
            valeuDef={field["PCACODIGO"] || ""}
            options={options}
            error={errors["PARTES"] ? errors["PARTES"][order]?.PCACODIGO : null}
            rules={{ required: "Campo Obrigatório" }}
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <SelectForm
            InputLabelProps={{ shrink: true }}
            FormControlProps={{
              style: { width: "100%" },
              variant: "outlined",
            }}
            name={`${prefix}.LATCODIGO`}
            label="Lateralidade"
            rules={{ required: "Campo obrigatório" }}
            control={control}
            options={selectOptions}
            errors={
              errors["PARTES"] ? errors["PARTES"][order]?.LATCODIGO : null
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function Step() {
  const { data: partesAtingidas } = useFetch<IParteAtingida[]>(
    "/cadastro/partes-corpo-atingidas"
  );
  const { data: lateralidades } = useFetch<ILateralidade[]>(
    "/cadastro/lateralidades"
  );

  const { control, errors, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: "PARTES" });

  useEffect(() => {
    if (!fields.length) append({});
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DependsOn
        dependencies={[partesAtingidas, lateralidades]}
        Component={
          <div>
            {fields.map((field, idx) => {
              return (
                <FormItemArray
                  field={field}
                  order={idx}
                  prefix={`PARTES[${idx}]`}
                  errors={errors}
                  control={control}
                  onRemove={() => {
                    remove(idx);
                  }}
                  options={partesAtingidas?.map((parte) => ({
                    label: parte.PCADESCRICAO,
                    code: parte.PCACODIGO,
                  }))}
                  selectOptions={lateralidades?.map((lateralidade) => ({
                    label: lateralidade.LATDESCRICAO,
                    value: lateralidade.LATCODIGO,
                  }))}
                  setValue={setValue}
                />
              );
            })}
          </div>
        }
      />
    </div>
  );
}
