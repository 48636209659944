import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Typography,
  MenuItem,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../../../shared/Button/LoadingButton";
import { Form } from "../../../shared";
import { api } from "../../../shared/services/api";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";
import { Controller } from "react-hook-form";
import "react-toastify/dist/index";

interface ResponseESocial {
  EMPCODIGO: number;
  ESOCCNPJENVIO: null | string;
  ESOCEMAIL: string;
  ESOCGUIDCERTIFICADO: string;
  ESOCSENHACERTIFICADO: string;
  ESOCTEMPOENVIO: null;
  ESOCTIPOCERTIFICADO: number;
  ESOCTIPOENVIO: number;
  GRUCODIGO: number;
  EMPAUTOESOCIAL: number;
  EMPESOCIALWEB: number;
  EMPESOCS2220: number;
  EMPESOCS2240: number;
}

export default function ESocialConfig() {
  const [valueCertificado, setValueCertificado] = React.useState("assessoria");
  const [envioCss, setEnvioCss] = React.useState("none");
  const [certCss, setCertCss] = React.useState("none");
  const [requiredCertificado, setRequiredCertificado] = React.useState(false);
  const [requiredCertificadoSenha, setRequiredCertificadoSenha] =
    React.useState(false);
  const [valorCampoSenha, setValorCampoSenha] = React.useState("");
  const [valorGuidCertificado, setValorGuidCertificado] = React.useState("");
  const [valorCampoCertificado, setValorCampoCertificado] = React.useState("");
  const [certificado, setCertificado] = React.useState(null);
  const [selectGrupos, setSelectGrupos] = React.useState<
    JSX.Element[] | null
  >();
  const setValueRef = React.useRef<any>();
  const [loadingButton, setLoadingButton] = React.useState<boolean>(false);
  const propertiesRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const exportarChaveCertificado = async () => {
    setLoading(true);
    const req = await api();
    try {
      const response = await req.put(
        "/eSocial/certificados/propriedades-extendidas"
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get<ResponseESocial>("/esocial");
        propertiesRef.current = {
          EMPAUTOESOCIAL: res.data.EMPAUTOESOCIAL,
          EMPESOCIALWEB: res.data.EMPESOCIALWEB,
          EMPESOCS2220: res.data.EMPESOCS2220,
          EMPESOCS2240: res.data.EMPESOCS2240,
          ESOCTIPOENVIO: res.data.ESOCTIPOENVIO,
        };
        if (res.data !== null) {
          const { data } = res;
          // Se já foi enviado um certificado anteriormente
          if (data.ESOCGUIDCERTIFICADO !== null) {
            setCertificado(true);
          }

          // Valores padrão
          if (setValueRef.current) {
            const setValue = setValueRef.current;
            setValue("ESOCEMAIL", data.ESOCEMAIL);
            setValue("ESOCTEMPOENVIO", data.ESOCTEMPOENVIO);
            setValue("GRUCODIGO", data.GRUCODIGO);
            setValue("ESOCSENHACERTIFICADO", data.ESOCSENHACERTIFICADO);
            setValue("ESOCCNPJENVIO", data.ESOCCNPJENVIO);
          }

          // GUID do certificado
          setValorGuidCertificado(data.ESOCGUIDCERTIFICADO);

          // Manual ou Automático
          if (data.ESOCTIPOCERTIFICADO === 1) {
            setEnvioCss("none");
          } else if (data.ESOCTIPOCERTIFICADO === 2) {
            setEnvioCss("flex");
          }

          //verificação do tipo de certificado
          if (data.ESOCTIPOCERTIFICADO === 1) {
            setValueCertificado("assessoria");
            setCertCss("none");
          } else if (data.ESOCTIPOCERTIFICADO === 2) {
            setValueCertificado("cliente");
            setCertCss("flex");
          }
        }
      } catch (err) {
        toast.error(
          err.response?.data?.message ||
            "Ocorreu um erro ao tentar carregar as informações"
        );
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    async function getApi() {
      const req = await api();
      await req.get("/esocial/grupos").then((response) => {
        let groupItem = response.data.map((item, index) => (
          <MenuItem key={index} value={item.GRUCODIGO}>
            {item.GRUDESCRICAO}
          </MenuItem>
        ));
        setSelectGrupos(groupItem);
      });
    }
    getApi();
  }, []);

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("myFile", valorCampoCertificado);
    const req = await api();
    try {
      await req.put("/esocial/certificados", formData);
      toast.success("Certificado salvo com sucesso");
    } catch (err) {
      toast.error("Certificado não foi salvo, entrar em contato com o suporte");
    }
  };

  const handleSubmit = async (data) => {
    if (
      valueCertificado === "cliente" &&
      valorCampoCertificado.length === 0 &&
      !certificado
    ) {
      setRequiredCertificado(true);
      return;
    }

    setRequiredCertificado(false);
    setLoadingButton(true);
    const body = {
      ESOCTEMPOENVIO: data.ESOCTEMPOENVIO || null,
      ESOCSENHACERTIFICADO: data.ESOCSENHACERTIFICADO,
      ESOCEMAIL: data.ESOCEMAIL,
      ESOCCNPJENVIO: data.ESOCCNPJENVIO,
      GRUCODIGO: data.GRUCODIGO ? data.GRUCODIGO : 0,
      ESOCGUIDCERTIFICADO: valorGuidCertificado,
      ESOCTIPOCERTIFICADO: valueCertificado === "assessoria" ? 1 : 2,
      ...propertiesRef.current,
    };

    try {
      const req = await api();
      await req.put("/esocial", body);
      if (valorCampoCertificado) {
        await onFileUpload();
      }
      toast.success("Os dados foram salvos com sucesso");
    } catch {
      toast.error(
        "Não foi possível atualizar seus dados, entrar em contato com o suporte"
      );
    } finally {
      setLoadingButton(false);
    }
  };

  const handleChangeCertificadoFile = (event) => {
    setValorCampoCertificado(event.target.files[0]);
  };

  const handleChangeRadio = async (event) => {
    setValueCertificado(event.target.value);

    if (event.target.value === "cliente") {
      setCertCss("flex");
      const req = await api();
      const res = await req.get("/esocial");
      if (res.data !== null) {
        setValorCampoSenha(res.data.ESOCSENHACERTIFICADO);
      }
    } else if (event.target.value === "assessoria") {
      setCertCss("none");
      setValorCampoSenha("");
    }
  };

  return (
    <Paper style={{ width: 1000, padding: 28 }}>
      <Typography
        align="left"
        style={{
          width: "600px",
          marginBottom: "20px",
          fontWeight: "bold",
          fontSize: "15pt",
        }}
        color="primary"
      >
        Configurações eSocial
      </Typography>
      <Form
        spacing={"0px 24px 0px 0px"}
        onSubmit={handleSubmit}
        buttons={() => (
          <div
            style={{
              maxWidth: 1048,
              width: "100%",
              margin: "24px auto",
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              style={{ width: "97%", marginTop: 10 }}
              size="large"
              type="submit"
            >
              {loadingButton ? (
                <CircularProgress color="secondary" />
              ) : (
                "Gravar"
              )}
            </LoadingButton>
          </div>
        )}
        schema={[
          {
            content: [
              {
                lg: 7,
                type: "custom",
                customComponent: ({ control, name }) => (
                  <>
                    <FormLabel component="legend" style={{ marginTop: 25 }}>
                      Tipo do Certificado
                    </FormLabel>
                    <RadioGroup
                      aria-label="quiz"
                      name="quiz"
                      value={valueCertificado}
                      onChange={handleChangeRadio}
                      style={{ display: "inline" }}
                    >
                      <FormControlLabel
                        value="assessoria"
                        control={<Radio color="primary" />}
                        label="Assessoria"
                      />
                      <FormControlLabel
                        value="cliente"
                        control={<Radio color="primary" />}
                        label="Cliente"
                      />
                    </RadioGroup>
                  </>
                ),
              },
              {
                lg: 12,
                name: "alert",
                type: "custom",
                customDefValue: new Date(),
                customComponent: () => (
                  <>
                    <Alert
                      severity="success"
                      style={{ display: certificado ? "flex" : "none" }}
                    >
                      Você já possui um certificado salvo!
                    </Alert>
                  </>
                ),
              },
              {
                lg: 8,
                name: "CERTIFICADO",
                type: "custom",
                rules: { required: "Campo Obrigatório" },
                customComponent: ({ control, name, error }) => (
                  <TextField
                    id="standard-number"
                    label="Certificado"
                    type="file"
                    inputProps={{ accept: ".pfx" }}
                    onChange={handleChangeCertificadoFile}
                    error={requiredCertificado && !certificado}
                    helperText={
                      requiredCertificado && !certificado
                        ? "Campo Obrigatório"
                        : ""
                    }
                    style={{ display: certCss, marginTop: 12, width: "100%" }}
                    variant="outlined"
                    color="primary"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                ),
              },
              {
                lg: 4,
                customComponent: () => {
                  return (
                    <LoadingButton
                      loading={loading}
                      variant="outlined"
                      color="primary"
                      onClick={exportarChaveCertificado}
                      disabled={!certificado}
                      style={{
                        marginTop: 10,
                        display: certCss,
                      }}
                    >
                      Exportar chave do certificado
                    </LoadingButton>
                  );
                },
              },
              {
                lg: 6,
                name: "ESOCSENHACERTIFICADO",
                type: "custom",
                customComponent: ({ control, name, setValue, error }) => {
                  if (!setValueRef.current) setValueRef.current = setValue;
                  return (
                    <Controller
                      control={control}
                      name={name}
                      rules={{ required: valueCertificado === "cliente" }}
                      render={({ value, onChange }) => {
                        return (
                          <TextField
                            id="standard-number"
                            label="Senha do certificado"
                            type="password"
                            //value={valorCampoSenha}
                            value={value}
                            onChange={onChange}
                            error={error}
                            helperText={error ? "Campo Obrigatório" : ""}
                            style={{
                              display: certCss,
                              marginTop: 12,
                              width: "100%",
                              marginBottom: 12,
                            }}
                            variant="outlined"
                            color="primary"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }}
                    />
                  );
                },
              },
              {
                lg: 6,
                name: "ESOCCNPJENVIO",
                type: "custom",
                customComponent: ({ control, name }) => (
                  <Controller
                    control={control}
                    name={name}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          id="standard-number"
                          label="CNPJ a ser usado no Envio"
                          value={value}
                          onChange={onChange}
                          error={requiredCertificadoSenha}
                          helperText={
                            requiredCertificadoSenha ? "Campo Obrigatório" : ""
                          }
                          style={{
                            display: certCss,
                            marginTop: 12,
                            width: "100%",
                            marginBottom: 12,
                          }}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                ),
              },
              {
                lg: 9,
                name: "ESOCEMAIL",
                type: "custom",
                customComponent: ({ control, name, error }) => (
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name={name}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          id="standard-number"
                          label="Email de Recebimento"
                          type="email"
                          value={value}
                          onChange={onChange}
                          error={error}
                          helperText={error ? "Campo Obrigatório" : ""}
                          style={{
                            marginTop: 12,
                            width: "100%",
                            marginBottom: 12,
                          }}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                ),
              },
              {
                lg: 3,
                name: "ESOCTEMPOENVIO",
                type: "custom",
                customComponent: ({ control, name, error }) => (
                  <Controller
                    control={control}
                    name={name}
                    render={({ value, onChange }) => {
                      return (
                        <TextField
                          id="standard-number"
                          label="Tempo de Atraso em Dias"
                          type="number"
                          value={value}
                          onChange={onChange}
                          style={{
                            display: envioCss,
                            marginTop: 10,
                            width: "100%",
                          }}
                          error={error}
                          helperText={error ? "Campo Obrigatório" : ""}
                          variant="outlined"
                          color="primary"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                ),
              },
              {
                lg: 12,
                name: "GRUCODIGO",
                label: "Grupo da empresa no eSocial",
                type: "select",
                options: selectGrupos,
              },
            ],
          },
        ]}
      />
    </Paper>
  );
}
