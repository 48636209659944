import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { SchemaSection, SchemaContent } from "./model";
import { InputDef, SelectDef } from "./Fields";
import { useFormContext } from "react-hook-form";
import SwitchDef from "./Fields/Checkbox";
import CheckboxDef from "./Fields/Switch";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.text.secondary,
    width: "100%",
  },
  icon: {
    margin: "0px 15px 0px 0px",
    padding: 0,
  },
  default: {
    width: "100%",
  },
}));

function FormBody({
  schema,
  spacing,
}: {
  schema: SchemaSection[];
  spacing: string | number;
}) {
  const classes = useStyles();

  return (
    <>
      {schema.map((schemaSection: SchemaSection, index: number) => {
        const ContainerSchema = schemaSection.container
          ? schemaSection.container
          : Box;

        return (
          <ContainerSchema
            id={`${schemaSection.title}-form`}
            style={
              schemaSection.containerStyle
                ? schemaSection.containerStyle
                : { width: "100%" }
            }
            key={index}
          >
            <div>
              {schemaSection.title && (
                <Grid
                  style={{ marginBottom: 10, marginLeft: spacing || 0 }}
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                >
                  <Grid container direction="row" alignItems="center">
                    {schemaSection.icon && (
                      <div className={classes.icon}>
                        <schemaSection.icon />
                      </div>
                    )}
                    <Typography
                      style={{ margin: "0px 0px 5px 0px", padding: 0 }}
                      variant="h6"
                    >
                      {schemaSection.title}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                direction="row"
                container
                spacing={0}
                style={{ width: "100%", display: "flex", ...schemaSection.gridStyles }}
              >
                {schemaSection.content.map((schemaItem, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      style={{ padding: spacing || 0, ...schemaItem?.styles }}
                      xs={schemaItem.xs || schemaItem.lg}
                      sm={schemaItem.sm || schemaItem.lg}
                      md={schemaItem.md || schemaItem.lg}
                      lg={schemaItem.lg}
                    >
                      <FormItem schemaItem={schemaItem} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </ContainerSchema>
        );
      })}
    </>
  );
}

export function FormItem({ schemaItem }: { schemaItem: SchemaContent<any> }) {
  const { errors, control, register, setValue, getValues, setError, ...rest } =
    useFormContext(); // retrieve all hook methods
  const name = schemaItem.name ? schemaItem.name.toString() : "";

  if (schemaItem.customComponent) {
    return schemaItem.customComponent({
      name,
      control,
      register,
      setValue,
      setError,
      getValues,
      error: errors[name],
      ...rest
    });
  } else {
    const { type, disabled, label, rules } = schemaItem;

    let item: JSX.Element = <></>;

    switch (type) {
      case "select":
        item = (
          <SelectDef
            data-testid={`select-${name}`}
            InputLabelProps={{ shrink: true }}
            FormControlProps={{
              style: { width: "100%" },
              variant: "outlined",
            }}
            onChangeItem={(data, other) => {
              if (schemaItem.onChange) {
                schemaItem.onChange(data, setValue, other);
              }
            }}
            disabled={disabled}
            name={name}
            label={label}
            rules={rules}
            control={control}
            errors={errors[name]}
          >
            {schemaItem.options}
          </SelectDef>
        );
        break;
      case "checkbox":
        item = (
          <CheckboxDef
            onChangeItem={(data: any) => {
              if (schemaItem.onChange) {
                schemaItem.onChange(data, setValue);
              }
            }}
            label={schemaItem.label}
            name={name}
            control={control}
          />
        );
        break;
      case "switch":
        item = (
          <SwitchDef
            onChangeItem={(data: any) => {
              if (schemaItem.onChange) {
                schemaItem.onChange(data, setValue);
              }
            }}
            label={schemaItem.label}
            name={name}
            control={control}
          />
        );
        break;
      default:
        item = (
          <InputDef
            data-testid={`input-${name}`}
            id={name}
            style={{ width: "100%" }}
            {...schemaItem.inputPropsAdittional}
            errors={errors[name]}
            disabled={schemaItem.disabled || schemaItem.loading}
            inputRef={register(schemaItem.rules)}
            name={name}
            label={`${schemaItem.label}${
              schemaItem?.rules?.required ? " *" : ""
            }`}
            variant={schemaItem.variant || "outlined"}
            InputProps={
              schemaItem.loading && {
                endAdornment: (
                  <InputAdornment position="start">
                    <CircularProgress size={16} />
                  </InputAdornment>
                ),
              }
            }
            InputLabelProps={{ shrink: true }}
            onBlur={(data: any) => {
              if (schemaItem.onBlur) {
                schemaItem.onBlur(data, setValue);
              }
            }}
            onChange={(data: any) => {
              if (schemaItem.onChange) {
                schemaItem.onChange(data, setValue);
              }
            }}
          />
        );
        break;
    }

    return item;
  }
}

export default FormBody;
