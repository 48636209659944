export interface PortalModel {
  host: string;
  ambient: string;
  logoUrl: string;
  companyName: string;
  companyDocument: string;
  companyUserName: string;
  agendamentoRapido?: string;
}

export type Action =
  | { type: "SET_PORTAL"; payload: PortalModel }
  | { type: "REMOVE_AGENDAMENTO_RAPIDO" };

export const setPortal = (type: PortalModel): Action => ({
  type: "SET_PORTAL",
  payload: type,
});

export const removeAgendamentoRapido = (): Action => ({
  type: "REMOVE_AGENDAMENTO_RAPIDO",
});

const portalReducer = (
  state: PortalModel | null = {
    host: /*localStorage.getItem('LinkGet') === 'agendahealthtotal.com.br' || 'healthteste.com.br' ? "v1-hom-ht" :*/ 'v1-hom',
    ambient: "",
    logoUrl: "",
    companyDocument: "",
    companyName: "",
    companyUserName: "",
    agendamentoRapido: "",
  },
  action: Action
): PortalModel | null => {
  switch (action.type) {
    case "SET_PORTAL":
      return action.payload;
    case "REMOVE_AGENDAMENTO_RAPIDO":
      return { ...state, agendamentoRapido: null };
    default:
      return state;
  }
};

export default portalReducer;
