import { useEffect } from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { api } from "../../../../../shared/services/api";
import DataShow from "../../../../../shared/Item/DataShow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useHistory, useParams } from "react-router-dom";

export default function EsocialDashboardProcessosItemLog(props) {
  const history = useHistory();
  const params = useParams<{ tipo?: string; processoid: string }>();
  useEffect(() => {
    async function loadSchedule() {
      try {
        const teste = localStorage.getItem('Token_Key');
        if (teste === props.match.params.token) {
          const req = await api();
          await req.get(`/users/token`)
        } else {
          history.push('/')
        }
      } catch {
        history.push('/')
      }
    }

    loadSchedule();
  }, [history, props.match.params.token]);
  const redirectBack = () =>
    history.push(
      `/esocial/dashboard/${params.tipo}/items/${params.processoid}`
    );

  return (
    <div style={{ maxWidth: 1366, margin: "0px auto" }}>
      <Box display="flex">
        <IconButton onClick={redirectBack} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <div style={{ width: 30 }}></div>
        <Typography variant="h4">Log</Typography>
      </Box>
      <div style={{ height: 30 }}></div>

      <DataShow<any>
        data={{
          CPF: "50876294824",
          Nome: "Carlos Eduardo Alves do Nascimento",
          Tipo: "S2220 - Monitoramento da Saúde do Trabalhador",
        }}
        schema={[
          [
            {
              content: "Status",
              label: "Status",
              size: 12,
            },
          ],
          [
            {
              content: "Código do processo",
              label: "Código do processo",
              size: 12,
            },
          ],
          [
            {
              content: "Arquivo",
              label: "Arquivo",
              size: 12,
            },
          ],
          [
            {
              content: "Mensagem",
              label: "Mensagem",
              size: 12,
            },
          ],
          [
            {
              content: "Data de realização",
              label: "Data de realização",
              size: 12,
            },
          ],
          [
            {
              content: "Data de inicio do processo",
              label: "Data de inicio do processo",
              size: 12,
            },
          ],
          [
            {
              content: "Tipo",
              label: "Tipo",
              size: 12,
            },
          ],
        ]}
      />
    </div>
  );
}
