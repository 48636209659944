import { Box, CircularProgress } from "@material-ui/core";

interface LoadingCircularProps {
  size?: number;
  additionalProps?: any;
  title?: string;
}

export default function LoadingCircular({
  size = 40,
  additionalProps,
  title,
}: LoadingCircularProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gridGap={16}
      my={5}
    >
      <CircularProgress size={size} {...additionalProps} />
      {title && <span>{title}</span>}
    </Box>
  );
}
