import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import { format as formatTZ } from "date-fns-tz";
import { convertDateToUTC } from "../../hooks/format";

interface IInputDate {
  name: string;
  control: any;
  rules?: any;
  error?: any;
  label: string;
  onChangeItem?: (value: any) => any;
  variant?: "inline" | "static" | "dialog";
  disabled?: boolean;
  inputPropsAdditional?: any;
  defaultValue?: any;
  setValueAs?: any;
  invalidDateMessage?: string;
}

interface InputDateProps {
  name?: string;
  control: any;
  rules?: any;
  label: string;
  disabled?: boolean;
  onChangeItem?: (data: string | Date) => void;
  error?: any;
  invalidDateMessage?: string | null;
  onDoubleClick?: any;
  small?: boolean;
  defaultValue?: any;
  setValueAs?: (data: Date | unknown) => string | Date;
  inputPropsAdditional?: { style?: any };
}

export default function InputDate({
  name,
  control,
  error,
  label,
  rules,
  onChangeItem,
  disabled = false,
  inputPropsAdditional = {},
  invalidDateMessage = "Data inválida",
  small = false,
  setValueAs = (value: Date) => {
    if (value && isValid(new Date(value))) {
      return formatTZ(convertDateToUTC(new Date(value)), "yyyy-MM-dd", {
        timeZone: "America/Sao_Paulo",
      });
    }
    return null;
  },
  defaultValue,
  ...rest
}: InputDateProps) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Controller
        defaultValue={defaultValue}
        render={({ onChange, value, ref }) => {
          const formatValue = value
            ? formatTZ(
                convertDateToUTC(new Date(value)),
                "yyyy-MM-dd'T'HH:mm:ss",
                {
                  timeZone: "America/Sao_Paulo",
                }
              )
            : null;
          return (
            <KeyboardDatePicker
              style={{
                width: small ? "175px" : "100%",
                ...inputPropsAdditional?.style,
              }}
              InputLabelProps={{ shrink: true }}
              inputRef={ref}
              fullWidth
              error={error}
              onChange={(date) => {
                if (date) {
                  if (isValid(date)) {
                    onChange(format(date, "yyyy-MM-dd'T'HH:mm:ss'-0300'"));
                    if (onChangeItem) {
                      onChangeItem(
                        format(date, "yyyy-MM-dd'T'HH:mm:ss'-0300'")
                      );
                    }
                  }
                } else {
                  onChange(null);
                  if (onChangeItem) onChangeItem(null);
                }
              }}
              disabled={disabled}
              value={formatValue}
              invalidDateMessage={invalidDateMessage}
              inputVariant="outlined"
              variant="dialog"
              format="dd/MM/yyyy"
              label={label}
              helperText={error && <span>{error.message}</span>}
              {...rest}
            />
          );
        }}
        rules={{
          setValueAs,
          ...rules,
        }}
        control={control}
        name={name}
        placeholder={label}
      />
    </MuiPickersUtilsProvider>
  );
}

export function InputDateAgend({
  name,
  control,
  label,
  rules,
  variant,
}: IInputDate) {
  function disableSundays(date: Date) {
    if (date.getDay() === 0 || date.getDay() === 6) return true;
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Controller
        render={({ onChange, value }) => (
          <DatePicker
            value={value ? value : null}
            fullWidth
            onChange={(date: Date) => {
              if (date) {
                onChange(date);
              } else {
                onChange(null);
              }
            }}
            minDate={new Date().setDate(new Date().getDate())}
            shouldDisableDate={disableSundays}
            disablePast={true}
            autoOk={true}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            invalidDateMessage="Data inválida"
            defaultValue={null}
            animateYearScrolling
            label={label}
            variant={variant ?? "dialog"}
          />
        )}
        rules={rules}
        control={control}
        name={name}
        placeholder={label}
        autoClose={true}
      />
    </MuiPickersUtilsProvider>
  );
}

export function InputDateNasc({
  name,
  control,
  error,
  label,
  rules,
}: IInputDate) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Controller
        render={({ onChange, value }) => (
          <KeyboardDatePicker
            fullWidth
            onClose={() => true}
            onChange={(date) => {
              if (date) {
                onChange(date);
              } else {
                onChange(null);
              }
            }}
            InputLabelProps={{ shrink: true }}
            value={value}
            defaultValue={null}
            error={error}
            helperText={error?.message}
            invalidDateMessage="Data inválida"
            inputVariant="outlined"
            variant="inline"
            format="dd/MM/yyyy"
            label={label}
          />
        )}
        rules={{
          validate: {
            validDate: (dateString) => {
              if (!dateString) return "Campo Obrigatório";
              var today = new Date();
              var birthDate = new Date(dateString);
              var age = today.getFullYear() - birthDate.getFullYear();
              var m = today.getMonth() - birthDate.getMonth();
              if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
              }
              return age >= 14 && age < 95
                ? undefined
                : "Nascimento deve ser maior que 14 anos e menor que 90 anos.";
            },
          },
        }}
        control={control}
        name={name}
        placeholder={label}
        autoClose={true}
      />
    </MuiPickersUtilsProvider>
  );
}

export function InputTime({
  name,
  control,
  label,
  rules,
  invalidDateMessage = "Horario inválido",
  disabled = false,
  error,
  defaultValue = null,
  setValueAs = (value: any) => {
    if (value && isValid(new Date(value))) {
      return formatTZ(new Date(value), "HH:mm", {
        timeZone: "America/Sao_Paulo",
      });
    }
    return null;
  },
}: IInputDate) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Controller
        defaultValue={defaultValue}
        render={({ onChange, value, ref }) => (
          <KeyboardTimePicker
            inputRef={ref}
            disabled={disabled}
            fullWidth
            autoOk
            error={error}
            ref={ref}
            onChange={(date) => {
              if (date) {
                if (isValid(date)) {
                  onChange(
                    format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss'-0300'")
                  );
                } else {
                  onChange(date);
                }
              } else {
                onChange(null);
              }
            }}
            value={value && new Date(value)}
            ampm={false}
            invalidDateMessage={invalidDateMessage}
            inputVariant="outlined"
            variant="inline"
            label={label}
            InputLabelProps={{ shrink: true }}
            helperText={error && <span>{error.message}</span>}
          />
        )}
        rules={{
          setValueAs,
          ...rules,
        }}
        control={control}
        name={name || ""}
        placeholder={label}
      />
    </MuiPickersUtilsProvider>
  );
}
