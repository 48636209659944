import { createContext, useCallback, useContext, useState } from "react";

type CredentialsType = {
  GERALE?: boolean;
  AGENDAMENTO?: boolean;
  ESOCIAL?: boolean;
  FINANCEIRO?: boolean;
  SOLICITACOES?: boolean;
  RELATORIO?: boolean;
};

type AccessContextType = {
  credentials: CredentialsType;
  setCredentials: (credentials: CredentialsType) => void;
  abasAccess: any;
  setAbasAccess: any;
};

const AccessContext = createContext({} as AccessContextType);

export const useAccess = () => useContext(AccessContext);

export const AccessProvider = ({ children }) => {
  const [credentials, setCredentialsData] = useState({
    GERALE: false,
    AGENDAMENTO: false,
    ESOCIAL: false,
    FINANCEIRO: false,
    SOLICITACOES: false,
    RELATORIO: false,
  });

  const [abasAccess, setAbasAccessData] = useState({});

  const setAbasAccess = useCallback((newAccessData: any) => {
    setAbasAccessData((access) => ({ ...access, ...newAccessData }));
  }, []);

  const setCredentials = useCallback(
    (credentials: Partial<CredentialsType>) => {
      setCredentialsData((_) => ({ ..._, ...credentials }));
    },
    []
  );

  return (
    <AccessContext.Provider
      value={{
        credentials,
        setCredentials,
        abasAccess,
        setAbasAccess,
      }}
    >
      {children}
    </AccessContext.Provider>
  );
};
