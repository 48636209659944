import { combineReducers } from "redux";

import dateReducer from "./dateReducer";
import eSocialReducer, { eSocialModel } from "./eSocialReducer";
import portalReducer, { PortalModel } from "./portalReducer";
import providerReducer, { ProviderDomain } from "./providerReducer";
import scheduleReducer, { ScheduleEventDomain, Solicitation } from "./scheduleReducer";
import funcionarioReducer from "./funcReducer";

export interface RootModel {
  funcionario: any;
  provider: ProviderDomain | null;
  date: string;
  schedule: ScheduleEventDomain[] | null;
  portal: PortalModel | null;
  eSocial: eSocialModel | null;
  solicitations: Solicitation[] | any;
}

export default combineReducers<RootModel>({
  funcionario: funcionarioReducer,
  provider: providerReducer,
  date: dateReducer,
  schedule: scheduleReducer,
  portal: portalReducer,
  eSocial: eSocialReducer,
  solicitations: scheduleReducer,
});
