import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

export function AutoCompleteLabelHTMLRequired({
  control,
  name,
  label,
  error,
  rules = {},
  disabled,
  changed,
  multiple,
  defOptions = [],
  startAuto = 0,
  optionsOutside,
  data,
  defValue,
  onChangeItem,
  onClickItem,
  update,
}: any) {
  const [options, setOptions] = useState<any>(
    data && defValue && name
      ? [
          {
            label: data[defValue],
            code: data[name],
          },
        ]
      : defOptions
  );

  useEffect(() => {
    if (changed) changed(setOptions, "", setLoading, update);
  }, [update]);

  const [loading, setLoading] = useState(false);

  const changeValue = (newValue: string) => {};

  return (
    <Controller
      render={({ onChange, value, ref }) => (
        <Autocomplete
          value={options.find((item) => item.code == value)}
          onInputChange={(_, newInputValue) => {
            if (changed && newInputValue.length >= startAuto) {
              changeValue(newInputValue);
            }
          }}
          onClick={onClickItem}
          id={name}
          multiple={multiple}
          loading={loading}
          options={optionsOutside || options}
          disabled={disabled}
          getOptionLabel={(option: any) =>
            option?.code ? `${option.label}` : ""
          }
          renderOption={(option: any) => (
            <span>{option.labelHtml ?? option.label}</span>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={error}
              autoComplete="off"
              inputRef={ref}
              helperText={error && <span>{error.message}</span>}
            />
          )}
          onChange={(_, newValue) => {
            if (newValue) {
              if (multiple && Array.isArray(newValue)) {
                onChange(newValue.map((item) => item.code));
                if (onChangeItem)
                  onChangeItem(newValue.map((item) => ({ ...item })));
              } else if (newValue.code as { label: string; code: any }) {
                onChange(newValue.code);
                if (onChangeItem)
                  onChangeItem(
                    newValue.code2 || newValue.code2 == 0
                      ? newValue.code2
                      : newValue.code
                  );
              }
            }
          }}
        />
      )}
      onChange={([, data]) => {
        return data ? data.code : "";
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
}

export default function AutoCompleteDef({
  control,
  name,
  label,
  error,
  rules = {},
  disabled,
  changed,
  multiple,
  style,
  defOptions = [],
  startAuto = 0,
  optionsOutside,
  data,
  defValue,
  onChangeItem,
  onClickItem,
  update,
  defaultValue,
  limit = null,
}: any) {
  const [options, setOptions] = useState<any>(
    data && defValue && name
      ? [
          {
            label: data[defValue],
            code: data[name],
          },
        ]
      : defOptions
  );

  useEffect(() => {
    if (changed) changed(setOptions, "", setLoading, update);
  }, [update]);

  const [loading, setLoading] = useState(false);

  const changeValue = (newValue: string) => {};

  return (
    <Controller
      render={({ onChange, value, ref, name }) => {
        const result =
          multiple && value
            ? options
                .filter((item: { label: string; code: number | string }) =>
                  value.includes(item.code)
                )
                .sort((itemOne, itemTwo) => {
                  if (
                    value.indexOf(itemOne.code) < value.indexOf(itemTwo.code)
                  ) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
            : options.find((item: { label: string; code: number | string }) => {
                if (value) {
                  return item.code == value;
                }
                if (!value && defaultValue) {
                  onChange(defaultValue);
                }
                return item.code == defaultValue;
              });

        // onChange
        return (
          <Autocomplete
            value={result}
            style={style}
            onInputChange={(_, newInputValue) => {
              if (changed && newInputValue.length >= startAuto) {
                changeValue(newInputValue);
              }
            }}
            onClick={onClickItem}
            id={name}
            multiple={multiple}
            loading={loading}
            options={optionsOutside || options}
            disabled={disabled}
            getOptionLabel={(option: any) => `${option.label} - ${option.code}`}
            renderOption={(option: any) => (
              <span>{option.labelHtml ?? option.label}</span>
            )}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  id={`${name}-id`}
                  label={label}
                  variant="outlined"
                  error={error}
                  autoComplete="off"
                  inputRef={ref}
                  helperText={error && <span>{error.message}</span>}
                />
              );
            }}
            onChange={(_, newValue, reason) => {
              if (reason === "clear") {
                onChange(null);
                if (onChangeItem) {
                  onChangeItem(null);
                }
              }

              if (newValue) {
                if (multiple && Array.isArray(newValue)) {
                  if (limit !== null && newValue.length > limit) {
                    newValue.pop();
                  } else {
                    onChange(newValue.map((item) => item.code));
                  }
                  if (onChangeItem)
                    onChangeItem(newValue.map((item) => ({ ...item })));
                } else if (newValue.code as { label: string; code: any }) {
                  onChange(newValue.code);
                  if (onChangeItem)
                    onChangeItem(
                      newValue.code2 || newValue.code2 == 0
                        ? newValue.code2
                        : newValue.code,
                      newValue.code3
                    );
                }
              }
            }}
          />
        );
      }}
      onChange={([, data]) => {
        return data ? data.code : "";
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
}

export function AutoCompleteUpdate({
  control,
  name,
  label,
  error,
  rules = {},
  disabled,
  changed,
  multiple,
  defOptions = [],
  startAuto = 0,
  optionsOutside,
  data,
  defValue,
  onChangeItem,
  onClickItem,
  update,
  notSetOptions = false,
  opt,
  upgrade,
}: any) {
  const [options, setOptions] = useState<any>(
    data && defValue && name
      ? [
          {
            label: data[defValue],
            code: data[name],
          },
        ]
      : defOptions
  );

  useEffect(() => {
    if (notSetOptions && !disabled) {
      opt(setOptions);
      if (upgrade) upgrade(setOptions);
    }
  }, [disabled]);

  useEffect(() => {
    if (changed) changed(setOptions, "", setLoading, update);
  }, [update]);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeValue = (newValue: string) => {};

  return (
    <Controller
      render={({ onChange, value }) => (
        <Autocomplete
          value={options.find((item) => item.code == value)}
          onInputChange={(_, newInputValue) => {
            if (changed && newInputValue.length >= startAuto) {
              changeValue(newInputValue);
            }
          }}
          onClick={onClickItem}
          multiple={multiple}
          loading={loading}
          options={optionsOutside || options}
          disabled={disabled}
          getOptionLabel={(option: any) => `${option.label} - ${option.code}`}
          renderOption={(option: any) => (
            <span>{option.labelHtml ?? option.label}</span>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={error}
              autoComplete="off"
              helperText={error && <span>{error.message}</span>}
            />
          )}
          onChange={(_, newValue) => {
            if (newValue) {
              if (multiple && Array.isArray(newValue)) {
                onChange(newValue.map((item) => item.code));
                if (onChangeItem)
                  onChangeItem(newValue.map((item) => ({ ...item })));
              } else if (newValue.code as { label: string; code: any }) {
                onChange(newValue.code);
                if (onChangeItem)
                  onChangeItem(
                    newValue.code2 || newValue.code2 == 0
                      ? newValue.code2
                      : newValue.code
                  );
              }
            }
          }}
        />
      )}
      onChange={([, data]) => {
        return data ? data.code : "";
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
}

export function AutoCompleteDemanda({
  control,
  name,
  label,
  error,
  rules = {},
  disabled,
  changed,
  multiple,
  defOptions = [],
  startAuto = 0,
  optionsOutside,
  data,
  defValue,
  onChangeItem,
  update,
  setValue,
}: any) {
  const [options, setOptions] = useState<any>(
    data && defValue && name
      ? [
          {
            label: data[name],
            code: {
              EMPCODIGO: data[defValue.EMPCODIGO],
              EMPORIGEM: data[defValue.EMPORIGEM],
            },
          },
        ]
      : defOptions
  );

  const [firstValue, setFirstValue] = useState({ label: "", code: {} });
  const [firstExecute, setFirstExecute] = useState(null);

  useEffect(() => {
    if (data)
      setFirstExecute(
        options.find(
          (item) =>
            item.code.EMPCODIGO === data.EMPCODIGO &&
            item.code.EMPORIGEM === data.CLIENTE.EMPORIGEM
        )
      );
  });

  useEffect(() => {
    if (data) {
      setFirstValue(
        options.find(
          (item) =>
            item.code.EMPCODIGO === data.EMPCODIGO &&
            item.code.EMPORIGEM === data.CLIENTE.EMPORIGEM
        )
      );
      return;
    }
    return;
  }, [firstExecute]);

  useEffect(() => {
    if (changed) changed(setOptions, "", setLoading, update);
  }, [update]);
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState<any>(multiple ? [] : {});

  useEffect(() => {
    // if (valeuDef) setValueInput(valeuDef);
    if (onChangeItem) onChangeItem(defValue);

    if (defValue && options.length)
      setValueInput(options.find((item) => item.code == defValue));
  }, [options, defValue]);

  useEffect(() => {
    if (onChangeItem) onChangeItem(valueInput);
  }, [valueInput]);

  useEffect(() => {
    changeInfo();
  }, [valueInput]);

  const changeInfo = () => {
    let value = null;
    if (!valueInput) return;

    if (multiple && Array.isArray(valueInput)) {
      value = valueInput.map((item) => item.code);
    } else if (valueInput.code as { label: string; code: any }) {
      value = valueInput.code;
    }

    setValue(name, value, {
      shouldValidate: true,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeValue = (newValue) => {};

  return (
    <Controller
      render={({ onChange }) => (
        <Autocomplete
          value={firstValue}
          onInputChange={(_, newInputValue) => {
            if (changed && newInputValue.length >= startAuto) {
              changeValue(newInputValue);
            }
          }}
          id={name}
          multiple={multiple}
          loading={loading}
          options={optionsOutside || options}
          disabled={disabled}
          getOptionLabel={(option: any) => `${option.label}`}
          renderOption={(option: any) => (
            <span>{option.labelHtml ?? option.label2}</span>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={error}
              autoComplete="off"
              helperText={error && <span>{error.message}</span>}
            />
          )}
          onChange={(_, newValue) => {
            setFirstValue(newValue);
            onChange(newValue.code);
            const changeValue = onChangeItem;
            changeValue(newValue);
          }}
        />
      )}
      onChange={([, data]) => {
        return data ? data.code : "";
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
}

export function AutoCompleteNew({
  label,
  multiple = false,
  options,
  name,
  setValue,
  error,
  control,
  valeuDef,
  onChange,
  variant = "outlined",
  disabled = false,
  onClickItem,
  inputValue,
  onInputChange,
  rules = {},
}: {
  label: string;
  multiple?: boolean;
  options: any;
  name: any;
  rules?: any;
  setValue: any;
  error: any;
  control: any;
  valeuDef?: any;
  onChange?: any;
  variant?: "filled" | "standard" | "outlined";
  disabled?: boolean;
  onClickItem?: any;
  inputValue?: any;
  onInputChange?: (event: any, value) => void;
}) {
  const [valueInput, setValueInput] = useState<any>(multiple ? [] : {});
  let additionalProps: {
    inputValue?: any;
    onInputChange?: (event: any, value) => void;
    onClose?: any;
  } = {};
  if (onInputChange) {
    additionalProps = {
      ...additionalProps,
      inputValue,
      onInputChange,
      onClose: (_, reason) => {
        if (!inputValue && reason === "blur") onInputChange(null, "");
      },
    };
  }

  useEffect(() => {
    if (onChange) onChange(valeuDef);
    if (valeuDef && options.length)
      setValueInput(options.find((item) => item.code == valeuDef));
  }, [options, valeuDef]);

  useEffect(() => {
    if (onChange) onChange(valueInput);
  }, [valueInput]);

  useEffect(() => {
    changeInfo();
  }, [valueInput]);

  const changeInfo = () => {
    let value = null;
    if (!valueInput) return;

    if (multiple && Array.isArray(valueInput)) {
      value = valueInput.map((item) => item.code);
    } else if (valueInput.code as { label: string; code: any }) {
      value = valueInput.code;
    }

    setValue(name, value, {
      shouldValidate: false,
    });
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ value }) => {
          return <input type="hidden" value={value} />;
        }}
        rules={rules}
      />
      <Autocomplete
        {...additionalProps}
        disabled={disabled}
        value={valueInput}
        options={options}
        multiple={multiple}
        onClick={onClickItem}
        getOptionLabel={(option: any) =>
          option?.code ? `${option.label}` : ""
        }
        renderOption={(option: any) => (
          <span>{option.labelHtml ?? option.label}</span>
        )}
        onChange={(event: any, newValue: any) => {
          setValueInput(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={error}
              variant={variant}
              label={label}
              helperText={error && <span>{error.message}</span>}
            />
          );
        }}
      />
    </>
  );
}

export function AutoCompleteFreeSolo({
  label,
  options,
  name,
  error,
  control,
  variant = "outlined",
}: {
  label: string;
  options: any;
  name: any;
  error: any;
  control: any;
  rules?: any;
  onChange?: any;
  variant?: "filled" | "standard" | "outlined";
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <Autocomplete
          freeSolo
          fullWidth
          options={options}
          getOptionLabel={(option: any) =>
            option?.code ? `${option.label} - ${option.code}` : ""
          }
          renderOption={(option: any) => (
            <span>{option.labelHtml ?? option.label}</span>
          )}
          inputValue={value ? value : []}
          onInputChange={(_, value) => {
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              value="teste"
              error={error}
              variant={variant}
              name={name}
              label={label}
            />
          )}
        />
      )}
    />
  );
}

export function AutoCompleteFreeSoloNew({
  label,
  options,
  name,
  error,
  control,
  onChange,
  rules = {},
  variant = "outlined",
}: {
  label: string;
  options: any;
  name: any;
  error: any;
  control: any;
  rules?: any;
  onChange?: any;
  variant?: "filled" | "standard" | "outlined";
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <Autocomplete
          freeSolo
          fullWidth
          options={options}
          getOptionLabel={(option: any) => {
            if (typeof option === "string") {
              return option;
            }
            return `${option.label.toString()}`;
          }}
          renderOption={(option: any) => <span>{option.label}</span>}
          inputValue={value ? value : ""}
          onInputChange={(_, value) => {
            onChange(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              value="teste"
              error={error}
              variant={variant}
              name={name}
              label={label}
            />
          )}
        />
      )}
    />
  );
}

export function AutoCompleteOutside({
  label,
  multiple,
  options,
  valeuDef,
  onChange,
  variant = "outlined",
}: {
  label: string;
  multiple: boolean;
  options: any;
  valeuDef?: any;
  onChange?: any;
  variant?: "filled" | "standard" | "outlined";
}) {
  const [valueInput, setValueInput] = useState<any>(multiple ? [] : {});

  useEffect(() => {
    if (onChange) onChange(valeuDef);

    if (valeuDef && options.length)
      setValueInput(options.find((item) => item.code == valeuDef));
  }, [options, valeuDef]);

  useEffect(() => {
    if (onChange) onChange(valueInput);
  }, [valueInput]);

  useEffect(() => {
    changeInfo();
  }, [valueInput]);

  const changeInfo = () => {
    let value = null;
    if (!valueInput) return;
    if (multiple && Array.isArray(valueInput)) {
      value = valueInput.map((item) => item.code);
    } else if (valueInput.code as { label: string; code: any }) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      value = valueInput.code;
    }
  };
  return (
    <Autocomplete
      value={valueInput}
      options={options}
      multiple={multiple}
      getOptionLabel={(option: any) =>
        option?.code ? `${option.label} - ${option.code}` : ""
      }
      renderOption={(option: any) => (
        <span>{option.labelHtml ?? option.label}</span>
      )}
      onChange={(event: any, newValue: any) => {
        setValueInput(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} variant={variant} label={label} />
      )}
    />
  );
}
