import { MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { api } from "../../shared/services/api";

export interface SetorModel {
  POSNOME: string;
  SETCODIGO: number;
  SETNOME: string;
}

export const useSetores = () => {
  const [setores, setSetores] = useState<JSX.Element[] | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get<SetorModel[]>(`/agenda/setores`);
        const opts = res.data.map((item, idx) => {
          return (
            <MenuItem key={idx} value={item.SETCODIGO}>
              {item.POSNOME} \ {item.SETNOME}
            </MenuItem>
          );
        });
        setSetores(opts);
      } catch (err) {}
    };
    fetch();
  }, []);

  return { setores };
};
