import { useState } from "react";
import moment from "moment";

import AutoCompleteDef from "../../../shared/Form/Fields/AutoComplete";
import DependsOn from "../../../shared/HOC/DependsOn";
import { useFetch } from "../../../shared/services/useFetch";

import FormBody from "../../../shared/Form/Body";
import InputDate, { InputTime } from "../../../shared/Form/Fields/Date";
import { MenuItem } from "@material-ui/core";

interface IAtestado {
  ATSCODIGO: number;
  FUNCODIGO: number;
  ATSCID: string;
  ATSMEDICO: string;
  ATSCRM: string;
  ATSDATA: string;
  ATSDESCRICAO: string | null;
  ASSINA: null | string;
  ATSCODCNES: null;
  ATSHORA: string;
  ATSINTERNACAO: null;
  ATSAFASTAMENTO: number | null;
  DNLCODIGO: number | null;
  ATSOBSERVACAO: string | null;
  ATSDIAGPROVAVEL: null;
  ATSUFCRM: string | null;
  ATSTIPOAFASTAMENTO: number | null;
  ATSHORASAFASTADO: string | null;
  ATSDIAAFASTDO: number | null;
}

interface ITipoCAT {
  CLICODIGO: number | null;
  TPCCODIGO: number;
  TPCCODTABELA: number;
  TPCDESCRICAO: string | null;
}

interface ITipoAcidente {
  CATCODIGO: number;
  CLICODIGO: number | null;
  CATCODTABELA: string | null;
  CATDESCRICAO: string | null;
  ASSINA: string | null;
}

interface ISituacao {
  SGDCODIGO: number;
  CLICODIGO: number | null;
  SGDCODTABELA: string | null;
  SGDDESCRICAO: string | null;
  ASSINA: string | null;
}

interface IIniciativasCAT {
  INCCODIGO: number;
  INCCODTABELA: number;
  INCDESCRICAO: string;
}

export default function Step({ id }: { id: string }) {
  const [obito, setObito] = useState<boolean>(false);
  const [obrigatorioUltDiaTrabalhado, setObrigatorioUltDiaTrabalhado] =
    useState<boolean>(false);

  const { data: atestados } = useFetch<IAtestado[]>(
    `/clinico/funcionarios/${id}/atestados`
  );
  const { data: tiposAcidentes } = useFetch<ITipoAcidente[]>(
    `/cadastro/tipos-acidentes`
  );
  const { data: tiposCAT } = useFetch<ITipoCAT[]>("/cadastro/tipos-cat");
  const { data: situacoesGeradoras } = useFetch<ISituacao[]>(
    "/cadastro/situacoes-geradoras"
  );
  const { data: iniciativas } = useFetch<IIniciativasCAT[]>(
    "/cadastro/iniciativas-cat"
  );

  const onChangeObito = (value: boolean) => setObito(value);

  return (
    <DependsOn
      dependencies={[
        atestados,
        tiposAcidentes,
        tiposCAT,
        situacoesGeradoras,
        iniciativas,
      ]}
      Component={
        <FormBody
          spacing={10}
          schema={[
            {
              content: [
                {
                  name: "CATDATA",
                  lg: 2,
                  customComponent: ({ control, name, error }) => (
                    <InputDate
                      control={control}
                      label="Data do acidente"
                      name={name}
                      error={error}
                      defaultValue={null}
                      setValueAs={null}
                      rules={{ required: "Campo Obrigatório" }}
                      onChangeItem={(value) => {
                        setObrigatorioUltDiaTrabalhado(
                          moment(value).isAfter("2023-01-16")
                        );
                      }}
                    />
                  ),
                },
                {
                  name: "CATHORA",
                  lg: 2,
                  customComponent: ({ control, name, error }) => (
                    <InputTime
                      control={control}
                      label="Hora do acidente"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      error={error}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  name: "CATHORASTRABANTES",
                  lg: 2,
                  customComponent: ({ control, name, error }) => (
                    <InputTime
                      control={control}
                      label="Horas trabalhadas antes"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      error={error}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  name: "CATULTDIATRABALHADO",
                  lg: 2,
                  customDefValue: null,
                  customComponent: ({ control, name, error }) => (
                    <InputDate
                      control={control}
                      label="Último dia trabalhado"
                      name={name}
                      error={error}
                      setValueAs={null}
                      defaultValue={null}
                      rules={{
                        ...(obrigatorioUltDiaTrabalhado && {
                          required:
                            "Necessário preencher caso a data do acidente seja após o dia 16/01/2023",
                        }),
                      }}
                    />
                  ),
                },
                {
                  label: "Tipo de CAT",
                  name: "TPCCODIGO",
                  lg: 4,
                  type: "select",
                  options: tiposCAT?.map((tipoCAT) => (
                    <MenuItem value={tipoCAT.TPCCODIGO}>
                      {tipoCAT.TPCDESCRICAO}
                    </MenuItem>
                  )),
                },
                {
                  name: "CATTPACID",
                  lg: 3,
                  customComponent: ({ control, error, name }) => (
                    <AutoCompleteDef
                      control={control}
                      name={name}
                      label="Tipo de acidente"
                      defValue="CATTPACID"
                      error={error}
                      disabled={false}
                      defOptions={tiposAcidentes?.map((tipo) => ({
                        label: tipo.CATDESCRICAO,
                        code: tipo.CATCODIGO,
                      }))}
                    />
                  ),
                },
                {
                  label: "Houve óbito",
                  name: "CATOBITO",
                  lg: 3,
                  type: "switch",
                  onChange: onChangeObito,
                },
                {
                  name: "CATDATAOBITO",
                  lg: 2,
                  customComponent: ({ control, name, error }) => (
                    <InputDate
                      control={control}
                      label="Data do óbito"
                      name={name}
                      disabled={!obito}
                      defaultValue={null}
                      setValueAs={null}
                      error={error}
                      rules={{
                        ...(obito && {
                          required: "Se houve óbito a data deve ser preenchida",
                        }),
                      }}
                    />
                  ),
                },
                {
                  label: "Houve comunicação a polícia",
                  name: "CATCOMUNPOLICIA",
                  lg: 3,
                  type: "switch",
                },
                {
                  name: "SGACODIGO",
                  lg: 12,
                  customComponent: ({ control, error, name }) => (
                    <AutoCompleteDef
                      control={control}
                      name={name}
                      label="Situação geradora"
                      defValue="SGACODIGO"
                      error={error}
                      disabled={false}
                      defOptions={situacoesGeradoras?.map((situacao) => ({
                        label: situacao.SGDDESCRICAO,
                        code: situacao.SGDCODIGO,
                      }))}
                    />
                  ),
                },
                {
                  label: "Emitida por",
                  name: "INCCODIGO",
                  lg: 4,
                  type: "select",
                  options: iniciativas?.map((iniciativa) => (
                    <MenuItem value={iniciativa.INCCODIGO}>
                      {iniciativa.INCDESCRICAO}
                    </MenuItem>
                  )),
                },
                {
                  name: "ATSCODIGO",
                  lg: 8,
                  customComponent: ({ control, error, name }) => (
                    <AutoCompleteDef
                      control={control}
                      label="Atestado médico"
                      name={name}
                      error={error}
                      disabled={false}
                      defOptions={atestados?.map((atestado) => ({
                        label: moment(atestado.ATSDATA).format("DD/MM/YYYY"),
                        code: atestado.ATSCODIGO,
                      }))}
                      rules={{ required: "Campo obrigatório" }}
                    />
                  ),
                },
                {
                  label: "Observação",
                  name: "CATHISTORICO",
                  inputPropsAdittional: { multiline: true, rows: 4 },
                  lg: 12,
                },
              ],
            },
          ]}
        />
      }
    />
  );
}
