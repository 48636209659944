import React, { createContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 50,
    background: `linear-gradient(90deg, ${theme.palette.primary} 0%, ${theme.palette.secondary} 41%)`,
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: 50,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    height: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  tooltip: {
    marginTop: "-.01rem",
    fontSize: "1.5rem",
    color: "red",
  },
}));

const alertData: any = {
  status: false,
  message: "",
  severity: undefined,
};

export const AlertContext = createContext(alertData);

function DefaultLayout({ children }: any) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      <div className={classes.root}>
        <main
          className={classes.content}
          style={{
            marginTop: "100px",
            marginLeft: matches ? "300px" : "0px",
          }}
        >
          <AlertContext.Provider value={alertData}>
            {children}
          </AlertContext.Provider>
        </main>
      </div>
    </>
  );
}

export default React.memo(DefaultLayout);
