import { Grid, IconButton, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function HeaderWithBack({
  linkBack,
  title,
  state,
}: {
  linkBack: string;
  title: string;
  state?: any;
}) {
  const history = useHistory();
  const loc = useLocation().state;

  const redirectBack = () => {
    history.push({ pathname: linkBack, state: loc });
  };

  return (
    <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} lg={12}>
      <Grid container direction="row" alignItems="center">
        <IconButton onClick={redirectBack} size="medium" color="primary">
          <ArrowBackIcon />
        </IconButton>
        <Typography
          style={{ margin: "0px 0px 0px 10px", padding: 0 }}
          variant="h6"
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
