import React, { useEffect, useState } from "react";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Box, Backdrop, Modal, Fade } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setDate } from "../../redux/reducers/dateReducer";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useLayout } from "../../hooks/auth";
import {
  ProviderDomain,
  ServiceProviderAvailabilityDomain,
} from "../../redux/reducers/providerReducer";
import { api } from "../../shared/services/api";
import { RootModel } from "../../redux/reducers";
import { formatDate } from "../../utils/formats";
import { setProvider } from "../../redux/actions/providerActions";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { ContainedLoadingButton, OutlinedLoadingButton } from "../../shared/Button/LoadingButton";

const useStyles = makeStyles({
  root: {
    width: "100%",
    minWidth: 275,
    maxWidth: 400,
    display: "flex",
    margin: "0 auto",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  address: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  inlineBlock: {
    display: "inline-block",
  },
});

const validateTypeOfString = (field) => {
  if (validateData(field)) {
    return typeof field === "string";
  }
  return false;
};

const validateData = (field) => {
  if (field) return true;
  return false;
};

const toUpperCase = (string) => {
  return validateTypeOfString(string) ? string.toUpperCase() : "";
};

const useStylesModal = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    gap: "21px",
    width: "535px",
    outline: "none",
    display: "flex",
    padding: "21px",
    flexDirection: "column",
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Prestador(props) {

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStylesModal();
  const {
    validCPF,
    isClinico,
    setIsComplementar,
    setIsClinico,
    previousSchedule,
  } = useStatePersistent();
  const { date, portal } = useSelector<RootModel, RootModel>((state) => state);

  const [providers, setProviders] = useState([]);
  const [erro, setErro] = useState<null | string>(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openWarning, setOpenWarning] = useState(null);
  const [filterProviders, setFilterProviders] = useState([]);
  const [loading, setLoading] = useState<null | boolean>(true);
  const [textFilterValue, setTextFilterValue] = useState<string>("");
  const [proceed, setProceed] = useState(false)
  const [dateItem, setDateItem] = useState(null)
  
  useEffect(() => {
    if (proceed) {
        setOpenWarning(false);
        selectItem(selectedItem);
    }
  },[proceed])

  useEffect(() => {
    console.log(previousSchedule)
  },[previousSchedule])

  useEffect(() => {
    async function getPrestadores() {
      const req = await api();
      await req
        .get<ProviderDomain[]>(
          `/agenda/prestadores`,
          {
            params: {
              DATAAGENDA: moment(date).format("YYYY-MM-DD"),
              "visualiza-complementares": validCPF?.EXAMES_COMPLEMENTARES && !isClinico ? 1 : 0
            }
          }
        )
        .then((response) => {
          setProviders(response.data);
          setFilterProviders(response.data);
          setErro(null);
          setLoading(false);
        })
        .catch((err) => {
          setErro(err.response.data.message);
        });
    }
    getPrestadores();
  }, [date, isClinico, validCPF?.EXAMES_COMPLEMENTARES]);

  const selectItem = (item, value?: string) => {
    if (!proceed) {
        if (validCPF?.EXAMES_COMPLEMENTARES && !previousSchedule) {
          if (item?.PROCOMPLEMENTAR !== 1) {
            setOpenWarning(true);
            setSelectedItem(item);
            return;
          }
          setIsClinico(item?.PROCOMPLEMENTAR !== 1 ? true : false);
          setIsComplementar(item?.PROCOMPLEMENTAR === 1 ? true : false);
        }
    }
    if (value) {
          const [dia, mes, ano] = value.split("/");
          dispatch(setDate(`${mes}/${dia}/${ano}`));
    }
    else if (dateItem) {
        const [dia, mes, ano] = dateItem.split("/");
        dispatch(setDate(`${mes}/${dia}/${ano}`));
    }
    dispatch(setProvider({ ...item, Issuer: portal?.companyUserName }));
    history.push("/pages/funcionario");
  };

  if (erro) return <div>{erro}</div>;

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  function containsSubstring(text, substring) {
    return text.toUpperCase().includes(substring.toUpperCase());
  }

  const handleChangeTextFilter = (event) => {
    const value = event.target.value;
    const textNormalize = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    setTextFilterValue(value);
    const filterProviders = providers.filter((provider) => {
      return (
        containsSubstring(provider.PRONOME, textNormalize) ||
        containsSubstring(provider.PROCIDADE, textNormalize)
      );
    });
    setFilterProviders(filterProviders);
  };

  return (
    <Box>
      <Typography variant="h6">
        Agendamento: {moment(date).format("DD/MM/YYYY")}
      </Typography>
      <div style={{ marginTop: "15px" }}>
        <TextField
          inputProps={{ spellCheck: "false" }}
          fullWidth
          id="outlined-basic"
          label="Busque por nome ou cidade"
          variant="outlined"
          type="search"
          value={textFilterValue}
          onChange={handleChangeTextFilter}
          autoComplete="off"
        />
      </div>
      <br />
      <Grid container spacing={3} alignItems="stretch">
        {filterProviders.length > 0 ? (
          filterProviders.map((item, index) => (
            <Grid
              item
              style={{ display: "flex" }}
              key={index}
              xs={12}
              md={6}
              lg={4}
            >
              <CartPrestador
                setDateItem={setDateItem}
                selectItem={selectItem}
                item={item}
                date={date}
              />
            </Grid>
          ))
        ) : (
          <div
            style={{ width: "100%", marginTop: "25px", textAlign: "center" }}
          >
            <p>Não foram encontrados resultados para sua busca.</p>
          </div>
        )}
      </Grid>
      <Modal
        open={openWarning && !previousSchedule}
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openWarning}>
          <div className={classes.paper}>
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              style={{
                marginTop: "24px",
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: "100px",
                }}
                htmlColor="#C9B116"
              />
            </Grid>
            <Typography align="center" variant="h6">
              O Funcionário informado possui exames complementares pendentes,
              deseja continuar somente com o exame clínico?
            </Typography>
            <Grid container justifyContent="flex-end">
              <Grid lg={3}>
                <OutlinedLoadingButton
                  onClick={() => {
                    setOpenWarning(false);
                    setSelectedItem(null);
                  }}
                >
                  Cancelar
                </OutlinedLoadingButton>
              </Grid>
              <Grid lg={3}>
                <ContainedLoadingButton
                  onClick={() => {
                    setProceed(() => true);
                    setOpenWarning(false);
                    selectItem(selectedItem);
                  }}
                >
                  Confirmar
                </ContainedLoadingButton>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </Box>
  );
}

function CartPrestador({ item, selectItem, date, setDateItem }) {

  const classes = useStyles();
  const { colorPrimary } = useLayout();

  const getAva = (data: ServiceProviderAvailabilityDomain[]) => {
    let result = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].DISPONIBILIDADE) result = true;
    }
    return result;
  };

  const getAvaItem = (data: ServiceProviderAvailabilityDomain[]) => {
    let result = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i].DISPONIBILIDADE === "S") result = false;
    }
    return result;
  };

  const getValidItem = (data: ServiceProviderAvailabilityDomain[]) => {
    let result = "";

    for (let i = 0; i < data.length; i++) {
      if (data[i].DISPONIBILIDADE === "S") {
        result = data[i].DESCRICAO;
        break;
      }
    }

    if (!result) return "";

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {result && (
          <span className={classes.inlineBlock}>Primeiro horário</span>
        )}
        <span
          className={classes.inlineBlock}
          style={{
            marginTop: "10px",
            color: colorPrimary,
          }}
        >
          {toUpperCase(result)}
        </span>
      </div>
    );
  };

  const formatPhone = (value) => {
    let telefone = value.replace("-", "");
    return telefone.slice(0, 4) + "-" + telefone.slice(4, 8);
  };

  const disponibilidades = (disponibilidade: string) => {
    const DataItem = ({
      data,
      handleClick,
    }: {
      data: string;
      handleClick(data: string): void;
    }) => {
      return (
        <span
          onClick={() => handleClick(data)}
          style={{
            color: colorPrimary,
            display: "inline-block",
            cursor: "pointer",
          }}
          className={classes.link}
        >
          {data}
        </span>
      );
    };
    if (disponibilidade.indexOf(" e ")) {
      const handleClick = (value: string) => {
        setDateItem(value);
        selectItem(item, value);
      };
      const items = disponibilidade.split(" e ").map((item) => {
        return <DataItem data={item?.trim()} handleClick={handleClick} />;
      });
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          Datas disponíveis para agendamento <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignContent: "center",
              marginTop: "10px",
            }}
          >
            {items}
          </div>
        </div>
      );
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "25px",
        }}
      >
        <div>
          <Typography variant="h6" component="h2" align="left">
            {toUpperCase(item?.PROFANTASIA) +
              (validateTypeOfString(item?.PROFANTASIA) ? " - " : "") +
              toUpperCase(item?.PRONOME)}
          </Typography>
        </div>
        <br />
        <div
          className={classes.address}
          style={{
            border: `1px solid ${colorPrimary}`,
            padding: 10,
            borderRadius: 15,
            textAlign: "center",
          }}
        >
          <div>
            <Typography variant="body1" component="p"></Typography>
            <Typography variant="body1" component="p">
              {item?.PROTIPOENDERECO} - {item?.PROENDERECO},{" "}
              {item?.PROENDERECONUMERO}
            </Typography>
            <Typography variant="body1" component="p" noWrap>
              {item?.PROBAIRRO} /{" "}
              {validateTypeOfString(item?.PROCEP) &&
                `${item?.PROCEP.slice(0, 5)}-${item?.PROCEP.slice(5, 8)}`}
            </Typography>
            <Typography variant="body1" component="p">
              {item?.PROCIDADE}/{item?.PROESTADO}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" component="p">
              Telefone: {!!item?.PRODDD && `(${item?.PRODDD})`}{" "}
              {!!item?.PROTELEFONE && formatPhone(item?.PROTELEFONE)}
            </Typography>
          </div>
        </div>
        <br />
        <Typography variant="body1" component="p" align="center">
          {item?.DISPONIBILIDADE &&
            item?.DISPONIBILIDADE?.length > 0 &&
            getValidItem(item?.DISPONIBILIDADE)}
        </Typography>
      </CardContent>
      <Divider />
      <div style={{ padding: 20, textAlign: "center" }}>
        {item?.DISPONIBILIDADE7DIAS
          ? disponibilidades(item?.DISPONIBILIDADE7DIASLISTA)
          : "Sem disponibilidade nos próximos 7 dias."}
      </div>
      <Divider />
      <CardActions style={{ padding: 20 }}>
        {getAva(item?.DISPONIBILIDADE) &&
        item?.DISPONIBILIDADE &&
        item?.DISPONIBILIDADE.length > 0 ? (
          getAvaItem(item?.DISPONIBILIDADE) ? (
            <div style={{ margin: "auto" }}>
              <Button disabled>Sem vagas disponíveis</Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                selectItem(item);
              }}
              variant="contained"
              color="primary"
              fullWidth
            >
              Agendar para o dia <br /> {formatDate(date)}
            </Button>
          )
        ) : (
          <Button fullWidth disabled>
            Sem disponibilidade
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
