import { FormControlLabel, Switch } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function SwitchDef({
  name,
  label,
  control,
  onChangeItem,
  rules = {},
}: {
  name: string;
  label: string;
  control: any;
  onChangeItem: (data: boolean) => void;
  rules?: any;
}) {
  return (
    <FormControlLabel
      control={
        <Controller
          rules={{
            setValueAs: (value) => {
              return value ? Number(value) : null;
            },
            ...rules,
          }}
          render={({ value, onChange }) => (
            <Switch
              color="primary"
              checked={value === true || value === 1}
              onChange={(e) => {
                onChangeItem(e.target.checked);
                onChange(e.target.checked);
              }}
            />
          )}
          type="checkbox"
          name={name}
          control={control}
        />
      }
      label={label}
    />
  );
}
