import { Action } from "../actions/providerActions";

export interface ProviderDomain {
   /*----------------------------------------------------------------------*/
  EMPCODIGO: number;
  PROCODIGO: number;
  PRONOME: string;
  PROCNPJ: string;
  PROTIPOTELEFONE: string,
  PRODDD: string,
  PROTELEFONE: number;
  PROTIPOTELEFONE2:number;
  PRODDD2:number;
  PROTELEFONE2:number;
  PROTIPOTELEFONE3:number;
  PRODDD3:number;
  PROTELEFONE3:number;
  PROTIPOENDERECO:string;
  PROCEP:string;
  PROBAIRRO:string;
  PROCIDADE:string;
  PROESTADO:string;
  PROENDERECO:string;
  PROENDERECONUMERO:string;
  PROCOMPLEMENTO:string;
  PROEMAIL:string;
  PROFANTASIA:string;
  ASSINA:string;
  PATHORAINICIO:string;
  PATHORAFIM:string;
  PATLIMAGENDA: number;
  PATLIMAGENDAEMPRESA: number;
  TIPO: string;
  DISPONIBILIDADE: ServiceProviderAvailabilityDomain[];
}

export interface ServiceProviderAvailabilityDomain {
  /*------------------------------*/
  HORA: string;
  HORAFINAL: string;
  TIPO: number,
  DESCRICAO: string,
  DISPONIBILIDADE: string;
  DISPONIBILIDADEDESCRICAO: string;
  CAPACIDADE: string;
  CAPACIDADEEMPRESA: string;
}
const providerReducer = (
  state: ProviderDomain | null = null,
  action: Action
): ProviderDomain | null => {
  switch (action.type) {
    case "SET_PROVIDER":
      return action.payload;
    default:
      return state;
  }
};

export default providerReducer;
