import { Chip, Typography } from '@material-ui/core';
import axios from 'axios';

export function getFilterText (value: number): JSX.Element {
  let info = {} as { text: string; color: string };
  switch (value) {
    case 1:
      info = { text: 'Pendentes de envio', color: '#479FE7' };
      break;
    case 2:
      info = { text: 'Enviados com sucesso', color: '#03D30F' };
      break;
    case 3:
      info = { text: 'Com erro no envio', color: '#EA0D04' };
      break;
    case 4:
      info = { text: 'Aguardando retorno', color: '#dbdf00' };
      break;
    default:
      info = { text: 'Status inválido', color: '#EA0D04' };
      break;
  }
  return (
    <Typography
      variant="h6"
      style={{ fontWeight: 'bold', fontSize: 18 }}
    >
      Você está visualizando: <Chip label={info.text} style={{
        margin: '0px 10px',
        color: info.color,
        backgroundColor: 'inherit',
        fontSize: 16,
        border: `1px solid ${info.color}`
      }} variant="outlined" />
    </Typography>
  );
}

export function compare (property: string) {
  return function (a, b) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  }
}

export async function openFile (filelink) {
  try {
    const response = await axios.get(filelink.replaceAll('"', ''), {
      responseType: 'blob'
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/xml'
        }),
      );
      window.open(url, '_blank');
    }
  } catch (err) { /* */ }
}