import { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Typography, CircularProgress, Chip } from "@material-ui/core";
import { Button, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import Form from "../Form";
import Crud, { CrudModel, SchamaCurd } from "../Crud";
import useSearchStyles from "./styles";
import DrawerFiltros from "./DrawerFiltros";

export default function Search<T>({
  search,
  title,
  fieldsFilter,
  fieldsSearchMain,
  fieldsContent,
  loading,
  content,
  extraButtons,
  crudProps,
}: {
  search?: any;
  title?: any;
  fieldsFilter?: any;
  fieldsSearchMain?: any;
  fieldsContent?: SchamaCurd<T>[];
  loading?: any;
  content?: T[];
  edit?: any;
  count?: any;
  extraButtons?: any;
  searchDrawer?: any;
  crudProps?: CrudModel<T>;
}) {
  const classes = useSearchStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filters, setFilters] = useState({});

  const attFilters = (data) => {
    setFilters(data);
    search(data);
  };

  const removeFilter = (key: string) => {
    setFilters((f) => ({ ...f, [key]: null }));
    search({ ...filters, [key]: null });
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const searchInside = (data) => {
    search({ ...data, ...filters });
  };

  return (
    <div>
      {typeof title === "string" ? (
        <Typography style={{ padding: "0px 0px 10px 0px" }} variant="body1">
          <b>{title}</b>
        </Typography>
      ) : (
        title
      )}

      <Paper variant="outlined">
        <div className={classes.header}>
          <Form
            defaultValues={{}}
            title={null}
            onSubmit={searchInside}
            classBody={classes.rootBody}
            classForm={classes.rootForm}
            schema={fieldsSearchMain}
            buttons={() => {
              return (
                <IconButton
                  id="button-search"
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              );
            }}
          />
          <div className={classes.buttons}>
            {fieldsFilter && (
              <Button
                startIcon={<FilterListIcon />}
                className={classes.button}
                onClick={toggleDrawer(true)}
                variant="outlined"
                color="primary"
              >
                Filtros
              </Button>
            )}
            {extraButtons}

            {fieldsFilter && (
              <DrawerFiltros
                open={openDrawer}
                onClose={toggleDrawer}
                search={attFilters}
                filters={filters}
                fields={fieldsFilter}
              />
            )}
          </div>
        </div>
        <div className={classes.chipsContainer}>
          {filters &&
            Object.keys(filters).map((key, index) =>
              filters[key] ? (
                <Chip
                  className={classes.chipItem}
                  key={index}
                  label={`${fieldsFilter[0].content.find(
                    (item) => item.name.toLowerCase() === key.toLowerCase()
                  ).label
                    }: ${filters[key]}`}
                  onClick={toggleDrawer(true)}
                  onDelete={() => removeFilter(key)}
                  variant="outlined"
                />
              ) : null
            )}
        </div>
        {loading ? (
          // @ts-ignore
          <center>
            <br />
            <br />
            <CircularProgress />
            <br />
            <br />
            {/* 
          //@ts-ignore */}
          </center>
        ) : content ? (
          <Crud<T | null>
            content={content}
            schema={fieldsContent}
            {...crudProps}
          />
        ) : null}
      </Paper>
    </div>
  );
}
