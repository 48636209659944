import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Dashboard from "../../../../views/Dashboard";
import { api } from "../../../services/api";
import routes from "./routesModel";
import { useAccess } from "../../../../contexts/access";
import isValidAccess from "../../../../utils/isValidAcess";

export default function Routes({ setPrimary, setSecondary, verify }: any) {
  const { credentials } = useAccess();

  function PrivateRoute({ verify, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => (
          <>
            <Switch>
              {routes.map((route, idx) => {
                let isPermitted = true;

                if (route.privateRoute && route.credentiaisRequired) {
                  if (Array.isArray(route.credentiaisRequired)) {
                    if (route.type && route.type === "OR") {
                      isPermitted = route.credentiaisRequired.reduce(
                        (prev, next) => {
                          return prev || credentials[next];
                        },
                        false
                      );
                    } else {
                      isPermitted = route.credentiaisRequired.reduce(
                        (prev, next) => {
                          return prev && credentials[next];
                        },
                        true
                      );
                    }
                  }
                }
                if (isPermitted && (route.component as any).access) {
                  isPermitted = isValidAccess((route.component as any).access);
                }
                return (
                  route.component &&
                  isPermitted && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      render={(props) => {
                        return (
                          <route.component
                            {...props}
                            setPrimary={setPrimary}
                            setSecondary={setSecondary}
                          />
                        );
                      }}
                    />
                  )
                );
              })}
            </Switch>
          </>
        )}
      />
    );
  }
  const [isAuthenticated, setIsAuthenticated] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const req = await api();
    try {
      const response = await req.get(`/users/token`);
      setIsAuthenticated(response);
    } catch (err) {
      setIsAuthenticated(null);
    }
  };

  return (
    <>
      {isAuthenticated ? (
        <>
          <Dashboard />
          <PrivateRoute verify={true}></PrivateRoute>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}
