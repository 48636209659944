import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Body, Head } from "./styles";

interface SimpleModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content: JSX.Element | string;  
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl"
}

export default function SimpleModal({
  open,
  onClose,
  title = "",
  content,
  maxWidth = 'md'
}: SimpleModalProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
      <Head>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={onClose} component="span">
          <CloseIcon />
        </IconButton>
      </Head>
      <Body>{content}</Body>
    </Dialog>
  );
}