import { Box, Typography, Tab, Tabs, AppBar } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ReactNode, useState } from 'react';

type TabPanelProps = {
  children: JSX.Element | ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '25px',
    maxWidth: '95%',
  },
}));

type SimpleTabsProps = {
  onChangeSelect: (value: number) => void;
  quantidade2220: number | null;
  quantidade2240: number | null;
}

export default function SimpleTabs({ onChangeSelect, quantidade2220, quantidade2240 }: SimpleTabsProps) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChangeSelect(newValue + 1);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={-1} style={{ position: "sticky", top: 60, backgroundColor: '#fafafa' }}>
        <Tabs
          orientation="horizontal"
          indicatorColor="primary"
          value={value}
          textColor="primary"
          style={{ background: "inherit" }}
          onChange={handleChange}
          aria-label="tabs S2220/S2240"
          variant="fullWidth"
        >
          <Tab label={`S-2220 - ASO (${quantidade2220 ?? 0})`} {...a11yProps(0)} />
          <Tab label={`S-2240 - Fatores de risco (${quantidade2240 ?? 0})`} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <></>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <></>
      </TabPanel>
    </div>
  );
}