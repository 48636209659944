import { useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { TextField, FormLabel, Paper } from "@material-ui/core";

import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { useLayout } from "../../hooks/auth";
import { Form } from "../../shared";
import { toast } from "react-toastify";

export default function useNovaSenha(props) {
  const { imageLoginURL } = useLayout();
  const [error, setError] = useState(null);
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const params = useParams<{ token: string }>();
  const history = useHistory();

  const valueSenha = (event) => {
    setSenha(event.target.value);
  };

  const confirmValueSenha = (event) => {
    setConfirmSenha(event.target.value);
  };

  const redefinirSenha = async () => {
    const dataValues = {
      SENHANOVA: senha,
      SENHACONFIRMADA: confirmSenha,
    };

    if (!senha.trim() || !confirmSenha.trim()) {
      setError(true);
      return;
    }

    if (senha !== confirmSenha) {
      setError(true);
      toast.error("Senhas não conferem, necessário verificar.");
      return;
    }

    try {
      const req = await api();
      await req.post("/users/recuperarSenha", dataValues, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      });
      toast.success("Senha redefinida com sucesso!");
      history.push("/");
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  return (
    <>
      <Paper
        style={{
          display: "flex",
          width: 400,
          minHeight: 400,
          padding: 48,
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "5px",
        }}
      >
        <Form
          spacing={"0px 24px 0px 0px"}
          onSubmit={(data) => {}}
          buttons={() => (
            <div
              style={{
                maxWidth: 848,
                width: "100%",
                margin: "24px auto",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ width: "94%", height: "50px", marginTop: 10 }}
                size="large"
                type="submit"
                onClick={redefinirSenha}
              >
                Enviar
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  lg: 12,
                  name: "jwtusername",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <>
                      <img src={imageLoginURL} alt="logo" />
                      <hr />
                      <FormLabel
                        component="legend"
                        style={{ marginBottom: 25, fontWeight: "bold" }}
                      >
                        Nova Senha
                      </FormLabel>
                      <TextField
                        id="standard-number"
                        label="Senha"
                        error={error}
                        onChange={valueSenha}
                        style={{
                          marginBottom: 18,
                          width: "100%",
                          display: "flex",
                        }}
                        type="password"
                        variant="outlined"
                        color="primary"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  lg: 12,
                  name: "jwtpassword",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <>
                      <TextField
                        id="standard-number"
                        label="Confirme a Senha"
                        type="password"
                        error={error}
                        onChange={confirmValueSenha}
                        style={{
                          marginBottom: 18,
                          width: "100%",
                          display: "flex",
                        }}
                        variant="outlined"
                        color="primary"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </>
                  ),
                },
              ],
            },
          ]}
        />
      </Paper>
    </>
  );
}
