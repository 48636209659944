import {
  Typography,
  Box,
  CircularProgress,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  styled,
  Paper,
} from "@material-ui/core";
import { Form } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import { useState, useEffect, useRef, useCallback } from "react";
import InputDate from "../../shared/Form/Fields/Date";
import { useParams } from "react-router-dom";
import { api } from "../../shared/services/api";
import AlertItem, { IAlert } from "./alert";
import { useSelect } from "../employee/emp/useSelect";
import { isValidCnpjOrCpf } from "../../utils/validate";
import { Masked } from "../../shared/Form/Fields/Masked";
import Checkbox from "../../shared/Form/Fields/CheckboxReverse";

import { useSetores } from "./controller";
import { InputDef } from "../../shared/Form/Fields";
import { toast } from "react-toastify";
import { ModalEsocialInfo } from "../../shared/ModalEsocialInfo";
import SimpleModal from "../../simple-modal";
import { useForm } from "react-hook-form";
import { AutoCompleteNew } from "../../shared/Form/Fields/AutoComplete";

const genders = [
  { code: 2, label: "Feminino" },
  { code: 1, label: "Masculino" },
];

export default function Dados() {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ id: string }>();
  const [statusOptions, setStatusOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [funcData, setFuncData] = useState<any>(null);
  const [alert, setAlert] = useState<IAlert>({} as IAlert);
  const [open, setOpen] = useState<boolean>(false);
  const [idSetor, setIdSetor] = useState<string | null>(null);
  const [funcoes, setFuncoes] = useState<any[]>([]);
  const [funcoesInfos, setFuncoesInfos] = useState<any[]>([]);
  const funcpf = useRef<string>();
  const setValueRef = useRef<any>();
  const [openModal, setOpenModal] = useState(false);
  const [idFuncao, setIdFuncao] = useState<string | null>(null);
  const { categeSocial } = useSelect();
  const { setoresOpts, setores } = useSetores();

  useEffect(() => {
    return () => {};
  }, [funcData]);

  const fetchFuncoes = async () => {
    const req = await api();
    try {
      const res = await req.get<any[]>(`/agenda/setores/${idSetor}/funcoes`);
      setFuncoesInfos(
        res.data?.map((e) => ({
          code: e.FCOCODIGO,
          label: `${e.GHENOME} ${e.FCONOME && `- ${e.FCONOME}`}`,
        }))
      );
      const opts = res.data.map((item: any, idx) => {
        return (
          <MenuItem key={idx} value={item.FCOCODIGO}>
            {item.GHENOME + " / " + item.FCONOME}
          </MenuItem>
        );
      });
      setFuncoes(opts);
    } catch (err) {}
  }

  const fetchFuncionario = async () => {
    setIsLoading(true);
    setFuncData(null);
    const req = await api();
    try {
      const res = await req.get(`/funcionarios/funcionarios/${params.id}`);
      funcpf.current = res.data.FUNCPF;
      setIdSetor(res.data.SETCODIGO.toString());
      setFuncData(res.data);
      setIsLoading(false);
    } catch (err) {}
  };
  useEffect(() => {
    fetchFuncionario();
  }, []);

  useEffect(() => {
    fetchFuncoes();
  }, [idSetor]);
  const fetchEstadosCivis = async () => {
    const req = await api();
    try {
      const res = await req.get(`/funcionarios/funcionarios/estadoscivis`);
      let opt: any[] = [];
      res.data?.forEach((item: any) => {
        opt = [
          ...opt,
          {
            label: item.ESTDESCRICAO,
            code: item.ESTCODIGO,
          },
        ];
      });
      setStatusOptions(opt);
    } catch (err) {}
  };
  useEffect(() => {
    fetchEstadosCivis();
  }, []);

  //   const { control, setValue, errors, register } = useFormContext();

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  interface IButtonsProps {
    bgColor: string;
    textColor: string;
  }

  const Buttons = styled(Button)((props: IButtonsProps) => ({
    backgroundColor: props.bgColor,
    color: props.textColor,
  }));

  const ButtonsDiv = styled("div")({
    marginTop: 16,
    display: "flex",
    gap: 8,
    justifyContent: "end",
    paddingRight: 8,
  });

  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffect(() => {
  //     console.log(setores)
  //   },[setores])

  return (
    <Box sx={{ flexGrow: 1 }}>
      {open && (
        <ModalEsocialInfo
          open={open}
          onClose={() => setOpen(false)}
          funcpf={funcpf.current}
          setValue={setValueRef.current}
        />
      )}
      <Box className="no-print" style={{ margin: 0, padding: 0 }}>
        <Typography
          className="no-print"
          align="left"
          style={{
            marginBottom: "35px",
            marginLeft: "10px",
            fontWeight: "bold",
            fontSize: "15pt",
          }}
          color="primary"
        >
          Dados
        </Typography>
        {funcData && (
          <Form
            defaultValues={funcData}
            spacing={"8px 8px"}
            onSubmit={async (data) => {
              setLoading(true);
              data.FUNSTATUS = data.FUNSTATUS ? 1 : 0;
              data.USEESOCIALDATA =
                data.USEESOCIALDATA && data.USEESOCIALDATA === "1" ? 1 : 0;

              const req = await api();
              try {
                await req.put(`/funcionarios/funcionarios/${params.id}`, {
                  ...funcData,
                  ...data,
                });
                await req.put(
                  `/funcionarios/funcionarios/${params.id}/profissiografias`,
                  {
                    ...funcData,
                    ...data,
                    SETCODIGO: data.SETCODIGO,
                    FCOCODIGO: data.FCOCODIGO,
                  }
                );
                setAlert({
                  open: true,
                  message: "Funcionário editado com sucesso",
                  severity: "success",
                });
              } catch (error) {
                if (error.response?.status === 403) {
                  toast.error(
                    "Usuário sem autorização para realizar essa operação. Procure o suporte."
                  );
                } else {
                  setAlert({
                    open: true,
                    message: error.response?.data?.message,
                    severity: "error",
                  });
                }
              } finally {
                setLoading(false);
              }
            }}
            buttons={() => (
              <div
                style={{
                  width: "100%",
                  margin: "24px auto",
                  padding: "7px",
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{ width: "100%", marginTop: -20 }}
                  size="large"
                  type="submit"
                >
                  {loading ? <CircularProgress color="secondary" /> : "Guardar"}
                </LoadingButton>
              </div>
            )}
            schema={[
              {
                content: [
                  {
                    label: "Nome do Funcionário",
                    name: "FUNNOME",
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 10,
                    rules: { required: "Campo Obrigatório" },
                    inputPropsAdittional: { type: "text" },
                  },
                  {
                    name: "FUNSTATUS",
                    lg: 2,
                    type: "custom",
                    customComponent: ({ name, control, setValue }) => {
                      if (!setValueRef.current) setValueRef.current = setValue;
                      return (
                        <Box style={{ marginLeft: 5, marginTop: 5 }}>
                          <Checkbox
                            name={name}
                            label="Afastado"
                            control={control}
                          />
                        </Box>
                      );
                    },
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 3,
                    name: "FUNNASCIMENTO",
                    type: "custom",
                    customComponent: ({ control, name, error }) => (
                      <InputDate
                        control={control}
                        label="Nascimento"
                        name={name}
                        error={error}
                        rules={{ required: "Campo Obrigatório" }}
                      />
                    ),
                  },

                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 3,
                    name: "FUNADMISSAO",
                    type: "custom",
                    customComponent: ({ control, name }) => (
                      <InputDate
                        control={control}
                        label="Admissão"
                        name={name}
                        //disabled={true}
                        //rules={{ required: "Campo obrigatório" }}
                      />
                    ),
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 3,
                    name: "FUNDEMISSAO",
                    type: "custom",
                    customComponent: ({ control, name }) => (
                      <InputDate
                        control={control}
                        label="Demissão"
                        name={name}
                        //rules={{ required: "Campo obrigatório" }}
                      />
                    ),
                  },
                  {
                    sm: 6,
                    md: 6,
                    lg: 4,
                    type: "select",
                    name: "FUNSEXO",
                    label: "Sexo",
                    options: genders.map((g) => (
                      <MenuItem value={g.code}>{g.label}</MenuItem>
                    )),
                    rules: { required: "Campo Obrigatório" },
                  },
                  {
                    sm: 6,
                    md: 6,
                    lg: 4,
                    type: "select",
                    name: "ESTCODIGO",
                    label: "Estado Civil",
                    options: statusOptions.map((s) => (
                      <MenuItem value={s.code}>{s.label}</MenuItem>
                    )),
                  },
                  {
                    label: "RG",
                    xs: 12,
                    name: "FUNRG",
                    sm: 6,
                    md: 6,
                    lg: 4,
                    inputPropsAdittional: { type: "text" },
                  },
                  {
                    label: "CPF",
                    name: "FUNCPF",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 3,
                    customComponent: ({ control, error, name }) => (
                      <Masked
                        rules={{
                          validate: { isValidCnpjOrCpf: isValidCnpjOrCpf },
                        }}
                        format="###.###.###-##"
                        mask="_"
                        control={control}
                        name={name}
                        error={error}
                        label="CPF"
                        disabled={true}
                        onChangeItem={(value: string) => {
                          funcpf.current = value;
                        }}
                      />
                    ),
                  },
                  {
                    lg: 1,
                    label: "DDD",
                    name: "FUNDDD1",
                  },
                  {
                    lg: 2,
                    name: "FUNFONE1",
                    label: "Telefone",
                  },
                  {
                    lg: 3,
                    type: "checkbox",
                    name: "FUNPERMITEMSG",
                    label: "Permitir comunicação por mensagens",
                  },
                  {
                    lg: 1,
                    customComponent: () => {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Tooltip
                            title={
                              <h3>Consultar dados do funcionário no eSocial</h3>
                            }
                          >
                            <IconButton
                              onClick={() => setOpen((open) => !open)}
                              style={{ width: "60px", height: "60px" }}
                            >
                              <img
                                src="/esocial_icon.png"
                                width="78px"
                                height="78px"
                                style={{ transform: "translate(3px, 0)" }}
                                alt=""
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                  {
                    label: "CTPS",
                    name: "FUNCTPS",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    inputPropsAdittional: { type: "text" },
                  },
                  {
                    label: "NIT",
                    name: "FUNNIT",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    inputPropsAdittional: { type: "text" },
                  },
                  {
                    label: "Categoria",
                    name: "ESOCCATEGCODIGO",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    type: "select",
                    options: categeSocial,
                  },
                  {
                    label: "Matrícula",
                    name: "FUNMATRICULA",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    inputPropsAdittional: { type: "text" },
                    customComponent: ({ control, name }) => {
                      return (
                        <Tooltip
                          title="Matricula que é utilizada no ESocial"
                          placement="top"
                          arrow
                        >
                          <InputDef
                            name={name}
                            control={control}
                            style={{ width: "100%" }}
                            type="text"
                            variant="outlined"
                            label="Matrícula"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Tooltip>
                      );
                    },
                  },
                  {
                    label: "Setor",
                    name: "SETCODIGO",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    disabled: true,
                    type: "select",
                    options: setores,
                    onChange: (data, setValue) => {
                      setValue("FCOCODIGO", null);
                      setIdSetor(data.toString());
                    },
                  },
                  {
                    label: "Função",
                    name: "FCOCODIGO",
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    value: idFuncao,
                    disabled: true,
                    type: "select",
                    options: funcoes,
                  },
                  {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    name: "FUNDTINICIO",
                    type: "custom",
                    customComponent: ({ control, name, error }) => (
                      <InputDate
                        control={control}
                        label="Data de Início na Função"
                        name={name}
                        error={error}
                        disabled
                      />
                    ),
                  },
                  {
                    lg: 12,
                    name: "USEESOCIALDATA",
                    inputPropsAdittional: { style: { display: "none" } },
                  },
                  {
                    lg: 2,
                    name: "BUTTON",
                    customComponent: () => (
                      <div style={{ marginTop: "4%" }}>
                        <Button onClick={() => setOpenModal(true)}>
                          Alterar Função
                        </Button>
                      </div>
                    ),
                  },
                ],
              },
            ]}
          />
        )}
      </Box>
      <SimpleModal
        open={openModal}
        title="Manutenção Locação"
        onClose={() => setOpenModal(false)}
        content={
          <>
            <Paper elevation={2} style={{ width: 700, padding: 16 }}>
              <Form
                defaultValues={funcData}
                onSubmit={async (data) => {
                  setLoading(true);
                  const req = await api();
                  try {
                    await req.post(
                      `/funcionarios/${params.id}/profissiografias`,
                      data
                    );
                    setAlert({
                      open: true,
                      message: "Funcionário editado com sucesso",
                      severity: "success",
                    });
                    const ghe = funcoesInfos.find(
                      (e) => e.code === data.FCOCODIGO
                    );
                    if (funcData.PROFISSIOGRAFIA.GHECODIGO !== ghe?.CHECODIGO) {
                      setAlert({
                        open: true,
                        message:
                          "A nova função está em um GHE diferente da anterior, caso necessário, agende um exame de Mudança de Risco.",
                        severity: "warning",
                      });
                    }
                    fetchFuncionario();
                  } catch (error) {
                    if (error.response?.status === 403) {
                      toast.error(
                        "Usuário sem autorização para realizar essa operação. Procure o suporte."
                      );
                    } else {
                      console.log(error);
                      setAlert({
                        open: true,
                        message: error.response?.data?.message,
                        severity: "error",
                      });
                    }
                  } finally {
                    setOpenModal(false);
                    setLoading(false);
                    fetchFuncoes();
                  }
                }}
                spacing={8}
                schema={[
                  {
                    content: [
                      {
                        label: "Setor",
                        name: "SETCODIGO",
                        rules: { required: "Campo Obrigatório" },
                        xs: 12,
                        sm: 6,
                        md: 6,
                        lg: 12,
                        options: setoresOpts,
                        type: "custom",
                        customComponent: ({ setValue, control, error }) => (
                          <AutoCompleteNew
                            valeuDef={funcData.SETCODIGO}
                            control={control}
                            error={error}
                            label="Setor"
                            name={"SETCODIGO"}
                            rules={{ required: "Campo Obrigatório" }}
                            options={setoresOpts}
                            setValue={setValue}
                            onChange={(info) => {
                              setIdSetor(info?.code?.toString());
                              setValue("FCOCODIGO", null);
                              setValue("SETCODIGO", info?.code);
                            }}
                          />
                        ),
                      },
                      {
                        label: "Função",
                        name: "FCOCODIGO",
                        rules: { required: "Campo Obrigatório" },
                        xs: 12,
                        value: idFuncao,
                        sm: 6,
                        md: 6,
                        lg: 12,
                        options: funcoes,
                        type: "custom",
                        customComponent: ({ setValue, control, error }) => (
                          <AutoCompleteNew
                            valeuDef={funcData.FCOCODIGO}
                            control={control}
                            error={error}
                            rules={{ required: "Campo Obrigatório" }}
                            label="Função"
                            name={"FCOCODIGO"}
                            options={funcoesInfos}
                            setValue={setValue}
                            onChange={(info) => {
                              setValue("FCOCODIGO", info?.code);
                            }}
                          />
                        ),
                      },
                      {
                        lg: 12,
                        xs: 12,
                        sm: 6,
                        md: 6,
                        name: "FUNDTINICIO",
                        type: "custom",
                        customComponent: ({ control, name }) => (
                          <InputDate
                            control={control}
                            label="Data Início"
                            name={name}
                          />
                        ),
                      },
                    ],
                  },
                ]}
                buttons={() => (
                  <>
                    <button
                      type="submit"
                      id="submitForm"
                      style={{ display: "none" }}
                    ></button>
                  </>
                )}
              />
            </Paper>
            <ButtonsDiv>
              <Buttons
                size="large"
                variant="contained"
                type="submit"
                bgColor="white"
                textColor="#8C89A1"
                onClick={() => setOpenModal(false)}
              >
                Cancelar
              </Buttons>
              <Buttons
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                bgColor="#0b8e2e"
                textColor="white"
                onClick={() => {
                  const submitForm = document.getElementById("submitForm");
                  submitForm.click();
                }}
              >
                Gravar
              </Buttons>
            </ButtonsDiv>
          </>
        }
      />
      <AlertItem {...alert} closeItem={() => setAlert({} as IAlert)} />
    </Box>
  );
}
