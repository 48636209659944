import ExcelJS, { Border, Worksheet } from "exceljs";
import moment from "moment";

export type FormatRow = "string" | "number" | "date";

export interface Headers<T> {
  header: string;
  key: keyof T;
  width?: number;
  type?: FormatRow;
  formatCell?: (value: string | number) => string | null;
}

export interface WorksheetOptions<T> {
  name: string;
  headers: Headers<T>[];
  title?: string;
  subtitle?: string;
  data: any;
}

function addSubtitle(ws: Worksheet, value: string, numColumns: number) {
  ws.getCell(3, 1).value = value;
  ws.mergeCells(3, 1, 3, numColumns);
}

function addTitleWorksheet(
  ws: Worksheet,
  title: string,
  numColumns: number,
  width = 65,
  height = 50,
  titleBg = "FFE2EFDA"
) {
  ws.getCell(1, 1).value = title.toUpperCase();
  ws.mergeCells(1, 1, 1, numColumns);
  ws.getColumn(1).width = width;
  ws.getRow(1).height = height;
  ws.getRow(1).alignment = { horizontal: "center", vertical: "middle" };
  ws.getRow(1).font = { size: 18, bold: true };
  ws.getCell(1, 1).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: titleBg },
  };

  ws.getRow(1).alignment = { horizontal: "center", vertical: "middle" };

  const styles: Partial<Border> = {
    style: "medium",
    color: { argb: "00FFFFFF" },
  };

  ws.getRow(1).border = {
    top: styles,
    bottom: styles,
    left: styles,
    right: styles,
  };

  ws.getRow(1).border = {
    top: { style: "medium", color: { argb: "00FFFFFF" } },
    bottom: { style: "medium", color: { argb: "00FFFFFF" } },
    left: { style: "medium", color: { argb: "00FFFFFF" } },
    right: { style: "medium", color: { argb: "00FFFFFF" } },
  };
}

export default async function createWorksheet<T>({
  name,
  headers,
  title,
  subtitle,
  data,
}: WorksheetOptions<T>) {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(name);

  let linha = 3;

  if (title) {
    addTitleWorksheet(ws, title, headers.length);
  }

  if (subtitle) {
    addSubtitle(ws, subtitle, headers.length);
    linha = 5;
  }

  headers.forEach((item, idx) => {
    ws.getCell(linha, idx + 1).value = item.header;
    ws.getCell(linha, idx + 1).font = { bold: true };
  });

  ws.autoFilter = {
    from: {
      row: linha,
      column: 1,
    },
    to: {
      row: linha,
      column: headers.length,
    },
  };

  linha++;

  const formatRowValue = (value: "string", type = "string"): string => {
    if (type === "date") {
      return value ? moment(new Date(value)).format("DD/MM/YYYY") : "";
    }
    return value;
  };

  const rows = data.map((item: T) => ({ ...item }));
  for (const item of rows) {
    headers.forEach(({ key, width = 15, type, formatCell }, idx) => {
      ws.getColumn(idx + 1).width = width;
      let value = formatRowValue(item[key], type);
      if (formatCell) {
        value = formatCell(item[key]);
      }
      ws.getCell(linha, idx + 1).value = value;
    });
    linha++;
  }

  try {
    const buff = await wb.xlsx.writeBuffer();
    const blob = new Blob([buff], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = url;
    link.download = name;
    link.click();
  } catch (error) {
    throw new Error(`Ocorreu um erro ao gerar o ${name}`);
  }
}
