import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { useFetch } from "../services/useFetch";
import CloseIcon from "@material-ui/icons/Close";
import DataShow from "../Item/DataShow";
import LoadingCircular from "../LoadingCircular";
import { format, isValid } from "date-fns";

export const ModalEsocialInfo = ({ open, onClose, funcpf, setValue }: any) => {
  const { data, error } = useFetch(`/esocial/0/consulta-cpf/${funcpf}`);

  return (
    <Dialog open={open} maxWidth="md" onClose={onClose} fullWidth>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <DialogTitle style={{ margin: "auto" }}>
          <img src="/esocial.png" width="185px" height="45px" alt="" />
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ margin: "auto" }}>
        {data ? (
          <DataShow<any>
            schema={[
              [
                {
                  content: "FUNNOME",
                  label: "Funcionário",
                  size: 4,
                  formatAll: true,
                  format: (data: any) => {
                    return (
                      <span>
                        {data.FUNNOME} <br /> {data.FUNCPF}
                      </span>
                    );
                  },
                },
                {
                  content: "FUNSTATUS",
                  label: "Status",
                  size: 4,
                  format: (data: number) => {
                    return data ? (
                      <span style={{ color: "green" }}>Ativo</span>
                    ) : (
                      <span style={{ color: "red" }}>Inativo</span>
                    );
                  },
                },
                {
                  content: "FUNMATRICULA",
                  label: "Matrícula",
                  size: 4,
                },
                {
                  content: "FUNNASCIMENTO",
                  label: "Data de nascimento",
                  size: 4,
                  format: (value: any) => {
                    return value && isValid(new Date(value))
                      ? format(new Date(value), "dd/MM/yyyy")
                      : "Não informado";
                  },
                },
                {
                  content: "FUNADMISSAO",
                  label: "Data de admissão",
                  size: 4,
                  format: (value: any) => {
                    return value && isValid(new Date(value))
                      ? format(new Date(value), "dd/MM/yyyy")
                      : "Não informado";
                  },
                },
                !data.FUNSTATUS && {
                  content: "FUNDEMISSAO",
                  label: "Data de demissão",
                  size: 4,
                  formatAll: true,
                  format: (data: any) => {
                    return !data.FUNSTATUS &&
                      data.FUNDEMISSAO &&
                      isValid(new Date(data.FUNDEMISSAO))
                      ? format(new Date(data.FUNDEMISSAO), "dd/MM/yyyy")
                      : "";
                  },
                },
                {
                  content: "FCONOME",
                  label: "Função",
                  size: 4,
                },
                {
                  content: "ESOCCATEGDESCRICAO",
                  label: "Categoria",
                  size: data.FUNSTATUS ? 12 : 8,
                  formatAll: true,
                  format: (data: any) => {
                    return (
                      data.ESOCCATEGCODIGO + " - " + data.ESOCCATEGDESCRICAO
                    );
                  },
                },
              ],
            ]}
            data={data}
          />
        ) : error ? (
          <div style={{ textAlign: "center" }}>
            <h4>Não foi possível consultar o funcionário no eSocial:</h4>
            <h3 style={{ color: "red" }}>
              {error.response.data.error
                ? error.response.data.error
                : error.response.data.message}
            </h3>
            <h4>Em caso de dúvidas, contate o suporte.</h4>
          </div>
        ) : (
          <LoadingCircular size={44} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!data}
          onClick={() => {
            for (const [field, value] of Object.entries(data)) {
              if (
                [
                  "FUNNOME",
                  "FUNMATRICULA",
                  "FCONOME",
                  "FUNNASCIMENTO",
                  "FUNADMISSAO",
                  "FUNDEMISSAO",
                  "ESOCCATEGCODIGO",
                ].includes(field)
              ) {
                if (field === "FUNDEMISSAO" && data.FUNSTATUS) {
                  continue;
                }
                setValue(field, value);
              }
            }
            setValue("USEESOCIALDATA", 1);
            onClose();
          }}
        >
          Utilizar os dados na ficha do funcionário
        </Button>
      </DialogActions>
    </Dialog>
  );
};
