import { Control, Controller } from "react-hook-form";
import NumberFormat, {
  FormatInputValueFunction,
  NumberFormatValues,
} from "react-number-format";
import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { CSSProperties } from "react";

export default function Currency({
  control,
  name,
  label,
  rules,
  error,
}: {
  control: Control<Record<string, any>>;
  name: string;
  label: string;
  rules: any;
  error: any;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          style={{ width: "100%" }}
          customInput={TextField}
          label={label}
          prefix={"R$ "}
          variant="outlined"
          decimalSeparator=","
          thousandSeparator="."
          error={error}
          value={value}
          helperText={error && <span>{error.message}</span>}
          onValueChange={(v) => {
            //value without dollar signe
            onChange(v.floatValue);
          }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}

export function Masked({
  control,
  name,
  label,
  rules,
  error,
  format,
  onChangeItem,
  mask,
  disabled,
}: {
  onChangeItem?: (data: string) => void;
  control: Control<Record<string, any>>;
  format: string;
  mask: string;
  name: string;
  label: string;
  rules: any;
  error: any;
  disabled?: boolean;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          disabled={disabled}
          format={format}
          mask={mask}
          style={{ width: "100%" }}
          customInput={TextField}
          label={label}
          variant="outlined"
          decimalSeparator=","
          thousandSeparator="."
          error={error}
          value={value}
          helperText={error && <span>{error.message}</span>}
          onValueChange={(v) => {
            if (onChangeItem) onChangeItem(v.value);
            onChange(v.value);
          }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}

export function MaskedPro({
  name,
  mask,
  label,
  rules,
  error,
  format,
  onBlur,
  style,
  control,
  loading,
  helperText,
  onChangeItem,
  forceInt = false,
  disabled = false,
  formatValue = false,
}: {
  error: any;
  rules?: any;
  name: string;
  label: string;
  mask?: string;
  loading?: boolean;
  forceInt?: boolean;
  disabled?: boolean;
  helperText?: string;
  style?: CSSProperties;
  formatValue?: boolean;
  onBlur?: (value: string) => void;
  control?: Control<Record<string, any>>;
  onChangeItem?: (values: NumberFormatValues) => void;
  format?: string | FormatInputValueFunction | undefined;
}) {
  return (
    <Controller
      render={({ onChange, value }) => (
        <NumberFormat
          id={name}
          mask={mask}
          label={label}
          error={error}
          value={value}
          format={format}
          variant="outlined"
          customInput={TextField}
          style={{ width: "100%", ...style }}
          helperText={helperText ? <span>{helperText}</span> : error && <span>{error.message}</span>}
          onValueChange={(v) => {
            if (onChangeItem) onChangeItem(v);
            if (formatValue) {
              onChange(v.formattedValue);
              return;
            }
            onChange(forceInt ? v.floatValue : v.value);
          }}
          onBlur={(v) => {
            if (onBlur) onBlur(v.target.value.replace(/\D/g, ""));
          }}
          disabled={disabled || loading}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={24} />
              </InputAdornment>
            ) : null,
          }}
        />
      )}
      rules={rules}
      name={name}
      control={control}
    />
  );
}
