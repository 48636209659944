import { api } from "../../shared/services/api";

export function getStatusColor(status) {
  switch (status) {
    case "Pendente com o cliente":
      return "#D40000";
    case "Pendente com o responsável":
      return "#0000FF";
    case "Em andamento":
      return "#FF9900";
    case "Concluída":
      return "#008000";
    default:
      return "black";
  }
}

export const getSolCondition = async (solcodigo) => {
  try {
    const req = await api();
    const { data } = await req.get(`/solicitacoes/dashboard/${solcodigo}`);
    return [data];
  } catch (error) {
    console.error(error);
  }
};

export const sendFollowUp = async (solcodigo, body) => {
  try {
    const req = await api();
    await req.post(`/solicitacoes/dashboard/${solcodigo}/follow-up`, body);
  } catch (error) {
    console.error(error);
  }
};
