import {
  Box,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Form } from "../../shared";
import { Crud } from "../../shared";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useCache } from "../../contexts/cache";
import { useEffect, useRef, useState } from "react";
import { api, instanceNew } from "../../shared/services/api";
import LoadingButton from "../../shared/Button/LoadingButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { generateExcel } from "./generateExcel";

function TableFunc({ data }: { data: any }) {
  const history = useHistory();

  return (
    <Crud
      schema={[
        {
          label: "Nome do funcionário",
          content: "FUNNOME",
        },
      ]}
      content={data}
      edit
      onEditClick={(func: any) =>
        history.push(`/pages/editarFuncionario/${func.FUNCODIGO}`)
      }
    />
  );
}

export default function FuncionarioPesquisar() {
  const history = useHistory();
  const statusRef = useRef<any>("1");
  const { getCache, removeCache } = useCache();
  const [response, setResponse] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [ isLoadingExcel, setIsLoadingExcel ] = useState(false);

  useEffect(() => {
    if (getCache && !response.length) {
      const cache = getCache("/pages/funcionarios");
      if (cache) {
        onSubmit(cache.data);
      }
    }

    return () => removeCache("/pages/funcionarios");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCache]);

  const onSubmit = async (data) => {
    setLoading(true);
    const req = await api();
    try {
      const res = await req.get(
        `/funcionarios/funcionarios?FUNNOME=${data.FUNNOME}&FUNATIVO=${data.FUNATIVO || statusRef.current
        }`
      );
      setResponse(res.data);
    } catch (err) {
      if (err.response?.status === 403) {
        toast.error("Acesso negado");
      } else {
        toast.error(
          err.response?.data?.message ||
          "Ocorreu um erro ao buscar os funcionários"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const gerarExcel = async () => {
    try {
      setIsLoadingExcel(true);
      await generateExcel()
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsLoadingExcel(false);
    }
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
        <Box style={{ margin: 0, padding: 0 }}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              align="left"
              style={{
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "15pt",
              }}
              color="primary"
            >
              Pesquisar Funcionário
            </Typography>

            <div
              style={
                {
                  display: "flex",
                  gap: "30px"
                }
              }
            >
              <LoadingButton
                type="button"
                color="primary"
                variant="outlined"
                onClick={gerarExcel}
                loading={isLoadingExcel}
                style={{ marginBottom: "25px", width: "400px" }}
              >
                Status dos Funcionários - S2240 (Planilha)
              </LoadingButton>
              <LoadingButton
                type="button"
                color="primary"
                variant="contained"
                style={{ marginBottom: "25px", width: "150px" }}
                onClick={() => history.push("/pages/adicionarFuncionario")}
              >
                Novo
              </LoadingButton>
            </div>
          </Box>
          <Form
            spacing={"0px 4px"}
            onSubmit={onSubmit}
            buttons={() => (
              <div
                style={{
                  width: "100%",
                  margin: "24px auto",
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                  size="large"
                  type="submit"
                >
                  {loading ? <CircularProgress color="secondary" /> : "Buscar"}
                </LoadingButton>
              </div>
            )}
            schema={[
              {
                content: [
                  {
                    label: "Nome do Funcionário",
                    name: "FUNNOME",
                    xs: 8,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    inputPropsAdittional: { type: "text" },
                  },
                  {
                    label: "",
                    name: "",
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    customComponent: () => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FormControl>
                            <FormLabel
                              id="radio-status-funcionario"
                              style={{ textAlign: "center" }}
                            >
                              Status
                            </FormLabel>
                            <RadioGroup
                              defaultValue="1"
                              row
                              onChange={({ target }) =>
                                (statusRef.current = target.value)
                              }
                            >
                              <FormControlLabel
                                value="1"
                                control={<Radio color="primary" />}
                                label="Ativos"
                              />
                              <FormControlLabel
                                value="0"
                                control={<Radio color="primary" />}
                                label="Inativos"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      );
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              margin: "50px auto 50px auto",
              width: "90%",
            }}
          >
            <Grid container>
              <Grid item xs={12} lg={12}>
                {response?.length > 0 ? (
                  <TableFunc data={response} />
                ) : (
                  <p style={{ textAlign: "center" }}>
                    Não foram encontrados funcionários.
                  </p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
