import axios from "axios";

export async function api () {
  const api = axios.create({
    baseURL: process.env.REACT_APP_RELATORIOS_API,
    timeout: 120000,
  });

  const accessToken = localStorage.getItem("Token_Key");

  api.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers = Object.assign(
          {
            Authorization: accessToken,
          },
          config.headers
        );
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.defaults.timeout = 5000000;
  api.defaults.timeoutErrorMessage = "Timeout";

  return api;
}