import Form from "../Form";
import { api } from "../services/api";
import { toast } from "react-toastify";
import { formatCPF } from "../../utils/formats";
import { MaskedPro } from "../Form/Fields/Masked";
import { isValidCnpjOrCpf } from "../../utils/validate";
import { NumberFormatValues } from "react-number-format";
import AutoCompleteDef from "../Form/Fields/AutoComplete";
import { ExamTypeDomain } from "../../views/employee/tipoExame";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Backdrop, Fade, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import { ContainedLoadingButton, OutlinedLoadingButton } from "../Button/LoadingButton";
import { EmployeeDomain } from "../../views/employee/emp/EmployeeDomain";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        gap: "29px",
        width: "650px",
        outline: "none",
        display: "flex",
        padding: "28px",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
    },
}));

export type formCPF = { FUNCPF: string; TIPOEXAME: string }

export const ModalCPF = ({ onClose, onSubmit }: { onClose: () => void; onSubmit?: (data: formCPF, setLoading: SetStateAction<Dispatch<boolean>>) => any; }) => {

    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingFun, setLoadingFun] = useState<boolean>(false);
    const [funcionario, setFuncionario] = useState<EmployeeDomain>(null);
    const [exam, setExam] = useState<{ code: string; label: string; }[] | null>();

    const { modalCPF: openModal, resetPersistent } = useStatePersistent();

    useEffect(() => {
        const reqTypeExam = async () => {
            const req = await api();
            await req.get<ExamTypeDomain[]>(`/agenda/exames`).then((response) => {
                setExam(response.data.map(item => ({ code: item.CODIGO, label: item.DESCRICAO })));
            });
        };

        if (!exam || exam?.length === 0) reqTypeExam();
    }, [openModal, exam]);

    if (openModal === null || openModal === undefined) {
        return null;
    }

    const onChangeCPF = async (value: NumberFormatValues) => {
        if (value.value.length === 11) {
            try {
                setLoadingFun(true)
                const request = await api();
                const response = await request.get<EmployeeDomain[]>(
                    `/agenda/funcionarios`,
                    {
                        params: {
                            FUNCPF: value.value
                        },
                    }
                )
                if (response.data.length > 0) {
                    setFuncionario(response.data[0])
                } else {
                    resetPersistent(true)
                }
            } catch (e) {
                toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
            } finally {
                setLoadingFun(false)
            }
        }
    }

    return (
        <Modal
            open={openModal}
            onClose={() => {
                setFuncionario(null);
                onClose()
            }}
            closeAfterTransition
            className={classes.modal}
            BackdropComponent={Backdrop}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <div className={classes.paper}>
                    <Typography
                        align="left"
                        variant="h5"
                        style={{
                            fontWeight: "bold"
                        }}
                    >
                        Informações sobre o funcionário
                    </Typography>
                    <Form<formCPF>
                        buttons={({ handleSubmit }) => (
                            <Grid
                                style={{
                                    gap: "10px",
                                    marginTop: "25px",
                                }}
                                container
                                justifyContent="flex-end"
                            >
                                <Grid lg={3}>
                                    <OutlinedLoadingButton
                                        fullWidth
                                        onClick={() => {
                                            setFuncionario(null)
                                            onClose()
                                        }}
                                    >
                                        Cancelar
                                    </OutlinedLoadingButton>
                                </Grid>
                                <Grid lg={3}>
                                    <ContainedLoadingButton
                                        fullWidth
                                        disabled={!funcionario}
                                        loading={loading || loadingFun}
                                        onClick={handleSubmit((data: formCPF) => {
                                            onSubmit(data, setLoading)
                                            setFuncionario(null)
                                        })}
                                    >
                                        Confirmar
                                    </ContainedLoadingButton>
                                </Grid>
                            </Grid>
                        )}
                        schema={[
                            {
                                content: [
                                    {
                                        lg: 6,
                                        name: "FUNCPF",
                                        type: "custom",
                                        styles: {
                                            display: "flex",
                                            justifyContent: "center"
                                        },
                                        customComponent: ({ control, name, error }) => (
                                            <MaskedPro
                                                style={{
                                                    width: "95%"
                                                }}
                                                label="CPF"
                                                name={name}
                                                error={error}
                                                control={control}
                                                format={formatCPF}
                                                loading={loadingFun}
                                                onChangeItem={onChangeCPF}
                                                helperText={funcionario?.FUNNOME}
                                                rules={{
                                                    required: true,
                                                    validate: { isValidCnpjOrCpf: isValidCnpjOrCpf },
                                                }}
                                            />
                                        )
                                    },
                                    {
                                        lg: 6,
                                        type: "select",
                                        name: "TIPOEXAME",
                                        styles: {
                                            display: "flex",
                                            justifyContent: "center"
                                        },
                                        customComponent: ({ control, name, error }) => (
                                            <AutoCompleteDef
                                                name={name}
                                                style={{
                                                    width: "95%"
                                                }}
                                                rules={{
                                                    required: true
                                                }}
                                                error={error}
                                                multiple={false}
                                                disabled={false}
                                                control={control}
                                                defOptions={exam}
                                                key={exam?.length}
                                                label="Tipo do exame"
                                            />
                                        )
                                    }
                                ],
                            }
                        ]}
                    />
                </div>
            </Fade>
        </Modal>
    );
}
