import {
  Box,
  Button,
  Typography,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  List,
  CircularProgress,
  Paper,
  Dialog,
  DialogTitle,
  makeStyles,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";

import { ButtonStatusProps, CustomTableProps, ModalProps } from "./types";
import format from "date-fns/format";
import { api } from "../../shared/services/api";
import InputDate from "../../shared/Form/Fields/Date";
import { formatCPFCNPJ } from "../../utils/formats";
import { openFile } from "./utils";
import { Form } from "../../shared";

export function ButtonStatus({
  title,
  handleClick,
  icon,
  value,
  isActive,
  color,
}: ButtonStatusProps) {
  const borderColor = isActive ? color : "#dbd5d5";

  return (
    <Button
      disableElevation
      disabled={!value}
      variant="contained"
      onClick={handleClick}
      style={{
        minWidth: "250px",
        maxWidth: "250px",
        border: `3px solid ${borderColor}`,
        backgroundColor: "rgb(250, 250, 250)",
        borderRadius: "10px",
      }}
    >
      <Box style={{ textTransform: "none", padding: "10px 0" }}>
        <Box style={{ marginBottom: "5px" }}>
          <Typography style={{ fontWeight: "bolder", fontSize: "1.05rem" }}>
            {title}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon && icon}
          <Typography style={{ marginLeft: "5px", fontSize: "40px" }}>
            {value ?? 0}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
}

export function CustomTable({
  data,
  numSelected,
  rowCount,
  onSelectAllClick,
  isSelected,
  changeCheck,
  handleClickSearch,
  setLoadingLogs,
}: CustomTableProps) {
  return (
    <div>
      <TableContainer>
        <Table aria-label="table-funcs">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "select all funcs" }}
                />
              </TableCell>
              <TableCell style={{ fontSize: "15px" }}>
                Nome do funcionário
              </TableCell>
              <TableCell>CPF</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Status ESocial</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, idx) => {
              const isItemSelected = isSelected(item);
              return (
                <TableRow
                  key={idx}
                  onClick={() => {}}
                  hover={true}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onChange={(event) => {
                        changeCheck(item);
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ fontSize: "15px" }}>
                    {item.FUNNOME}
                  </TableCell>
                  <TableCell>{formatCPFCNPJ(item.FUNCPF as string)}</TableCell>
                  <TableCell>{item.ESOCSTATUS}</TableCell>
                  <TableCell>{item.LOGSTATUS}</TableCell>
                  <TableCell>
                    <Box
                      style={{
                        margin: "0 auto",
                        width: "85px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.ESOCCODIGO && (
                        <Tooltip title="Ver informações">
                          <IconButton
                            style={{ height: "25px" }}
                            onClick={() => {
                              handleClickSearch(item);
                              setLoadingLogs(true);
                            }}
                          >
                            <SearchIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )}

                      <Tooltip title="Imprimir arquivo">
                        <IconButton
                          style={{ height: "25px" }}
                          onClick={async () => {
                            const req = await api();
                            try {
                              if (item.ESOCTIPO === 1) {
                                const response = await req.get(
                                  `/esocial/relatorios?tipo=1&funcodigo=${item.FUNCODIGO}&feacodigo=${item.FEACODIGO}`
                                );
                                window.open(response.data.message);
                              } else {
                                const response = await req.get(
                                  `/esocial/relatorios?tipo=2&funcodigo=${item.FUNCODIGO}&ghecodigo=${item.GHECODIGO}`
                                );
                                window.open(response.data.message);
                              }
                            } catch (err) {}
                          }}
                        >
                          <PrintIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 800,
    maxHeight: "60vh",
    overflowY: "auto",
    margin: "0px auto",
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function Modal({
  openStatus,
  setOpenStatus,
  item,
  setResultProtocolo,
  resultProtocolo,
  loadingLogs,
  protocolo,
  logProtocolo,
  setProtocolo,
  setLoadingLogs,
}: ModalProps) {
  const classes = useStyles();

  return (
    <div style={{ margin: "0 auto" }}>
      {openStatus ? (
        <Dialog
          onClose={() => {
            setOpenStatus(false);
            setResultProtocolo("");
          }}
          aria-labelledby="simple-dialog-title"
          open={openStatus}
          fullWidth
        >
          <DialogTitle id="simple-dialog-title">
            <Typography
              variant="h5"
              style={{ textTransform: "uppercase", paddingTop: "20px" }}
            >
              {`Consultando - ${
                item.FUNNOME ? item.FUNNOME : item.IDEVINCULO_NOMETRAB
              }`}
            </Typography>
          </DialogTitle>
          <Paper className={classes.root}>
            {loadingLogs ? (
              <div
                style={{
                  marginLeft: "45%",
                  marginBottom: "10%",
                  marginTop: "10%",
                }}
              >
                <CircularProgress />
              </div>
            ) : resultProtocolo !== "" ? (
              <List style={{ marginLeft: "5%", marginBottom: "5%" }}>
                <p>
                  <span style={{ fontWeight: "bold" }}>
                    Resultado do protocolo: {protocolo}
                  </span>
                </p>
                <p>{resultProtocolo}</p>
              </List>
            ) : logProtocolo.length > 0 ? (
              <>
                <List style={{ marginLeft: "2%", marginBottom: "5%" }}>
                  {logProtocolo.map((element) => {
                    return (
                      <>
                        <div>
                          <div
                            style={{
                              margin: "10px 10px 20px 0px",
                              fontSize: 19,
                              padding: 15,
                              border: "3px solid #CECECE",
                              borderRadius: 10,
                            }}
                          >
                            <table>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Data e Hora:{" "}
                                </span>{" "}
                                {moment(element.LOGDATAHORA).isValid()
                                  ? moment(
                                      new Date(element.LOGDATAHORA)
                                    ).format("DD/MM/YYYY HH:mm:ss")
                                  : ""}{" "}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Status:{" "}
                                </span>{" "}
                                {element.LOGSTATUS.replaceAll('"', "")}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Protocolo:{" "}
                                </span>
                                {element.LOGPROTOCOLO ? (
                                  <button
                                    onClick={() => {
                                      async function Consulta() {
                                        try {
                                          setLoadingLogs(true);
                                          const req = await api();
                                          const response = await req.get(
                                            `/eSocial/${
                                              item.ESOCTIPO === 1
                                                ? "S2220"
                                                : "S2240"
                                            }/${
                                              element.ESOCCODIGO
                                            }/status?logprotocolo=${
                                              element.LOGPROTOCOLO
                                            }`
                                          );
                                          setProtocolo(element.LOGPROTOCOLO);
                                          setResultProtocolo(
                                            response.data.message
                                          );
                                          setLoadingLogs(false);
                                        } catch (e) {
                                          setProtocolo(element.LOGPROTOCOLO);
                                          setResultProtocolo(
                                            "Falha ao consultar o protocolo. Contate o suporte."
                                          );
                                          setLoadingLogs(false);
                                        }
                                      }
                                      Consulta();
                                    }}
                                  >
                                    {element.LOGPROTOCOLO}
                                  </button>
                                ) : (
                                  ""
                                )}{" "}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Envio:{" "}
                                </span>
                                {element.LOGENVIO ? (
                                  <button
                                    onClick={async () => {
                                      await openFile(element.LOGARQENVIO);
                                    }}
                                  >
                                    Clique para efetuar o download do arquivo
                                    enviado
                                  </button>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Não existem arquivos de envio
                                  </span>
                                )}{" "}
                              </p>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  Retorno:{" "}
                                </span>
                                {element.LOGRETORNO ? (
                                  <button
                                    onClick={async () => {
                                      await openFile(element.LOGARQRETORNO);
                                    }}
                                  >
                                    Clique para efetuar o download do arquivo
                                    retornado
                                  </button>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Não existem arquivos de retorno
                                  </span>
                                )}{" "}
                              </p>
                            </table>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </List>
              </>
            ) : (
              "Sem logs"
            )}
          </Paper>
          <DialogTitle>
            <Button
              style={{ marginLeft: "87%" }}
              color="secondary"
              onClick={() => {
                if (resultProtocolo !== "") {
                  setResultProtocolo("");
                } else {
                  setOpenStatus(false);
                }
              }}
            >
              Voltar
            </Button>
          </DialogTitle>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
}

function generateMenuItem(
  item: { value: string | number; label: string },
  index
): JSX.Element {
  return (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  );
}

const optionsFilter = [
  {
    value: 1,
    label: "Últimos 7 dias",
  },
  {
    value: 2,
    label: "Últimos 30 dias",
  },
  {
    value: 4,
    label: "Todos",
  },
  {
    value: 3,
    label: "Escolher data",
  },
];

function SelectFilterDate({ handleChange }) {
  return (
    <Box style={{ marginBottom: 20 }}>
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <InputLabel id={`input-label`}>
          Selecionar intervalo de tempo
        </InputLabel>
        <Select
          label="Selecionar intervalo de tempo"
          labelId={`input-label`}
          onChange={handleChange}
        >
          {optionsFilter.map((filter, idx) => generateMenuItem(filter, idx))}
        </Select>
      </FormControl>
    </Box>
  );
}

function FormInputDate({ setDataInicial, setDataFinal }) {
  return (
    <Box style={{ display: "flex", justifyContent: "space-between" }}>
      <Form
        onSubmit={async (data: any) => {
          setDataInicial(format(new Date(data.dataInicial), "yyyy-MM-dd"));
          setDataFinal(format(new Date(data.dataFinal), "yyyy-MM-dd"));
        }}
        buttons={() => (
          <div style={{ margin: "15px 0px 0px 0px" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth
            >
              Filtrar
            </Button>
          </div>
        )}
        schema={[
          {
            content: [
              {
                lg: 6,
                type: "custom",
                customDefValue: moment().format("MM/DD/YYYY"),
                customComponent: ({ control }) => (
                  <div style={{ marginRight: 5 }}>
                    <InputDate
                      control={control}
                      label="Inicial"
                      name="dataInicial"
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  </div>
                ),
              },
              {
                lg: 6,
                name: "date",
                type: "custom",
                customDefValue: moment().format("MM/DD/YYYY"),
                customComponent: ({ control }) => (
                  <div style={{ marginLeft: 5 }}>
                    <InputDate
                      control={control}
                      label="Final"
                      name="dataFinal"
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  </div>
                ),
              },
            ],
          },
        ]}
      />
    </Box>
  );
}
function getLabel(timeFilter) {
  return timeFilter === 0 || timeFilter === 4
    ? "Todos"
    : timeFilter === 1
    ? "ÚLtimos 7 dias"
    : timeFilter === 2
    ? "Últimos 30 dias"
    : "Outro";
}
export function SelectDate({
  setTimeFilter,
  handleClick,
  handleClose,
  timeFilter,
  anchorEl,
  setDataInicial,
  setDataFinal,
}) {
  function handleChange(event) {
    if (event.target.value === 3) {
      setDataInicial(null);
      setDataFinal(null);
    }
    setTimeFilter(event.target.value);
  }

  return (
    <>
      <div
        style={{
          maxWidth: 400,
          marginRight: 30,
          marginLeft: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              minWidth: "100px",
              textTransform: "uppercase",
              marginRight: 15,
            }}
          >
            <Chip
              label={`Filtro aplicado: ${getLabel(timeFilter)}`}
              color="primary"
              variant="outlined"
              clickable
              size="medium"
            />
          </div>
          <div>
            <Button
              onClick={handleClick}
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<FilterListIcon />}
            >
              Filtros
            </Button>
          </div>
        </Box>
      </div>
      <Menu
        id="simple-menu"
        getContentAnchorEl={null}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div style={{ minWidth: 450, borderRadius: 30, padding: 15 }}>
          <SelectFilterDate handleChange={handleChange} />
          {timeFilter === 3 && (
            <FormInputDate
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
            />
          )}
        </div>
      </Menu>
    </>
  );
}
