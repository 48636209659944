import CrudReorder from "../../shared/CrudReorder";
import NewButton from "../../shared/Button/NewButton";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { useFetch } from "../../shared/services/useFetch";

export default function CatPage() {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { id } = params;

  const { data: content } = useFetch<any[]>(`/clinico/funcionarios/${id}/cat`);
  const openNew = () => history.push(`/pages/editarFuncionario/${id}/cat/0`);

  return (
    <CrudReorder<any>
      content={content}
      actions={
        <>
          <NewButton access="05001005-I" openNew={openNew} />
        </>
      }
      edit
      onEditClick={({ CATCODIGO }: { CATCODIGO: number }) => {
        history.push(`/pages/editarFuncionario/${id}/cat/${CATCODIGO}`);
      }}
      schema={[
        {
          label: "Número",
          content: "CATNUMERO",
        },
        {
          label: "Data",
          content: "CATDATA",
          formatFun: (data: Date) => {
            if (moment(data).isValid()) {
              return moment(data).format("DD/MM/YYYY");
            }
            return "";
          },
        },
        {
          label: "Descrição",
          content: "CATDESCRICAO",
        },
      ]}
    />
  );
}
