import { api } from "../../shared/services/api";
import moment from 'moment';
import { compare } from "./utils";
import { FuncType } from "./types";

export const getStatus = async (dateFinal = null, dateInitial = moment(new Date()).format('YYYY-MM-DD')) => {
  const req = await api();
  const params = {
    DATAINICIAL: dateInitial,
    DATAFINAL: dateFinal
  };
  try {
    const res = await req.get('/esocial/dashboard', { params });
    return [res.data, res.data.sort(compare)];
  } catch (err) {
    /**/
  }
}

export const getFuncsStatus = async (code, filterSelect, dateFinal = null, dateInitial = moment(new Date()).format('YYYY-MM-DD')) => {
  const req = await api();
  const params = {
    DATAINICIAL: dateInitial,
    DATAFINAL: dateFinal
  };
  try {
    const res = await req.get<FuncType[]>(`/esocial/dashboard/${code}?tipoeSocial=${filterSelect}`, { params  });
    if (res.status === 200) {
      if (Array.isArray(res.data)) {
        const funcs = res.data.map((func, idx) => ({ ...func, idx }));
        return funcs;
      }
    }
    return [];
  } catch (err) {
    return []
  }
}
