import { useRef, useState } from "react";
import { Typography, Box } from "@material-ui/core";

import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { Form } from "../../shared";
import { toast } from "react-toastify";

export default function TrocarSenha(props) {
  const getValuesRef = useRef<any>();
  const [laoding, setLaoding] = useState(false);

  const trocarSenhaInside = (data) => {
    async function senha() {
      setLaoding(true);
      const req = await api();
      await req
        .put("/users/senha", data)
        .then((res) => {
          toast.success("Senha alterada com sucesso");
        })
        .catch((error) => {
          toast.error(
            error.response.data?.Message ?? "Falha ao trocar a senha"
          );
        })
        .finally(() => {
          setLaoding(false);
        });
    }
    senha();
  };

  const passwordsEqual = (v) => {
    if (getValuesRef.current) {
      const confirmValue = getValuesRef.current(["SENHANOVA"]);

      if (confirmValue.SENHANOVA === v) {
        return undefined;
      } else {
        return "Campos 'Nova senha' e 'Confirmar nova senha' devem ter o mesmo valor";
      }
    } else {
      getValuesRef.current = null;
      return undefined;
    }
  };

  return (
    <Box style={{ margin: "0 10px" }}>
      <Typography variant="subtitle2">Trocar senha</Typography>
      <br />
      <Form
        spacing="10px 0px"
        buttons={() => (
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            style={{ width: "100%", marginTop: 5 }}
            size="large"
            loading={laoding}
          >
            Salvar
          </LoadingButton>
        )}
        onSubmit={trocarSenhaInside}
        schema={[
          {
            content: [
              {
                label: "Senha atual",
                name: "SENHA",
                lg: 12,
                rules: { required: "Campo Obrigatório" },
                inputPropsAdittional: { type: "password" },
              },
              {
                label: "Nova senha",
                name: "SENHANOVA",
                lg: 12,
                rules: {
                  required: "Campo Obrigatório",
                  minLength: {
                    value: 6,
                    message: "Tamanho minimo 6 caracteres",
                  },
                },
                inputPropsAdittional: { type: "password" },
              },
              {
                label: "Confirmar nova senha",
                name: "SENHACONFIRMADA",
                lg: 12,
                rules: {
                  required: "Campo Obrigatório",
                  validate: {
                    passwordsEqual,
                  },
                },
                inputPropsAdittional: { type: "password" },
              },
              {
                type: "custom",
                lg: false,
                customComponent: ({ getValues }) => {
                  if (!getValuesRef.current) getValuesRef.current = getValues;
                  return <></>;
                },
              },
            ],
          },
        ]}
      />
    </Box>
  );
}
