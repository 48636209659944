import { useEffect, useState, forwardRef } from "react";
import { FormProvider, useForm, UseFormMethods } from "react-hook-form";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Dialog,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  SlideProps,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoadingButton from "../Button/LoadingButton";

interface FormProps<T = any> {
  children: JSX.Element;
  defaultValues?: T;
}

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalConfirm({
  openModal,
  title,
  description,
  cancelar,
  confirmar,
}: {
  description: null | string | JSX.Element;
  title: JSX.Element | string;
  openModal: boolean;
  cancelar: () => void;
  confirmar: () => void;
}) {
  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={cancelar}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      )}
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={cancelar}>Cancelar</Button>
        <Button
          data-cy="confirm-button"
          onClick={confirmar}
          color="primary"
          variant="contained"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface StepperFormProps {
  getStepContent: (index: number, methods: UseFormMethods<unknown>) => any;
  getSteps: () => any;
  orientation?: "vertical" | "horizontal";
  content: any;
  onSubmit: (data: unknown) => void;
  loadingSubmit: boolean;
  loadingDelete: boolean;
  disabledDestroy: boolean;
  onClickDelete?: () => void;
  onChangeStep: (
    data: unknown,
    resetForm: (data: unknown) => void,
    idx?: number
  ) => void;
  handleCancel: () => void;
  schema?: any;
  type: "create" | "edit";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginLeft: "auto",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      position: "fixed",
      bottom: 0,
      gap: 8,
      right: 40,
      background: "white",
      width: "100%",
      padding: "15px 0px",
      zIndex: 1,
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    stepper: {
      display: "flex",
      flexDirection: "column",
    },
    delete: {
      color: "white",
      background: theme.palette.error.main,
      "&:hover": {
        background: theme.palette.error.main,
      },
      "&:disabled": {
        background: "transparent",
      },
    },
  })
);

function Form({ children }: FormProps) {
  return (
    <div style={{ padding: "18px", marginBottom: "50px" }}>{children}</div>
  );
}

export default function StepperForm({
  getStepContent,
  getSteps,
  orientation = "vertical",
  content,
  onSubmit,
  onChangeStep,
  loadingSubmit,
  loadingDelete,
  disabledDestroy,
  onClickDelete,
  handleCancel,
  schema,
  type,
}: StepperFormProps) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (window && localStorage) {
      const cache = localStorage.getItem("@ProSESMT:Form");
      setOpen(!!cache);
    }
  }, []);

  const methods = useForm({
    defaultValues: content,
  });

  const handleNext = (data: any) => {
    onChangeStep(data, methods.reset, activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (data: any) => {
    onChangeStep(data, methods.reset, activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCancel = () => {
    localStorage.removeItem("@ProSESMT:Form");
    setOpen(false);
  };

  const onConfirm = () => {
    const cache = localStorage.getItem("@ProSESMT:Form");
    onChangeStep(JSON.parse(cache), methods.reset);
    setOpen(false);
  };

  return (
    <>
      <ModalConfirm
        openModal={open}
        title="Existem dados salvos anteriormente"
        cancelar={onCancel}
        confirmar={onConfirm}
        description="Deseja restaurá-los?"
      />
      <FormProvider {...methods}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} orientation={orientation}>
            {steps.map((label: string) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={classes.stepper}>
            <Form>{getStepContent(activeStep, methods)}</Form>
            <div className={classes.actionsContainer}>
              {handleCancel && (
                <Button onClick={handleCancel} color="secondary">
                  Cancelar
                </Button>
              )}
              {type === "edit" && (
                <LoadingButton
                  loading={loadingDelete}
                  className={[classes.delete, classes.button]}
                  variant="outlined"
                  type="button"
                  disabled={disabledDestroy}
                  data-cy={`delete-button`}
                  onClick={() => onClickDelete()}
                >
                  Excluir
                </LoadingButton>
              )}
              <Button
                disabled={activeStep === 0}
                onClick={methods.handleSubmit(handleBack)}
                className={classes.button}
                variant="outlined"
                color="primary"
              >
                Anterior
              </Button>
              {activeStep === steps.length - 1 ? (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={methods.handleSubmit(onSubmit)}
                  className={classes.button}
                  loading={loadingSubmit}
                >
                  Finalizar
                </LoadingButton>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={methods.handleSubmit(handleNext)}
                  className={classes.button}
                >
                  Próximo
                </Button>
              )}
            </div>
          </div>
        </div>
      </FormProvider>
    </>
  );
}
