import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootModel } from '../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setDate } from "../../redux/reducers/dateReducer";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { Backdrop, Fade, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import { ContainedLoadingButton, OutlinedLoadingButton } from "../Button/LoadingButton";
import { addDays, format, parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        gap: "21px",
        width: "535px",
        outline: "none",
        display: "flex",
        padding: "21px",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
    },
}));

export type formCPF = { FUNCPF: string; TIPOEXAME: number }

export const ModalClinico = () => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);

    const { date, provider, schedule } = useSelector<RootModel, RootModel>((state) => state);
    const { isComplementar, setIsComplementar, setPreviousSchedule, setIsClinico } = useStatePersistent();

    return (
        <Modal
            closeAfterTransition
            open={isComplementar && open}
            className={classes.modal}
            BackdropComponent={Backdrop}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade
                in={isComplementar && open}
            >
                <div className={classes.paper}>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Deseja Agendar o Exame Clínico do Funcionário {schedule[0]?.Employee?.FUNNOME}?
                    </Typography>
                    <Grid
                        container
                        justifyContent="flex-end"
                    >
                        <Grid lg={6}>
                            <OutlinedLoadingButton
                                onClick={() => {
                                    setOpen(false)
                                }}
                            >
                                Agendar Posteriormente
                            </OutlinedLoadingButton>
                        </Grid>
                        <Grid lg={3}>
                            <ContainedLoadingButton
                                onClick={() => {
                                    setIsComplementar(false)
                                    setIsClinico(true)
                                    let agendamento = []
                                    schedule.forEach(async (element) => {
                                        if (!element.Employee.ESOCCATEGCODIGO) {
                                            element.Employee.ESOCCATEGCODIGO = null;
                                        }
                                        agendamento = [
                                            ...agendamento,
                                            {
                                                TIPO: provider.TIPO,
                                                EXAMES: element.Exam,
                                                FILES: element.files,
                                                PROCODIGO: provider.PROCODIGO,
                                                FUNCIONARIO: element.Employee,
                                                SOLICITACAO: element.Solicitation,
                                                ATEOBSERVACAO: element.Observation,
                                                ATETIPOEXAME: element.ExamType.CODIGO,
                                                ATEHORA: element.Time.substring(0, 8),
                                                ATEDATA: moment(date).format("YYYY-MM-DD"),
                                                ATEDATAHORA: moment(date).format("YYYY-MM-DD"),
                                                NOTIFICAFUNCIONARIO: element.Employee.NOTIFICAFUNCIONARIO
                                            },
                                        ];
                                    });
                                    const dateFormat = "MM/dd/yyyy";
                                    const dateFormated = parse(date, dateFormat, new Date());
                                    const newDate = addDays(dateFormated, 2);
                                    const newDateString = format(newDate, dateFormat);
                                    dispatch(setDate(newDateString))
                                    setPreviousSchedule(agendamento[0])
                                    history.push("/pages/prestador")
                                }}
                            >
                                Confirmar
                            </ContainedLoadingButton>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}
