import { useState } from "react";
import { toast } from "react-toastify";

import { api } from "../shared/services/api";
import createWorksheet, { Headers } from "../utils/xlsx";

export function useReportExcel<T = any>(
  url: string,
  reportName: string,
  headers: Headers<T>[]
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onReport = async (params: any) => {
    setIsLoading(true);
    try {
      const req = await api();
      const response = await req.get(url, { params });

      if (!response.data.length) {
        toast.warning("Não existem dados pra geração da planilha");
        return;
      }

      await createWorksheet<any>({
        name: reportName,
        headers,
        title: reportName,
        data: response.data,
      });

      toast.success(`Planilha de ${reportName} gerada com sucesso`);
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, onReport };
}
