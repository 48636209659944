import Exams from "./exames";
import { useSchedule } from ".";
import Horario from "./horario";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import TipoExame from "./tipoExame";
import Finalizar from "./finalizar";
import EmployeeData from "./emp/data";
import { toast } from "react-toastify";
import Step from "@material-ui/core/Step";
import { useHistory } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import { api } from "../../shared/services/api";
import { formatUSDate } from "../../utils/formats";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { useState, useEffect, useCallback, createContext, Dispatch, SetStateAction, useContext } from "react";
import { format, parse } from "date-fns";

var def: {
  activeStep?: number;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  verifyDuplicity?: (redirect: boolean) => Promise<void>;
} = {};

const defFilesExamesContext: {
  files?: File[];
  addFiles?: any;
  removeFile?: any;
} = {};

let interval;

const SetepperContext = createContext(def);

const FilesExamesContext = createContext(defFilesExamesContext);

export const useSetepper = () => {
  return useContext(SetepperContext);
};

export const useFilesExames = () => {
  return useContext(FilesExamesContext);
};

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <TipoExame />;
    case 1:
      return <Horario />;
    case 2:
      return <EmployeeData />;
    case 3:
      return <Exams />;
    case 4:
      return <Finalizar />;
    default:
      return "Unknown step";
  }
}

const onBeforeUnload = (event) => {
  event.preventDefault();
  window.confirm("Dados ainda não foram salvos tem certeza que deseja sair?");
  return "";
};

export default function VerticalLinearStepperEmployee() {

  const history = useHistory()
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();
  const step = useStepperProvider();
  const { schedule } = useSchedule();
  const [redirect, setRedirect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { files, addFiles, removeFile } = useFilesExamesProvider();

  useEffect(() => {
    window.onbeforeunload = onBeforeUnload;
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const verifyDuplicity = async (redirect: boolean) => {
    try {
      const params = Cookies.get("@PortalWEB:timeSelected") ? JSON.parse(Cookies.get("@PortalWEB:timeSelected")) : null
      if (params?.TIPO == 2 || !params) return
      const instance = await api()
      const parsedDate = parse(params?.proData, 'MM/dd/yyyy', new Date());
      const formattedDate = format(parsedDate, 'yyyy-MM-dd')
      const response = await instance.get("/agenda/agendamentos-simultaneos", {
        params: {
          ...params,
          proData: formattedDate
        }
      })
      if (response.data.AGENDAMENTO_SIMULTANEO) {
        if (redirect) setRedirect(true)
        setOpenModal(true)
      }
      else if (redirect) history.push("/pages/finalizar")
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
    }
  }

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }

    if (step.activeStep > 1) {
      interval = setInterval(() => {
        verifyDuplicity(false)
      }, 15000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [step.activeStep]);

  const steps = [
    <div>
      Tipo de Exame:{" "}
      <span style={{ color: "grey" }}>
        {schedule?.ExamType?.DESCRICAO && schedule?.ExamType?.DESCRICAO}
      </span>
    </div>,
    <div>
      Selecione um horário para o agendamento:{" "}
      <span style={{ color: "grey" }}>
        {schedule?._Periodo && schedule?._Periodo}
      </span>
    </div>,
    <div>
      Dados do funcionário:{" "}
      <span style={{ color: "grey" }}>
        {schedule?.Employee?.FUNNOME &&
          schedule?.Employee?.FUNCPF &&
          schedule?.Employee?.FUNCPF + " - " + schedule?.Employee?.FUNNOME}
      </span>
    </div>,
    "Selecione os exames que deseja realizar",
    "Finalizar",
  ];

  return (
    <div className={classes.root}>
      <SetepperContext.Provider value={{
        ...step,
        verifyDuplicity
      }}>
        <FilesExamesContext.Provider value={{ files, addFiles, removeFile }}>
          <Stepper activeStep={step.activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{getStepContent(index)}</StepContent>
              </Step>
            ))}
            <Dialog
              maxWidth="lg"
              open={openModal}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                O Horário Escolhido já foi Selecionado!
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" color="textSecondary">
                  Para continuar, escolha outro horário para o agendamento!
                </Typography>
                <Horario
                  modal={
                    () => {
                      setOpenModal(false)
                      toast.success("Horário alterado com sucesso!")
                      if (redirect) history.push("/pages/finalizar")
                    }
                  }
                />
              </DialogContent>
            </Dialog>
          </Stepper>
        </FilesExamesContext.Provider>
      </SetepperContext.Provider>
    </div>
  );
}

export const useStepperProvider = () => {
  const history = useHistory();
  const [activeStep, _] = useState(0);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    setAccessToken(localStorage.getItem("Token_Key"));
  }, []);

  // useEffect(() => {
  //   const timeRegistered = Cookies.get("@PortalWEB:timeSelected") ? JSON.parse(Cookies.get("@PortalWEB:timeSelected")) : null;
  //   if (activeStep == 1 || activeStep == 0 && timeRegistered) {
  //     (
  //       async () => {
  //         try {
  //           const instance = await api()
  //           await instance.post("/agenda/finaliza-agendamentos", {
  //             ...timeRegistered,
  //             proData: formatUSDate(timeRegistered.proData).toISOString().split("T")[0]
  //           })
  //           Cookies.remove("@PortalWEB:timeSelected")
  //         } catch (e) {
  //           toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
  //         }
  //       }
  //     )()
  //   }
  // }, [activeStep])

  const setActiveStep = (step: any) => {
    if (accessToken !== localStorage.getItem("Token_Key")) {
      toast.warning(
        "Foram detectadas incongruências no processo de agendamento"
      );
      history.push("/pages");
    }
    _(step);
  };

  return {
    activeStep,
    setActiveStep,
  };
};

export const useFilesExamesProvider = () => {
  const [files, setFiles] = useState([]);

  const addFiles = useCallback((newFiles) => {
    const formatFiles = newFiles.map((file) => {
      return {
        id: uuid(),
        file,
      };
    });
    setFiles((files) => [...files, ...formatFiles]);
  }, []);

  const removeFile = useCallback((id) => {
    setFiles((files) => {
      return files.filter((file) => {
        return file.id !== id;
      });
    });
  }, []);

  return {
    files,
    addFiles,
    removeFile,
  };
};
