import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grid,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
} from "@material-ui/core";
import { api } from "../../shared/services/api";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DescriptionIcon from "@material-ui/icons/Description";
import ErrorIcon from "@material-ui/icons/Error";
import BackupIcon from "@material-ui/icons/Backup";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { toast } from "react-toastify";
import { FuncType, InfoType } from "./types";
import SimpleTabs from "./tabs";
import { getFilterText } from "./utils";
import { ButtonStatus, CustomTable, Modal, SelectDate } from "./components";
import "./styles.css";
import moment from "moment";
import { getFuncsStatus, getStatus } from "./requests";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: "10px",
      "& .item": {
        marginBottom: "10px",
      },
    },
  },
}));

function feederror(message: string): void {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

async function gerarPDF(esoccodigo) {
  const req = await api();
  try {
    const res = await req.get(`/esocial/dashboard/${esoccodigo}/relatorios`);
    if (res.status === 200) {
      window.open(res.data.message);
    }
  } catch (err) {
    feederror(err.response.data.message);
  }
}

async function enviaXml(
  selecionados: FuncType[],
  typeESocial: number,
  typeDownload: number,
  assinado = false
): Promise<any> {
  let newData: any[] = [];

  newData = selecionados.map((item) =>
    item.ESOCTIPO === 1
      ? {
          FEACODIGO: item.FEACODIGO,
          FUNCODIGO: item.FUNCODIGO,
          ...(assinado && { XmlAssinado: 1 }),
        }
      : {
          FUNCODIGO: item.FUNCODIGO,
          GHECODIGO: item.GHECODIGO,
          FCOCODIGO: item.FCOCODIGO,
          ...(assinado && { XmlAssinado: 1 }),
        }
  );
  const req = await api();
  try {
    const res = await req.post(
      `/eSocial/${
        typeESocial === 1 ? "S2220" : "S2240"
      }/exportarXML?tipoExportacao=${typeDownload}`,
      newData
    );
    if (res.status === 200) {
      if (typeDownload === 1) {
        window.open(res.data.message);
        return;
      }
      toast.success(res.data.message);
    }
  } catch (err) {
    // eslint-disable-next-line no-useless-escape
    let message = err.response.data.message.split(/\"/);
    message = message.filter((m) => Boolean(m))[0];
    feederror(message);
  }
}

function ButtonsActions({
  isPermittedGov,
  selecionados,
  typeESocial,
  esoccodigo,
  onAfterSend,
}: {
  isPermittedGov: boolean;
  selecionados: FuncType[];
  typeESocial: number;
  esoccodigo: number;
  onAfterSend: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(false);
  const [isLoadingGOV, setIsLoadingGOV] = useState<boolean>(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Popper
        style={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="simple-menu" autoFocusItem>
                  <MenuItem
                    style={{ fontWeight: 500 }}
                    onClick={async () => {
                      if (!isPermittedGov) {
                        feederror("Selecione ao menos um funcionário");
                      } else {
                        setIsLoadingEvents(true);
                        await enviaXml(selecionados, typeESocial, 1, true);
                        setIsLoadingEvents(false);
                      }
                    }}
                  >
                    Exportar XML Assinado
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button
          startIcon={<BackupIcon />}
          endIcon={
            isLoadingGOV ? <CircularProgress color="inherit" size={20} /> : null
          }
          color="primary"
          variant="contained"
          style={{
            marginRight: "10px",
            borderRadius: "10px",
            textTransform: "none",
          }}
          onClick={async () => {
            if (!isPermittedGov) {
              feederror("Selecione ao menos um funcionário");
            } else {
              setIsLoadingGOV(true);
              await enviaXml(selecionados, typeESocial, 2);
              setIsLoadingGOV(false);
              onAfterSend(true);
            }
            if (onAfterSend) {
              onAfterSend(false);
            }
          }}
        >
          Enviar para o Governo
        </Button>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          style={{
            borderRadius: "10px",
            marginRight: "10px",
          }}
        >
          <Button
            startIcon={<DescriptionIcon />}
            color="default"
            variant="contained"
            style={{
              textTransform: "none",
            }}
            endIcon={
              isLoadingEvents ? (
                <CircularProgress color="inherit" size={20} />
              ) : null
            }
            onClick={async () => {
              if (!isPermittedGov) {
                feederror("Selecione ao menos um funcionário");
              } else {
                setIsLoadingEvents(true);
                await enviaXml(selecionados, typeESocial, 1);
                setIsLoadingEvents(false);
              }
            }}
          >
            Exportar XML
          </Button>
          <Button
            endIcon={<KeyboardArrowDownIcon fontSize="small" />}
            color="default"
            variant="contained"
            onClick={handleToggle}
          />
        </ButtonGroup>
        <Button
          startIcon={<PictureAsPdfIcon />}
          color="default"
          variant="contained"
          style={{ borderRadius: "10px", textTransform: "none" }}
          endIcon={
            isLoadingPDF ? <CircularProgress color="inherit" size={20} /> : null
          }
          onClick={async () => {
            setIsLoadingPDF(true);
            await gerarPDF(esoccodigo);
            setIsLoadingPDF(false);
          }}
        >
          Exportar PDF
        </Button>
      </Box>
    </>
  );
}

export default function EsocialDashboard() {
  const [info, setInfo] = useState<InfoType[]>([]);
  const [funcs, setFuncs] = useState<FuncType[]>([]);
  const [pendentes, setPendentes] = useState<InfoType>({} as InfoType);
  const [falhas, setFalhas] = useState<InfoType>({} as InfoType);
  const [retornos, setRetornos] = useState<InfoType>({} as InfoType);
  const [sucessos, setSucessos] = useState<InfoType>({} as InfoType);
  const [code, setCode] = useState<number | null>(1);
  const [selected, setSelected] = useState<any[]>([]);
  const [filterSelect, setFilterSelect] = useState<number>(1);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [logProtocolo, setLogProtocolo] = useState<any[]>(null);
  const [resultProtocolo, setResultProtocolo] = useState("");
  const [protocolo, setProtocolo] = useState("");
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [funcSelect, setFuncSelect] = useState<null | FuncType>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timeFilter, setTimeFilter] = useState<number>(4);
  const [dataInicial, setDataInicial] = useState<string | null>();
  const [dataFinal, setDataFinal] = useState<string | null>();
  const [s2220, setS2220] = useState<number>(null);
  const [s2240, setS2240] = useState<number>(null);
  const [reloading, setReloading] = useState<boolean>(false);
  const [loadingFuncs, setLoadingFuncs] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorChecked, setErrorChecked] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (!code) {
      return;
    }
    const fetch = async () => {
      setLoadingFuncs(true);
      let funcs: any[] = [];
      let data: any = [];
      if (timeFilter === 1) {
        await getQuantity(
          moment(new Date()).add(7, "days").format("YYYY-MM-DD")
        );
        data = await getStatus(
          moment(new Date()).add(7, "days").format("YYYY-MM-DD")
        );
        funcs = await getFuncsStatus(
          code,
          filterSelect,
          moment(new Date()).add(7, "days").format("YYYY-MM-DD")
        );
      } else if (timeFilter === 2) {
        await getQuantity(
          moment(new Date()).add(30, "days").format("YYYY-MM-DD")
        );
        data = await getStatus(
          moment(new Date()).add(30, "days").format("YYYY-MM-DD")
        );
        funcs = await getFuncsStatus(
          code,
          filterSelect,
          moment(new Date()).add(30, "days").format("YYYY-MM-DD")
        );
      } else if (timeFilter === 3) {
        if (dataInicial && dataFinal) {
          await getQuantity(dataFinal, dataInicial);
          data = await getStatus(dataFinal, dataInicial);
          funcs = await getFuncsStatus(
            code,
            filterSelect,
            dataFinal,
            dataInicial
          );
        } else {
          return;
        }
      } else if (timeFilter === 0 || timeFilter === 4) {
        await getQuantity(null, null);
        data = await getStatus(null, null);
        funcs = await getFuncsStatus(code, filterSelect, null, null);
      }
      setFuncs(funcs);
      setInfo(data[0]);
      setPendentes(data[1][0]);
      setSucessos(data[1][1]);
      setFalhas(data[1][2]);
      setRetornos(data[1][3]);
      setLoadingFuncs(false);
    };
    if (errorChecked) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter, dataInicial, dataFinal]);

  async function get(func: FuncType) {
    const req = await api();
    if (func.ESOCCODIGO) {
      const response = await req.get(
        `/eSocial/${func.ESOCTIPO === 1 ? "S2220" : "S2240"}/${func.ESOCCODIGO}`
      );
      let logs = [];
      response.data.forEach((item) => {
        logs = [
          ...logs,
          {
            LOGCODIGO: item.LOGCODIGO,
            LOGTIPO: item.LOGTIPO,
            ESOCCODIGO: item.ESOCCODIGO,
            LOGENVIO: item.LOGENVIO,
            LOGRETORNO: item.LOGRETORNO,
            LOGSTATUS: item.LOGSTATUS,
            LOGPROTOCOLO: item.LOGPROTOCOLO,
            LOGARQENVIO: item.LOGARQENVIO,
            LOGARQRETORNO: item.LOGARQRETORNO,
            LOGDATAHORA: item.LOGDATAHORA,
          },
        ];
      });
      setLogProtocolo(logs);
    } else {
      setLogProtocolo([]);
    }
    setLoadingLogs(false);
  }

  function handleClickSearch(item: FuncType) {
    setOpenStatus(true);
    setFuncSelect(item);
    get(item);
  }

  const isSelected = (func: FuncType & { idx: number }) => {
    return (
      selected.findIndex(
        (elem) => elem.FUNCODIGO === func.FUNCODIGO && elem.idx === func.idx
      ) !== -1
    );
  };

  const changeCheck = useCallback(
    (func) => {
      if (
        selected.findIndex(
          (elem) => elem.FUNCODIGO === func.FUNCODIGO && elem.idx === func.idx
        ) !== -1
      ) {
        const newSelected = selected.filter((elem) => elem.idx !== func.idx);
        setSelected(newSelected);
        return;
      }
      setSelected((old) => [...old, func]);
    },
    [selected]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = funcs.map((func) => func);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  async function getQuantity(
    dateFinal = null,
    dateInitial = moment(new Date()).format("YYYY-MM-DD")
  ) {
    const req = await api();

    const params = {
      DATAINICIAL: dateInitial,
      DATAFINAL: dateFinal,
    };

    try {
      const s2220 = await (
        await req.get(`/esocial/dashboard/${code}?tipoeSocial=1`, { params })
      ).data.length;
      const s2240 = await (
        await req.get(`/esocial/dashboard/${code}?tipoeSocial=2`, { params })
      ).data.length;
      setS2220(s2220);
      setS2240(s2240);
    } catch (err) {}
  }

  useEffect(() => {
    setSelected([]);
    const fetch = async () => {
      setLoadingFuncs(true);
      let funcs: any[] = [];
      if (timeFilter === 1) {
        await getQuantity(
          moment(new Date()).add(7, "days").format("YYYY-MM-DD")
        );
        funcs = await getFuncsStatus(
          code,
          filterSelect,
          moment(new Date()).add(7, "days").format("YYYY-MM-DD")
        );
      } else if (timeFilter === 2) {
        await getQuantity(
          moment(new Date()).add(30, "days").format("YYYY-MM-DD")
        );
        funcs = await getFuncsStatus(
          code,
          filterSelect,
          moment(new Date()).add(30, "days").format("YYYY-MM-DD")
        );
      } else if (timeFilter === 3 && dataInicial && dataFinal) {
        await getQuantity(dataFinal, dataInicial);
        funcs = await getFuncsStatus(
          code,
          filterSelect,
          dataFinal,
          dataInicial
        );
      } else if (timeFilter === 0 || timeFilter === 4) {
        await getQuantity(null, null);
        funcs = await getFuncsStatus(code, filterSelect, null, null);
      }
      setFuncs(funcs);
      setLoadingFuncs(false);
    };
    if (code !== null) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, filterSelect, timeFilter, dataInicial, dataFinal]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get<InfoType[]>("/esocial/dashboard");
        if (res.status === 200) {
          setPendentes(res.data.find((item) => item.ESOCCODIGO === 1));
          setSucessos(res.data.find((item) => item.ESOCCODIGO === 2));
          setFalhas(res.data.find((item) => item.ESOCCODIGO === 3));
          setRetornos(res.data.find((item) => item.ESOCCODIGO === 4));
          setInfo(res.data);
          setErrorChecked(true);
        }
      } catch (err) {
        setErrorMessage(
          "Ops! Você precisa configurar o grupo pertencido pela empresa no eSocial!"
        );
      }
    };
    if (!reloading) {
      fetch();
    }
  }, [reloading]);

  return errorMessage ? (
    <>
      <div>
        <h1 style={{ justifyContent: "center", display: "flex" }}>
          {errorMessage}
        </h1>
        <br />
        <h3 style={{ justifyContent: "center", display: "flex" }}>
          No menu esquerdo, acesse a aba eSocial e então Configurações eSocial
          para ajustar o grupo
        </h3>
        <br />
        <p style={{ justifyContent: "center", display: "block" }}>
          <b>Grupo 1</b> - Empresas com faturamento anual superior a R$ 78
          milhões
          <br />
          <b>Grupo 2</b> - Entidades empresariais com faturamento no ano de 2016
          de até R$ 78.000.000,00 (setenta e oito milhões) e que não sejam
          optantes pelo Simples Nacional
          <br />
          <b>Grupo 3</b> - Pessoas Jurídicas - empregadores optantes pelo
          Simples Nacional e entidades sem fins lucrativos
          <br />
          <b>Grupo 4</b> - Órgãos públicos e organizações internacionais
        </p>
      </div>{" "}
    </>
  ) : (
    <Box>
      {info && info.length > 0 && (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <SelectDate
              handleClick={handleClick}
              handleClose={handleClose}
              timeFilter={timeFilter}
              anchorEl={anchorEl}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
              setTimeFilter={setTimeFilter}
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "space-around" }}>
            <ButtonStatus
              title="Pendentes de envio"
              value={pendentes.ESOCCONTADOR}
              isActive={code === 1}
              color="#479FE7"
              icon={<ErrorIcon style={{ color: "#479FE7", fontSize: 40 }} />}
              handleClick={() => setCode(1)}
            />
            <ButtonStatus
              title="Aguardando retorno"
              value={retornos.ESOCCONTADOR}
              isActive={code === 4}
              color="#dbdf00"
              icon={
                <AutorenewIcon style={{ color: "#dbdf00", fontSize: 40 }} />
              }
              handleClick={() => setCode(4)}
            />
            <ButtonStatus
              title="Com erro no envio"
              value={falhas.ESOCCONTADOR}
              isActive={code === 3}
              color="#EA0D04"
              icon={
                <AssignmentLateIcon
                  style={{ color: "#EA0D04", fontSize: 40 }}
                />
              }
              handleClick={() => setCode(3)}
            />
            <ButtonStatus
              title="Enviados com sucesso"
              value={sucessos.ESOCCONTADOR}
              isActive={code === 2}
              color="#03D30F"
              icon={
                <AssignmentTurnedInIcon
                  style={{ color: "#03D30F", fontSize: 40 }}
                />
              }
              handleClick={() => setCode(2)}
            />
          </Box>
          <SimpleTabs
            quantidade2220={s2220}
            quantidade2240={s2240}
            onChangeSelect={(value: number) => setFilterSelect(value)}
          />
        </>
      )}
      {loadingFuncs ? (
        <CircularProgress
          style={{ marginLeft: "50%" }}
          color="primary"
          size={20}
        />
      ) : funcs.length > 0 ? (
        <>
          <Grid
            container
            style={{ margin: "10px 25px 30px 0px" }}
            className={classes.root}
          >
            <Grid item lg={5} md={12} sm={12} className="item">
              {getFilterText(code)}
            </Grid>
            <Grid item lg={7} md={12} sm={12}>
              <ButtonsActions
                esoccodigo={code}
                selecionados={selected}
                typeESocial={filterSelect}
                isPermittedGov={selected.length > 0}
                onAfterSend={setReloading}
              />
            </Grid>
          </Grid>
          <Box>
            <CustomTable
              setLoadingLogs={setLoadingLogs}
              data={funcs}
              numSelected={selected.length}
              rowCount={funcs.length}
              onSelectAllClick={handleSelectAllClick}
              isSelected={isSelected}
              changeCheck={changeCheck}
              handleClickSearch={handleClickSearch}
            />
          </Box>
        </>
      ) : (
        <h1 style={{ justifyContent: "center", display: "flex" }}>
          Sem funcionários para exibição
        </h1>
      )}
      <Modal
        item={funcSelect}
        logProtocolo={logProtocolo}
        setProtocolo={setProtocolo}
        loadingLogs={loadingLogs}
        setLoadingLogs={setLoadingLogs}
        protocolo={protocolo}
        resultProtocolo={resultProtocolo}
        openStatus={openStatus}
        setOpenStatus={setOpenStatus}
        setResultProtocolo={setResultProtocolo}
      />
    </Box>
  );
}
