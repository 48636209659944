import moment from "moment";
import { useSchedule } from ".";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useSetepper } from "./Stepper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { api } from "../../shared/services/api";
import { RootModel } from "../../redux/reducers";
import { formatUSDate } from "../../utils/formats";
import { Button, CircularProgress } from "@material-ui/core";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { ProviderDomain, ServiceProviderAvailabilityDomain } from "../../redux/reducers/providerReducer";

export default function Horario({ modal }: { modal?: () => void; }) {
  const [times, setTime] = useState<
    ServiceProviderAvailabilityDomain[] | null
  >();
  const [loading, setLoading] = useState<string>(null)

  const { setSchedule } = useSchedule();
  const { validCPF, isClinico } = useStatePersistent();


  const { date, provider } = useSelector<RootModel, RootModel>(
    (state) => state
  );

  useEffect(() => {
    const reqHoursExam = async () => {
      const req = await api();
      req
        .get<ProviderDomain[]>(
          `/agenda/prestadores`,
          {
            params: {
              PROCODIGO: provider?.PROCODIGO,
              DATAAGENDA: moment(date).format("YYYY-MM-DD"),
              "visualiza-complementares": validCPF?.EXAMES_COMPLEMENTARES && !isClinico ? 1 : 0
            }
          }
        )
        .then((response) => {
          setTime(response.data[0].DISPONIBILIDADE);
        });
    };
    reqHoursExam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validCPF?.EXAMES_COMPLEMENTARES]);

  const { setActiveStep } = useSetepper();

  if (!times) return <div>Carregando...</div>;

  return (
    <div>
      {times.map((itemT, index) => {
        return (
          loading === `${itemT.HORA} - ${itemT.HORAFINAL}`
            ? <CircularProgress />
            : (
              <Button
                key={index}
                variant="outlined"
                color="primary"
                disabled={itemT.DISPONIBILIDADE === "N"}
                onClick={async () => {
                  try {
                    setLoading(`${itemT.HORA} - ${itemT.HORAFINAL}`)
                    const instance = await api()
                    Cookies.set(
                      "@PortalWEB:timeSelected",
                      JSON.stringify({
                        proData: date,
                        TIPO: itemT.TIPO,
                        proHorario: itemT.HORA,
                        proTipo: provider.TIPO,
                        proCodigo: provider.PROCODIGO,
                      })
                    )
                    if (itemT?.TIPO !== 2) {
                      await instance.post("/agenda/inicializa-agendamentos", {
                        proHorario: itemT.HORA,
                        proTipo: provider.TIPO,
                        proCodigo: provider.PROCODIGO,
                        proData: formatUSDate(date).toISOString().split("T")[0],
                      })
                    }
                    setSchedule((v) => ({
                      ...v,
                      Time: itemT.HORA,
                      _Periodo: itemT.DESCRICAO,
                    }));
                    if (!modal) setActiveStep(2);
                    else modal()
                  } catch (e) {
                    toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
                  } finally {
                    setLoading(null)
                  }
                }}
                style={{ margin: "20px 10px" }}
              >
                {itemT.DESCRICAO}
              </Button>
            )
        );
      })}
    </div>
  );
}
