import {
  Breadcrumbs,
  Divider,
  Fab,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useStyles } from "./index";
import clsx from "clsx";

export function EsocialDashboardContainer({
  children,
  subTitle,
  title = "eSocial",
}: {
  children: JSX.Element;
  subTitle?: string;
  title?: string;
}) {
  let location = useLocation();
  const params = useParams<{ tipo: string }>();
  const classes = useStyles();

  const [show, setShow] = useState(localStorage.getItem("show") === "true");
  const [showItems, setShowItems] = useState(
    localStorage.getItem("show") === "true"
  );

  const toggleHide = () => {
    setShow((h) => !h);
  };

  useEffect(() => {
    localStorage.setItem("show", show ? "true" : "false");
    const chengeShowItems = () => {
      if (show) {
        setTimeout(() => {
          setShowItems(true);
        }, 300);

        return;
      }

      setShowItems(false);
      return;
    };

    chengeShowItems();
  }, [show]);

  return (
    <div className={classes.root}>
      <div className={classes.data}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={`/esocial/dashboard/${params.tipo}`}>
            Selecionar Tipo
          </Link>
          {(location.pathname.includes("items") ||
            location.pathname.includes("filtros")) && (
              <Link
                color={
                  location.pathname.includes("filtros")
                    ? "textPrimary"
                    : "inherit"
                }
                href={`/esocial/dashboard/${params.tipo}/filtros`}
              >
                Filtros
              </Link>
            )}
          {location.pathname.includes("items") && (
            <Typography
              color={
                location.pathname.includes("items") ? "textPrimary" : "inherit"
              }
            >
              Resultados
            </Typography>
          )}
        </Breadcrumbs>
        <div className={classes.separator}></div>
        {title && (
          <>
            <Typography variant="h3">{title}</Typography>
            <div className={classes.separatorSmall} />
          </>
        )}
        {subTitle && (
          <>
            <Typography variant="h5" color="textSecondary">
              {subTitle}
            </Typography>
            <div className={classes.separator}></div>
          </>
        )}
        {children}
      </div>
      <Hidden mdDown>
        <div
          className={clsx(classes.history, {
            [classes.historyShift]: !show,
          })}
        >
          <Tooltip title={show ? "Esconder" : "Expandir"} aria-label="Expandir">
            <Fab
              onClick={toggleHide}
              className={classes.icon}
              size="small"
              variant="round"
            >
              {show ? (
                <ChevronRightIcon color="primary" fontSize="default" />
              ) : (
                <ChevronLeftIcon color="primary" fontSize="default" />
              )}
            </Fab>
          </Tooltip>
          {showItems && (
            <>
              <Typography style={{ paddingLeft: 16 }} variant="h5">
                Atividade recente
              </Typography>
              <div style={{ height: 25 }}></div>
              <List>
                {[1, 2, 3, 4].map((item) => (
                  <>
                    <ListItem className={classes.listItem} button>
                      <ListItemText
                        primary={
                          <span style={{ fontSize: 14 }}>
                            Consulta do processo <b>X</b> retornou statatus{" "}
                            <b style={{ color: "#667700" }}>Processando</b>
                            no dia <b>dd/MM/yyyy</b>
                          </span>
                        }
                      />
                    </ListItem>
                    <Divider style={{ margin: "5px" }} component="li" />
                  </>
                ))}
                <ListItem className={classes.listItem} button>
                  <ListItemText primary="Carregar mais..." />
                </ListItem>
              </List>
            </>
          )}
        </div>
      </Hidden>
    </div>
  );
}
