import { validateBr } from "js-brasil";
import moment from "moment";

export const isValidCnpjOrCpf = (v: any) => {
  if (!v) return "Campo Obrigatório";
  // v = v.replace(/\D/g, "");
  var newitem = v.toString();
  if (!validateBr.cpf(newitem)) return "CPF inválido";

  return undefined;
};

export function validateDate(date) {
  if (!moment(date, "YYYY-MM-DD", true).isValid()) {
    return null;
  }
  return moment(date).format("YYYY-MM-DD");
}
