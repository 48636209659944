import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";
import "react-toastify/dist/index";
import { Form } from "../../shared";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../shared/services/api";
import InputDate from "../../shared/Form/Fields/Date";
import TransitionsModal from "../../shared/modalAgenda";
import { addFunc } from "../../redux/reducers/funcReducer";
import LoadingButton from "../../shared/Button/LoadingButton";
import { Link, Typography, Paper, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutoCompleteDef from "../../shared/Form/Fields/AutoComplete";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { eSocialFilterModel } from "../../redux/reducers/eSocialReducer";

import "./styles.scss";

interface State {
  value: string;
}

export default function VerAgendamentos() {

  const dispatch = useDispatch();
  const { setOpenModalAgend } = useStatePersistent();

  const [data, setData] = useState(null);
  const [exams, setExams] = useState<any[]>([]);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  useEffect(() => {
    async function fetch() {
      const request = await api();
      try {
        const response = await request.get("/relatorio/exames/empresa");
        setExams(
          response.data.map((exam) => ({
            label: exam.EXANOMECOMERCIAL,
            code: exam.EXACODIGO,
          }))
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.error(error.response?.data?.message);
        }
        toast.error(error.message);
      }
    }
    fetch();
  }, []);

  const filterExams = (data) => {
    return data.EXAMES.length
      ? exams
        .filter((exam) => {
          return !data.EXAMES.includes(exam.code);
        })
        .map((exam) => exam.code)
      : [];
  };

  async function gerarExcel(data) {
    setLoadingExcel(true);
    try {
      const req = await api();
      const response = await req.post(
        "/relatorio/exames/pendente",
        {
          EXAMES: filterExams(data),
        },
        {
          params: {
            DATAVENCIMENTO: moment(data.DATAFINAL).format("YYYY-MM-DD"),
          },
        }
      );

      if (!response.data || !response.data.length) {
        toast.warn("Não existem dados pra geração da planilha");
        return;
      }

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Relatórios Exames Pendentes");

      const headers = [
        {
          header: "Funcionário",
          key: "FUNNOME",
          width: 40,
        },
        {
          header: "CPF",
          key: "FUNCPF",
          width: 15,
        },
        {
          header: "Posto de trabalho",
          key: "POSNOME",
          width: 25,
        },
        {
          header: "Setor",
          key: "SETNOME",
          width: 30,
        },
        {
          header: "Função",
          key: "FCONOME",
          width: 30,
        },
        {
          header: "Exame",
          key: "EXANOMECOMERCIAL",
          width: 30,
        },
        {
          header: "Último Exame",
          key: "FEADATA",
          width: 20,
        },
        {
          header: "Peridiocidade (meses)",
          key: "EXAPERIODICIDADE",
          width: 25,
        },
      ];

      ws.autoFilter = {
        from: {
          row: 5,
          column: 1,
        },
        to: {
          row: 5,
          column: headers.length,
        },
      };

      ws.getCell(1, 1).value = "RELATÓRIO DE EXAMES PENDENTES";
      ws.mergeCells(1, 1, 1, headers.length);
      ws.getColumn(1).width = 65;
      ws.getRow(1).height = 50;
      ws.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      ws.getRow(1).font = { size: 18, bold: true };
      ws.getCell(1, 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE2EFDA" },
      };
      ws.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      ws.getRow(1).border = {
        top: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        bottom: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        left: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        right: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
      };
      ws.getCell(3, 1).value = `Empresa: ${localStorage.getItem("EMP_NAME")}`;
      ws.getCell(3, 1).font = { bold: true };
      ws.mergeCells(3, 1, 3, headers.length);
      let linha = 5;

      const rowsData = response.data.map((item) => ({
        ...item,
        FEADATA: item.FEADATA ? moment(item.FEADATA).format("DD/MM/YYYY") : "",
      }));

      headers.forEach((item, idx) => {
        ws.getCell(linha, idx + 1).value = item.header;
        ws.getCell(linha, idx + 1).font = { bold: true };
      });
      linha++;

      for (let item of rowsData) {
        // eslint-disable-next-line no-loop-func
        headers.forEach(({ key, width }, propIdx) => {
          ws.getColumn(propIdx + 1).width = width;
          ws.getCell(linha, propIdx + 1).value = item[key];
        });
        linha++;
      }

      const buff = await wb.xlsx.writeBuffer();
      const blob = new Blob([buff], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.download = "Relatórios Exames Pendentes";
      link.click();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Não foi possível gerar a planilha"
      );
    } finally {
      setLoadingExcel(false);
    }
  }

  const handleCloseModalAgend = () => {
    setOpenModalAgend(false);
  };

  const request = async (dateFinal, useDateInitial = false) => {
    const dateInitial = moment(new Date()).format("YYYY-MM-DD");
    const url = `/relatorio/exames/pendente?DATAVENCIMENTO=${dateFinal}${useDateInitial ? `&DATAINICIAL=${dateInitial}` : ""
      }`;
    const req = await api();
    const response = await req.post(url);
    const validacao = validacaoReduce(response.data);

    const outraVariante = Object.keys(validacao).map((FUNNOME) => {
      return {
        FUNNOME,
        EXAMES: validacao[FUNNOME],
      };
    });

    if (useDateInitial) {
      const funcs = {};
      outraVariante.forEach(({ FUNNOME, EXAMES }) => {
        EXAMES.forEach(
          ({
            FEADATA,
            EXAPERIODICIDADE,
            EXANOMECOMERCIAL,
            FUNCPF,
            FCONOME,
            SETNOME,
            FEAVENCIMENTO,
            POSNOME
          }) => {
            if (FEAVENCIMENTO !== null) {
              const compareDate = moment(FEAVENCIMENTO).format("YYYY-MM-DD");
              if (moment(compareDate).isBetween(dateInitial, dateFinal)) {
                if (!funcs[FUNNOME]) funcs[FUNNOME] = [];
                funcs[FUNNOME].push({
                  FEADATA,
                  EXAPERIODICIDADE,
                  EXANOMECOMERCIAL,
                  FUNCPF,
                  FCONOME,
                  SETNOME,
                  FEAVENCIMENTO,
                  POSNOME
                });
              }
            }
          }
        );
      });
      const result = Object.keys(funcs).map((FUNNOME) => {
        return {
          FUNNOME,
          EXAMES: funcs[FUNNOME],
        };
      });
      return setData(result);
    }
    setData(outraVariante);
  };

  const state: State = useLocation().state as { value: string };

  const validacaoReduce = (data) => {
    return data.reduce(
      (
        obj,
        {
          FUNNOME,
          FEADATA,
          EXANOMECOMERCIAL,
          EXAPERIODICIDADE,
          FUNCPF,
          FCONOME,
          SETNOME,
          FEAVENCIMENTO,
          POSNOME
        }
      ) => {
        if (!obj[FUNNOME]) obj[FUNNOME] = [];
        obj[FUNNOME].push({
          FEADATA,
          EXAPERIODICIDADE,
          EXANOMECOMERCIAL,
          FUNCPF,
          FCONOME,
          SETNOME,
          FEAVENCIMENTO,
          POSNOME
        });
        return obj;
      },
      {}
    );
  };

  useEffect(() => {
    let date;
    if (state && state.value) {
      const values = state.value.match(/(\d+)/g);
      if (values.length === 1) {
        date = moment(new Date()).format("YYYY-MM-DD");
        request(date);
      } else {
        date = moment(new Date())
          .add(Number(values[1]), "days")
          .format("YYYY-MM-DD");
        request(date, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Paper
      style={{
        flexDirection: "column",
        width: "80%",
        alignItems: "center",
        minWidth: "800px",
        marginLeft: "50%",
        transform: "translateX(-50%)",
        maxWidth: "1250px",
      }}
    >
      <Box style={{ padding: "12px 24px" }}>
        <Typography
          variant="h5"
          align="left"
          style={{ margin: "5px 0 20px" }}
          color="primary"
        >
          Relatório de Exames Pendentes
        </Typography>
        <Form<eSocialFilterModel>
          spacing={8}
          onSubmit={async (data) => {
            const req = await api();
            setLoadingButton(true);
            const response = await req.post(
              "/relatorio/exames/pendente",
              {
                EXAMES: filterExams(data),
              },
              {
                params: {
                  DATAVENCIMENTO: moment(data.DATAFINAL).format("YYYY-MM-DD"),
                },
              }
            );
            setLoadingButton(false);
            const validacao = validacaoReduce(response.data);
            const outraVariante = Object.keys(validacao).map((FUNNOME) => {
              return {
                FUNNOME,
                EXAMES: validacao[FUNNOME],
              };
            });
            setData(outraVariante);
          }}
          buttons={({ handleSubmit }) => (
            <div
              style={{
                width: "100%",
                margin: "24px 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                style={{
                  minWidth: "200px",
                  height: "42px",
                  marginRight: "15px",
                }}
                size="large"
                type="submit"
              >
                {loadingButton ? (
                  <CircularProgress size={28} color="secondary" />
                ) : (
                  "Buscar"
                )}
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                color="primary"
                style={{ minWidth: "200px", height: "42px" }}
                size="large"
                onClick={handleSubmit(gerarExcel)}
                loading={loadingExcel}
              >
                Gerar Planilha
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  lg: 8,
                  name: "EXAMES",
                  customComponent: ({ control, error, name }) => (
                    <>
                      <AutoCompleteDef
                        multiple={true}
                        key={exams.length}
                        control={control}
                        name={name}
                        defOptions={exams}
                        label="Exames"
                        error={error}
                        defValue="EXANOMECOMERCIAL"
                        disabled={false}
                      />
                      <div className="ml-2 mt-1">
                        <Typography
                          color="textSecondary"
                          style={{ fontSize: "12px" }}
                        >
                          Selecione os exames no filtro, caso nenhum seja
                          selecionado todos serão retornados
                        </Typography>
                      </div>
                      <br />
                    </>
                  ),
                },
                {
                  lg: 4,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <>
                      <InputDate
                        control={control}
                        label="Data de Expiração"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                    </>
                  ),
                },
              ],
            },
          ]}
        />
      </Box>
      <h2
        className="only-print"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        Relatório de Exames Pendentes
      </h2>
      <Box style={{ width: "100%" }}>
        {data?.length > 0 &&
          data.map((element) => (
            <Box style={{ padding: "0 24px 12px" }}>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#c0c0c0",
                  margin: "10px -20px",
                }}
              />
              <Box className="flex">
                <div style={{ whiteSpace: "nowrap" }}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Funcionário: </span>
                    {element.FUNNOME}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Posto: </span>{" "}
                    {element.EXAMES[0].POSNOME}
                  </p>
                </div>
                <div style={{ whiteSpace: "nowrap" }}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>CPF: </span>{" "}
                    {element.EXAMES[0].FUNCPF}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Setor: </span>{" "}
                    {element.EXAMES[0].SETNOME}
                  </p>
                </div>
                <div style={{ whiteSpace: "nowrap", display: "block" }}>
                  <Link
                    className="no-print"
                    onClick={() => {
                      dispatch(addFunc(element.EXAMES[0].FUNCPF));
                      setOpenModalAgend(true);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Agendamento Rápido
                  </Link>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Função: </span>{" "}
                    {element.EXAMES[0].FCONOME}
                  </p>
                </div>
              </Box>
              <table style={{ width: "100%", marginTop: '7px' }}>
                <thead>
                  <tr style={{ borderBottom: "1px solid #c0c0c0" }}>
                    <td>
                      <span style={{ fontWeight: "bold" }}>Exame</span>
                    </td>
                    <td style={{ width: "35%" }}>
                      <span style={{ fontWeight: "bold" }}>Último exame</span>
                    </td>
                    <td style={{ width: "15%" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Periodicidade (Meses)
                      </span>
                    </td>
                  </tr>
                </thead>
                {element.EXAMES.map((item) => (
                  <>
                    <tr style={{ borderBottom: "1px solid #c0c0c0" }}>
                      <td>{item.EXANOMECOMERCIAL}</td>
                      <td>
                        {item.FEADATA
                          ? moment(item.FEADATA).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="align">{item.EXAPERIODICIDADE}</td>
                    </tr>
                  </>
                ))}
              </table>
            </Box>
          ))}
        {data?.length === 0 && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "10px",
            }}
          >
            <Typography variant="body1">
              Não foram encontrados registros nesta data.
            </Typography>
          </Box>
        )}
      </Box>
      <TransitionsModal
        closeModal={handleCloseModalAgend}
      />
    </Paper>
  );
}
