import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";

import LoadingButton from "../../shared/Button/LoadingButton";
import InputDate from "../../shared/Form/Fields/Date";
import { Form } from "../../shared";
import { api as apiRelatorio } from "../../shared/services/relatorios";
import { api as apiPlanilha } from "../../shared/services/planilha";
import AutoCompleteNew from "../../shared/AutoCompleteNew";

import "./styles.scss";
import RadioButtons from "../../shared/Form/Fields/RadioButtons";
import { Alert } from "@material-ui/lab";

const tipoOptions = [
  <MenuItem key="0" value={0}>
    Medicina e Segurança do trabalho
  </MenuItem>,
  <MenuItem key="1" value={1}>
    Consulta Médica Online
  </MenuItem>,
  <MenuItem key="2" value={2}>
    Toxicologico
  </MenuItem>,
];

function TableDownloads({ data }) {
  const reverse = [...data].reverse();

  return (
    <Box style={{ marginTop: "25px" }}>
      <TableContainer>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>Arquivo</TableCell>
              <TableCell>Download</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reverse.length > 0 ? (
              reverse.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.Name}</TableCell>
                    <TableCell>
                      <a download href={item.Url}>
                        Pronto para download ({item.Size}) bytes
                      </a>
                    </TableCell>
                    <TableCell>
                      {moment(new Date(item.LastModified)).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: "center" }}>
                  Nenhum registro encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ProcessComponentLog({ inProcess }) {
  return (
    <Box
      style={{
        marginTop: "25px",
        padding: "15px",
        borderLeft: !inProcess ? "green 10px solid" : "blue 10px solid",
      }}
      display="flex"
      alignItems="center"
    >
      <Box>{inProcess && <CircularProgress color="inherit" />}</Box>
      <Box style={{ marginLeft: "25px" }}>
        <Typography>
          {inProcess ? "Executando a tarefa" : "A tarefa foi finalizada"}
        </Typography>
      </Box>
    </Box>
  );
}

export default function Relatorios() {
  const [planosOptions, setPlanosOptions] = useState([]);
  const [planos, setPlanos] = useState("");
  const [downloads, setDownloads] = useState([]);
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  async function getDownloads() {
    return apiRelatorio().then((req) => {
      return req.get("/v1/report/log/app/files");
    });
  }

  useEffect(() => {
    getDownloads().then((res) => setDownloads(res.data));
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const req = await apiRelatorio();
        const res = await req.get("/v1-gerale/schedules/plan");
        let opt: any[] = [];
        res.data.forEach((item: any) => {
          opt = [
            ...opt,
            {
              label: item.descricao,
              code: item.codigo,
            },
          ];
        });
        setPlanosOptions(opt);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
          return;
        }
        toast.error(error.message);
      }
    };
    fetch();
  }, []);

  async function gerarRelatorios(data) {
    const dataInicial = moment(data.DATAINICIAL).format("MM/DD/YYYY");
    const dataFinal = moment(data.DATAFINAL).format("MM/DD/YYYY");
    const tipo = data.TIPO;

    const url = `/log/app/report?initialdate=${dataInicial}&finaldate=${dataFinal}&Environment=Production&App=GeraleRestFull.exe&reportmodel=GeraleAcessos&gerale.planobsf=${planos}&type=${tipo}&tpeempresa=${data.TPEEMPRESA}`;

    try {
      setLoading(true);
      setInProcess(true);
      const req = await apiPlanilha();
      await req.get(url);
      setInProcess(false);
      getDownloads().then((res) => setDownloads(res.data));
      setTimeout(() => setLoading(false), 5000);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error(error.message);
    } finally {
      setInProcess(false);
    }
  }

  return (
    <Box>
      <br />
      <br />
      <Box>
        <Typography
          align="left"
          style={{
            marginBottom: "20px",
            fontWeight: "bold",
            fontSize: "15pt",
          }}
          color="primary"
        >
          Relatórios
        </Typography>
        <Form
          spacing={"0px 2px"}
          onSubmit={gerarRelatorios}
          buttons={() => (
            <div style={{ marginTop: "25px" }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
                fullWidth
              >
                Gerar
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <>
                      <InputDate
                        control={control}
                        label="Data inicial"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                      <br />
                      <br />
                    </>
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAFINAL",
                  customDefValue: new Date(),
                  type: "custom",
                  customComponent: ({ control, name }) => (
                    <>
                      <InputDate
                        control={control}
                        label="Data final"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                      <br />
                      <br />
                    </>
                  ),
                },
                {
                  xs: 12,
                  sm: 5,
                  md: 5,
                  lg: 5,
                  name: "PLANO",
                  type: "custom",
                  customComponent: ({ control, name, error, setValue }) => (
                    <div className="margin">
                      <AutoCompleteNew
                        multiple={true}
                        options={planosOptions}
                        label="Planos"
                        name={name}
                        error={error}
                        setValue={setValue}
                        control={control}
                        onChange={(planosNew) =>
                          setPlanos(
                            planosNew?.map((v) => v.code)?.join(",") || ""
                          )
                        }
                      />
                    </div>
                  ),
                },
                {
                  xs: 12,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  name: "TIPO",
                  type: "select",
                  label: "Tipo",
                  options: tipoOptions,
                  rules: { required: "Campo Obrigatório" },
                },
                {
                  xs: 12,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  name: "TPEEMPRESA",
                  type: "custom",
                  customDefValue: 3,
                  customComponent: ({ control, error, name }) => (
                    <div style={{ marginLeft: "10px" }}>
                      <RadioButtons
                        schama={[
                          { label: "BSF", value: 3 },
                          { label: "Apoiar", value: 1 },
                        ]}
                        control={control}
                        error={error}
                        label="Filtrar por"
                        name={name}
                        rules={{ required: "Campo obrigatório" }}
                      />
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </Box>
      {loading && <ProcessComponentLog inProcess={inProcess} />}
      {<TableDownloads data={downloads} />}
    </Box>
  );
}
