import { Box, CircularProgress, MenuItem, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form } from "../../shared";
import InputDate from "../../shared/Form/Fields/Date";
import { api } from "../../shared/services/api";

export default function PlanoEditar() {
  const [data, setData] = useState<any[]>([]);
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [optionsStatus, setOptionsStatus] = useState<any[]>([]);

  const state = useLocation().state as { empCodigo: string; anaCodigo: string };
  const params = useParams<{ plCodigo: string }>();

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(
        `/analiserisco/empresas/${state.empCodigo}/analises/${state.anaCodigo}/planosacao/${params.plCodigo}`
      );
      setData(res.data);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(
        `/analiserisco/empresas/${state.empCodigo}/analises/${state.anaCodigo}/planosacao/riscos`
      );
      let opt: any[] = [];
      res.data?.forEach((item: any) => {
        opt = [
          ...opt,
          {
            label: item.RISDESCRICAO + " - " + item.AGTDESCRICAO,
            code: item.ID,
          },
        ];
      });
      setOptions(opt);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(`/tecnico/${state.empCodigo}/tarefas`);
      let opt: any[] = [];
      res.data.forEach((item: any) => {
        opt = [
          ...opt,
          {
            label: item.TARDESCRICAO,
            code: item.TARCODIGO,
          },
        ];
      });
      setOptionsStatus(opt);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box className="no-print" style={{ margin: 0, padding: 0 }}>
        <Typography
          className="no-print"
          align="left"
          style={{
            marginBottom: "35px",
            marginLeft: "10px",
            fontWeight: "bold",
            fontSize: "15pt",
          }}
          color="primary"
        >
          Dados
        </Typography>
        <Form
          defaultValues={data}
          spacing={"8px 8px"}
          onSubmit={(data) => {}}
          buttons={() => {}}
          schema={[
            {
              title: "Riscos",
              content: [
                {
                  lg: 12,
                  label: "Risco",
                  name: "IDRISCO",
                  type: "select",
                  rules: { required: "Selecione um risco" },
                  disabled: true,
                  options: options.map((item) => (
                    <MenuItem value={item.code}>{item.label}</MenuItem>
                  )),
                },
              ],
            },
            {
              title: "Dados",
              content: [
                {
                  lg: 12,
                  label: "Título",
                  name: "PLTITULO",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Passo",
                  name: "PLSTATUS",
                  type: "select",
                  disabled: true,
                  options: optionsStatus.map((item) => (
                    <MenuItem value={item.code}>{item.label}</MenuItem>
                  )),
                  rules: { required: "Selecione um status" },
                },
                {
                  lg: 12,
                  label: "Intervenção",
                  name: "PLMEDPREVENCAO",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Email",
                  name: "PLEMAIL",
                  disabled: true,
                },
                {
                  lg: 6,
                  name: "PLDTLEVANTAMENTO",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Levantamento"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data Inicial"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDTPREVISTA",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Prevista"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDTMAXIMA",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Máxima"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 12,
                  label: "Responsável",
                  name: "PLRESPONSAVEL",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Agente Nocivo",
                  name: "PLAGTNOVICO",
                  disabled: true,
                  inputPropsAdittional: {
                    multiline: true,
                    rows: 6,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  );
}
