import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";

export const OutlinedLoadingButton = (props: any) => {
  return <LoadingButton variant="outlined" color="primary" {...props} />;
};

export const ContainedLoadingButton = (props: any) => {
  return <LoadingButton variant="contained" color="primary" {...props} />;
};

export const OutlinedButton = (props: any) => {
  return <Button variant="outlined" color="primary" {...props} />;
};
  
const LoadingButton = (props: any) => {
  const { loading, onlyLoader, ...other } = props;

  if (loading) {
    if (onlyLoader) {
      return (
        <Button {...other} style={{ ...other.style, opacity: 0.5 }} disabled>
          <CircularProgress color="inherit" />
        </Button>
      );
    }

    return (
      <Button
        {...other}
        endIcon={<CircularProgress color="inherit" size={18} />}
        disabled
      />
    );
  } else {
    return <Button {...other} />;
  }
};

export default LoadingButton;
