import { useDropzone } from "react-dropzone";

const DropFile = ({
  onDrop,
  message,
  multiple = true,
}: {
  onDrop: any;
  message?: JSX.Element;
  multiple?: boolean;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
  });

  return (
    <div
      {...getRootProps({
        className: "dropzone ",
        style: {
          border: "2px dashed #bcd3ff",
          background: isDragActive ? "#eeeeee" : "#f7faff",
          padding: 20,
          height: "100%",
          textAlign: "center",
          outline: "none",
          cursor: "pointer",
        },
      })}
    >
      <input {...getInputProps()} />
      {message ? (
        message
      ) : (
        <>
          <b>Clique</b> ou <b>arraste arquivos aqui</b> para adicionar um anexo
          ao agendamento
        </>
      )}
    </div>
  );
};

export default DropFile;
