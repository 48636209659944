import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Paper, Typography } from "@material-ui/core";
import { api } from "../../shared/services/api";
import ReactHtmlParser from "react-html-parser";
import LoadingButton from "../../shared/Button/LoadingButton";

export default function useTermosUso() {
  const history = useHistory();
  const [ termos, setTermos ] = React.useState(null)
  const [ loading, setLoading ] = React.useState(false)
  
  const getTermos = async () => {
    
    const req = await api()

    req.get('/agenda/contratos').then((res) => {
      setTermos(res.data.INFCNTTERMO)
    })
  }

  const aceite = async () => {
    setLoading(true)
    const req = await api()
    req.post('/agenda/contratos/aceites').then((res) => {
      setLoading(false)
      history.push('/pages')
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getTermos()
  }, [])

  return (
    <Paper style={{ width: '80%', padding: 48, marginTop: '2%', marginLeft: '7%' }}>
      <Typography variant="h3" >Termos de uso</Typography>
      <Divider style={{ width: '100%', marginBottom: '3%' }} />
      { ReactHtmlParser(termos) }
      <Divider style={{ width: '100%', marginTop: '3%' }} />
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={aceite}
        loading={loading}
        style={{ marginTop: '5%' }}
      >
        Eu aceito os termos de uso
      </LoadingButton>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => { history.push('/') }}
        style={{ marginTop: '5%', marginLeft: '2%' }}
      >
        Recusar
      </Button>
    </Paper>
  );
}
