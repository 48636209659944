import { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { toast } from "react-toastify";

import Form from "../Form";
import LoadingButton from "../Button/LoadingButton";
import ModalConfirm from "../Modal/ModalConfirm";
import { SchemaSection } from "../Form/model";
import HeaderWithBack from "./HeaderWithBack";
import { findChangedField } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    // maxWidth: 700,
    width: "100%",
    margin: "0 auto",
    bottom: "0px",
  },
  spaced: {
    padding: "25px 0px 25px 20px",
    margin: "15px 275px",
    bottom: "0px",
  },
  buttons: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    position: "sticky",
    bottom: 0,
    padding: 10,
    background: "white",
    zIndex: 10,
    marginTop: 10,
    "& button": {
      margin: "5px 0px 0px 10px",
    },
  },
  back: {},
  delete: {
    color: "white",
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
    },
  },
  save: {
    color: "white",
    background: theme.palette.success.dark,
    "&:hover": {
      background: theme.palette.success.dark,
    },
  },
}));

export default function ItemPage<T>({
  loading = false,
  content,
  linkBack,
  type,
  schema,
  create,
  edit,
  destroy,
  loadingSubmit,
  deleteLoading,
  title,
}: {
  content?: T;
  title?: string;
  loading: Boolean;
  loadingSubmit?: Boolean;
  deleteLoading?: Boolean;
  linkBack: string;
  type: "create" | "edit";
  schema: SchemaSection<T>[];
  create?: (data: T) => void;
  edit?: (data: T) => void;
  destroy?: (data: T) => void;
}) {
  const [openModalConfirm, setOpenModalConfirm] = useState<Boolean>(false);
  const classes = useStyles();
  const history = useHistory();

  const redirectBack = () => {
    history.push(linkBack);
  };

  const editInternal = (data: T) => {
    if (!content) edit(data);
    const { hasChanged, errorDefaultMessage } = findChangedField(data, content);
    if (hasChanged) {
      edit(data);
    } else {
      toast.warning(errorDefaultMessage);
    }
  };

  const onSubmit = (data: any) => {
    switch (type) {
      case "create":
        create(data);
        break;
      case "edit":
        editInternal(data);
        break;
      default:
        break;
    }
  };

  return !loading ? (
    <div className={classes.root}>
      <ModalConfirm
        description={null}
        openModal={openModalConfirm}
        setOpenModal={setOpenModalConfirm}
        title={`Deseja deletar esse ${title}?`}
        confirmar={() => {
          destroy(content);
          setOpenModalConfirm(false);
        }}
        cancelar={() => {
          setOpenModalConfirm(false);
        }}
      />
      {title && (
        <HeaderWithBack
          title={`${type === "create" ? "Criar" : "Manutenção"} ${title}`}
          linkBack={linkBack}
        />
      )}
      <Form
        onSubmit={onSubmit}
        schema={schema}
        defaultValues={content}
        spacing={15}
        buttons={(reset: (data: any) => void) => (
          <div className={classes.buttons}>
            <Button
              variant="text"
              color="primary"
              type="button"
              onClick={redirectBack}
            >
              Cancelar
            </Button>
            {(type === "create" || edit) && (
              <LoadingButton
                loading={loadingSubmit}
                className={classes.save}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {type === "create" ? "Criar" : "Salvar"}
              </LoadingButton>
            )}

            {type === "edit" && destroy && (
              <LoadingButton
                loading={deleteLoading}
                className={classes.delete}
                variant="outlined"
                type="button"
                onClick={() => setOpenModalConfirm(true)}
              >
                Excluir
              </LoadingButton>
            )}
          </div>
        )}
      />
    </div>
  ) : (
    <div>Carregando...</div>
  );
}
