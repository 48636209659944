import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { api } from "../shared/services/api";
import { formCPF, ModalCPF } from "../shared/ModalCPF";
import { ModalInformation } from "../shared/ModalInfo";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ModalRefuse } from "../shared/ModalRefuse";

interface validateResponse {
    EXAMES_COMPLEMENTARES: boolean;
    MENSAGEM_EXIBICAO: string;
}

const defStatePersistent: {
    cpfFun?: string;
    modalCPF?: boolean;
    isClinico?: boolean;
    examSelected?: string;
    previousSchedule?: any;
    openModalAgend?: boolean;
    isComplementar?: boolean;
    openModalRefuse?: boolean;
    validCPF?: validateResponse;
    changeValidCPF?: (confirm: boolean) => void;
    resetPersistent?: (openModal?: boolean) => void;
    setIsClinico?: Dispatch<SetStateAction<boolean>>;
    setPreviousSchedule?: Dispatch<SetStateAction<any>>;
    setIsComplementar?: Dispatch<SetStateAction<boolean>>;
    setOpenModalAgend?: Dispatch<SetStateAction<boolean>>;
    setOpenModalRefuse?: Dispatch<SetStateAction<boolean>>;
} = {};

export const StatePersistentContext = createContext(defStatePersistent);

export const useStatePersistent = () => useContext(StatePersistentContext);

export const StatePersistentContextProvider = ({ children }) => {

    const [cpfFun, setCpfFun] = useState<string>(null);
    const [modalCPF, setModalCPF] = useState<boolean>(false);
    const [isClinico, setIsClinico] = useState<boolean>(false);
    const [examSelected, setExamSelected] = useState<string>(null);
    const [validCPF, setValidCPF] = useState<validateResponse>(null);
    const [previousSchedule, setPreviousSchedule] = useState<any>(null);
    const [isComplementar, setIsComplementar] = useState<boolean>(false);
    const [openModalAgend, _setOpenModalAgend] = useState<boolean>(false);
    const [openModalRefuse, setOpenModalRefuse] = useState<boolean>(false);

    useEffect(() => {
        const cache = Cookies.get("@PortalWEB:validCPF")
        if (cache) setValidCPF(JSON.parse(cache))
    }, [])

    const setOpenModalAgend = async (state) => {
        if (!state) {
            return _setOpenModalAgend(state)
        }
        try {
            const instance = await api()
            const response = await instance.get<{ PRECISA_VALIDAR: boolean }>('/agenda/precisa-validar-cpf')
            if (!response.data.PRECISA_VALIDAR) return _setOpenModalAgend(state)
            resetPersistent()
            setModalCPF(true)
        } catch (e) {
            toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
        }
    }

    const submitCPF = async (data: formCPF, setLoading: Dispatch<SetStateAction<boolean>>) => {
        try {
            setLoading(true)
            const instance = await api()
            const response = await instance.get<validateResponse>('/agenda/valida-cpf', {
                params: data
            })
            resetPersistent()
            setCpfFun(data.FUNCPF)
            setExamSelected(data.TIPOEXAME)
            Cookies.set("@PortalWEB:validCPF", JSON.stringify(response.data))
            setValidCPF(response.data)
            handleCloseCPF()
            _setOpenModalAgend(true)
        } catch (e) {
            toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
        } finally {
            setLoading(false)
        }
    }

    const handleCloseCPF = () => {
        setModalCPF(false)
    }

    const resetPersistent = (openModal?: boolean) => {
        setCpfFun(null);
        setValidCPF(null);
        setModalCPF(false);
        setIsClinico(false);
        setExamSelected(null);
        setIsComplementar(false);
        setPreviousSchedule(null);
        _setOpenModalAgend(false);
        Cookies.remove("@PortalWEB:validCPF")
        if (openModal) setOpenModalRefuse(true)
    }

    const changeValidCPF = (confirm: boolean) => {
        if (confirm) return setValidCPF(prev => ({ ...prev, MENSAGEM_EXIBICAO: null }))

        setValidCPF(null)
        Cookies.delete("@PortalWEB:validCPF")
        return confirm
    }

    return (
        <StatePersistentContext.Provider
            value={{
                cpfFun,
                modalCPF,
                validCPF,
                isClinico,
                setIsClinico,
                examSelected,
                openModalAgend,
                changeValidCPF,
                isComplementar,
                resetPersistent,
                openModalRefuse,
                previousSchedule,
                setOpenModalAgend,
                setIsComplementar,
                setOpenModalRefuse,
                setPreviousSchedule
            }}
        >
            {children}
            <ModalCPF
                onSubmit={submitCPF}
                onClose={handleCloseCPF}
            />
            <ModalRefuse />
            <ModalInformation />
        </StatePersistentContext.Provider>
    )
}