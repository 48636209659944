import { Button, DialogActions, Paper } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "../../../shared";
import { Masked } from "../../../shared/Form/Fields/Masked";
import RadioButtons from "../../../shared/Form/Fields/RadioButtons";
import { SchemaSection } from "../../../shared/Form/model";
import { EsocialDashboardContainer } from "../EsocialDashboardContainer";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";

export default function EsocialDashboardFiltros(props) {
  const params = useParams<{ tipo: string }>();
  const history = useHistory();

  const redirectBack = () => history.push(`/esocial/dashboard/${params.tipo}`);
  const redirectNext = () =>
    history.push(`/esocial/dashboard/${params.tipo}/items`);

  const buttonsForm = (
    <DialogActions style={{ marginTop: 50 }}>
      <Button
        startIcon={<ChevronLeftRoundedIcon />}
        onClick={redirectBack}
        variant="outlined"
        color="primary"
      >
        Voltar
      </Button>
      <Button
        endIcon={<NavigateNextRoundedIcon />}
        onClick={redirectNext}
        variant="contained"
        color="primary"
      >
        Continuar
      </Button>
    </DialogActions>
  );

  const onSubmit = (data) => {};

  const getFiltros = (tipo) => {
    let schema: SchemaSection<any>[] = [];

    switch (tipo) {
      case "1":
        schema = [
          {
            container: ({ children }) => (
              <Paper variant="outlined" style={{ padding: "15px" }}>
                {children}
              </Paper>
            ),
            content: [
              {
                lg: 6,
                name: "transmissao",
                label: "Tipo",
                customComponent: ({ control, error, name }) => (
                  <RadioButtons
                    schama={[
                      {
                        label: "Transmitido",
                        value: "1",
                      },
                      {
                        label: "Não Transmitido",
                        value: "2",
                      },
                    ]}
                    control={control}
                    rules={{ required: "Tipo obrigatório" }}
                    error={error}
                    label="Tipo"
                    name={name}
                  />
                ),
              },
              {
                lg: 6,
                type: "custom",
                customComponent: () => <></>,
              },
            ],
          },
          {
            container: ({ children }) => (
              <Paper
                variant="outlined"
                style={{ padding: "15px", marginTop: 30 }}
              >
                {children}
              </Paper>
            ),
            content: [
              {
                lg: 3,
                name: "Document_CPF",
                label: "CPF",
                type: "custom",
                customComponent: ({ control, error, name, setValue }) => (
                  <Masked
                    // onChangeItem={(value) => searchEmp(value, setValue)}
                    rules={{}}
                    format="###.###.###-##"
                    mask="_"
                    control={control}
                    name={name}
                    error={error}
                    label="CPF"
                  />
                ),
                // rules: { required: "Campo obrigatório" },
              },
              {
                lg: 9,
                name: "FUNNOME",
                label: "Nome do funcionário",
              },
            ],
          },
        ];
        break;
      case "2":
        schema = [
          {
            content: [
              {
                lg: 12,
                name: "2",
                label: "2",
              },
            ],
          },
        ];
        break;
    }

    return (
      <Form
        spacing="15px"
        onSubmit={onSubmit}
        buttons={() => buttonsForm}
        schema={schema}
      />
    );
  };

  return (
    <EsocialDashboardContainer subTitle="Filtros">
      <>{getFiltros(params.tipo)}</>
    </EsocialDashboardContainer>
  );
}
