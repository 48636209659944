import { useFetch } from "../../shared/services/useFetch";
import CrudReorder from "../../shared/CrudReorder";
import NewButton from "../../shared/Button/NewButton";
import { formatDate } from "../../utils/formats";
import { useHistory, useParams } from "react-router-dom";

// import { useFetch } from "@/shared/services/useFetch";
// import { FuncionarioAtestadoClinicoModel } from "./controller";
// import CrudReorder from "@/shared/CrudReorder";
// import NewButton from "@/shared/Button/NewButton";
// import { formatDate } from "@/shared/hooks/format";

export default function ClinicoFuncionarioAtestadoTab() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: content } = useFetch<any[]>(
    `/clinico/funcionarios/${id}/atestados`
  );

  const openNew = () => {
    history.push(`/pages/editarFuncionario/${id}/atestado/0`);
  };

  return (
    <CrudReorder<any>
      content={content}
      edit
      onEditClick={(value: any) =>
        history.push(
          `/pages/editarFuncionario/${id}/atestado/${value.ATSCODIGO}`
        )
      }
      actions={
        <>
          <NewButton access="05001001-I" openNew={openNew} />
        </>
      }
      schema={[
        {
          label: "CID",
          content: "ATSCID",
        },
        {
          label: "Médico",
          content: "ATSMEDICO",
        },
        {
          label: "CRM",
          content: "ATSCRM",
          type: "number",
        },
        {
          label: "Data",
          content: "ATSDATA",
          formatFun: formatDate,
          type: "date",
        },
      ]}
    />
  );
}
