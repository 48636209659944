import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSchedule } from ".";
import { addSchedule } from "../../redux/actions/scheduleActions";
import { RootModel } from "../../redux/reducers";
import {
  PortalModel,
  removeAgendamentoRapido,
} from "../../redux/reducers/portalReducer";
import { useSetepper, useFilesExames } from "./Stepper";

export default function Finalizar() {
  const { schedule, setSchedule } = useSchedule();
  const { files } = useFilesExames();
  const { setActiveStep } = useSetepper();
  const { logoUrl, agendamentoRapido } = useSelector<RootModel, PortalModel>(
    (state) => state.portal
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let sD = { ...schedule, files };
    sD.logoUrl = logoUrl;
    if (agendamentoRapido) {
      dispatch(removeAgendamentoRapido());
    }
    dispatch(addSchedule(sD));
    setSchedule(null);
    setActiveStep(5);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Adicionando...</div>;
}
