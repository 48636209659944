import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ContainedLoadingButton } from "../Button/LoadingButton";
import { useStatePersistent } from "../../contexts/statesPersistent";
import { Backdrop, Fade, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        gap: "21px",
        width: "625px",
        outline: "none",
        display: "flex",
        padding: "21px",
        flexDirection: "column",
        boxShadow: theme.shadows[5],
        backgroundColor: theme.palette.background.paper,
    },
}));

export type formCPF = { FUNCPF: string; TIPOEXAME: number }

export const ModalRefuse = () => {
    const history = useHistory()

    const classes = useStyles();

    const { openModalRefuse, setOpenModalRefuse } = useStatePersistent();

    if (
        !openModalRefuse
    ) {
        return null;
    }

    return (
        <Modal
            closeAfterTransition
            open={openModalRefuse}
            className={classes.modal}
            BackdropComponent={Backdrop}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade
                in={openModalRefuse}
            >
                <div className={classes.paper}>
                    <Grid
                        container
                        alignContent="center"
                        justifyContent="center"
                        style={{
                            marginTop: "24px"
                        }}
                    >
                        <InfoOutlinedIcon
                            style={{
                                fontSize: "100px"
                            }}
                            htmlColor="#C9B116"
                        />
                    </Grid>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Prezado(a) usuário(a)
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Informamos que o colaborador não está registrado na nossa base de dados. Para proceder com o agendamento do exame, é necessário que o cadastro do colaborador seja realizado previamente.
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Por favor, entre em contato com o SESMT COMUM através do número (98) 98431-6843 para efetuar o cadastro do colaborador. Somente após esta etapa será possível prosseguir com o agendamento.
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Agradecemos pela compreensão.
                    </Typography>
                    <Typography
                        align="center"
                        variant="h6"
                    >
                        Atenciosamente
                    </Typography>
                    <Grid
                        container
                        justifyContent="center"
                    >
                        <Grid lg={6}>
                            <ContainedLoadingButton
                                fullWidth
                                onClick={() => {
                                    setOpenModalRefuse(false)
                                    history.push("/pages")
                                }}
                            >
                                Continuar
                            </ContainedLoadingButton>
                        </Grid>
                    </Grid>
                </div>
            </Fade>
        </Modal>
    );
}
