import { MouseEvent } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import isValidAccess from "../../utils/isValidAcess";

function NewButton({
  openNew,
  access,
  disabled = false,
  title,
  variant = "contained",
  loading,
}: {
  openNew: (event: MouseEvent<HTMLButtonElement>) => void;
  access?: string;
  disabled?: boolean;
  title?: string;
  variant?: "text" | "outlined" | "contained";
  loading?: boolean;
}) {
  return (
    <Button
      variant={variant}
      // style={{ color: "white" }}
      color="primary"
      disabled={(access && !isValidAccess(access)) || disabled || loading}
      onClick={openNew}
      startIcon={<AddIcon />}
      endIcon={loading && <CircularProgress size={24} />}
    >
      {title ? title : "Novo"}
    </Button>
  );
}

function Icon({ openNew }: { openNew: () => void }) {
  return (
    <Tooltip title="Novo">
      <IconButton color="secondary" onClick={openNew}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

NewButton.Icon = Icon;

export default NewButton;
