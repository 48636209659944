import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Form from "../Form";
import LoadingButton from "../Button/LoadingButton";
import { InputDateAgend } from "../Form/Fields/Date";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDate } from "../../redux/reducers/dateReducer";
import { Divider, Typography } from "@material-ui/core";
import { useStatePersistent } from "../../contexts/statesPersistent";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModal({ closeModal }) {

  const { openModalAgend: openModal } = useStatePersistent()

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Typography
              align="left"
              style={{
                width: "350px",
                marginBottom: "15px",
                fontWeight: "bold",
                fontSize: "15pt",
                background: localStorage.getItem("colorMenu"),
              }}
            >
              Novo agendamento
            </Typography>
            <Divider
              color="primary"
              style={{
                width: "111%",
                marginLeft: "-34px",
                marginBottom: "35px",
              }}
            />
            <Form
              spacing={"0px 24px 0px 0px"}
              onSubmit={async (data) => {
                dispatch(setDate(moment(data.date).format("MM/DD/YYYY")));
                history.push("/pages/prestador");
              }}
              buttons={() => (
                <div
                  style={{
                    maxWidth: 350,
                    width: "100%",
                    margin: "24px auto",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={closeModal}
                    style={{ width: "95%", marginTop: 10 }}
                    size="large"
                    type="submit"
                  >
                    Próximo
                  </LoadingButton>
                </div>
              )}
              schema={[
                {
                  content: [
                    {
                      lg: 12,
                      name: "date",
                      type: "custom",
                      customDefValue: moment().format("MM/DD/YYYY"),
                      customComponent: ({ control, name }) => (
                        <>
                          <InputDateAgend
                            control={control}
                            label="Data inicial"
                            name={name}
                            rules={{ required: "Campo Obrigatório" }}
                            variant="static"
                          />
                        </>
                      ),
                    },
                  ],
                },
              ]}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
