import LoadingCircular from "../../LoadingCircular";

interface DependsOnProps {
  Component: JSX.Element;
  FallbackComponent?: JSX.Element;
  dependencies: unknown[];
}

export default function DependsOn({
  Component,
  FallbackComponent,
  dependencies,
}: DependsOnProps) {
  const result = dependencies.every((dependencie) => {
    if (typeof dependencie === "object") {
      if (Array.isArray(dependencie)) {
        return true;
      }
    }
    return false;
  });

  if (result) {
    return Component;
  }

  return FallbackComponent || <LoadingCircular />;
}
