import { useState, useEffect } from "react"
import { Box, Tabs, Tab, Typography, withStyles, AppBar, makeStyles } from '@material-ui/core';
import HeaderWithBack from "../../../shared/Item/HeaderWithBack";
import Dados from './dados';
import HistoricoDeAcoes from "./historicoAcoes";
import { useQuery } from "../../../utils/functions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  }
})(Tab);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    maxWidth: '95%',
    margin: 'auto'
  }
}));

function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const query = useQuery();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const params = new URLSearchParams(window.location.search);
    params.set('verticalTab', newValue.toString());
    window.history.replaceState({ ...window.history.state }, "", `?${params}`);
  };

  useEffect(() => {
    if (Number(query.get('verticalTab')) != value) {
      setValue(Number(query.get('verticalTab') || 0));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('verticalTab')]);

  return (
    <div className={classes.root}>
      <HeaderWithBack title="Plano de ação -  Empresa" linkBack="/pages/cronogramas" />
      <div className={classes.tabs}>
        <AppBar
          style={{ position: "static", top: 60, backgroundColor: '#fafafa' }}
          elevation={-1}
          position="static"
        >
          <Tabs
            orientation="horizontal"
            indicatorColor="primary"
            textColor="primary"
            style={{ background: "inherit" }}
            value={value}
            onChange={handleChange}
            aria-label="tabs empresas"
            variant="fullWidth"
          >
            <CustomTab label="Dados" {...a11yProps(0)} />
            <CustomTab label="Histórico de ações" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div>
      <TabPanel value={value} index={0}>
        <Dados />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HistoricoDeAcoes />
      </TabPanel>
    </div>
  );
}

export default function Planos () {
  return <SimpleTabs />
}
