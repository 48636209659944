import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Form from "../../../shared/Form";
import { useSelect } from "./useSelect";
import { Alert } from "@material-ui/lab";
import {
  EnumSolicitationType,
  Solicitation,
} from "../../../redux/reducers/scheduleReducer";
import "../../Login/styles.scss";
import axios from "axios";
import { InputDef } from "../../../shared/Form/Fields";

export default function DialogAdcFuncao({
  open,
  setOpen,
  setSolicitations,
  setValueRef,
}: {
  open: boolean;
  setOpen: any;
  setValueRef: any;
  setSolicitations: React.Dispatch<React.SetStateAction<Solicitation[]>>;
}) {
  const { tipoEnderecoOptions } = useSelect(true);

  const useEnviarSolicitacao = (data) => {
    var idItemp = Math.round(-Math.random() * 1000);
    setSolicitations((s) => {
      const newSolicitations = [
        ...s,
        {
          Title: `${data.Title} [Aguardando aprovação]`,
          Description: data.Description,
          SolicitationType: EnumSolicitationType.Function_Add,
          Id: data.Setor.split("|")[1],
          IdTemp: idItemp.toString(),
          Data: {
            Function_Name: `${data.Title} [Aguardando aprovação]`,
            Function_Description: data.Description,
            Department_Name: `${data.Setor.split("|")[0]}`,
            POSNOME: `${data.Setor.split("|")[2]}`,
          },
        },
      ];
      return newSolicitations;
    });
    setValueRef.current("FuncaoId", idItemp, {
      shouldValidate: true,
    });

    setOpen(false);
  };

  const [color, setColor] = React.useState("");

  useEffect(() => {
    async function getConfig() {
      const testeC = localStorage.getItem("LinkGet");
      let response;
      try {
        response = await axios.get(
          "https://apigestao.prosesmt.com.br/api/clientes/ambiente",
          {
            params: { host: testeC },
          }
        );
      } catch (err) {
        response = await axios.get(
          `https://v2ml9g04bl.execute-api.us-east-1.amazonaws.com/default/lambda-data-clients`,
          {
            params: {
              host: testeC,
            },
          }
        );
      }
      try {
        // response = await axios.get(
        //   `https://v2ml9g04bl.execute-api.us-east-1.amazonaws.com/default/lambda-data-clients`,
        //   { params: { host: testeC } }
        // );
      } catch (err) { }
      setColor(response.data.colorPrimary);
    }

    getConfig();
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{}}
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Adicionar Função</Box>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 40, paddingTop: 20 }}>
        <Form
          spacing={"18px 0px"}
          schema={[
            {
              content: [
                {
                  lg: 12,
                  name: "Setor",
                  type: "select",
                  label: "Setor",
                  options: tipoEnderecoOptions,
                  rules: { required: "Campo obrigatório" },
                },
                {
                  lg: 8,
                  name: "Title",
                  label: "Nome da Função",
                  rules: { required: "Campo obrigatório" },
                },
                {
                  lg: 4,
                  type: "number",
                  styles: {
                    paddingLeft: "20px"
                  },
                  name: "CBO",
                  label: "CBO",
                  customComponent: ({ name, control }) => (
                    <InputDef
                      name={name}
                      label="CBO"
                      type="number"
                      control={control}
                      variant="outlined"
                      style={{ width: "100%" }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )
                },
                {
                  lg: 12,
                  name: "Description",
                  label: "Descrição da Função",
                  rules: { required: "Campo obrigatório" },
                  inputPropsAdittional: { multiline: true, rows: 6 },
                },
              ],
            },
          ]}
          buttons={() => (
            <>
              <Alert severity="info">
                Ao clicar em salvar, será feita a solicitação para a clínica
                realizar o cadastro da nova função. <br />
                Você poderá continuar com o agendamento dos exames para essa
                nova função.
              </Alert>
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  onClick={() => setOpen(false)}
                  type="button"
                  variant="text"
                  color="primary"
                >
                  Cancelar
                </Button>
                <div style={{ width: 18 }}></div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: `${color}` }}
                >
                  Enviar Solicitação
                </Button>
              </Box>
            </>
          )}
          onSubmit={useEnviarSolicitacao}
        />
      </DialogContent>
    </Dialog>
  );
}
