import { format } from "date-fns";

import useCrud from "../../shared/Crud/useCrud";
import { getDateTime } from "../../utils/functions";

interface IParte {
  CPACODIGO: number;
  CATCODIGO: number;
  PCACODIGO: number;
  LATCODIGO: number;
  ASSINA: string;
  PCACODTABELA: string;
  PCADESCRICAO: string;
  LATDESCRICAO: string;
}

interface IAgente {
  CACCODIGO: number;
  CATCODIGO: number;
  ACACODIGO: number;
  ACATABELA: "ESOCSITGERADORADOENCA" | "ESOCAGENTECAUSADORACIDENTE";
  ASSINA: string;
  CODTABELA: string;
  DESCRICAO: string;
}

interface ICAT {
  FUNCODIGO: number;
  CATCODIGO: number;
  CATNUMERO: string | null;
  CATDATA: string | null;
  CATULTDIATRABALHADO: string | null;
  CATHISTORICO: null | string;
  CATRETIF: number | null;
  TPRCODIGO: number | null;
  CATTPACID: number | null;
  INCCODIGO: number | null;
  CATHORA: Date | string | null;
  CATHORASTRABANTES: string | Date | null;
  TPCCODIGO: number | null;
  CATOBITO: number | null;
  CATDATAOBITO: string | null;
  CATCOMUNPOLICIA: number | null;
  SGACODIGO: number | null;
  TLACODIGO: number | null;
  TPLCODIGO: number | null;
  CATLOCALDESC: string | null;
  ATSCODIGO: number | null;
  CATORIGEM: number | null;
  CATREGNRINSC: string | null;
  CATDESCLOGRAD: string | null;
  CATNRLOGRAD: string | null;
  CATMUNIBGE: string | null;
  CATCIDADEACIDENTE: string | null;
  CATUF: string | null;
  CATCNPJLOCALACID: string | null;
  CATPAIS: string | null;
  CATCODPOSTAL: string | null;
  ASSINA: string | null;
  CATCOMPLEMENTO: string | null;
  CATBAIRRO: string | null;
  FUNNOME: string | null;
  FUNCPF: string | null;
  FUNNIT: string | null;
  EMPRAZAOSOCIAL: string | null;
  EMPCNPJ: string | null;
  CATDESCRICAO: string | null;
  SGADESCRICAO: string | null;
  PARTES: IParte[];
  AGENTES: IAgente[];
}

export const formatTime = (value: string) => {
  try {
    return format(new Date(value), "HH:mm");
  } catch (ex) {
    return null;
  }
};

export const useCat = (
  idFuncionario: string,
  linkBack: string,
  itemId = "0"
) => {
  return useCrud<ICAT>(
    `/clinico/funcionarios/${idFuncionario}/cat`,
    "cat",
    linkBack,
    itemId,
    {
      onBeforeGet: (data) => {
        data.CATHORA = getDateTime(data.CATHORA as string);
        data.CATHORASTRABANTES = getDateTime(data.CATHORASTRABANTES as string);
        return data;
      },
      onBeforeEdit: (data) => {
        data.CATHORA = formatTime(data.CATHORA as string);
        data.CATHORASTRABANTES = formatTime(data.CATHORASTRABANTES as string);
        return data;
      },
      onBeforeCreate: (data) => {
        data.CATHORA = formatTime(data.CATHORA as string);
        data.CATHORASTRABANTES = formatTime(data.CATHORASTRABANTES as string);
        return data;
      },
    }
  );
};
