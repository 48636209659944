import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  makeStyles,
  Theme,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Paper,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { Form } from "../../shared";
import { eSocialFilterModel } from "../../redux/reducers/eSocialReducer";
import InputDate from "../../shared/Form/Fields/Date";
import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import TransitionsModalSol from "../../shared/modalSol";
import "./styles.scss";
import CrudReorder from "../../shared/CrudReorder";
import { Check, Edit, SearchSharp } from "@material-ui/icons";
import { format } from "date-fns";
import StyledChip from "../../shared/StyledChip";
import { getSolCondition, getStatusColor, sendFollowUp } from "./controller";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function GerenciarSolicitacao(props) {
  const classes = useStyles();
  const [openModalSol, setOpenModalSol] = useState(false);
  const [openModalDesc, setOpenModalDesc] = useState(false);
  const [valueCheck, setvalueCheck] = useState(false);
  const [response, setResponse] = useState([]);
  const [teste, setTeste] = useState([]);
  
  const [addComentario, setAddComentario] = useState({
    open: false,
    value: "",
  });

  const handleCloseModalSol = () => {
    setOpenModalSol(false);
  };
  const handleCloseModalDesc = () => {
    setOpenModalDesc(false);
  };

  const AddButton = styled(Button)({
    color: "#144979",
    border: "2px solid #144979",
    padding: "8px 16px",
    borderRadius: 31,
  });

  const handleSendFollowUp = async (codigo) => {
    const body = {
      SOLDESCRICAO: addComentario.value,
    };
    await sendFollowUp(codigo, body);
  };

  const onSubmitForm = async (data) => {
    const date = new Date(data.DATAFINAL);
    date.setDate(date.getDate() + 1);

    const objectValues = {
      DATAINICIAL: moment(data.DATAINICIAL).format("YYYY-MM-DD"),
      DATAFINAL: moment(date).format("YYYY-MM-DD"),
      STATUS: valueCheck ? 2 : null,
    };

    try {
      const req = await api();
      const responseData = await req.get(
        `/solicitacoes/dashboard?DATAINICIAL=${objectValues.DATAINICIAL}&DATAFINAL=${objectValues.DATAFINAL}&SOLSTATUS=${objectValues.STATUS}`
      );

      setResponse(responseData.data);
    } catch (err) {}
  };

  interface IDivProps {
    last?: boolean;
    finished?: boolean;
  }

  const FirstComment = styled("div")((props: IDivProps) => ({
    position: "absolute",
    width: "16px",
    top: "50%",
    left: "-16px",
    height: props.finished ? "160%" : "calc(90% + 16px)",
    borderLeft: "2px solid #144979",
    borderTop: "2px solid #144979",
    borderBottom: !props.last ? "2px solid #144979" : "none",
  }));

  return (
    <Box>
      <Box>
        <Typography
          align="left"
          style={{ marginBottom: "20px", fontWeight: "bold", fontSize: "15pt" }}
          color="primary"
        >
          Gerenciar solicitações
        </Typography>

        <Form<eSocialFilterModel>
          spacing={"0px 4px"}
          onSubmit={onSubmitForm}
          buttons={() => <></>}
          schema={[
            {
              content: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "DATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data inicial"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <div className="margin">
                      <InputDate
                        control={control}
                        label="Data final"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                      <br />
                      <br />
                    </div>
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4,
                  type: "custom",
                  customComponent: ({ control, name }) => (
                    <div
                      className="margin"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "50%",
                          width: "20px",
                          height: "50px",
                        }}
                        type="submit"
                      >
                        <SearchSharp
                          color="primary"
                          style={{ cursor: "pointer" }}
                        />
                      </Button>
                      <br />
                      <br />
                    </div>
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 2,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <div className="margin">
                      <LoadingButton
                        onClick={() => {
                          setOpenModalSol(true);
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                      >
                        Nova Solicitação
                      </LoadingButton>
                      <br />
                      <br />
                    </div>
                  ),
                },
                {
                  lg: 12,
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <div style={{ display: "flex", marginBottom: "10px" }}>
                      <Checkbox
                        className={classes.root}
                        disableRipple
                        onClick={() => {
                          setvalueCheck(!valueCheck);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "decorative checkbox" }}
                        {...props}
                      />
                      <p style={{ marginTop: "12px" }}>
                        Apenas Solicitações Resolvidas
                      </p>
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </Box>
      <TransitionsModalSol
        openModal={openModalSol}
        closeModal={handleCloseModalSol}
      />
      {response.length > 0 ? (
        <Paper
          style={{
            width: "90%",
            marginTop: "5%",
            padding: 0,
          }}
        >
          <CrudReorder
            pagination={false}
            content={response}
            edit
            onEditClick={async (item) => {
              const data = await getSolCondition(item.SOLCODIGO);
              setTeste(data);
              setOpenModalDesc(true);
            }}
            schema={[
              {
                label: "Título",
                content: "SOLTITULO",
              },
              {
                label: "Descrição",
                content: "SOLSOLICITACAO",
              },
              {
                label: "Data Solicitação",
                content: "SOLDATAHORA",
                formatFun: (item) => {
                  return format(new Date(item), "dd/MM/yyyy");
                },
              },
              {
                label: "Tipo",
                content: "SOLDESCTIPO",
              },
              {
                label: "Subtipo",
                content: "SOLSUBTIPODESC",
              },
              {
                label: "Condição",
                content: "SOLSTATUSDESC",
                formatFun: (item) => {
                  return (
                    <StyledChip
                      size="small"
                      bgColor={getStatusColor(item)}
                      style={{
                        color: getStatusColor(item),
                        borderColor: getStatusColor(item),
                      }}
                      variant="outlined"
                      label={item}
                    />
                  );
                },
              },
            ]}
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModalDesc}
            onClose={handleCloseModalDesc}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade
              style={{ overflow: "auto", maxHeight: "70vh", minWidth: 600 }}
              in={openModalDesc}
            >
              <div className={classes.paper}>
                {teste.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <h1 style={{ margin: "15px 0", fontSize: "22px" }}>
                      Condição:
                    </h1>
                    <StyledChip
                      size="small"
                      bgColor={getStatusColor(teste[0].SOLSTATUSDESC)}
                      style={{
                        color: getStatusColor(teste[0].SOLSTATUSDESC),
                        borderColor: getStatusColor(teste[0].SOLSTATUSDESC),
                      }}
                      variant="outlined"
                      label={teste[0].SOLSTATUSDESC}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {teste.map((element, i, a) => (
                  <>
                    <Paper
                      style={{
                        width: 600,
                        padding: 15,
                        marginTop: "16px",
                        position: "relative",
                      }}
                    >
                      {a[i + 1]?.SOLSTATUS === 2 ? (
                        <>
                          <FirstComment last finished />
                          <Check
                            style={{
                              position: "absolute",
                              left: "-26px",
                              top: "200%",
                              color: "white",
                              backgroundColor: "green",
                              borderRadius: "50%",
                            }}
                          />
                        </>
                      ) : (
                        element.SOLSTATUS !== 2 &&
                        i !== a.length - 1 && <FirstComment />
                      )}
                      <div style={{ display: "block", padding: 8 }}>
                        <p style={{ paddingBottom: 8, fontWeight: 700 }}>
                          Informações:{" "}
                        </p>
                        <p style={{ paddingBottom: 8 }}>
                          <strong>Título:</strong> {element.SOLTITULO}
                        </p>
                        <p style={{ paddingBottom: 8 }}>
                          <strong>Data:</strong>{" "}
                          {moment(element.SOLDATAHORA).format(
                            "DD/MM/YYYY hh:mm"
                          )}
                        </p>
                        <p style={{ paddingBottom: 8 }}>
                          <strong>Tipo: </strong>
                          {element.SOLDESCTIPO}
                        </p>
                        <p style={{ paddingBottom: 8 }}>
                          <strong>Subtipo: </strong>
                          {element.SOLSUBTIPODESC}
                        </p>
                        <p>
                          <strong>Descrição:</strong>
                        </p>
                        <p>{element.SOLSOLICITACAO}</p>
                      </div>
                    </Paper>

                    {teste[0]?.COMENTARIOS?.map((c, i, a) => {
                        console.log(c)
                      if (c.SOLSTATUS === 2) {
                        return (
                          <Paper
                            style={{
                              width: 600,
                              padding: 15,
                              marginTop: "5%",
                              cursor: "pointer !important",
                            }}
                          >
                            <div style={{ display: "block" }}>
                              <p
                                style={{
                                  paddingBottom: 8,
                                  fontWeight: 700,
                                  fontSize: "18px",
                                }}
                              >
                                Resolução{" "}
                              </p>
                              <p style={{ paddingBottom: 8 }}>
                                <strong>Responsável:</strong> {c.USUNOME}
                              </p>
                              <p style={{ paddingBottom: 8 }}>
                                <strong>Data:</strong>{" "}
                                {c.SOLDATAHORA
                                  ? moment(c.SOLDATAHORA).format(
                                      "DD/MM/YYYY hh:mm"
                                    )
                                  : "Data indisponível"}
                              </p>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <p>
                                  <strong>Descrição:</strong>
                                </p>
                                <p>
                                  {c.SOLDESCRICAO
                                    ? c.SOLDESCRICAO
                                    : "Descrição indisponível"}
                                </p>
                              </div>
                            </div>
                          </Paper>
                        );
                      }
                      return (
                        <Paper
                          style={{
                            padding: 8,
                            marginTop: "16px",
                            position: "relative",
                          }}
                        >
                          {a[i + 1]?.SOLSTATUS === 2 ? (
                            <>
                              <FirstComment last finished />
                              <Check
                                style={{
                                  position: "absolute",
                                  left: "-26px",
                                  top: "200%",
                                  color: "white",
                                  backgroundColor: "green",
                                  borderRadius: "50%",
                                }}
                              />
                            </>
                          ) : (
                            i !== a.length - 1 && <FirstComment />
                          )}
                          <div style={{ position: "relative", width: "100%" }}>
                            <p
                              style={{
                                paddingBottom: 8,
                                paddingTop: 8,
                                fontWeight: 700,
                                fontSize: "18px",
                              }}
                            >
                              {c.USUNOME}
                            </p>
                            <span
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                color: "#9B9B9B",
                              }}
                            >
                              Comentário enviado no dia{" "}
                              {c.SOLDATAHORA
                                ? format(
                                    new Date(c.SOLDATAHORA),
                                    "dd/MM/yyyy hh:mm"
                                  )
                                : "-"}
                            </span>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                padding: "8px 0",
                              }}
                            >
                              {c.SOLDESCRICAO}
                            </p>
                          </div>
                        </Paper>
                      );
                    })}
                  </>
                ))}
                <div
                  onClick={() =>
                    setAddComentario((s) => ({ ...s, open: true }))
                  }
                >
                  <AddButton
                    onClick={() =>
                      setAddComentario((s) => ({ ...s, open: true }))
                    }
                    style={{ marginTop: "16px" }}
                  >
                    <Edit style={{ fontSize: "14px" }} />{" "}
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        marginLeft: "8px",
                      }}
                    >
                      Adicionar comentário
                    </p>
                  </AddButton>
                </div>
                {addComentario.open && (
                  <Paper
                    style={{ padding: 8, marginTop: "16px" }}
                    elevation={2}
                  >
                    <h2 style={{ fontSize: "22px" }}>Enviar comentário</h2>
                    <TextField
                      style={{ marginTop: "8px" }}
                      fullWidth
                      multiline
                      rows={5}
                      variant="outlined"
                      onChange={(e) =>
                        setAddComentario((s) => ({
                          ...s,
                          value: e.target.value,
                        }))
                      }
                    />

                    <Grid
                      container
                      spacing={2}
                      style={{ marginTop: "16px", marginBottom: "8px" }}
                      className="buttons"
                    >
                      <Grid item xs={3}>
                        <LoadingButton
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setAddComentario((s) => ({ ...s, open: false }))
                          }
                        >
                          Cancelar
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={9}>
                        <LoadingButton
                          disabled={!addComentario.value}
                          variant="contained"
                          color="primary"
                          size="large"
                          fullWidth
                          type="submit"
                          onClick={async () => {
                            await handleSendFollowUp(teste[0].SOLCODIGO);
                            const data = await getSolCondition(
                              teste[0].SOLCODIGO
                            );
                            setTeste(data);
                            setAddComentario((s) => ({ ...s, open: false }));
                          }}
                        >
                          Enviar Comentário
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </div>
            </Fade>
          </Modal>
        </Paper>
      ) : (
        <p style={{ textAlign: "center", marginTop: "15px" }}>
          Não foram encontrados registros nesta data.
        </p>
      )}
    </Box>
  );
}
