import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { Paper, Typography } from "@material-ui/core";
import { CSSProperties } from "react";

export function CardEmpty({
  title,
  desc,
  removePaper,
  style,
}: {
  title: string;
  desc: string;
  removePaper?: boolean;
  style?: CSSProperties;
}) {
  return (
    <Paper
      style={{
        padding: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 30,
        textAlign: "center",
        maxWidth: 800,
        margin: "0px auto",
        ...style,
      }}
      elevation={removePaper ? 0 : 4}
    >
      <FilterNoneIcon style={{ fontSize: 62 }} />
      <Typography variant="h4" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {desc}
      </Typography>
    </Paper>
  );
}
