import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";

import { Form } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import DropFile from "../../shared/Files/DropFile";
import InputDate from "../../shared/Form/Fields/Date";
import HeaderWithBack from "../../shared/Item/HeaderWithBack";
import ModalConfirm from "../../shared/Modal/ModalConfirm";
import { api } from "../../shared/services/api";
import "./styles.scss";

const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    color: "white",
    margin: "10px 0px",
    background: theme.palette.error.main,
    "&:hover": {
      color: "white",
      background: theme.palette.error.main,
      opacity: 0.9,
    },
  },
}));

const ListFiles = (props) => {
  const { files, removeFile } = props;

  return (
    <List>
      <Typography variant="subtitle2">Anexos: </Typography>
      <br />
      {files.map((file, idx) => {
        return (
          <ListItem key={idx}>
            <ListItemText primary={file.file.name} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => removeFile(file.id)}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

interface FileId extends File {
  id: string;
}

export default function HistoricoEditar() {
  const {
    anaCodigo: idanalise,
    plCodigo: idplanoacao,
    hisCodigo: idhistorico,
    empCodigo: idempresa,
    type,
  } = useParams<{
    anaCodigo: string;
    plCodigo: string;
    hisCodigo: string;
    empCodigo: string;
    type: string;
  }>();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [content, setContent] = useState(null);
  const classes = useStyles();
  const urlMain =
    type === "fator"
      ? `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico/${idhistorico}`
      : `/analiserisco/${idanalise}/recomendacoes/historicos/${idhistorico}`;

  const linkBack = `/pages/cronogramas/editar/${idempresa}/${idanalise}/${type}/${idplanoacao}?verticalTab=1`;

  const urlArquivos =
    type === "fator"
      ? `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico/${idhistorico}/arquivos`
      : `/analiserisco/${idanalise}/recomendacoes/historicos/${idhistorico}/arquivos`;

  const uploadFiles = async (files, hiscodigo) => {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    files.forEach(async ({ file }) => {
      formData.append("file", file, file.name);
    });
    const request = await api();
    await request.post(urlArquivos, formData, config);
  };

  const [files, setFiles] = useState<FileId[]>([]);

  const addFiles = (acceptedFiles) => {
    const formatFiles = acceptedFiles.map((file) => {
      return {
        id: uuid(),
        file,
      };
    });
    setFiles((files) => [...files, ...formatFiles]);
  };

  const removeFile = (id) => {
    setFiles((files) => files.filter((file) => file.id !== id));
  };

  const onDrop = (acceptedFiles: File[]) => {
    addFiles(acceptedFiles);
  };

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const { data } = await req.get(
        `/analiserisco/${idanalise}/cronogramas/${idplanoacao}`
      );
      setContent(data);
    };
    if (type === "empresa") {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const req = await api();
      const res = await req.get(urlMain);
      setData(res.data);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <HeaderWithBack title="Editar Histórico de ações" linkBack={linkBack} />
      <Form
        spacing={"8px 8px"}
        onSubmit={async (dataForm) => {
          setLoading(true);
          const req = await api();
          try {
            if (content) {
              data["GHECODIGO"] = content.GHECODIGO || null;
              data["SETCODIGO"] = content.SETCODIGO || null;
              data["ANACODIGO"] = content.ANACODIGO || null;
              data["CROCODIGO"] = content.CROCODIGO || null;
            }
            const res = await req.put(urlMain, { ...data, ...dataForm });
            if (res.status === 200) {
              if (files.length) {
                await uploadFiles(files, res.data.HISCODIGO);
              }
              history.push(linkBack);
              toast.success("Histórico de ação editado com sucesso");
            }
          } catch (err) {
            toast.error(err.response.data.message);
          }
          setLoading(false);
        }}
        buttons={() => (
          <div
            style={{
              width: "100%",
              margin: "24px auto",
              padding: "7px",
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              style={{ marginTop: -20 }}
              type="submit"
              fullWidth
            >
              {loading ? <CircularProgress color="secondary" /> : "Guardar"}
            </LoadingButton>
            <LoadingButton
              className={classes.delete}
              variant="contained"
              type="button"
              onClick={() => setOpenModalConfirm(true)}
              fullWidth
              color="secondary"
            >
              {deleteLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                "Excluir"
              )}
            </LoadingButton>
          </div>
        )}
        defaultValues={data}
        schema={[
          {
            content: [
              {
                lg: 12,
                label: "Título",
                name: "HISTITULO",
              },
              {
                lg: 6,
                name: "HISDTINICIAL",
                customComponent: ({ control, name }) => (
                  <InputDate
                    control={control}
                    label="Data inicial"
                    name={name}
                  />
                ),
              },
              {
                lg: 6,
                name: "HISDTFINAL",
                customComponent: ({ control, name }) => (
                  <InputDate control={control} label="Data final" name={name} />
                ),
              },
              {
                lg: 12,
                label: "Responsável",
                name: "HISRESPONSAVEL",
              },
              {
                lg: 12,
                label: "Ação realizada",
                name: "HISACAOREALIZADA",
                inputPropsAdittional: {
                  multiline: true,
                  rows: 6,
                },
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  return (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ marginBottom: "10px" }}
                      >
                        Anexe documentos referentes a este histórico de ação
                      </Typography>
                      <DropFile
                        multiple={false}
                        onDrop={onDrop}
                        message={
                          <>
                            <b>Clique</b> ou <b>arraste arquivos aqui</b> para
                            adicionar um anexo ao histórico de ação
                          </>
                        }
                      />
                    </Box>
                  );
                },
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  if (!files.length) return <></>;
                  return <ListFiles files={files} removeFile={removeFile} />;
                },
              },
            ],
          },
        ]}
      />
      <ModalConfirm
        openModal={openModalConfirm}
        setOpenModal={setOpenModalConfirm}
        title="Deseja deletar esse histórico de ação?"
        description={null}
        confirmar={async () => {
          const req = await api();
          setDeleteLoading(true);
          try {
            await req.delete(urlMain);
            toast.success("Histórico de ação deletado com sucesso");
            history.push(linkBack);
          } catch (error) {
            toast.error(error.response?.data?.message);
          } finally {
            setDeleteLoading(false);
          }
        }}
        cancelar={() => setOpenModalConfirm(false)}
      />
    </div>
  );
}
