import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core";
import { CSSProperties } from "react";
import { Controller } from "react-hook-form";

interface RadioProps {
  label: string;
  value: any;
}

interface RadioButtonsProps {
  error: any;
  rules: any;
  name: string;
  control: any;
  label: string;
  onChangeItem?: any;
  inputPropsAdditional?;
  schama: Array<RadioProps>;
  formControlStyle?: CSSProperties;
}

export default function RadioButtons({
  name,
  label,
  rules,
  error,
  schama,
  control,
  onChangeItem,
  formControlStyle,
  inputPropsAdditional = {},
}: RadioButtonsProps) {
  return (
    <FormControl error={!!error} component="fieldset" style={{ width: "100%", ...formControlStyle }}>
      {label && (
        <FormLabel style={{ marginBottom: 5 }} component="legend">
          {label}
        </FormLabel>
      )}
      <Controller
        render={({ onChange, value }) => {
          return (
            <RadioGroup
              row
              onChange={(event) => {
                const value = Number(event.target.value);
                if (onChangeItem) onChangeItem(value);
                onChange(value);
              }}
              value={value}
            >
              {schama.map((schemaItem) => (
                <FormControlLabel
                  style={{ marginLeft: 0 }}
                  value={schemaItem.value}
                  control={<Radio color="primary" {...inputPropsAdditional} />}
                  label={schemaItem.label}
                // labelPlacement="start"
                />
              ))}
            </RadioGroup>
          );
        }}
        rules={rules}
        name={name}
        control={control}
      // style={{ justifyContent: "space-between" }}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
}
