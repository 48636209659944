import Cookies from "js-cookie";
import { createContext, useCallback, useContext, useState } from "react";

interface ICache {
  url: string;
  data: any;
}

const defCache: {
  cache?: ICache[];
  addCache?: (item: ICache) => void;
  getCache?: (url: string) => ICache | undefined;
  removeCache?: (url: string) => void;
} = {};

export const CacheContext = createContext(defCache);

export const useCache = () => useContext(CacheContext);

const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState<ICache[]>(() => {
    let cookies = Cookies.get("@PortalWEB:Cache");
    cookies = cookies === "undefined" ? "[]" : cookies;
    try {
      return JSON.parse(cookies);
    } catch (err) {
      return [];
    }
  });

  const loadCache = () => {
    const cookies = Cookies.get("@PortalWEB:Cache");
    const load = JSON.parse(cookies === "undefined" ? "[]" : cookies);
    setCache(load);
    return load;
  };

  const getCache = (url: string): ICache | undefined => {
    let load = cache;
    if (!load) load = loadCache();
    return load.find((c) => c.url === url);
  };

  const findCache = (item: ICache): number => {
    let load = cache;
    if (!load) load = loadCache();
    return load.findIndex((c) => item.url === c.url);
  };

  const addCache = useCallback((item: ICache) => {
    const cacheExists = findCache(item);
    let newCache;
    if (cacheExists !== -1) {
      newCache = [...cache];
      newCache[cacheExists].data = item.data;
    } else {
      newCache = [...cache, item];
    }
    setCache(newCache);
    Cookies.set("@PortalWEB:Cache", JSON.stringify(newCache));
  }, []);

  const removeCache = useCallback((url: string) => {
    const newCache = cache.filter((c) => c.url !== url);
    setCache(newCache);
    Cookies.set("@PortalWEB:Cache", JSON.stringify(newCache));
  }, []);

  return (
    <CacheContext.Provider value={{ cache, addCache, getCache, removeCache }}>
      {children}
    </CacheContext.Provider>
  );
};

export default CacheProvider;
