export const getInformations = (url: string) => {
    if(url.includes("egovsesmt-ma")) {
        return {
            name: "Governo do Maranhão :: Portal Web de Agendamento",
            image: "/ma.png"
        }
    } else {
        return {
            name: "ProSESMT :: Portal Web de Agendamento",
            image: "/favicon.ico"
        }
    }
}