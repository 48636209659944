import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";

export default function AutoCompleteNew({
  label,
  multiple,
  options,
  name,
  setValue,
  error,
  control,
  valeuDef,
  onChange,
  variant = "outlined",
  disabled = false,
  onClickItem,
  inputValue,
  onInputChange,
}: {
  label: string;
  multiple: boolean;
  options: any;
  name: any;
  rules?: any;
  setValue: any;
  error: any;
  control: any;
  valeuDef?: any;
  onChange?: any;
  variant?: "filled" | "standard" | "outlined";
  disabled?: boolean;
  onClickItem?: any;
  inputValue?: any;
  onInputChange?: (event: any, value) => void;
}) {
  const [valueInput, setValueInput] = useState<any>(multiple ? [] : {});

  let additionalProps: {
    inputValue?: any;
    onInputChange?: (event: any, value) => void;
    onClose?: any;
  } = {};
  if (onInputChange) {
    additionalProps = {
      ...additionalProps,
      inputValue,
      onInputChange,
      onClose: (_, reason) => {
        if (!inputValue && reason === "blur") onInputChange(null, "");
      },
    };
  }

  useEffect(() => {
    // if (valeuDef) setValueInput(valeuDef);
    if (onChange) onChange(valeuDef);

    if (valeuDef && options.length)
      setValueInput(options.find((item) => item.code == valeuDef));
  }, [options, valeuDef]);

  useEffect(() => {
    if (onChange) onChange(valueInput);
  }, [valueInput]);

  useEffect(() => {
    changeInfo();
  }, [valueInput]);

  const changeInfo = () => {
    let value = null;
    if (!valueInput) return;

    if (multiple && Array.isArray(valueInput)) {
      value = valueInput.map((item) => item.code);
    } else if (valueInput.code as { label: string; code: any }) {
      value = valueInput.code;
    }

    setValue(name, value, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ value }) => <input type="hidden" value={value} />}
      />
      <Autocomplete
        {...additionalProps}
        disabled={disabled}
        value={valueInput}
        options={options}
        multiple={multiple}
        onClick={onClickItem}
        getOptionLabel={(option: any) =>
          option?.code ? `${option.label}` : ""
        }
        renderOption={(option: any) => (
          <span>{option.labelHtml ?? option.label}</span>
        )}
        onChange={(event: any, newValue: any) => {
          setValueInput(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            variant={variant}
            label={label}
          />
        )}
      />
    </>
  );
}
