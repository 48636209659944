import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  ListSubheader,
} from "@material-ui/core";

interface ISelectForm {
  name?: string;
  label?: string;
  control?: any;
  errors?: any;
  FormControlProps?: any;
  rules?: any;
  disabled?: boolean;
  options?: any[];
  onChangeItem?: any;
  defaultValue?: string | number;
  InputLabelProps?: any;
  onDoubleClick?: any;
}

function SelectForm({
  name,
  label,
  control,
  errors,
  FormControlProps = { variant: "outlined" },
  rules = {},
  disabled,
  options,
  onChangeItem,
  defaultValue,
  InputLabelProps,
  onDoubleClick,
}: ISelectForm) {
  return (
    <FormControl {...FormControlProps} error={errors}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        render={({ value, onChange, ref }) => (
          <Select
            inputRef={ref}
            disabled={disabled}
            labelId={`${name}-label`}
            id={name}
            onChange={(event) => {
              onChange(event.target.value);
              if (onChangeItem) onChangeItem(event.target.value);
            }}
            label={label}
            value={value}
            defaultValue={defaultValue}
          >
            {options ? (
              options.map((item, index) =>
                item.header ? (
                  <ListSubheader key={index}>{item.label}</ListSubheader>
                ) : (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              )
            ) : (
              <MenuItem disabled value="loading">
                <ListItemIcon>
                  <CircularProgress size={18} />
                </ListItemIcon>
                <ListItemText primary="Carregando..." />
              </MenuItem>
            )}
          </Select>
        )}
        name={name}
        control={control}
        rules={rules}
      />
      {errors && errors.message && (
        <FormHelperText>{errors.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectForm;
