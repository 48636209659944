import React from "react";
import {
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  Table,
} from "@material-ui/core";

import useCrudStyles from "./styles";

export interface SchamaCurd<T> {
  label?: string;
  content: keyof T;
  formatFun?: (data: T | T[keyof T]) => string | JSX.Element;
  formatAll?: boolean;
  button?: (data: T[keyof T]) => JSX.Element;
}

export interface CrudModel<T> {
  content?: T[];
  title?: string;
  schema?: SchamaCurd<T>[];
  onEditClick?: (data: T) => void;
  onEditDoubleClick?: (data: T) => void;
  edit?: boolean;
  actions?: any;
}

export default function Crud<T>({
  content,
  title,
  schema,
  onEditClick,
  onEditDoubleClick,
  edit = false,
  actions,
}: CrudModel<T>) {
  const classes = useCrudStyles();
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className={classes.rootContent}>
      {actions && <div className={classes.button}>{actions}</div>}

      <TableContainer>
        <Table aria-label="table" size="small">
          <TableHead>
            <TableRow>
              {schema.map((item) => (
                <TableCell align="left">
                  {item.button ? "" : <b>{item.label}</b>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-cy="table-body">
            {content &&
              content
                .slice(page * 12, page * 12 + 12)
                .map((item, indexPage) => (
                  <TableRow
                    className={edit ? classes.tableRow : classes.empty}
                    hover={edit}
                    onClick={() => {
                      if (edit && onEditClick) onEditClick(item);
                    }}
                    onDoubleClick={() => {
                      if (edit && edit) onEditDoubleClick(item);
                    }}
                    key={indexPage}
                  >
                    {schema.map((schameItem, index) => (
                      <>
                        <TableCell
                          data-cy={`listTile-${
                            schameItem.content as string
                          }-${indexPage}`}
                          key={index}
                          align="left"
                        >
                          {schameItem.button
                            ? schameItem.button(item[schameItem.content])
                            : schameItem.formatAll
                            ? schameItem.formatFun(item)
                            : schameItem.formatFun
                            ? schameItem.formatFun(item[schameItem.content])
                            : item[schameItem.content]}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={content ? content.length : 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={() => {}}
      />
    </div>
  );
}
