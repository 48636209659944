import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Form } from "../../shared";
import { eSocialFilterModel } from "../../redux/reducers/eSocialReducer";
import InputDate from "../../shared/Form/Fields/Date";
import LoadingButton, {
  ContainedLoadingButton,
  OutlinedLoadingButton,
} from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { useReportExcel } from "../../hooks/useReportExcel";
import "./styles.scss";
import { headers } from "./controller";
import { toast } from "react-toastify";

export default function VerAgendamentos() {
  const [page, setPage] = useState(0);
  const [pageAt, setPageAt] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [responsePage, setResponsePage] = useState([]);
  const [open, setOpen] = useState(false);
  const [DeleteItem, setDeleteItem] = useState(null);
  const [agendamentos, setAgendamentos] = useState([]);
  const history = useHistory();

  const { isLoading, onReport } = useReportExcel(
    "/agenda/agendamentos",
    "Relatório de agendamentos",
    headers
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {"Tem certeza que deseja continuar ?"}
        </DialogTitle>
        <DialogContent>
          <Form
            spacing={"0px 24px 16px 0px"}
            onSubmit={async (data) => {
              const req = await api();
              req
                .delete(`/agenda/agendamentos/${DeleteItem}`, {
                  params: data,
                })
                .then((response) => {
                  toast.success(response.data?.message);
                  history.push("/pages/VerAgendamentos");
                })
                .catch((error) => {
                  toast.error(error.response?.data?.message);
                })
                .finally(() => {
                  setOpen(false);
                });
            }}
            buttons={() => (
              <div>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{ marginRight: "15px", marginLeft: "51%" }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  autoFocus
                >
                  Continuar
                </Button>
              </div>
            )}
            schema={[
              {
                content: [
                  {
                    lg: 12,
                    name: "MOTIVO",
                    label: "Motivo do cancelamento",
                    inputPropsAdittional: { multiline: true, rows: 4 },
                  },
                ],
              },
            ]}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Box sx={{ margin: "10px" }}>
        <Box sx={{ flexGrow: 1 }} className="testePrint">
          <Box style={{ margin: 0, padding: 0 }}>
            <Typography
              align="left"
              style={{
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "15pt",
              }}
              color="primary"
            >
              Buscar Agendamentos
            </Typography>
            <Form<eSocialFilterModel | any>
              spacing={"0px 4px"}
              onSubmit={async (data) => {
                try {
                  setPageAt(1);
                  setPage(0);
                  setLoadingButton(true);
                  const req = await api();
                  const response = await req.get("/agenda/agendamentos", {
                    params: data,
                  });
                  const pageQuantity = 10;
                  const page = response.data?.length / pageQuantity;
                  const totalPage = Math.ceil(page);
                  setPage(totalPage);
                  setAgendamentos(response.data?.slice(0, 10));
                  setResponsePage(response.data);
                  setLoadingButton(false);
                } catch (error) {
                  setLoadingButton(false);
                  toast.error(error.response?.data?.message);
                }
              }}
              buttons={({ handleSubmit }) => (
                <div
                  style={{
                    width: "100%",
                    margin: "24px 0",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ContainedLoadingButton
                    type="submit"
                    size="large"
                    loading={loadingButton}
                    style={{
                      minWidth: "200px",
                      height: "42px",
                      marginRight: "15px",
                    }}
                  >
                    Buscar
                  </ContainedLoadingButton>
                  <OutlinedLoadingButton
                    size="large"
                    loading={isLoading}
                    style={{ minWidth: "200px", height: "42px" }}
                    onClick={handleSubmit(onReport)}
                  >
                    Gerar Planilha
                  </OutlinedLoadingButton>
                </div>
              )}
              schema={[
                {
                  content: [
                    {
                      xs: 12,
                      sm: 3,
                      md: 3,
                      lg: 3,
                      name: "DATAINICIAL",
                      type: "custom",
                      customDefValue: new Date(),
                      customComponent: ({ control, name }) => (
                        <InputDate
                          control={control}
                          label="Data inicial"
                          name={name}
                        />
                      ),
                    },
                    {
                      xs: 12,
                      sm: 3,
                      md: 3,
                      lg: 3,
                      name: "DATAFINAL",
                      type: "custom",
                      customDefValue: new Date(),
                      customComponent: ({ control, name }) => (
                        <div className="margin">
                          <InputDate
                            control={control}
                            label="Data final"
                            name={name}
                          />
                          <br />
                          <br />
                        </div>
                      ),
                    },
                    {
                      label: "Nome do Funcionário",
                      name: "FUNNOME",
                      xs: 12,
                      sm: 6,
                      md: 6,
                      lg: 6,
                      inputPropsAdittional: {
                        type: "text",
                        autoComplete: "off",
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>
        {page !== 0 ? (
          <Box className="marginPrint">
            {agendamentos.map((element) => (
              <Grid container>
                <Grid item xs={12} md={6} style={{ marginTop: "25px" }}>
                  <strong style={{ color: "red", textTransform: "uppercase" }}>
                    {element?.ATESTATUS === 9 ? "Cancelado" : ""}
                  </strong>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      marginBottom: "10px",
                    }}
                  >
                    <p>
                      <span style={{ fontWeight: "bold" }}>Prestador: </span>
                      <span style={{ textTransform: "uppercase" }}>
                        {element.PRONOME}
                      </span>{" "}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Data: </span>
                      {moment
                        .utc(new Date(element.ATEDATA))
                        .format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Horário: </span>
                      {element.ATEHORA
                        ? element.ATEHORA?.toString().substring(0, 5)
                        : "não disponível"}
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div>
                    <Button
                      className="no_margin_min_size"
                      variant="text"
                      color="default"
                      disabled={
                        new Date(element.ATEDATA) <= new Date() ||
                        element.ATESTATUS === 9
                      }
                      type="button"
                      onClick={() => {
                        setOpen(true);
                        setDeleteItem(element.ATECODIGO);
                      }}
                      style={{ display: "block", margin: "auto" }}
                    >
                      Cancelar
                    </Button>
                  </div>
                </Grid>

                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        {element.FUNCPF} - {element.FUNNOME}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Rg: </span>
                      {element.FUNRG}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>NIS:</span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Data de Nascimento:{" "}
                      </span>
                      {element.FUNNASCIMENTO
                        ? moment
                          .utc(new Date(element.FUNNASCIMENTO))
                          .format("DD/MM/YYYY")
                        : ""}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Gênero: </span>
                      {element.FUNSEXO === 1 ? "Masculino" : "Feminino"}
                    </p>
                    <div style={{ marginTop: "10px" }}>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Tipo exame: </span>
                        {element.ATETIPOEXAMEDESC}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Exames: </span>
                        {typeof element.EXAMES === "string" &&
                          element.EXAMES?.substring(
                            0,
                            element.EXAMES.length - 2
                          )}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="field">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Função: </span>
                        {element.FCONOME}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>Setor: </span>
                        {element.SETNOME}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <div className="field">
                      <p>
                        <span style={{ fontWeight: "bold" }}>Matrícula: </span>{element.FUNMATRICULA
                          ? element.FUNMATRICULA
                          : "não disponível"}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Data de Admissão:
                        </span>{" "}
                        {element.FUNADMISSAO
                          ? moment(element.FUNADMISSAO).format("DD/MM/YYYY")
                          : ""}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Horario de entrada:{" "}
                        </span>
                        {element.HORA_CHEGADA
                          ? element.HORA_CHEGADA.toString().substring(11, 16)
                          : "não disponível"}
                      </p>
                      <p>
                        <span style={{ fontWeight: "bold" }}>
                          Horario de saída:{" "}
                        </span>{" "}
                        {element.HORA_SAIDA
                          ? element.HORA_SAIDA.toString().substring(11, 16)
                          : "não disponível"}
                      </p>
                    </div>
                  </Grid>
                </Grid>
                <Paper
                  style={{ width: "100%", padding: "25px", margin: "15px 0" }}
                >
                  <table style={{ marginTop: "15px", width: "100%" }}>
                    <tr
                      style={{
                        borderBottom: "2px solid #c0c0c0",
                        width: "100%",
                        display: "block",
                        marginBottom: "15px",
                        paddingBottom: "5px",
                      }}
                    >
                      <td style={{ width: "100%", margin: "5px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Guias e anexos
                        </span>
                      </td>
                    </tr>
                    {element.ARQUIVOS?.length > 0 &&
                      element.ARQUIVOS.map((item) => (
                        <>
                          <tr style={{ display: "block", marginBottom: "5px" }}>
                            <td>
                              <a
                                href={item.URL}
                                download
                                style={{ fontSize: "14px" }}
                              >
                                <strong>{item.ARQDESCRICAO}</strong>
                              </a>
                            </td>
                          </tr>
                        </>
                      ))}
                  </table>
                </Paper>
              </Grid>
            ))}
            {responsePage.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LoadingButton
                  variant="contained"
                  color="primary"
                  style={{
                    width: "5%",
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  size="large"
                  disabled={pageAt - 1 === 0}
                  onClick={async () => {
                    setLoading(true);
                    const newPage = pageAt - 1;
                    setPageAt(newPage);
                    setAgendamentos(
                      responsePage.slice(newPage * 10, newPage * 10 + 10)
                    );
                    window.scrollTo({ top: 300, behavior: "smooth" });
                    setLoading(false);
                  }}
                >
                  <p>◄</p>
                </LoadingButton>
                <div style={{ margin: "auto" }}>
                  {loading ? (
                    <CircularProgress size={30} />
                  ) : (
                    `Página ${pageAt} de ${page}`
                  )}
                </div>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={pageAt + 1 > page}
                  style={{
                    width: "5%",
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  size="large"
                  onClick={async () => {
                    setLoading(true);
                    const newPage = pageAt + 1;
                    setPageAt(newPage);
                    setAgendamentos(
                      responsePage.slice(pageAt * 10, newPage * 10)
                    );
                    window.scrollTo({ top: 300, behavior: "smooth" });
                    setLoading(false);
                  }}
                >
                  <p>►</p>
                </LoadingButton>
              </div>
            ) : (
              <p style={{ textAlign: "center" }}>
                Não foram encontrados agendamentos nesta data.
              </p>
            )}
          </Box>
        ) : !loadingButton ? (
          <p style={{ textAlign: "center" }}>
            Não foram encontrados agendamentos para a sua busca.
          </p>
        ) : null}
      </Box>
    </>
  );
}
