import axios, { AxiosError } from "axios";

export const accessToken = localStorage.getItem("Token_Key");

export const instance = axios.create({
  baseURL: "https://api1.prosesmt.com.br/",
  timeout: 120000,
});

export const instanceNew = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  timeout: 120000,
});

instance.interceptors.request.use(
  (config) => {
    if (accessToken) {
      config.headers = Object.assign(
        {
          Authorization: accessToken,
        },
        config.headers
      );
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function api() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 120000,
  });

  const accessToken = localStorage.getItem("Token_Key");

  api.interceptors.request.use(
    (config) => {
      if (accessToken) {
        config.headers = Object.assign(
          {
            Authorization: `Bearer ${accessToken}`,
          },
          config.headers
        );
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(undefined, function (error) {
    const axiosError = error as AxiosError<any>;
    if (
      axiosError.response?.status === 401 ||
      axiosError.response?.status === 403
    ) {
      const error = {
        response: {
          data: {
            message: "O usuário não possui permissão pra realizar esta ação",
          },
        },
      };
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });

  api.defaults.timeout = 5000000;
  api.defaults.timeoutErrorMessage = "Timeout";

  return api;
}
