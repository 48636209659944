import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  List,
  ListItemSecondaryAction,
  Chip,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../shared/services/api";
import { useStyles } from "..";
import LoadingButton from "../../../shared/Button/LoadingButton";
import { EsocialDashboardContainer } from "../EsocialDashboardContainer";

import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";

export default function EsocialDashboardProcessos(props) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ tipo?: string }>();

  useEffect(() => {
    async function loadSchedule() {
      try {

        const teste = localStorage.getItem('Token_Key');
        if (teste === props.match.params.token) {
          const req = await api();
          await req.get(`/users/token`)
        } else {
          history.push('/')
        }
      } catch {
        history.push('/')
      }
    }

    loadSchedule();
  }, [history, props.match.params.token]);

  const send = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const redirectBack = () =>
    history.push(`/esocial/dashboard/${params.tipo}/filtros`);
  const redirectProcesso = (id: number) =>
    history.push(`/esocial/dashboard/${params.tipo}/items/${id}`);

  return (
    <EsocialDashboardContainer subTitle="Resultados">
      <>
        <List>
          {[1, 2, 3, 4, 5].map((processo) => (
            <ListItem
              onClick={() => redirectProcesso(processo)}
              className={classes.listItem}
              button
            >
              <ListItemIcon>
                <Chip
                  label="Processando"
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </ListItemIcon>
              <div style={{ width: 30 }}></div>
              <ListItemText key={processo} primary={`Line item ${processo}`} />
              <ListItemSecondaryAction>
                <Checkbox onClick={(e) => e.stopPropagation()} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <div style={{ height: 50 }} />
        <DialogActions>
          <Button
            startIcon={<ChevronLeftRoundedIcon />}
            onClick={redirectBack}
            variant="outlined"
            color="primary"
          >
            Voltar
          </Button>
          <LoadingButton
            onClick={send}
            loading={loading}
            variant="contained"
            color="primary"
            endIcon={<SendRoundedIcon />}
          >
            Enviar
          </LoadingButton>
        </DialogActions>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 15,
          }}
        >
          <Typography
            color="textSecondary"
            style={{ textAlign: "end" }}
            variant="caption"
          >
            Ao clicar em enviar os items selecionados serão enviados para o
            governo
          </Typography>
        </div>
      </>
    </EsocialDashboardContainer>
  );
}
