import Cookies from "js-cookie";
import Horario from "./horario";
import { useState } from "react";
import { toast } from "react-toastify";
import { format, parse } from "date-fns";
import { useHistory } from "react-router-dom";
import { api } from "../../shared/services/api";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import { ModalClinico } from "../../shared/ModalClinico";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ScheduleEventDomain } from "../../redux/reducers/scheduleReducer";
import { Card, CardActionArea, CardContent, Typography, IconButton, Dialog, DialogTitle, DialogContent, CircularProgress } from "@material-ui/core";

export default function CartSchedule({
  item,
  onDeleteItem,
}: {
  item: ScheduleEventDomain;
  onDeleteItem: (item: ScheduleEventDomain) => void;
}) {
  return (
    <Card elevation={3} style={{ position: "relative" }}>
      <IconButton
        onClick={() => onDeleteItem(item)}
        style={{ position: "absolute", top: 0, right: 0, color: "red" }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent style={{ textAlign: "center", height: "100%" }}>
        <PersonIcon />
        <br />
        <Typography variant="h5">{item.Employee.FUNNOME}</Typography>
        <Typography variant="body1">{item.ExamType.DESCRICAO}</Typography>
        <Typography variant="body1">{item._Periodo}</Typography>
      </CardContent>
    </Card>
  );
}

export function CartProximo() {

  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const verifyDuplicity = async () => {
    try {
      setLoading(true)
      const params = Cookies.get("@PortalWEB:timeSelected") ? JSON.parse(Cookies.get("@PortalWEB:timeSelected")) : null
      if (params?.TIPO === 2 || !params) {
        history.push("/pages/finalizar")
        return
      }
      const instance = await api()
      const parsedDate = parse(params?.proData, 'MM/dd/yyyy', new Date());
      const formattedDate = format(parsedDate, 'yyyy-MM-dd')
      const response = await instance.get("/agenda/agendamentos-simultaneos", {
        params: {
          ...params,
          proData: formattedDate
        }
      })
      if (response.data.AGENDAMENTO_SIMULTANEO) {
        setOpenModal(true)
      } else {
        history.push("/pages/finalizar")
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card variant="outlined" style={{ height: "100%" }}>
      <CardActionArea
        onClick={async () => {
          verifyDuplicity()
        }}
        style={{ height: "100%" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {
            loading
              ? <CircularProgress />
              : (
                <>
                  <NavigateNextIcon fontSize="large" />
                  <Typography variant="subtitle1">Finalizar</Typography>
                </>
              )
          }
        </div>
      </CardActionArea>
      <Dialog
        maxWidth="lg"
        open={openModal}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          O Horário Escolhido já foi Selecionado!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="textSecondary">
            Para continuar, escolha outro horário para o agendamento!
          </Typography>
          <Horario
            modal={
              () => {
                setOpenModal(false)
                toast.success("Horário alterado com sucesso!")
                history.push("/pages/finalizar")
              }
            }
          />
        </DialogContent>
      </Dialog>
      <ModalClinico />
    </Card>
  );
}
