import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";

import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { Form } from "../../shared";
import InputDate from "../../shared/Form/Fields/Date";
import CrudSelect from "../../shared/CrudSelect";
import { useReportExcel } from "../../hooks/useReportExcel";
import { headers } from "./controller";
import { Headers } from "../../utils/xlsx";

export const b64toBlob = (base64) => fetch(base64).then((res) => res.blob());

function GerenciadorArquivos() {
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any[]>([]);
  const [selecteds, setSelecteds] = useState<any[]>([]);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  const { isLoading, onReport } = useReportExcel(
    "/funcionarios/documentos",
    "Relatório de arquivos",
    headers as Headers<any>[]
  );

  const download = async () => {
    setLoadingDownload(true);
    const req = await api();
    try {
      const response = await req.post("/funcionarios/documentos", selecteds);
      const responseGet = await axios.get(response.data.message, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([responseGet.data], {
        type: "application/zip",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.download = "Exames_" + Date.now();
      link.click();
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setLoadingDownload(false);
    }
  };

  return (
    <Box sx={{ margin: "10px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Box style={{ margin: 0, padding: 0 }}>
          <Typography
            align="left"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "15pt",
            }}
            color="primary"
          >
            Gerenciador de Arquivos
          </Typography>
          <Form<any>
            spacing={"5px 4px"}
            onSubmit={async (data) => {
              try {
                setSelecteds([]);
                setLoading(true);
                const req = await api();
                const response = await req.get("/funcionarios/documentos", {
                  params: data,
                });
                setContent(response.data);
              } catch (error) {
                return toast.error(error.response?.data?.message);
              } finally {
                setLoading(false);
              }
            }}
            buttons={({ handleSubmit }) => (
              <div
                style={{
                  width: "100%",
                  margin: "24px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{
                    minWidth: "200px",
                    height: "42px",
                    marginRight: "15px",
                  }}
                  rules={{ required: "Campo Obrigatório" }}
                  size="large"
                  loading={loading}
                >
                  Buscar
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  style={{ minWidth: "200px", height: "42px" }}
                  size="large"
                  onClick={handleSubmit(onReport)}
                  loading={isLoading}
                >
                  Gerar Planilha
                </LoadingButton>
              </div>
            )}
            schema={[
              {
                content: [
                  {
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    name: "DATAINICIAL",
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <InputDate
                        control={control}
                        label="Data inicial"
                        name={name}
                      />
                    ),
                  },
                  {
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    name: "DATAFINAL",
                    type: "custom",
                    customDefValue: new Date(),
                    customComponent: ({ control, name }) => (
                      <div className="margin">
                        <InputDate
                          control={control}
                          label="Data final"
                          name={name}
                        />
                      </div>
                    ),
                  },
                  {
                    label: "Nome do Funcionário",
                    name: "FUNNOME",
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    inputPropsAdittional: {
                      type: "text",
                      autoComplete: "off",
                    },
                  },
                  {
                    label: "Nome do arquivo",
                    name: "ARQNOME",
                    xs: 6,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    inputPropsAdittional: {
                      type: "text",
                      autoComplete: "off",
                    },
                  },
                  {
                    label: "Setor",
                    name: "SETNOME",
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    inputPropsAdittional: {
                      type: "text",
                      autoComplete: "off",
                    },
                  },
                  {
                    label: "GHE",
                    name: "GHENOME",
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    inputPropsAdittional: {
                      type: "text",
                      autoComplete: "off",
                    },
                  },
                  {
                    label: "Função",
                    name: "FCONOME",
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    inputPropsAdittional: {
                      type: "text",
                      autoComplete: "off",
                    },
                  },
                ],
              },
            ]}
          />
        </Box>
      </Box>
      <CrudSelect
        actions={
          <>
            {!!content.length && (
              <LoadingButton
                variant="outlined"
                color="primary"
                disabled={!selecteds.length}
                loading={loadingDownload}
                onClick={download}
              >
                Baixar Arquivos
              </LoadingButton>
            )}
          </>
        }
        content={content}
        schema={[
          { label: "Funcionário", content: "FUNNOME" },
          { label: "Nome do arquivo", content: "ARQNOME" },
          { label: "Setor", content: "SETNOME" },
          {
            label: "GHE/Função",
            content: "GHENOME",
            formatAll: true,
            formatFun: (data: any) => {
              return data.GHENOME && data.GHENOME + " / " + data.FCONOME;
            },
          },
          // { label: "Função", content: "FCONOME" },
          {
            label: "Data de realização",
            content: "FEADATAREALIZADO",
            formatFun: (value) => moment(value).format("DD/MM/YYYY"),
          },
        ]}
        selecteds={selecteds}
        selectedProp="ARQGUID"
        edit
        setSelecteds={setSelecteds}
      />
    </Box>
  );
}

export default GerenciadorArquivos;
