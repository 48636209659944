import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Tooltip,
  Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { Crud } from "../../shared";
import { api } from "../../shared/services/api";
import { useQuery } from "../../utils/functions";

type TabPanelProps = {
  children: JSX.Element | ReactNode;
  index: any;
  value: any;
};

const getStatus = (value) => {
  return <Chip size="small" variant="outlined" label={value} color="default" />;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "25px",
    maxWidth: "95%",
  },
}));

type SimpleTabsProps = { anaCodigo: number; empCodigo: number };

const Empresas = ({ content, onEditClick }) => {
  return (
    <Crud
      edit
      content={content}
      schema={[
        {
          label: "Prioridade",
          content: "CROPRIORIDADE",
        },
        {
          label: "Medidas Propostas",
          content: "CRORECOMENDACAO",
        },
        {
          label: "Estratégia e Metodologia",
          content: "CROESTRATEGIA",
        },
      ]}
      onEditClick={onEditClick}
    />
  );
};

const FatoresDeRisco = ({ content, onEditClick }) => {
  return (
    <Crud
      edit
      content={content}
      schema={[
        {
          label: "Pontuação Obtida",
          content: "RISPONTUACAO",
          formatFun: (data) => {
            if (data["Pontuação Obtida"]) {
              return (
                <Tooltip
                  title={
                    <div style={{ fontSize: 14, padding: 10, lineHeight: 2 }}>
                      {data["Orientação Para Tomada de Decisão"]}
                    </div>
                  }
                >
                  <Chip
                    label={data["Pontuação Obtida"]}
                    variant="outlined"
                    size="small"
                    style={{ border: `1px solid ${data.Color}` }}
                  />
                </Tooltip>
              );
            }
            return <></>;
          },
        },
        {
          label: "Risco",
          content: "RISDESCRICAO",
          formatFun: getStatus,
        },
        {
          label: "Agente",
          content: "AGTDESCRICAO",
        },
        {
          label: "Responsável",
          content: "PLRESPONSAVEL",
        },
        {
          label: "Intervenção",
          content: "PLMEDPREVENCAO",
        },
        {
          label: "Setor",
          content: "SETNOME",
        },
        {
          label: "GHE",
          content: "GHENOME",
        },
        {
          label: "Funções",
          content: "FCONOME",
        },
      ]}
      onEditClick={onEditClick}
    />
  );
};

export default function SimpleTabs({ anaCodigo, empCodigo }: SimpleTabsProps) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const query = useQuery();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const params = new URLSearchParams(window.location.search);
    params.set("verticalTab", newValue.toString());
    window.history.replaceState({ ...window.history.state }, "", `?${params}`);
  };

  useEffect(() => {
    if (Number(query.get("verticalTab")) != value) {
      setValue(Number(query.get("verticalTab") || 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("verticalTab")]);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        elevation={-1}
        style={{ position: "sticky", top: 60, backgroundColor: "#fafafa" }}
      >
        <Tabs
          orientation="horizontal"
          indicatorColor="primary"
          value={value}
          textColor="primary"
          style={{ background: "inherit" }}
          onChange={handleChange}
          aria-label="tabs"
          variant="fullWidth"
        >
          <Tab label="Empresa" {...a11yProps(1)} />
          <Tab label="Fatores de Risco" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {anaCodigo && (
          <Wrapper
            url={`/analiserisco/${anaCodigo}/cronogramas`}
            render={(content) => {
              return (
                <Empresas
                  content={content}
                  onEditClick={async (data) => {
                    history.push(
                      `/pages/cronogramas/editar/${empCodigo}/${anaCodigo}/empresa/${data?.CROCODIGO}`
                    );
                  }}
                />
              );
            }}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {anaCodigo && (
          <Wrapper
            url={`/analiserisco/empresas/${empCodigo}/analises/${anaCodigo}/planosacao`}
            render={(content) => {
              return (
                <FatoresDeRisco
                  content={content}
                  onEditClick={(data) => {
                    history.push(
                      `/pages/cronogramas/editar/${empCodigo}/${anaCodigo}/fator/${data?.PLCODIGO}`
                    );
                  }}
                />
              );
            }}
          />
        )}
      </TabPanel>
    </div>
  );
}

const Wrapper = (props) => {
  const { url, render } = props;
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const req = await api();
      try {
        const res = await req.get(url);
        setContent(res.data);
      } catch (error) {
        toast.error(error.response?.data?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [url]);

  if (!url) return null;

  if (loading) return <Loading />;

  if (content.length < 1) {
    return (
      <h3 style={{ textAlign: "center" }}>
        Não foram encontrados planos de ação pra essa análise.
      </h3>
    );
  }

  return render(content);
};

export const Loading = (props) => {
  const { color = "primary", size = 50 } = props;

  return (
    <div style={{ textAlign: "center" }}>
      <CircularProgress color={color} size={size} />
    </div>
  );
};

export function SelectDef({ children, value, onChange, label }) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="analise"
        label="Selecione uma Análise"
        onChange={onChange}
        value={value}
      >
        {children}
      </Select>
    </FormControl>
  );
}
