import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { api } from "../../shared/services/api";
import isValidAccess from "../../utils/isValidAcess";

export default function useCrud<T>(
  urlBase: string,
  name: string,
  backPath = "",
  itemId = "0",
  hooks?: {
    onAfterCreate?: (data: T, datCreated: any) => void;
    onBeforeCreate?: (data: T) => T;
    onBeforeGet?: (data: T) => T;
    onBeforeEdit?: (data: T) => T;
    onAfterEdit?: (data: T) => void;
    onAfterDelete?: () => void;
    onError?: (error: unknown) => void;
    createurl?: string;
    params?: any;
    revalidateOnEdit?: boolean;
    access?: {
      code?: string;
      destroy?: string;
      create?: string;
      edit?: string;
      get?: string;
    };
  }
) {
  const [content, setData] = useState<T | null>();
  const [loadingSubmit, setLoading] = useState(false);
  const [loadingDelete, setDeleteLoading] = useState(false);
  const [loading, setLoadingGet] = useState<boolean>(() => {
    if (itemId !== "0" && itemId !== null) {
      return true;
    }
    return false;
  });
  const history = useHistory();

  const [disabledDestroy, setDisabledDestroy] = useState<boolean>(false);
  const [disabledCreate, setDisabledCreate] = useState<boolean>(false);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [disabledGet, setDisabledGet] = useState<boolean>(false);

  useEffect(() => {
    if (hooks?.access?.code) {
      if (!isValidAccess(hooks?.access?.code + "-I")) setDisabledCreate(true);
      if (!isValidAccess(hooks?.access?.code + "-A")) setDisabledEdit(true);
      if (!isValidAccess(hooks?.access?.code + "-E")) setDisabledDestroy(true);
      if (!isValidAccess(hooks?.access?.code + "-V")) setDisabledGet(true);
    }
  }, []);

  useEffect(() => {
    if (itemId !== "0" && itemId !== null) {
      getData();
    }
  }, []);

  const getData = async () => {
    const instance = await api();
    setLoadingGet(true);
    instance
      .get<T>(itemId ? `${urlBase}/${itemId}` : urlBase)
      .then(function (response) {
        let { data } = response;
        if (hooks && hooks.onBeforeGet) data = hooks.onBeforeGet(data);
        setData(data);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(function (error) {
        setData({} as T);
        toast.error(
          error.response?.data?.message ||
            "Ocorreu um erro inesperado. Entre em contato com o Suporte"
        );
      })
      .finally(() => setLoadingGet(false));
  };

  const create = async (data: any, removeEmpty = true) => {
    if (hooks && hooks.onBeforeCreate) data = hooks.onBeforeCreate(data);
    setLoading(true);
    const newData: Partial<T> = {};
    if (removeEmpty) {
      Object.keys(data).forEach(function (key) {
        if (data[key] && data[key].toString() !== "") {
          newData[key] = data[key];
        }
      });
    }
    const instance = await api();
    instance
      .post(hooks?.createurl || urlBase, removeEmpty ? newData : data, {
        params: hooks?.params || {},
      })
      .then(function (res) {
        setLoading(false);
        toast.success(`${name} adicionado com sucesso`);
        if (hooks && hooks.onAfterCreate) {
          hooks.onAfterCreate(data, res?.data);
        } else {
          history.push(backPath);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const edit = async (dataEd: T, sendDefData = true) => {
    if (hooks && hooks.onBeforeEdit) dataEd = hooks.onBeforeEdit(dataEd);
    setLoading(true);

    const instance = await api();

    instance
      .put(
        itemId ? `${urlBase}/${itemId}` : urlBase,
        sendDefData ? { ...content, ...dataEd } : dataEd,
        {
          params: hooks?.params ?? {},
        }
      )
      .then(function () {
        setLoading(false);
        toast.success(`${name} editado com sucesso`);

        if (hooks && hooks.onAfterEdit) {
          hooks.onAfterEdit(dataEd);
        }

        if (hooks?.revalidateOnEdit) getData();

        history.push(backPath);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error.response?.data?.message);
      });
  };

  const destroy = async (data?: any, querystring = "") => {
    const instance = await api();
    if (!hooks?.access?.destroy || isValidAccess(hooks?.access?.destroy)) {
      setDeleteLoading(true);
      instance
        .delete(
          itemId
            ? `${urlBase}/${itemId}${querystring}`
            : `${urlBase}${querystring}`,
          {
            data,
          }
        )
        .then(function () {
          setDeleteLoading(false);
          if (hooks && hooks.onAfterDelete) {
            hooks.onAfterDelete();
          }
          toast.success(`${name} deletado com sucesso`);
          history.push(backPath);
        })
        .catch(function (error) {
          setDeleteLoading(false);
          if (hooks?.onError) {
            hooks.onError(error);
          } else {
            toast.error(error?.response?.data?.message);
          }
        });
    } else {
      toast.warning(`Você não tem permissão para deletar ${name}`);
    }
  };

  return {
    loadingSubmit,
    loadingDelete,
    destroy,
    create,
    edit,
    content,
    disabledDestroy,
    disabledCreate,
    disabledEdit,
    disabledGet,
    loading,
  };
}
