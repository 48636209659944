import { ScheduleEventDomain } from "../reducers/scheduleReducer";

export type Action =
  | { type: "ADD_SCHEDULE"; payload: ScheduleEventDomain }
  | { type: "REMOVE_SCHEDULE"; payload: string }
  | { type: "CLEAR_SCHEDULES" };

export const addSchedule = (type: ScheduleEventDomain): Action => ({
  type: "ADD_SCHEDULE",
  payload: type,
});

export const removeSchedule = (type: string): Action => ({
  type: "REMOVE_SCHEDULE",
  payload: type,
});

export const clearSchedules = (): Action => ({
  type: "CLEAR_SCHEDULES",
});
