import useCrud from "../../shared/Crud/useCrud";
import { getDateTime } from "../../utils/functions";

export interface FuncionarioAtestadoClinicoModel {
  ATSCODIGO: number;
  FUNCODIGO: number;
  ATSCID: string;
  ATSMEDICO: string;
  ATSCRM: string;
  ATSDIAAFASTDO: number;
  ATSDATA: Date;
  ATSDESCRICAO: string;
  ASSINA: string;
  ATSCODCNES?: any;
  ATSHORA?: any;
  ATSINTERNACAO?: any;
  ATSAFASTAMENTO?: any;
  DNLCODIGO?: any;
  ATSOBSERVACAO: string;
  ATSDIAGPROVAVEL?: any;
  ATSUFCRM?: any;
  ATSTIPOAFASTAMENTO?: any;
  ATSHORASAFASTADO?: any;
}

const onBeforeEdit = (data) => {
  data.ATSDIAAFASTDO = data.ATSDIAAFASTDO ? Number(data.ATSDIAAFASTDO) : null;
  data.ATSTIPOAFASTAMENTO = Number(data.ATSTIPOAFASTAMENTO);
  return data;
};

const onBeforeCreate = (data) => {
  data.ATSDIAAFASTDO = data.ATSDIAAFASTDO ? Number(data.ATSDIAAFASTDO) : null;
  data.ATSTIPOAFASTAMENTO =
    typeof data.ATSTIPOAFASTAMENTO === "string"
      ? Number(data.ATSTIPOAFASTAMENTO)
      : data.ATSTIPOAFASTAMENTO;
  data.DNLCODIGO = typeof data.DNLCODIGO === "string" ? null : data.DNLCODIGO;

  return data;
};

export const useAtestadosProvider = (
  urlMain: string,
  backPath = "",
  name: string,
  itemId = "0"
) =>
  useCrud<any>(urlMain, name, backPath, itemId, {
    access: { code: "05001001" },
    onBeforeGet: (data) => {
      if (data.ATSHORA && typeof data.ATSHORA === "string") {
        data.ATSHORA = getDateTime(data.ATSHORA);
      }
      if (data.ATSHORASAFASTADO && typeof data.ATSHORASAFASTADO === "string") {
        data.ATSHORASAFASTADO = getDateTime(data.ATSHORASAFASTADO);
      } else {
        data.ATSHORASAFASTADO = null;
      }
      return data;
    },
    onBeforeEdit,
    onBeforeCreate,
  });
