import { ExamDomain } from "../../views/employee/exames";
import { ExamTypeDomain } from "../../views/employee/tipoExame";
import { Action } from "../actions/scheduleActions";
import { ProviderDomain } from "./providerReducer";
import { v4 } from "uuid";
import { EmployeeDomain } from "../../views/employee/emp/EmployeeDomain";

export interface ScheduleEventDomain {
  Id: number;
  IdFrontEnd?: string;
  Date: Date | string | null;
  Time: string;
  CancelPreviousAttend?: number;
  CancelPreviousAttendDate?: string;
  DateAttended: Date | string | null;
  DateCanceled: Date | string | null;
  TimeStart: Date | string | null;
  TimeEnd: Date | string | null;
  ExamType: ExamTypeDomain;
  Exam: ExamDomain[];
  Employee: EmployeeDomain;
  Provider: ProviderDomain;
  Attachment: Attachment[];
  Code: string;
  Observation: string;
  //Solicitações referentes ao funcionário
  //Ex.: Criação de função
  Solicitation: Solicitation[];
  //View
  _Periodo: string;
  // Link: LinkPdf[];
  logoUrl: string;
  files: File[];
}

export interface Solicitation {
  SolicitationType: EnumSolicitationType;
  SolicitationTypeDesc?: string;
  Id?: string;
  IdTemp?: string;
  Title: string;
  Description?: string;
  Resolucao?: string;
  ResolucaoStatus?: string;
  Date?: Date | string | null;
  ResolutionDate?: Date | string | null;
  usuResolution?: string;
  Data?: any;
}

export enum EnumSolicitationType {
  Function_Add,
  Agenda = 1,
  Medicina = 2,
  Seguranca = 3,
  /**Se o tipo for Other, o título não é aplicado automaticamente */
  Other = 4,
}

export interface Response {
  Employee: string;
  Link: string;
}

export interface ResponseGuia {
  QrCode: string;
  ATECODIGO: string;
  idEmployee: number;
  error: string;
  schedule: ScheduleEventDomain;
  examesComplementaresContent: string;
  examesComplementaresContentAso: string;
}

export interface Attachment {
  Id: number; //ARQCODIGO
  Guid: string; //ARQGUID
  Description: string; //ARQDESCRICAO
  Name: string; //ARQNOME
  Attached_Web: number | null; //ARQWEB
  Url: string; //URL
  Signature: string; //ASSINA
}

export interface agendamentoRapido {
  status: boolean;
  cpf: string;
  nome: string;
  part: number;
}

export interface agendamentoReact {
  token: string;
  date: string;
  host: string;
  ambient: string;
  urlBase: string;
}

const scheduleReducer = (
  state: ScheduleEventDomain[] | null = [],
  action: Action
): ScheduleEventDomain[] | null => {
  switch (action.type) {
    case "ADD_SCHEDULE":
      return [...state, { ...action.payload, IdFrontEnd: v4() }];
    case "REMOVE_SCHEDULE":
      const newValue = state.filter(
        (item) => item.IdFrontEnd !== action.payload
      );
      return newValue;
    case "CLEAR_SCHEDULES":
      return [];
    default:
      return state;
  }
};

export default scheduleReducer;
