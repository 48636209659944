import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Crud } from "../../shared";
import { api } from "../../shared/services/api";
import { formatTipoExame, formatDate } from "../../utils/formats";

export default function ExamesClinicos() {
  const params = useParams<{ id: string }>();
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(
          `/funcionarios/funcionarios/${params.id}/exameComplementar`
        );
        setData(res.data);
        setIsLoading(false);
      } catch (err) {}
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  if (data.length > 0) {
    const fieldsContent = [
      {
        label: "Exame",
        content: "EXANOMECOMERCIAL",
      },
      {
        label: "Tipo Exame",
        content: "FEATIPOEXAME",
        formatFun: formatTipoExame,
      },
      {
        label: "Prestador",
        content: "PRONOME",
      },
      {
        label: "Data de cadastro",
        content: "FEADTENCAMINHADO",
        formatFun: formatDate,
      },
      {
        label: "Data de realização",
        content: "FEADTREALIZADO",
        formatFun: formatDate,
      },
    ];
    return <Crud<any> content={data} schema={fieldsContent} />;
  }
  return (
    <h3 style={{ textAlign: "center" }}>
      O funcionário não possui exames complementares
    </h3>
  );
}
