import { useState } from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import ExcelJS from "exceljs";
import { format } from "date-fns";

import { Form } from "../../shared";
import { eSocialFilterModel } from "../../redux/reducers/eSocialReducer";
import InputDate from "../../shared/Form/Fields/Date";
import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { InputDef } from "../../shared/Form/Fields";
import { toast } from "react-toastify";

export default function useExamesRealizados(props) {
  const [response, setResponse] = useState<any>(null);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);

  async function gerarExcel(data) {
    setLoadingExcel(true);

    const req = await api();
    const dataInicial = moment(data.DATAINICIAL).format("YYYY-MM-DD");
    const dataFinal = moment(data.DATAFINAL).format("YYYY-MM-DD");

    try {
      const response = await req.get(`/relatorio/exames/realizado`, {
        params: {
          DATAINICIAL: dataInicial,
          DATAFINAL: dataFinal,
          FUNNOME: data.NomeFuncionario,
        },
      });
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Relatórios Exames Realizados");

      const headers = [
        {
          header: "Funcionário",
          key: "FUNNOME",
          width: 40,
        },
        {
          header: "Setor",
          key: "SETNOME",
          width: 30,
        },
        {
          header: "Função",
          key: "FCONOME",
          width: 30,
        },
        {
          header: "Exame",
          key: "EXANOMECOMERCIAL",
          width: 30,
        },
        {
          header: "Tipo de Exame",
          key: "FEATIPOEXAME",
          width: 25,
        },
        {
          header: "Data da Realização",
          key: "FEADTREALIZADO",
          width: 20,
        },
        {
          header: "Periodicidade",
          key: "FEAPERIODICIDADE",
          width: 20,
        },
      ];
      ws.autoFilter = {
        from: {
          row: 6,
          column: 1,
        },
        to: {
          row: 6,
          column: headers.length,
        },
      };
      ws.getCell(1, 1).value = "RELATÓRIO DE EXAMES REALIZADOS";
      ws.mergeCells(1, 1, 1, headers.length);
      ws.getColumn(1).width = 65;
      ws.getRow(1).height = 50;
      ws.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      ws.getRow(1).font = { size: 18, bold: true };
      ws.getCell(1, 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE2EFDA" },
      };
      ws.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      ws.getRow(1).border = {
        top: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        bottom: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        left: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
        right: {
          style: "medium",
          color: { argb: "00FFFFFF" },
        },
      };
      ws.getCell(3, 1).value = `Período: ${moment(data.DATAINICIAL).format(
        "DD/MM/YYYY"
      )} à ${moment(data.DATAFINAL).format("DD/MM/YYYY")}`;
      ws.mergeCells(3, 1, 3, headers.length);
      ws.getCell(3, 1).font = { bold: true };
      ws.getCell(3, 1).alignment = { horizontal: "left" };

      ws.getCell(4, 1).value = `Empresa: ${localStorage.getItem("EMP_NAME")}`;
      ws.getCell(4, 1).font = { bold: true };
      ws.mergeCells(4, 1, 4, headers.length);
      let linha = 6;

      const rowsData = response.data.map((item) => ({
        ...item,
        FEADTREALIZADO: moment(item.FEADTREALIZADO).format("DD/MM/YYYY"),
        FEARESULTADOENTREGUE: data.FEARESULTADOENTREGUE ? "Apto" : "Inapto",
      }));

      headers.forEach((item, idx) => {
        ws.getCell(linha, idx + 1).value = item.header;
        ws.getCell(linha, idx + 1).font = { bold: true };
      });
      linha++;

      for (let item of rowsData) {
        // eslint-disable-next-line no-loop-func
        headers.forEach(({ key, width }, propIdx) => {
          ws.getColumn(propIdx + 1).width = width;
          ws.getCell(linha, propIdx + 1).value = item[key];
        });
        linha++;
      }

      const buff = await wb.xlsx.writeBuffer();
      const blob = new Blob([buff], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.download = "Relatórios Exames Realizados";
      link.click();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Não foi possível gerar a planilha"
      );
    } finally {
      setLoadingExcel(false);
    }
  }

  return (
    <Paper
      style={{
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        minWidth: "800px",
        marginLeft: "50%",
        transform: "translateX(-50%)",
        maxWidth: "1250px",
      }}
    >
      <Box style={{ padding: "12px 24px" }}>
        <Typography
          variant="h5"
          align="left"
          style={{ margin: "5px 0 20px" }}
          color="primary"
        >
          Relatório de Exames Realizados
        </Typography>
        <Form<eSocialFilterModel>
          onSubmit={async (data) => {
            const dataFinal = moment(data.DATAFINAL).format("YYYY-MM-DD");
            const dataInicial = moment(data.DATAINICIAL).format("YYYY-MM-DD");
            setLoadingButton(true);
            const req = await api();
            try {
              const responseData = await req.get(
                `/relatorio/exames/realizado?DATAINICIAL=${dataInicial}&DATAFINAL=${dataFinal}&FUNNOME=${data.NomeFuncionario}`
              );
              const validacao = responseData.data.reduce(
                (
                  obj,
                  {
                    FUNNOME,
                    FEADTREALIZADO,
                    EXANOMECOMERCIAL,
                    FEATIPOEXAME,
                    FUNCPF,
                    FCONOME,
                    SETNOME,
                    FEAPERIODICIDADE,
                  }
                ) => {
                  if (!obj[FUNNOME]) obj[FUNNOME] = [];
                  obj[FUNNOME].push({
                    FEADTREALIZADO,
                    FEATIPOEXAME,
                    EXANOMECOMERCIAL,
                    FUNCPF,
                    FCONOME,
                    SETNOME,
                    FEAPERIODICIDADE,
                  });
                  return obj;
                },
                {}
              );
              const outraVariante = Object.keys(validacao).map((FUNNOME) => {
                return {
                  FUNNOME,
                  EXAMES: validacao[FUNNOME],
                };
              });

              setResponse(outraVariante);
            } catch (error) {
              toast.error(error.response?.data?.message);
            } finally {
              setLoadingButton(false);
            }
          }}
          buttons={({ handleSubmit }) => (
            <div
              style={{
                width: "100%",
                margin: "24px 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                style={{
                  minWidth: "200px",
                  height: "42px",
                  marginRight: "15px",
                }}
                size="large"
                type="submit"
                loading={loadingButton}
              >
                Buscar
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                color="primary"
                style={{ minWidth: "200px", height: "42px" }}
                size="large"
                onClick={handleSubmit(gerarExcel)}
                loading={loadingExcel}
              >
                Gerar Planilha
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <Box style={{ paddingRight: 20 }}>
                      <InputDate
                        control={control}
                        label="Data inicial"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                    </Box>
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data final"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  name: "NomeFuncionario",
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  type: "custom",
                  customComponent: ({ control, name }) => (
                    <InputDef
                      name={name}
                      control={control}
                      style={{ width: "100%", marginTop: 20 }}
                      type="text"
                      variant="outlined"
                      label="Nome do Funcionário"
                      InputLabelProps={{ shrink: true }}
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </Box>
      <Box
        style={{ width: "100%", padding: "0px 24px 12px" }}
        className="marginPrint"
      >
        {response?.length > 0 &&
          response.map((element) => (
            <Box style={{ padding: "0 24px 12px" }}>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#c0c0c0",
                  margin: "10px -20px",
                }}
              />
              <div style={{ display: "flex" }}>
                <div>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Funcionáro: </span>
                    {element.FUNNOME}{" "}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Setor: </span>{" "}
                    {element.EXAMES[0].SETNOME}{" "}
                  </p>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>CPF: </span>{" "}
                    {element.EXAMES[0].FUNCPF}{" "}
                  </p>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Função: </span>{" "}
                    {element.EXAMES[0].FCONOME}{" "}
                  </p>
                </div>
              </div>
              <Box style={{ width: "100%", marginTop: "2%" }}>
                <table style={{ width: "100%" }}>
                  <tr style={{ borderBottom: "1px solid #c0c0c0" }}>
                    <td style={{}}>
                      <span style={{ fontWeight: "bold" }}>Exame</span>
                    </td>
                    <td>
                      <span style={{ width: "160px", fontWeight: "bold" }}>
                        Tipo de exame
                      </span>
                    </td>
                    <td>
                      <span style={{ width: "120px", fontWeight: "bold" }}>
                        Data de realização
                      </span>
                    </td>
                    <td>
                      <span style={{ width: "160px", fontWeight: "bold" }}>
                        Periodicidade (Meses)
                      </span>
                    </td>
                  </tr>
                  {element.EXAMES.map((item) => (
                    <tr style={{ borderBottom: "1px solid #c0c0c0" }}>
                      <td>{item.EXANOMECOMERCIAL}</td>
                      <td>{item.FEATIPOEXAME}</td>
                      <td>
                        {item.FEADTREALIZADO
                          ? moment(item.FEADTREALIZADO).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>{item.FEAPERIODICIDADE}</td>
                    </tr>
                  ))}
                </table>
              </Box>
            </Box>
          ))}
      </Box>
    </Paper>
  );
}
