import { useEffect, useState } from "react";
import { Divider, MenuItem, Modal, Backdrop, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { useLayout } from "../../hooks/auth";
import Form from "../Form";
import LoadingButton from "../Button/LoadingButton";
import { api } from "../services/api";
import { InputDef } from "../Form/Fields";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function TransitionsModalSol({
  openModal,
  closeModal,
}) {
  const classes = useStyles();
  const { colorPrimary } = useLayout();
  const [subtipos, setsubTipos] = useState([]);
  const [loading, setLoading] = useState(false)
  const [lengthSolicitacao, setLengthSolicitacao] = useState<number>(0);

  const maxLength = 8000;

  
  const getsubTipos = async () => {
    try {
      const req = await api();
      const { data } = await req.get(`/solicitacoes/subtipos`);
      setsubTipos(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getsubTipos();
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h2
              style={{
                width: "600px",
                color: colorPrimary,
                marginBottom: "15px",
              }}
            >
              Nova Solicitação
            </h2>
            <Divider
              color="primary"
              style={{
                width: "111%",
                marginLeft: "-34px",
                marginBottom: "35px",
              }}
            />
            <Form
              spacing={"10px 10px 10px 10px"}
              onSubmit={async (data) => {
                try {
                    setLoading(true)
                    const req = await api();
                    const subtipo = data.SOLSUBTIPO.length > 0 ? data.SOLSUBTIPO : null
                    await req.post("solicitacoes", {
                      ...data,
                      SOLSUBTIPO: subtipo
                    });
                    toast.success("Solicitação efetuada com sucesso");
                    setLoading(false)
                    } catch {
                    setLoading(false)
                  toast.error("Falha ao realizar solicitação");
                } finally {
                  closeModal();
                }
              }}
              buttons={() => (
                <div
                  style={{
                    maxWidth: 1115,
                    width: "101%",
                    margin: "24px auto",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    loading={loading}
                    color="primary"
                    style={{ width: "75%", marginTop: 10 }}
                    size="large"
                    type="submit"
                  >
                    Enviar solicitação
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    style={{ width: "17%", marginTop: 10, marginLeft: "4.3%" }}
                    size="large"
                    onClick={closeModal}
                  >
                    Cancelar
                  </LoadingButton>
                </div>
              )}
              schema={[
                {
                  content: [
                    {
                      lg: 12,
                      name: "SOLTIPO",
                      type: "select",
                      label: "Tipo de Solicitação",
                      options: [
                        "Agenda",
                        "Medicina",
                        "Segurança",
                        "Diversos",
                      ].map((item, idx) => (
                        <MenuItem value={idx + 1}>{item}</MenuItem>
                      )),
                      rules: { required: "Selecione um um tipo" },
                    },
                    {
                      lg: 12,
                      name: "SOLSUBTIPO",
                      type: "select",
                      customDefValue: null,
                      label: "Subtipo de Solicitação",
                      options: subtipos?.map((item, idx) => (
                        <MenuItem value={item.SOLSUBTIPO}>
                          {item.SOLSUBTIPODESC}
                        </MenuItem>
                      )),
                    },
                    {
                      lg: 12,
                      name: "SOLTITULO",
                      type: "custom",
                      label: "Título",
                      rules: { required: "Insira um título" },
                    },
                    {
                      lg: 12,
                      name: "SOLSOLICITACAO",
                      label: "Descrição",
                      inputPropsAdittional: { multiline: true, rows: 6 },
                      rules: { required: "Insira uma descrição" },
                      customComponent: ({ name, error, register }) => {
                        return (
                          <>
                            <InputDef
                              name={name}
                              id={name}
                              style={{ width: "100%" }}
                              errors={error}
                              label="Descrição"
                              inputRef={register({
                                required: "Insira uma descrição",
                              })}
                              multiline={true}
                              rows="6"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => {
                                if (e.target.value.length > maxLength) {
                                  e.target.value = e.target.value.slice(
                                    0,
                                    maxLength
                                  );
                                }
                                setLengthSolicitacao(e.target.value.length);
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px auto",
                                textAlign: "end",
                              }}
                            >
                              <span>
                                {lengthSolicitacao} / {maxLength} caracteres
                              </span>
                            </div>
                          </>
                        );
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
