import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { ExamDomain } from ".";
import CloseIcon from "@material-ui/icons/Close";

export default function DialogExam({ open, setOpen, examsAdd, addExam }: any) {
  const [selected, setSelected] = React.useState<ExamDomain[] | null>([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ExamDomain
  ) => {
    if (event.target.checked) {
      setSelected([...selected, item]);
      addExam(item);
    } else {
      var newSelected = selected.filter((a) => a.EXACODIGO !== item.EXACODIGO);
      setSelected(newSelected);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Mais Exames</Box>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: 40 }}>
        <FormGroup>
          {examsAdd ? (
            examsAdd.length > 0 ? (
              examsAdd.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!(
                          selected &&
                          selected.find((a) => a.EXACODIGO === item.EXACODIGO)
                        )
                      }
                      onChange={(e) => {
                        handleChange(e, item);
                      }}
                      name="gilad"
                      color="primary"
                    />
                  }
                  label={item.EXANOMECOMERCIAL}
                />
              ))
            ) : (
              <div>Não existem exames a serem inseridos</div>
            )
          ) : (
            <div>Carregando...</div>
          )}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}
