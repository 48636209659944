import ExcelJS from "exceljs";
import { toast } from "react-toastify";
import { api, instanceNew } from "../../shared/services/api";
import moment from "moment";

export async function generateExcel() {

      const req = await api()
      const response = await req.get("/funcionarios/funcionarios/status");
      
      if (!response.data?.length) {
        toast.error("Não existem funcionários nesta empresa");
        return;
      }
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Funcionários Status");
      const headers = [
        {
          header: "CPF",
          key: "FUNCPF",
          width: 25,
        },
        {
          header: "Nome",
          key: "FUNNOME",
          width: 40,
        },
        {
          header: "Posto de Trabalho",
          key: "POSNOME",
          width: 30,
        },
        {
          header: "Setor",
          key: "SETNOME",
          width: 30,
        },
        {
          header: "GHE",
          key: "GHENOME",
          width: 30,
        },
        {
          header: "Função",
          key: "FCONOME",
          width: 30,
        },
        {
          header: "Riscos Trabalhistas",
          key: "RISCOS_TRABALHISTAS",
          width: 50
        },
        {
          header: "Riscos Previdenciários(ESocial)",
          key: "RISCO",
          width: 30,
        },
        {
          header: "Conclusão da Nocividade",
          key: "CONCLUSAO",
          width: 30,
        },
        {
          header: "Conclusão da Insalubridade",
          key: "CONCLUSAOINSALUB",
          width: 30,
        },
        {
          header: "Conclusão da Periculosidade",
          key: "CONCLUSAOPERIC",
          width: 30,
        }
      ];

      ws.autoFilter = {
        from: {
          row: 6,
          column: 1,
        },
        to: {
          row: 6,
          column: headers.length,
        },
      };
      ws.getCell(1, 1).value = "STATUS FUNCIONÁRIOS";
      ws.mergeCells(1, 1, 1, headers.length);
      ws.getColumn(1).width = 65;
      ws.getRow(1).height = 50;
      ws.getRow(1).alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      ws.getRow(1).font = { size: 18, bold: true };
      ws.getCell(1, 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE2EFDA" },
      };

      ws.getCell(
        3,
        1
      ).value = `EMPRESA: ${response.data[0].EMPCODIGO} - ${response.data[0].EMPRAZAOSOCIAL}`;
      ws.getCell(3, 1).font = { bold: true };
      ws.mergeCells(3, 1, 3, headers.length);

      ws.getCell(4, 1).value = `Data: ${moment(Date.now()).format(
        "DD/MM/YYYY"
      )}`;
      ws.getCell(4, 1).font = { bold: true };
      ws.mergeCells(4, 1, 4, headers.length);

      let linha = 6;

      const rowsData = response.data.map((item) => ({
        ...item,
      }));

      headers.forEach((item, idx) => {
        ws.getCell(linha, idx + 1).value = item.header;
        ws.getCell(linha, idx + 1).font = { bold: true };
      });
      linha++;

      for (const item of rowsData) {
        // eslint-disable-next-line no-loop-func
        headers.forEach(({ key, width }, propIdx) => {
          ws.getColumn(propIdx + 1).width = width;
          ws.getCell(linha, propIdx + 1).value = item[key];
        });
        linha++;
      }

      const buff = await wb.xlsx.writeBuffer();
      const blob = new Blob([buff], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.download = "Relátório de Status dos Funcionários";
      link.click();
}