import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { validateBr } from "js-brasil";
import { useState, useEffect, useRef, useCallback } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import SearchIcon from "@material-ui/icons/Search";
import { Controller } from "react-hook-form";

import { Form, Search } from "../../../shared";

import InputDate, { InputDateNasc } from "../../../shared/Form/Fields/Date";
import { useFilesExames, useSetepper } from "../Stepper";
import { useSchedule } from "..";
import { api } from "../../../shared/services/api";
import { useSelect } from "./useSelect";
import { Masked } from "../../../shared/Form/Fields/Masked";
import { RootModel } from "../../../redux/reducers";
import { PortalModel } from "../../../redux/reducers/portalReducer";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import DialogAdcFuncao from "./adicionarFuncao";
import { Solicitation } from "../../../redux/reducers/scheduleReducer";
import { Alert, Autocomplete } from "@material-ui/lab";
import { EmployeeDomain, ErrorProp } from "./EmployeeDomain";
import DialogErrorAgendamento from "./dialogError";
import moment from "moment";
import { removeFunc } from "../../../redux/reducers/funcReducer";
import DropFile from "../../../shared/Files/DropFile";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogFunCPF from "./dialogCPF";
import { InputDef } from "../../../shared/Form/Fields";
import LoadingButton from "../../../shared/Button/LoadingButton";
import { useStatePersistent } from "../../../contexts/statesPersistent";
import { toast } from "react-toastify";

function Container({ children }) {
  return <Paper style={{ padding: "20px", marginTop: 20 }}>{children}</Paper>;
}

const ListFiles = (props) => {
  const { files, removeFile } = props;

  return (
    <List>
      <Typography variant="subtitle2">Anexos: </Typography>
      <br />
      {files.map((file, idx) => {
        return (
          <ListItem key={idx}>
            <ListItemText primary={file.file.name} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => removeFile(file.id)}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

interface ModalRaizFuncionarioProps {
  open: string;
  onClose: any;
}

function ModalRaizFuncionario({ open, onClose }: ModalRaizFuncionarioProps) {
  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="block" alignItems="center">
          <Box>
            <ErrorOutlineIcon
              style={{
                width: 100,
                height: 100,
                color: "#edf054",
                marginLeft: "50%",
                transform: "translate(-50%)",
              }}
            />
          </Box>
          <Box
            style={{
              marginLeft: "55%",
              transform: "translate(-55%)",
              fontSize: 16,
            }}
            flexGrow={1}
          >
            <h4>{open}</h4>
          </Box>
          <Box>
            <Button
              style={{
                marginLeft: "50%",
                transform: "translate(-50%)",
                marginTop: "35px",
                width: "75%",
                color: "#fff",
                background: "#edf054",
              }}
              onClick={onClose}
              variant="contained"
              disableElevation
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </DialogTitle>
    </Dialog>
  );
}

export default function EmployeeData() {
  const genders = [
    { value: "1", label: "Masculino" },
    { value: "2", label: "Feminino" },
  ];
  const { previousSchedule, cpfFun } = useStatePersistent();
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [openFunCPF, setOpenFunCPF] = useState<boolean>(false);
  const [openFunadd, setOpenFunadd] = useState<boolean>(false);
  const [content, setContent] = useState<EmployeeDomain[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledCPF, setDisabledCPF] = useState<boolean>(false);
  const { setSchedule, schedule } = useSchedule();
  const [dataFunc, setDataFunc] = useState<EmployeeDomain | null>(null);
  const [openModalRaiz, setOpenModalRaiz] = useState<string>("");
  const setValueRef = useRef<any>();
  const setReset = useRef<any>();
  const dataRef = useRef<any>();
  const [semDepartamentoOuFuncao, setSemDepartamentoOuFuncao] =
    useState<boolean>(true);
  const { addFiles, removeFile, files } = useFilesExames();
  const { setActiveStep } = useSetepper();
  const [solicitations, setSolicitations] = useState<Solicitation[]>([]);
  const [funCodigo, setFunCodigo] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState<ErrorProp>({
    TIPO: "0",
    MENSAGEM: "",
  });
  const [, setErrorRaiz] = useState<boolean>(false);
  const cancelAttendRef = useRef<boolean>(false);
  const cancelAttendDateRef = useRef<string>(null);
  const [, setFuncaoSelected] = useState<any>()
  const [isAutoComplete, setIsAutoComplete] = useState<any>(null)
  const [foneObrigatorio, setFoneObrigatorio] = useState<boolean>(true);
  const [errorAnalise, setErrorAnalise] = useState<string>('');
  const [loadingErrorAnalise, setLoadingErrorAnalise] = useState<boolean>(false);

  const setCancelAttend = (value: boolean) => (cancelAttendRef.current = value);

  const handleClose = () => {
    setContent(null);
    setOpen(false);
  };

  const portal = useSelector<RootModel, PortalModel>((state) => state.portal);
  const { date, provider, funcionario } = useSelector<RootModel, RootModel>(
    (state) => state
  );
  const onDrop = (acceptedFiles: File[]) => addFiles(acceptedFiles);
  const { options, changeFun, funcao, func, categeSocial, setores } =
    useSelect(false, solicitations, () => setOpenFunadd(true));

  useEffect(() => {
    if (error?.MENSAGEM) {
      if (error?.TIPO === "2") {
        cancelAttendRef.current = true;
        cancelAttendDateRef.current = error?.MENSAGEM.match(
          /(\d{1,2})\/(\d{1,2})\/(\d{4})/
        )[0];
      }
      setOpenError(true);
    }
  }, [error]);

  useEffect(() => {
    if (portal.agendamentoRapido && setValueRef && setValueRef.current) {
      setValueRef.current("Document_CPF", portal.agendamentoRapido, {
        shouldValidate: true,
      });

      document.getElementById("Document_CPF")?.focus();
      document.getElementById("Document_CPF")?.blur();
    }
  }, [setValueRef, portal]);

  const getEmployee = async (data) => {
    setLoading(true);
    const request = await api();
    try {
      const response = await request.get<EmployeeDomain[]>(
        `/agenda/funcionarios?DATAAGENDA=${moment(date).format(
          "YYYY-MM-DD"
        )}&FUNNOME=${data.employeename ? data.employeename : ""}`
      );
      setLoading(false);
      setContent(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (funcionario.cpf && setValueRef.current) {
      searchEmp(funcionario.cpf, setValueRef.current, true);
    }
    return () => {
      dispatch(removeFunc(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchEmp = async (value: string, setValue: any, setCpf = false) => {
    if (disabledCPF && dataRef.current?.FUNCODIGO) {
      setFunCodigo(dataRef.current.FUNCODIGO);
      dataRef.current = null;
      return;
    }
    if (value?.toString().length === 11) {
      setFunCodigo(null);
      const request = await api();
      try {
        const response = await request.get<EmployeeDomain[]>(
          `/agenda/funcionarios`,
          {
            params: {
              FUNCPF: value,
              DATAAGENDA: moment(date).format("YYYY-MM-DD"),
              TIPOEXAME: !setCpf ? schedule.ExamType.CODIGO : null,
            },
          }
        );

        if (response.data && response.data[0]?.RAIZ) {
          setErrorRaiz(true);
          setOpenModalRaiz(response.data[0].RAIZ);
        }

        setFunCodigo(response.data[0]?.FUNCODIGO || null);
        if (response.data && response.data[0]) {
          preencherCampos(response.data[0], setValue, setCpf);
          setDataFunc(response.data[0]);
        } else {
          setError({
            TIPO: "0",
            MENSAGEM: "",
          });
        }
      } catch (err) {
        setError({
          TIPO: "0",
          MENSAGEM: "",
        });
      }
    }
  };

  const validaAnalise = async (setcodigo: number) => {
    setLoadingErrorAnalise(true);
    try {
      const req = await api();
      await req.get(`/agenda/setores/${setcodigo}/valida-analises`);
      setErrorAnalise('');
    } catch (error) {
      setErrorAnalise(error.response?.data?.message ||
        "Ocorreu um erro inesperado. Entre em contato com o Suporte")
    } finally {
      setLoadingErrorAnalise(false);
    }
  }

  const preencherCampos = useCallback(async (
    data: EmployeeDomain,
    setValue: any,
    cpf: boolean = false
  ) => {
    setDisabledCPF(true);
    setDataFunc(data);

    if (data?.ERRO) {
      setError(data?.ERRO);
    }
    if (cpf) {
      setValue("FUNCPF", data.FUNCPF, {
        shouldValidate: true,
      });
    }

    setValue("FUNNOME", data.FUNNOME, {
      shouldValidate: true,
    });

    setValue("FUNNASCIMENTO", data.FUNNASCIMENTO, {
      shouldValidate: true,
    });

    setValue("FUNADMISSAO", data.FUNADMISSAO, {
      shouldValidate: true,
    });

    setValue("FUNDTINICIAL", data.FUNDTINICIAL, {
      shouldValidate: true,
    });

    setValue("FUNDEMISSAO", data.FUNDEMISSAO, {
      shouldValidate: true,
    });

    setValue("FUNDDD1", data.FUNDDD1, {
      shouldValidate: true,
    });

    setValue("FUNFONE1", data.FUNFONE1, {
      shouldValidate: true,
    });

    setValue("FUNRG", data.FUNRG, {
      shouldValidate: true,
    });

    setValue("FUNMATRICULA", data.FUNMATRICULA, {
      shouldValidate: true,
    });

    setValue("FUNSEXO", data.FUNSEXO, {
      shouldValidate: true,
    });

    if (data.ESOCCATEGCODIGO) {
      setValue("ESOCCATEGCODIGO", data.ESOCCATEGCODIGO, {
        shouldValidate: true,
      });
    }

    if (data.FCONOME) {
      setValue("FCONOME", data.FCONOME, {
        shouldValidate: true,
      });
    }

    if (data.SETNOME) {
      setValue("SETNOME", data.SETNOME, {
        shouldValidate: true,
      });
    }

    if (data.SETCODIGO) {
      setValue("SETCODIGO", data.SETCODIGO, {
        shouldValidate: true,
      })

      validaAnalise(data.SETCODIGO);
    }

    if (!data.RAIZ && schedule.ExamType.CODIGO !== "4") {
      if (data.SETCODIGO && data.FCOCODIGO && data.ANAATIVA) {
        setSemDepartamentoOuFuncao(false);
        setValue("SETCODIGO", options.find(item => item.value == data.SETCODIGO));

        setValue("FuncaoId", "");
        setValue(
          "SETNOME",
          data?.SETNOME
        );
        await changeFun(data.SETCODIGO as string);

        await changeFun(data.SETCODIGO.toString(), () => {
          setIsAutoComplete(data.FCOCODIGO)
        });
      } else {
        setSemDepartamentoOuFuncao(true);
      }
    }
  }, [schedule.ExamType.CODIGO, options, changeFun]);

  const setByCPF = async () => {
    try {
      let data = null
      if (cpfFun) {
        const request = await api()
        const response = await request.get<EmployeeDomain[]>(
          `/agenda/funcionarios`,
          {
            params: {
              FUNCPF: cpfFun,
              TIPOEXAME: schedule.ExamType.CODIGO,
              DATAAGENDA: moment(date).format("YYYY-MM-DD"),
            },
          }
        );
        data = response.data[0]
      }

      setTimeout(() => preencherCampos(previousSchedule?.FUNCIONARIO || data, setValueRef.current, true), 1000)
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
    }
  }

  useEffect(() => {
    if ((previousSchedule || cpfFun) && (setores && setores?.length > 0)) {
      setByCPF()
    }
  }, [previousSchedule, setores, options, cpfFun])

  const isValidCnpjOrCpf = (v: any) => {
    if (!v) return "Campo Obrigatório";
    var newitem = v.toString();
    if (!validateBr.cpf(newitem)) return "CPF inválido";
    return undefined;
  };

  const isPhoneMandatory = (v: any) => {
    if (!v && foneObrigatorio) return "Campo Obrigatório";
    return undefined;
  };

  const closeModalRaiz = (_event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpenModalRaiz("");
    }
  };

  const finalizarAgendamento = (data: EmployeeDomain) => {
    let dataFormated = { ...data }
    if (data.FCONOME.includes('[Aguardando aprovação]')) {
      dataFormated.FCOCODIGO = 0
    } else {
      dataFormated.FCOCODIGO = dataFormated.FCOCODIGO.value
    }
    dataFormated.SETCODIGO = dataFormated.SETCODIGO.value

    dataFormated.FUNNASCIMENTO = dataFormated.FUNNASCIMENTO
      ? moment(new Date(dataFormated.FUNNASCIMENTO)).format("YYYY-MM-DD")
      : null;

    dataFormated.NOTIFICAFUNCIONARIO = Number(dataFormated.NOTIFICAFUNCIONARIO);
    dataFormated.FUNCODIGO = funCodigo;
    dataFormated.EMPCODIGO = provider.EMPCODIGO;
    dataFormated.SOLICITACAO = solicitations;

    if (Number(dataFormated.FCOCODIGO) > 0) {
      let funItem: any = func.find(
        (item) => item.FCOCODIGO.toString() === dataFormated.FCOCODIGO
      );
      if (funItem) {
        dataFormated.FCOCODIGO = funItem.IdTemp;
      }
    }

    setSchedule((v) => {
      return ({
        ...v,
        Employee: { ...dataFunc, ...dataFormated },
        Observation: dataFormated.OBSERVACOES,
        Solicitation: solicitations,
        CancelPreviousAttend: cancelAttendRef.current ? error.ATECODIGO : null,
        CancelPreviousAttendDate: cancelAttendRef.current
          ? cancelAttendDateRef.current
          : null,
      })
    });
    setActiveStep(3);
  };

  useEffect(() => {
    if (isAutoComplete && funcao) {
      setValueRef.current("FCOCODIGO", funcao.find(item => item.value == isAutoComplete));
      setIsAutoComplete(null)
    }
  }, [funcao, isAutoComplete])

  return (
    <>
      <DialogFunCPF
        open={openFunCPF}
        setOpen={setOpenFunCPF}
        setValueRef={setValueRef}
        preencherCampos={preencherCampos}
        data={dataRef.current}
      />
      <DialogErrorAgendamento
        open={openError}
        setOpen={setOpenError}
        error={error}
        setCancelAttend={setCancelAttend}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll="paper"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Dados Funcionário</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 40 }}>
          <Search<EmployeeDomain>
            search={getEmployee}
            loading={loading}
            content={content}
            crudProps={{
              onEditClick: (data) => {
                localStorage.setItem('FCOCODIGO', data.FCOCODIGO)
                setOpen(false);
                if (data.FUNCPF) {
                  preencherCampos(data, setValueRef.current, true);
                  return;
                }
                dataRef.current = data;
                setOpenFunCPF(true);
              },
              edit: true,
            }}
            fieldsContent={[
              { label: "Nome do Funcionário", content: "FUNNOME" },
              { label: "CPF", content: "FUNCPF" },
              { label: "RG", content: "FUNRG" },
            ]}
            fieldsFilter={[
              {
                content: [
                  {
                    lg: 12,
                    name: "employeename",
                    label: "Nome",
                    variant: "standard",
                  },
                  {
                    lg: 12,
                    name: "employeedocument",
                    label: "CPF",
                    variant: "standard",
                  },
                  {
                    lg: 12,
                    name: "documentRgEmployee",
                    label: "RG",
                    variant: "standard",
                  },
                ],
              },
            ]}
            fieldsSearchMain={[
              {
                content: [
                  {
                    lg: 12,
                    name: "employeename",
                    label: "Pesquisar por Nome",
                    variant: "standard",
                  },
                ],
              },
            ]}
          />
        </DialogContent>
      </Dialog>
      <DialogAdcFuncao
        open={openFunadd}
        setOpen={setOpenFunadd}
        setValueRef={setValueRef}
        setSolicitations={setSolicitations}
      />
      <ModalRaizFuncionario open={openModalRaiz} onClose={closeModalRaiz} />
      <Form<EmployeeDomain | any>
        onSubmit={finalizarAgendamento}
        spacing={15}
        buttons={() => (
          <div style={{ marginTop: 25 }}>
            {error?.MENSAGEM && (
              <Alert
                style={{ marginBottom: 25 }}
                severity={error.TIPO === "1" ? "error" : "info"}
              >
                {error.MENSAGEM}
              </Alert>
            )}

            {errorAnalise && (
              <Alert
                style={{ marginBottom: 25 }}
                severity={"error"}
              >
                {errorAnalise}
              </Alert>
            )}

            <LoadingButton
              loading={loadingErrorAnalise}
              type="submit"
              style={{
                width: "100%",
                background:
                  error?.TIPO === "1" || (errorAnalise !== '')
                    ? "#949494"
                    : "",
              }}
              variant="contained"
              size="large"
              disabled={
                ((error?.TIPO === "1") || (errorAnalise !== ''))
              }
              color="primary"
            >
              Confirmar Funcionário
            </LoadingButton>
          </div>
        )}
        schema={[
          {
            title: "Dados do funcionário",
            container: Container,
            content: [
              {
                lg: 3,
                name: "FUNCPF",
                label: "CPF",
                type: "custom",
                customComponent: ({ control, error, name, setValue }) => (
                  <Masked
                    onChangeItem={(value) => searchEmp(value, setValue)}
                    rules={{ validate: { isValidCnpjOrCpf: isValidCnpjOrCpf } }}
                    format="###.###.###-##"
                    mask="_"
                    control={control}
                    name={name}
                    error={error}
                    label="CPF"
                    disabled={disabledCPF}
                  />
                ),
              },
              {
                lg: 8,
                name: "FUNNOME",
                label: "Nome do Funcionário",
                rules: { required: "Campo Obrigatório" },
                onBlur: (data, setValue) => {
                  let palavras = data.target.value.split(' ');

                  // Capitalize a primeira letra de cada palavra
                  for (let i = 0; i < palavras.length; i++) {
                    if (i === 0 || !["de", "do", "da", "dos", "das"].includes(palavras[i].toLowerCase())) {
                      palavras[i] = palavras[i].charAt(0).toUpperCase() + palavras[i].slice(1).toLowerCase();
                    } else {
                      palavras[i] = palavras[i].toLowerCase();
                    }
                  }

                  // Junte as palavras de volta em uma string
                  let novaString = palavras.join(' ');
                  setValue("FUNNOME", novaString)
                  return novaString;
                }
              },
              {
                lg: 1,
                type: "custom",
                customComponent: ({ setValue, reset }) => {
                  if (!setReset.current) setReset.current = reset;
                  if (!setValueRef.current) setValueRef.current = setValue;
                  return (
                    <IconButton color="primary" onClick={() => setOpen(true)}>
                      <SearchIcon />
                    </IconButton>
                  );
                },
              },
              {
                lg: 3,
                name: "FUNRG",
                label: "RG",
              },
              {
                lg: 3,
                name: "FUNNIT",
                label: "NIS",
              },
              {
                lg: 3,
                name: "FUNMATRICULA",
                label: "Matrícula",
                customComponent: ({ control, name }) => {
                  return (
                    <Tooltip
                      title="Matrícula que é utilizada no ESocial"
                      placement="top"
                      arrow
                    >
                      <InputDef
                        name={name}
                        control={control}
                        style={{ width: "100%" }}
                        type="text"
                        variant="outlined"
                        label="Matrícula"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Tooltip>
                  );
                },
              },
              {
                lg: 3,
                name: "FUNSEXO",
                label: "Gênero",
                type: "select",
                options: genders.map((g) => (
                  <MenuItem value={g.value}>{g.label}</MenuItem>
                )),
                rules: { required: "Campo Obrigatório" },
              },
              {
                lg: 4,
                name: "FUNNASCIMENTO",
                type: "custom",
                customDefValue: new Date(),
                customComponent: ({ control, name, error }) => (
                  <InputDateNasc
                    error={error}
                    control={control}
                    label="Data de Nascimento"
                    name={name}
                    rules={{ required: "Campo Obrigatório" }}
                  />
                ),
              },
              {
                lg: 4,
                name: "FUNADMISSAO",
                type: "custom",
                customDefValue: new Date(),
                customComponent: ({ control, name, error }) => (
                  <InputDate
                    error={error}
                    control={control}
                    label="Data de Admissão"
                    name={name}
                    rules={{ required: "Campo Obrigatório" }}
                  />
                ),
              },
              {
                lg: 4,
                name: "FUNDEMISSAO",
                type: "custom",
                customComponent: ({ control, name, error }) => (
                  <InputDate
                    error={error}
                    control={control}
                    label="Data de Demissão"
                    name={name}
                    defaultValue={null}
                    rules={{}}
                  />
                ),
              },
              {
                lg: 2,
                name: "FUNDDD1",
                label: "DDD",
                type: "custom",
                customComponent: ({ control, error, name, setValue }) => (
                  <Masked
                    mask=" "
                    rules={{ validate: isPhoneMandatory }}
                    format="(##)"
                    control={control}
                    name={name}
                    error={error}
                    label="DDD"
                  />
                ),
              },
              {
                lg: 4,
                name: "FUNFONE1",
                label: "Número",
                type: "custom",
                customComponent: ({ control, error, name, setValue }) => (
                  <Masked
                    mask=" "
                    rules={{ validate: isPhoneMandatory }}
                    format="# ####-####"
                    control={control}
                    name={name}
                    error={error}
                    label="Número"
                  />
                ),
              },
              {
                lg: 6,
                type: "checkbox",
                name: "NOTIFICAFUNCIONARIO",
                customDefValue: schedule.ExamType.CODIGO !== "3",
                label:
                  "Enviar informações do agendamento para o WhatsApp do trabalhador",
                onChange: (event) => {
                  setFoneObrigatorio(event);
                },
              },
              {
                lg: 12,
                name: "ESOCCATEGCODIGO",
                label: "Categoria",
                type: "select",
                options: categeSocial,
              },
              {
                lg: 12,
                name: "SETCODIGO",
                customComponent: ({ error, name, control, setValue }) => (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={error}
                  >
                    <Controller
                      render={({ onChange, value, ref }) => (
                        <Autocomplete
                          autoComplete
                          key={value?.value}
                          value={value?.value}
                          ref={ref}
                          disabled={
                            schedule.ExamType.CODIGO.toString() === "1"
                              ? false
                              : schedule.ExamType.CODIGO.toString() === "4"
                                ? false
                                : semDepartamentoOuFuncao
                                  ? false
                                  : true
                          }
                          loading={loading}
                          options={options || []}
                          defaultValue={""}
                          getOptionLabel={(option) => {
                            return value?.label || option?.label || ""
                          }}
                          renderOption={(option: any) => (
                            <span>{option?.label}</span>
                          )}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                // id={`${name}-id`}
                                label={"Setor"}
                                variant="outlined"
                                error={!!error}
                                autoComplete="new-password"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                                inputRef={ref}
                                style={{ borderRadius: 20 }}
                                helperText={error && <span>{error.message}</span>}
                              />
                            );
                          }}
                          onChange={(_event: any, value) => {
                            setValue("FuncaoId", "");
                            setValue(
                              "SETNOME",
                              value?.setnome
                            );
                            setValue(
                              "FCOCODIGO",
                              ""
                            );
                            changeFun(value?.value as string);
                            onChange(value);

                            validaAnalise(value?.value);
                          }}
                        />
                      )}
                      name={name}
                      control={control}
                      rules={{ required: "Campo Obrigatório" }}
                    />
                    {error && error?.message && (
                      <FormHelperText>{error?.message}</FormHelperText>
                    )}
                  </FormControl>
                ),
              },
              {
                lg: 12,
                name: "FCOCODIGO",
                customComponent: ({ error, name, control, setValue }) => {
                  return (
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      error={error}
                    >
                      <Controller
                        render={({ onChange, value, ref }) => (
                          <Autocomplete
                            autoComplete
                            key={value?.value}
                            value={value?.value}
                            ref={ref}
                            disabled={
                              !funcao
                                ? true
                                : schedule.ExamType.CODIGO.toString() === "1"
                                  ? false
                                  : schedule.ExamType.CODIGO.toString() === "4"
                                    ? false
                                    : semDepartamentoOuFuncao
                                      ? false
                                      : true
                            }
                            loading={loading}
                            options={funcao || []}
                            defaultValue={""}
                            getOptionLabel={(option) => {
                              return value?.label || option?.label || ""
                            }}
                            renderOption={(option: any) => (
                              <span>{option?.label}</span>
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={ref}
                                  error={!!error}
                                  // id={`${name}-id`}
                                  variant="outlined"
                                  style={{ borderRadius: 20 }}
                                  helperText={error && <span>{error.message}</span>}
                                  label={!funcao ? "Primeiramente, selecione o setor" : "GHE/Função"}
                                />
                              );
                            }}
                            onChange={(_, value) => {
                              setFuncaoSelected(value)
                              setValue("FCONOME", value?.fconome);
                              onChange(value);
                            }}
                          />
                        )}
                        name={name}
                        control={control}
                        rules={{ required: "Campo Obrigatório" }}
                      />
                      {error && error?.message && (
                        <FormHelperText>{error?.message}</FormHelperText>
                      )}
                    </FormControl>
                  )
                }
              },
              {
                lg: 3,
                type: "custom",
                customComponent: () => (
                  <Button
                    disabled={!funcao}
                    onClick={() => setOpenFunadd(!openFunadd)}
                    variant="outlined"
                  >
                    + Cadastrar nova função
                  </Button>
                )
              },
              {
                lg: 3,
                name: "FUNCODIGO",
                type: "custom",
                customComponent: () => <> </>,
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  return (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ marginBottom: "10px" }}
                      >
                        Anexe os documentos relevantes para o atendimento
                      </Typography>
                      <DropFile onDrop={onDrop} />
                    </Box>
                  );
                },
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  if (!files.length) return <></>;
                  return <ListFiles files={files} removeFile={removeFile} />;
                },
              },
              {
                lg: 12,
                name: "OBSERVACOES",
                label: "Observações",
                inputPropsAdittional: { multiline: true, rows: 6 },
              },
              {
                lg: false,
                name: "FUNDTINICIAL",
                type: "custom",
                customComponent: ({ control, name, error }) => (
                  <InputDate
                    inputPropsAdditional={{ style: { display: "none" } }}
                    error={error}
                    control={control}
                    label="Data de Ínicio"
                    name={name}
                    disabled={true}
                  />
                ),
              },
              {
                lg: false,
                name: "SETNOME",
                customComponent: ({ name, register }) => (
                  <input type="hidden" ref={register} name={name} />
                ),
              },
              {
                lg: false,
                name: "FCONOME",
                customComponent: ({ name, register }) => (
                  <input type="hidden" ref={register} name={name} />
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
}
