import { Typography, Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Crud } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { formatDate } from "../../utils/formats";

export default function HistoricoDeAcoes() {
  const history = useHistory();
  const params = useParams<{ plCodigo: string; anaCodigo: string }>();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(
        `/analiserisco/${params.anaCodigo}/setores/0/ghe/0/planosacao/${params.plCodigo}/historico`
      );
      setData(res.data);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
      <Box style={{ margin: 0, padding: 0 }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            align="left"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "15pt",
            }}
            color="primary"
          >
            Histórico de ações
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            type="button"
            style={{ marginBottom: "10px", width: "150px" }}
            onClick={() =>
              history.push({
                pathname: `/pages/planosDeAcao/${params.anaCodigo}/${params.plCodigo}/adicionarHistorico`,
                state: params,
              })
            }
          >
            Novo
          </LoadingButton>
        </Box>
      </Box>
      <Crud
        content={data}
        edit
        onEditClick={(data) =>
          history.push({
            pathname: `/pages/planosDeAcao/${params.plCodigo}/editarHistorico/${data.HISCODIGO}`,
            state: params,
          })
        }
        schema={[
          {
            content: "HISRESPONSAVEL",
            label: "Responsável",
          },
          {
            content: "HISDTINICIAL",
            label: "Data inicial",
            formatFun: formatDate,
          },
          {
            content: "HISDTFINAL",
            label: "Data final",
            formatFun: formatDate,
          },
        ]}
      />
    </Box>
  );
}
