import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Button from "@material-ui/core/Button";
import { ErrorProp } from "./EmployeeDomain";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { ChangeEvent } from "react";

export default function DialogErrorAgendamento({
  open,
  setOpen,
  error,
  setCancelAttend,
}: {
  open: boolean;
  setOpen: any;
  error: ErrorProp;
  setCancelAttend(value: boolean): void;
}) {
  const { TIPO } = error;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="block" alignItems="center">
          <Box>
            {error.TIPO === "1" ? (
              <>
                <HighlightOffIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "#f05454",
                    marginLeft: "50%",
                    transform: "translate(-50%)",
                  }}
                />
              </>
            ) : (
              <>
                <ErrorOutlineIcon
                  style={{
                    width: 100,
                    height: 100,
                    color: "#edf054",
                    marginLeft: "50%",
                    transform: "translate(-50%)",
                  }}
                />
              </>
            )}
          </Box>
          <Box
            style={{
              marginLeft: "55%",
              transform: "translate(-55%)",
              fontSize: 16,
            }}
            flexGrow={1}
          >
            <h4>{error?.MENSAGEM}</h4>
            {TIPO === "2" && (
              <div style={{ textAlign: "center", margin: "10px 0px 0px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setCancelAttend(event.target.checked)
                      }
                      name=""
                      color="primary"
                    />
                  }
                  label="Cancelar o agendamento"
                />
              </div>
            )}
          </Box>
          <Box>
            <Button
              style={{
                marginLeft: "50%",
                transform: "translate(-50%)",
                marginTop: "25px",
                width: "75%",
                color: "#fff",
                background:
                  error.TIPO === "1"
                    ? "#f05454"
                    : "#edf054",
              }}
              onClick={() => setOpen(false)}
              variant="contained"
              disableElevation
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </DialogTitle>
    </Dialog>
  );
}
