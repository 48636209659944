import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ILayoutContextData {
  imageLoginURL: string;
  imageMainURL: string;
  colorPrimary: string;
  colorSideBar: string;
  colorFontPrimrary: string;
  colorFontSidebar: string;
  colorFontSidebarHover: string;
  imageGuia: string;
  setLayout: (layout: ILayoutContextData) => void;
}

const LayoutContext = createContext<ILayoutContextData>({} as ILayoutContextData);

export const LayoutProvider: React.FC = ({ children }) => {
  const [layoutData, setLayoutData] = useState<ILayoutContextData>(() => {
    const layout = localStorage.getItem('@Prosesmt:Layout');

    if (layout) {
      return JSON.parse(layout);
    }

    return {} as ILayoutContextData;
  })


  const setLayout = useCallback(
    (data: ILayoutContextData) => {
      const metaTags = document.getElementsByTagName('meta');
      for (let i = 0; i < metaTags.length; i++) {
        const property = metaTags[i].getAttribute('property');
        if (property === 'og:image' || property === 'og:description') {
          metaTags[i].setAttribute('content', data.imageMainURL /* ou nova descrição */);
        }
      }
      setLayoutData(data);
      localStorage.setItem('@Prosesmt:Layout', JSON.stringify(data));
    }, []);

  return (
    <LayoutContext.Provider
      value={{
        ...layoutData,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export function useLayout(): ILayoutContextData {
  const context = useContext(LayoutContext);

  return context;
}
