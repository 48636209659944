import DependsOn from "../../../shared/HOC/DependsOn";
import { formatCPFCNPJ } from "../../../utils/formats";
import FormBody from "../../../shared/Form/Body";
import AutoCompleteDef from "../../../shared/Form/Fields/AutoComplete";
import { MaskedPro } from "../../../shared/Form/Fields/Masked";
import { ufs } from "../../../shared/services/ufs.json";
import { useFetch } from "../../../shared/services/useFetch";
import { MenuItem } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { instanceNew, accessToken } from "../../../shared/services/api";
import { SelectDef } from "../../../shared/Form/Fields";

interface ITipoLogradouro {
  TPLCODIGO: number;
  TPLDESCRICAO: string;
  TPLCODTABELA: string;
}

export default function Step() {
  const { data: tiposLogradouro } = useFetch<ITipoLogradouro[]>(
    "/cadastro/tipos-logradouros"
  );
  const [uf, setUF] = useState<string>("");
  const [tpl, setTPL] = useState<number>();

  const { data: tiposLocais } = useFetch<any[]>("/cadastro/tipos-locais");

  return (
    <DependsOn
      dependencies={[tiposLogradouro, tiposLocais]}
      Component={
        <FormBody
          spacing={10}
          schema={[
            {
              content: [
                {
                  label: "Tipo de Local",
                  name: "TLACODIGO",
                  lg: 5,
                  type: "select",
                  options: tiposLocais?.map((item) => (
                    <MenuItem value={item.TLACODIGO}>
                      {item.TLADESCRICAO}
                    </MenuItem>
                  )),
                },
                {
                  label:
                    "Especificação do local (pátio, rampa de acesso, posto de trabalho, etc.)",
                  name: "CATLOCALDESC",
                  lg: 7,
                },
                {
                  label: "CEP",
                  name: "CATCODPOSTAL",
                  lg: 3,
                  onBlur: async (data, setValue) => {
                    try {
                      instanceNew.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                      const response = await instanceNew.get(
                        `/viaCEP/ConsultaCEP/${data.target.value}`
                      );
                      const endereco = response.data.Endereco.split(" ");
                      if (tiposLogradouro && response.data.Endereco)
                        tiposLogradouro.map((itemEnd) => {
                          if (itemEnd.TPLDESCRICAO === endereco[0]) {
                            setValue("TPLCODIGO", itemEnd.TPLCODIGO, {
                              shouldValidate: true,
                            });
                            setTPL(itemEnd.TPLCODIGO);
                            endereco.shift();
                            response.data.Endereco = endereco.join(" ");
                          }
                        });
                      setValue("CATMUNIBGE", response.data.IBGE);
                      setValue("CATDESCLOGRAD", response.data.Endereco);
                      setValue("CATBAIRRO", response.data.Bairro);
                      setValue("CATCOMPLEMENTO", response.data.Complemento);
                      setValue("CATCIDADEACIDENTE", response.data.Cidade);
                      setValue("CATUF", response.data.Estado);
                      setUF(response.data.Estado);
                    } catch (error) {
                      toast.warning("CEP não encontrado");
                    }
                  },
                },
                {
                  name: "TPLCODIGO",
                  lg: 3,
                  customComponent: ({ control, error, name }) => (
                    <AutoCompleteDef
                      control={control}
                      name={name}
                      label="Tipo de logradouro"
                      defValue="CATTPACID"
                      error={error}
                      disabled={false}
                      defOptions={tiposLogradouro?.map((tipo) => ({
                        label: tipo.TPLDESCRICAO,
                        code: tipo.TPLCODIGO,
                      }))}
                    />
                  ),
                },
                {
                  label: "Endereço",
                  name: "CATDESCLOGRAD",
                  lg: 5,
                },
                {
                  label: "Número",
                  name: "CATNRLOGRAD",
                  lg: 1,
                },
                {
                  label: "Bairro",
                  name: "CATBAIRRO",
                  lg: 6,
                },
                {
                  label: "Complemento",
                  name: "CATCOMPLEMENTO",
                  lg: 6,
                },
                {
                  label: "IBGE da cidade",
                  name: "CATMUNIBGE",
                  lg: 2,
                },
                {
                  label: "Cidade",
                  name: "CATCIDADEACIDENTE",
                  lg: 4,
                },
                {
                  label: "CNPJ do local",
                  name: "CATCNPJLOCALACID",
                  lg: 4,
                  customComponent: ({ error, control, name }) => (
                    <MaskedPro
                      format={formatCPFCNPJ}
                      control={control}
                      label="CNPJ do local"
                      name={name || ""}
                      error={error}
                    />
                  ),
                },
                {
                  name: "CATUF",
                  label: "Estado",
                  lg: 2,
                  customComponent: ({ control, name }) => {
                    return (
                      <SelectDef
                        control={control}
                        name={name}
                        FormControlProps={{
                          style: { width: "100%" },
                          variant: "outlined",
                        }}
                        key={uf}
                        defaultValue={uf}
                        label="Estado"
                      >
                        {
                          ufs.map((uf) => (
                            <MenuItem value={uf.sigla}>{uf.sigla}</MenuItem>
                          ))
                        }
                      </SelectDef>
                    );
                  },
                },
              ],
            },
          ]}
        />
      }
    />
  );
}
