import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MenuItem } from "@material-ui/core";

import { useAtestadosProvider } from "./controller";
import { useFetch } from "../../shared/services/useFetch";
import { api } from "../../shared/services/api";
import { ItemPage } from "../../shared";
import InputDate, { InputTime } from "../../shared/Form/Fields/Date";
import RadioButtons from "../../shared/Form/Fields/RadioButtons";
import AutoCompleteDef from "../../shared/Form/Fields/AutoComplete";
import { ufs } from "../../shared/services/ufs.json";

let setErrorCustom: any;

function ClinicoFuncionarioAtestadoItem() {
  const { id, idAtestado } = useParams<{ id: string; idAtestado: string }>();
  const type = idAtestado === "0" ? "create" : "edit";

  const linkBack = `/pages/editarFuncionario/${id}`;
  const [optionsNatureza, setOptionsNatureza] = useState<any[]>([]);
  const [tipoAtestado, setTipoAtestado] = useState<string | null>(
    type === "create" ? "0" : null
  );

  const { data: cids } = useFetch("/clinico/cid");

  const crudProps = useAtestadosProvider(
    `/clinico/funcionarios/${id}/atestados`,
    linkBack,
    "Atestado",
    idAtestado
  );

  useEffect(() => {
    if (crudProps?.content) {
      setTipoAtestado(crudProps.content.ATSTIPOAFASTAMENTO);
    }
  }, [crudProps?.content]);

  useEffect(() => {
    async function fetch() {
      const request = await api();
      const response = await request.get("/esocial/naturezaslesao");
      setOptionsNatureza(
        response.data.map((item) => ({
          label: item.DNLDESCRICAO,
          code: item.DNLCODIGO,
        }))
      );
    }
    fetch();
  }, []);

  return (
    <ItemPage
      type={type}
      linkBack={linkBack}
      {...crudProps}
      create={(data) => {
        if (data.ATSTIPOAFASTAMENTO == 1 && !data.ATSHORASAFASTADO) {
          setErrorCustom(
            "ATSHORASAFASTADO",
            { type: "custom", message: "Campo obrigatório" },
            { shouldFocus: true }
          );
          return;
        }

        if (data.ATSTIPOAFASTAMENTO == 0 && !data.ATSDIAAFASTDO) {
          setErrorCustom(
            "ATSDIAAFASTDO",
            { type: "custom", message: "Campo obrigatório" },
            { shouldFocus: true }
          );
          return;
        }
        crudProps.create(data, false);
      }}
      edit={(data) => {
        if (data.ATSTIPOAFASTAMENTO == 1 && !data.ATSHORASAFASTADO) {
          setErrorCustom(
            "ATSHORASAFASTADO",
            { type: "custom", message: "Campo obrigatório" },
            { shouldFocus: true }
          );
          return;
        }

        if (data.ATSTIPOAFASTAMENTO == 0 && !data.ATSDIAAFASTDO) {
          setErrorCustom(
            "ATSDIAAFASTDO",
            { type: "custom", message: "Campo obrigatório" },
            { shouldFocus: true }
          );
          return;
        }

        crudProps.edit(data);
      }}
      schema={[
        {
          content: [
            {
              name: "ATSCODCNES",
              label: "Código CNES",
              lg: 3,
            },
            {
              name: "ATSDATA",
              lg: 3,
              customComponent: ({ control, name, error }) => {
                return (
                  <InputDate
                    control={control}
                    error={error}
                    label="Data do atendimento"
                    name={name}
                    rules={{ required: "Campo Obrigatório" }}
                    invalidDateMessage={error ? "Data inválida" : ""}
                  />
                );
              },
            },
            {
              name: "ATSHORA",
              lg: 3,
              customComponent: ({ control, name, error }) => {
                return (
                  <InputTime
                    control={control}
                    name={name}
                    label="Hora do atendimento"
                    invalidDateMessage={error ? "Hora inválida" : ""}
                    error={error}
                    rules={{ required: "Campo Obrigatório" }}
                  />
                );
              },
            },
            {
              name: "ATSRELANUAL",
              label: "Exibir no relatório anual",
              lg: 3,
              type: "switch",
            },
            {
              lg: 6,
              name: "ATSTIPOAFASTAMENTO",
              type: "custom",
              customDefValue: "0",
              customComponent: ({ control, error, name }) => (
                <RadioButtons
                  schama={[
                    { label: "Horas", value: "1" },
                    { label: "Dias", value: "0" },
                  ]}
                  control={control}
                  error={error}
                  label="Tipo"
                  name={name}
                  rules={{ required: "Campo obrigatório" }}
                  onChangeItem={(data: string) => setTipoAtestado(data)}
                />
              ),
            },
            {
              name: "ATSDIAAFASTDO",
              label: "Dias afastado",
              lg: 3,
              disabled: tipoAtestado == "1",
              inputPropsAdittional: { type: "number" },
            },
            {
              name: "ATSHORASAFASTADO",
              lg: 3,
              customComponent: ({ control, name, error }) => {
                return (
                  <InputTime
                    control={control}
                    name={name}
                    label="Horas afastado"
                    invalidDateMessage=""
                    disabled={tipoAtestado == "0"}
                    error={error}
                  />
                );
              },
            },
            {
              name: "CIDID",
              label: "CID",
              lg: 12,
              customComponent: ({ control, error, name, setValue }) => {
                return (
                  <AutoCompleteDef
                    key={cids?.length}
                    control={control}
                    name={name}
                    label="CID"
                    error={error}
                    defValue="EMPCODIGO"
                    onChangeItem={(value: string | number) => {
                      setValue("ATSCID", value);
                    }}
                    defOptions={cids?.map((item) => ({
                      label: item.CID + " - " + item.CIDDESCRICAO,
                      code: item.ID,
                      code2: item.CID,
                    }))}
                    rules={{ valueAsNumber: true }}
                  />
                );
              },
            },
            {
              name: "DNLCODIGO",
              lg: 12,
              type: "custom",
              customComponent: ({ control, name, error }) => {
                return (
                  <AutoCompleteDef
                    key={optionsNatureza.length}
                    multiple={false}
                    control={control}
                    name={name}
                    label="Natureza da lesão"
                    error={error}
                    disabled={false}
                    defOptions={optionsNatureza}
                  />
                );
              },
            },
            {
              name: "ATSDESCRICAO",
              label: "Descrição complementar da lesão",
              lg: 12,
              inputPropsAdittional: { multiline: true, rows: 4 },
            },
            {
              name: "ATSDIAGPROVAVEL",
              label: "Diagnóstico Provável",
              lg: 12,
              inputPropsAdittional: { multiline: true, rows: 2 },
            },
            {
              name: "ATSINTERNACAO",
              label: "Houve internação",
              lg: 3,
              type: "switch",
            },
            {
              name: "ATSAFASTAMENTO",
              label: "Houve afastamento",
              lg: 3,
              type: "switch",
            },

            {
              name: "ATSOBSERVACAO",
              label: "Observação",
              lg: 12,
              inputPropsAdittional: { multiline: true, rows: 2 },
            },
            {
              lg: false,
              name: "ATSCID",
              type: "custom",
              customComponent: ({ name, register }) => (
                <input type="hidden" ref={register} name={name} />
              ),
            },
          ],
        },
        {
          title: "Identificação do emitente do atestado",
          content: [
            {
              name: "ATSUFCRM",
              label: "UF Orgão",
              rules: {
                required: "Campo obrigatório",
              },
              lg: 4,
              type: "select",
              options: ufs.map((uf) => (
                <MenuItem value={uf.sigla}>{uf.sigla}</MenuItem>
              )),
            },
            {
              name: "ATSCRM",
              label: "Número inscrição",
              lg: 4,
            },
            {
              name: "ATSMEDICO",
              label: "Nome do emitente",
              lg: 4,
              rules: { required: "Campo Obrigatório" },
            },
            {
              name: "",
              customComponent: ({ setError }) => {
                setErrorCustom = setError;
                return <></>;
              },
            },
          ],
        },
      ]}
      title="atestado"
    />
  );
}

export default ClinicoFuncionarioAtestadoItem;
