import moment from "moment";
import { Headers } from "../../utils/xlsx";

export const headers: Headers<any>[] = [
  {
    header: "Empresa",
    key: "EMPRAZAOSOCIAL",
    width: 40,
  },
  {
    header: "Prestador",
    key: "PRONOME",
    width: 40,
  },
  {
    header: "Estado",
    key: "PROESTADO",
    width: 10
  },
  {
    header: "Funcionário",
    key: "FUNNOME",
    width: 15,
  },
  {
    header: "CPF",
    key: "FUNCPF",
    width: 15,
  },
  {
    header: "RG",
    key: "FUNRG",
    width: 15,
  },
  {
    header: "Data de nascimento",
    key: "FUNNASCIMENTO",
    width: 30,
    type: "date",
  },
  {
    header: "Setor",
    key: "SETNOME",
    width: 15,
  },
  {
    header: "GHE",
    key: "GHENOME",
    width: 15,
  },
  {
    header: "Função",
    key: "FCONOME",
    width: 15,
  },
  {
    header: "Cargo",
    key: "CGONOME",
    width: 15,
  },
  {
    header: "Data de admissão",
    key: "FUNADMISSAO",
    width: 30,
    type: "date",
  },
  {
    header: "Tipo exame",
    key: "ATETIPOEXAMEDESC",
    width: 15,
  },
  {
    header: "Exames",
    key: "EXAMES",
    width: 25,
  },
  {
    header: "Sexo",
    key: "FUNSEXO",
    width: 15,
    formatCell(value) {
      return value === 1 ? "Masculino" : "Feminino";
    },
  },
  {
    header: "Data do agendamento",
    key: "ATEDATA",
    width: 30,
    type: "date",
  },
  {
    header: "Data do exame",
    key: "FEADATAREALIZADO",
    width: 30,
    type: "date",
  },
  {
    header: "Hora do agendamento",
    key: "ATEHORA",
    width: 30,
    formatCell(value: string) {
      return value ? value.substring(0, 5) : "";
    },
  },
  {
    header: "Horário de chegada",
    key: "HORA_CHEGADA",
    width: 30,
    formatCell(value: string) {
      return value && moment(new Date(value)).isValid()
        ? moment(new Date(value)).format("HH:mm")
        : "";
    },
  },
  {
    header: "Horário de saída",
    key: "HORA_SAIDA",
    width: 30,
    formatCell(value: string) {
      return value && moment(new Date(value)).isValid()
        ? moment(new Date(value)).format("HH:mm")
        : "";
    },
  },
  {
    header: "Observações",
    key: "ATEOBSERVACAO",
    width: 30,
  },
];
