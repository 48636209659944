import { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Link, Paper, Typography, Box } from "@material-ui/core";

import LoadingButton from "../../shared/Button/LoadingButton";
import { api } from "../../shared/services/api";
import { Form } from "../../shared";
import InputDate from "../../shared/Form/Fields/Date";
import "./styles.scss";

export default function Financeiro() {
  const [response, setResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box>
        <Typography
          align="left"
          style={{ marginBottom: "20px", fontWeight: "bold", fontSize: "15pt" }}
          color="primary"
        >
          Títulos Financeiros
        </Typography>
        <Form
          spacing={"0px 4px"}
          onSubmit={async (data) => {
            setLoading(true);
            const dateFinValue = moment(data.DATAFINAL).format("YYYY-MM-DD");
            const dateIniValue = moment(data.DATAINICIAL).format("YYYY-MM-DD");
            const req = await api();
            try {
              const response = await req.get(
                `/agenda/financeiros?DATAINICIAL=${dateIniValue}&DATAFINAL=${dateFinValue}`
              );
              setResponse(response.data);
            } catch (err) {
              if (err.response.status === 403) {
                toast.error("Acesso negado");
              } else {
                toast.error(
                  err.response?.data?.message ||
                    "Ocorreu um erro ao tentar gerar os títulos financeiros"
                );
              }
            } finally {
              setLoading(false);
            }
          }}
          buttons={() => (
            <div
              style={{
                width: "100%",
                margin: "10px auto",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
                size="large"
                type="submit"
                loading={loading}
              >
                Gerar
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data inicial"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <div className="margin">
                      <InputDate
                        control={control}
                        label="Data final"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                      <br />
                      <br />
                    </div>
                  ),
                },
              ],
            },
          ]}
        />
      </Box>
      <Box className="marginPrint">
        {response.length > 0 ? (
          response.map((element) => (
            <>
              <Paper
                style={{
                  padding: "25px",
                  marginTop: "25px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="break"
                >
                  <div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Status: </span>
                      {element.LANSTATUS}{" "}
                    </p>
                  </div>
                  <div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Valor: </span>{" "}
                      {element.LANVALOR.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}{" "}
                    </p>
                  </div>
                  <div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Data de Vencimento:{" "}
                      </span>{" "}
                      {moment(element.LANDATAVENCIMENTO).format("DD/MM/YYYY")}{" "}
                    </p>
                  </div>
                </div>
                <br />
                <table style={{ display: "1inline-block" }}>
                  <tr style={{}}>
                    {element.ARQUIVOS && element.ARQUIVOS.length > 0 ? (
                      <td>
                        <span style={{ fontWeight: "bold" }}>Arquivos</span>
                      </td>
                    ) : (
                      <td>
                        <span style={{ fontWeight: "bold" }}>
                          Não existem arquivos neste título
                        </span>
                      </td>
                    )}
                  </tr>
                  {element.ARQUIVOS
                    ? element.ARQUIVOS.map((item) => (
                        <>
                          <tr>
                            <td>
                              <Link
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  window.open(item.URL);
                                }}
                              >
                                {item.ARQDESCRICAO}
                              </Link>
                            </td>
                          </tr>
                        </>
                      ))
                    : ""}
                </table>
              </Paper>
            </>
          ))
        ) : (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            Não foram encontrados registros nesta data.
          </p>
        )}
      </Box>
    </Box>
  );
}

Financeiro.access = "03004001-V";
