import { useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import moment from "moment";
import { CardEmpty } from "./CardEmpty";
import useCrudStyles from "./styles";

export interface SchamaCurd<T> {
  label: string;
  content: keyof T;
  formatFun?: (data: T[keyof T] | T) => string | JSX.Element;
  formatAll?: boolean;
  type?: "number" | "string" | "date";
}

export interface CrudModel<T> {
  content?: T[];
  title?: string | JSX.Element;
  schema?: SchamaCurd<T>[];
  onEditClick?: (data: T) => void;
  onEditonMouseDown?: (data: T, e: any) => void;
  onEditContextMenu?: (data: T, e: any) => void;
  onEditDoubleClick?: (data: T) => void;
  onEditClickCell?: (data: T) => void;
  edit?: boolean;
  actions?: any;
  selected?: T;
  selectedProp?: keyof T;
  id?: string | number;
  showEmptyCard?: boolean;
  cellName?: string;
  dense?: boolean;
  initialPropertyToOrder?: string;
  selecteds?: any[];
  setSelecteds?: any;
  blockedProp?: string;
  search?: boolean;
  searchColumn?: string;
  onChange?: any;
  pagination?: boolean
}

interface ISearchForm {
  onChange: any;
  name: string;
}

function SearchForm({ onChange, name }: ISearchForm) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flex: 2,
      }}
    >
      <TextField name={name} onChange={onChange} fullWidth />
      <IconButton type="submit">
        <SearchIcon />
      </IconButton>
    </div>
  );
}

export default function CrudReorder<T>({
  content,
  title,
  schema,
  onEditClick,
  onEditDoubleClick,
  edit = false,
  actions,
  selected,
  selectedProp,
  onEditonMouseDown,
  onEditContextMenu,
  id,
  onEditClickCell,
  cellName,
  showEmptyCard = true,
  dense = false,
  initialPropertyToOrder,
  search,
  searchColumn,
  onChange,
  pagination = true
}: CrudModel<T>) {
  const classes = useCrudStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>(initialPropertyToOrder || "");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event) => {
    handleRequestSort(event, property);
  };

  function getComparator(order, orderBy) {
    const taskIndex = schema.findIndex((item) => item.content === orderBy);
    const task = schema[taskIndex];
    if (task?.type === "date") {
      return order === "desc"
        ? (a, b) => dateComparator(a, b, orderBy)
        : (a, b) => -dateComparator(a, b, orderBy);
    } else if (task?.type === "number") {
      return order === "desc"
        ? (a, b) => numberComparator(a, b, orderBy)
        : (a, b) => -numberComparator(a, b, orderBy);
    } else {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
  }

  function numberComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString().toLowerCase() <
      a[orderBy]?.toString().toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString().toLowerCase() >
      a[orderBy]?.toString().toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  const dateComparator = (a, b, orderBy) => {
    if (moment(new Date(b[orderBy])).isBefore(new Date(a[orderBy]))) {
      return -1;
    }
    if (moment(new Date(b[orderBy])).isAfter(new Date(a[orderBy]))) {
      return 1;
    }
    return 0;
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      try {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      } catch (err) {
        return a[1] - b[1];
      }
    });
    return stabilizedThis.map((el) => el[0]);
  }

  if (!content) {
    return (
      <div
        className={classes.rootContent}
        style={{ display: "flex", flexDirection: "column", gap: 15 }}
      >
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
      </div>
    );
  }

  if (content?.length <= 0 && !search) {
    return (
      <>
        <div className={classes.header}>
          {title ? (
            typeof title === "string" ? (
              <Typography className={classes.title} variant="h5">
                {title}
              </Typography>
            ) : (
              title
            )
          ) : (
            <div></div>
          )}

          {actions && <div>{actions}</div>}
        </div>
        {showEmptyCard && (
          <CardEmpty
            title={`Nenhum/a ${
              typeof title === "string" ? title.toLowerCase() : "Item"
            } cadastrado/a`}
            desc={`Utilize o botão Novo acima para criar um/a ${
              typeof title === "string" ? title.toLowerCase() : "Item"
            }`}
          />
        )}
        <br />
        <br />
      </>
    );
  }

  return (
    <div className={classes.rootContent}>
      <div
        style={{
          display: "flex",
          justifyContent: actions ? "space-around" : "flex-start",
          alignItems: "center",
          gap: 24,
        }}
      >
        {title && (
          <div style={{ marginTop: 12 }}>
            {typeof title === "string" ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}
            <br />
          </div>
        )}

        {search && searchColumn ? (
          <SearchForm name={searchColumn} onChange={onChange} />
        ) : null}
        {actions && <div className={classes.button}>{actions}</div>}
      </div>
      {content.length ? (
        <>
          <TableContainer>
            <Table aria-label="table" size="small">
              <TableHead>
                <TableRow>
                  {schema.map((item, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      sortDirection={orderBy === item.content ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === item.label}
                        direction={orderBy === item.content ? order : "asc"}
                        onClick={createSortHandler(item.content as string)}
                      >
                        <b>{item.label}</b>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-cy="table-body">
                {content &&
                  stableSort(content, getComparator(order, orderBy))
                    ?.slice(page * 15, page * 15 + 15)
                    .map((item, indexPage) => (
                      <TableRow
                        selected={
                          selectedProp &&
                          selected &&
                          item[selectedProp] === selected[selectedProp]
                        }
                        className={edit ? classes.tableRow : classes.empty}
                        hover={edit}
                        onClick={() => {
                          if (edit && onEditClick) onEditClick(item);
                        }}
                        onMouseDown={(e) => {
                          if (edit && onEditonMouseDown)
                            onEditonMouseDown(item, e);
                        }}
                        onDoubleClick={() => {
                          if (edit && onEditDoubleClick)
                            onEditDoubleClick(item);
                        }}
                        onContextMenu={(e) => {
                          if (edit && onEditContextMenu)
                            onEditContextMenu(item, e);
                        }}
                        key={id ? item[id] : indexPage}
                      >
                        {schema.map((schemaItem, index) => {
                          return (
                            <TableCell
                              data-cy={`listTile-${schemaItem.content}-${indexPage}`}
                              key={index}
                              align="left"
                              onClick={() => {
                                if (
                                  edit &&
                                  onEditClickCell &&
                                  schemaItem.content === cellName
                                )
                                  onEditClickCell(item);
                              }}
                            >
                              {schemaItem.formatFun
                                ? schemaItem.formatFun(
                                    schemaItem.formatAll
                                      ? item
                                      : item[schemaItem.content]
                                  )
                                : item[schemaItem.content]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={content ? content.length : 0}
              rowsPerPage={15}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={() => {}}
            />
          )}
        </>
      ) : (
        <h3 style={{ textAlign: "center" }}>
          Não foram encontrados registros com os filtros informados
        </h3>
      )}
    </div>
  );
}
