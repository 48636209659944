import { useState } from "react";
import {
  Typography,
  Box,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  withStyles,
} from "@material-ui/core";

import Dados from "./dados";
import Header from "./header";
import ExamesClinicos from "./examesClinicos";
import ExamesComplementares from "./examesComplementares";
import CatPage from "../cat";
import AtestadoPage from "../atestado";

const CustomTab = withStyles({
  root: {
    textTransform: "none",
  },
})(Tab);

export default function FuncionariosEditar() {
  return <SimpleTabs />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    maxWidth: "95%",
    margin: "auto",
  },
}));

function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Header title="Editar funcionário" />
      <div className={classes.tabs}>
        <AppBar
          style={{ position: "static", top: 60, backgroundColor: "#fafafa" }}
          elevation={-1}
          position="static"
          //color="default"
        >
          <Tabs
            orientation="horizontal"
            indicatorColor="primary"
            textColor="primary"
            style={{ background: "inherit" }}
            value={value}
            onChange={handleChange}
            aria-label="tabs usuários"
            variant="fullWidth"
          >
            <CustomTab label="Dados" {...a11yProps(0)} />
            <CustomTab label="Exames Clínicos" {...a11yProps(1)} />
            <CustomTab label="Exames Complementares" {...a11yProps(2)} />
            <CustomTab label="Atestado" {...a11yProps(3)} />
            <CustomTab label="CAT" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
      </div>
      <TabPanel value={value} index={0}>
        <Dados />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExamesClinicos />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ExamesComplementares />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AtestadoPage />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CatPage />
      </TabPanel>
    </div>
  );
}
