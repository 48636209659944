import { FormControlLabel, Checkbox } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function CheckboxReverse({
  name,
  label,
  control,
  onChangeItem,
}: {
  name: string;
  label: string;
  control: any;
  onChangeItem?: (data: boolean) => void;
}) {
  return (
    <FormControlLabel
      control={
        <Controller
          render={({ value, onChange }) => (
            <Checkbox
              color="primary"
              checked={value == 0 || value === false}
              onChange={(e) => {
                if (onChangeItem) onChangeItem(!e.target.checked);
                onChange(!e.target.checked);
              }}
            />
          )}
          name={name}
          control={control}
        />
      }
      label={label}
    />
  );
}
