export type Action = { type: "SET_DATE"; payload: string };

export const setDate = (type: string): Action => ({
  type: "SET_DATE", 
  payload: type,
});

const dateReducer = (
  state: string | null = "12/09/2021",
  action: Action
): string | null => {
  switch (action.type) {
    case "SET_DATE":
      return action.payload;
    default:
      return state;
  }
};

export default dateReducer;
