import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import {
  Assignment,
  Event,
  Store,
  Autorenew,
  Person,
  AttachMoney,
  Notifications,
} from "@material-ui/icons";
import { Divider } from "@material-ui/core";
import { useHistory } from "react-router";
import PeopleRoundedIcon from "@material-ui/icons/PeopleRounded";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import isValidAccess from "../../../../utils/isValidAcess";

declare module "csstype" {
  interface Properties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  labelIcon?: React.ElementType<SvgIconProps>;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      padding: theme.spacing(0.5, 1),
      fontWeight: theme.typography.fontWeightMedium as any,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      marginBottom: theme.spacing(1),
      "& $content": {
        padding: theme.spacing(0.2, 0, 0.5, 4),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
      fontSize: "1.05rem",
    },
  })
);

function StyledTreeItem(props: StyledTreeItemProps) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {LabelIcon && (
            <LabelIcon color="inherit" className={classes.labelIcon} />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
        </div>
      }
      classes={{
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

interface IDrawerDefaultProps {
  agendamentoVisible: boolean;
  eSocialVisible: boolean;
  financeiroVisible: boolean;
  solicitacoesVisible: boolean;
  relatoriosVisible: boolean;
  relatoriosClientesVisible: boolean;
  colorSideBar: string;
  colorMenu: string;
  imgLogin: string;
  setOpenModalAgenda: (value: boolean) => void;
  setOpenModalSol: (value: boolean) => void;
  abasAccess?: any;
}

function DrawerDefault({
  agendamentoVisible,
  eSocialVisible,
  financeiroVisible,
  solicitacoesVisible,
  relatoriosVisible,
  relatoriosClientesVisible,
  colorSideBar,
  colorMenu,
  imgLogin,
  setOpenModalAgenda,
  setOpenModalSol,
  abasAccess,
}: IDrawerDefaultProps) {
  let history = useHistory();

  return (
    <div
      style={{
        background: colorSideBar,
        overflowX: "hidden",
        minHeight: "100vh",
        color: colorMenu,
      }}
    >
      <div style={{ display: "flex" }}>
        <img
          src={imgLogin}
          alt=""
          style={{
            maxHeight: "90px",
            maxWidth: "100%",
            padding: "8px",
            margin: "0 auto",
          }}
        />
      </div>
      <Divider style={{ marginBottom: "24px" }} />
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        style={{ color: colorMenu }}
      >
        <StyledTreeItem
          nodeId="1"
          labelText="Inicio"
          labelIcon={Store}
          color={colorMenu}
          onClick={() => {
            history.push("/pages");
          }}
        />
        {agendamentoVisible && (
          <>
            <StyledTreeItem nodeId="2" labelText="Agenda" labelIcon={Event}>
              <StyledTreeItem
                nodeId="8"
                labelText="Novo Agendamento"
                onClick={() => {
                  setOpenModalAgenda(true);
                }}
              />
              <StyledTreeItem
                nodeId="9"
                labelText="Ver Agendamentos"
                onClick={() => history.push("/pages/VerAgendamentos")}
              />
            </StyledTreeItem>
          </>
        )}
        {relatoriosClientesVisible && (
          <StyledTreeItem
            nodeId="3"
            labelText="Relatório"
            labelIcon={Assignment}
          >
            <StyledTreeItem
              nodeId="10"
              labelText="Exames Realizados"
              onClick={() => {
                history.push("/pages/examesRealizados");
              }}
            />
            <StyledTreeItem
              nodeId="11"
              labelText="Exames Pendentes"
              onClick={() => {
                history.push("/pages/examesPendentes");
              }}
            />
            {isValidAccess("03004002-V") && (
              <StyledTreeItem
                nodeId="12"
                labelText="Documentos"
                onClick={() => {
                  history.push("/pages/documentos");
                }}
              />
            )}
          </StyledTreeItem>
        )}
        {solicitacoesVisible && agendamentoVisible && (
          <StyledTreeItem
            nodeId="4"
            labelText="Solicitações"
            labelIcon={Autorenew}
          >
            <StyledTreeItem
              nodeId="13"
              labelText="Nova Solicitação"
              onClick={() => {
                setOpenModalSol(true);
              }}
            />
            <StyledTreeItem
              nodeId="14"
              labelText="Gerenciar Solicitação"
              onClick={() => {
                history.push("/pages/GerenciarSolicitações");
              }}
            />
          </StyledTreeItem>
        )}
        {eSocialVisible && (
          <StyledTreeItem nodeId="5" labelText="eSocial" labelIcon={Person}>
            <StyledTreeItem
              nodeId="15"
              labelText="Dashboard eSocial"
              onClick={() => {
                history.push("/pages/eSocial");
              }}
            />
            <StyledTreeItem
              nodeId="16"
              labelText="Configurações eSocial"
              onClick={() => {
                history.push("/pages/Configuração");
              }}
            />
          </StyledTreeItem>
        )}
        {agendamentoVisible && (
          <StyledTreeItem
            nodeId="6"
            labelText="Notificações"
            labelIcon={Notifications}
            onClick={() => {
              history.push("/pages/centralNotificacoes");
            }}
          />
        )}
        {(agendamentoVisible || eSocialVisible) && (
          <StyledTreeItem
            nodeId="25"
            labelText="Administrativo"
            labelIcon={PeopleRoundedIcon}
          >
            <StyledTreeItem
              nodeId="28"
              labelText="Funcionários"
              onClick={() => history.push("/pages/funcionarios")}
            />
            <StyledTreeItem
              nodeId="29"
              labelText="Planos de Ação"
              onClick={() => history.push("/pages/cronogramas")}
            />
            <StyledTreeItem
              nodeId="30"
              labelText="Gerenciador de Arquivos"
              onClick={() => history.push("/pages/gerenciadorArquivos")}
            />
          </StyledTreeItem>
        )}
        {financeiroVisible &&
          agendamentoVisible &&
          isValidAccess("03004001-V") && (
            <StyledTreeItem
              nodeId="7"
              labelText="Financeiro"
              labelIcon={AttachMoney}
              onClick={() => {
                history.push("/pages/financeiro");
              }}
            />
          )}
        {relatoriosVisible && (
          <StyledTreeItem
            nodeId="27"
            labelText="Relatórios"
            labelIcon={InsertDriveFileIcon}
            onClick={() => {
              history.push("/pages/relatorios");
            }}
          />
        )}
      </TreeView>
    </div>
  );
}

export { DrawerDefault };
