export const findChangedField = (newValue: any, oldValue: any) => {
  let updtedValue: any = {};
  let hasChanged = false;
  let errorDefaultMessage: "Nenhum campo alterado";

  if (newValue.funcionario) {
    delete newValue.funcionario;
  }

  for (var prop in newValue) {
    if (
      //prettier-ignore
      !(!newValue[prop] && !oldValue[prop]) &&
      oldValue[prop] !== newValue[prop]
    ) {
      //@ts-ignore
      updtedValue[prop] = newValue[prop];
    }
  }

  if (
    !(
      Object.keys(updtedValue).length === 0 &&
      updtedValue.constructor === Object
    )
  ) {
    hasChanged = true;
  }

  return { updtedValue, hasChanged, errorDefaultMessage };
};
