import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { ArrowDropDown } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { Form } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import TransitionsModal from "../../shared/modalAgenda";
import TransitionsModalSol from "../../shared/modalSol";
import { useLayout } from "../../hooks/auth";
import { DrawerDefault } from "../../shared/Layout/Default/sideNav/Drawer";
import { api } from "../../shared/services/api";
import "./styles.scss";
import { useAccess } from "../../contexts/access";
import routes from "../../shared/Layout/Default/routes/routesModel";
import isValidAccess from "../../utils/isValidAcess";
import { formatCPFCNPJ } from "../../utils/formats";
import { useStatePersistent } from "../../contexts/statesPersistent";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export default function Dashboard(props) {

  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { setCredentials, credentials, abasAccess, setAbasAccess } = useAccess();
  const { setOpenModalAgend } = useStatePersistent();

  const { imageMainURL, colorFontSidebar, colorSideBar } = useLayout();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [empresa, setEmpresa] = useState("");
  const [companyDocument, setCompanyDocument] = useState<string>("");
  const [nome, setNome] = useState("");
  const [open, setOpen] = useState(false);
  const [openModalSol, setOpenModalSol] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [valueAutoComplere] = useState<string | null>(
    empresas[0]
  );
  const [inputAutoCompleteValue, setInputAutoCompleteValue] = useState("");
  const [valueOptionName, setValueOptionName] = useState("");
  const [idOption, setIdOption] = useState<any | null>(0);
  const [getNotification, setGetNotification] = useState([]);
  const [notification, setNotification] = useState(false);

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    async function validaUsuario() {
      const req = await api();
      req.get("/agenda/acessos").then((response) => {
        req.get("/users/niveisAcesso").then((res) => {
          routes.forEach((route) => {
            localStorage.setItem("Access", JSON.stringify(res.data));
            if ((route.component as any).access) {
              setAbasAccess({
                [route.component.name]: isValidAccess(
                  (route.component as any).access
                ),
              });
            }
          });
        });

        if (response.data.AGENDAMENTO === "1") {
          setVisible(true);
          setCredentials({ AGENDAMENTO: true });
        }

        if (response.data.ESOCIAL === "1") {
          setCredentials({ ESOCIAL: true });
        }

        if (response.data.FINANCEIRO === "1") {
          setCredentials({ FINANCEIRO: true });
        }

        if (response.data.SOLICITACOES === "1") {
          setCredentials({ SOLICITACOES: true });
        }

        if (response.data.GERALE === "1") {
          setCredentials({ GERALE: true });
        }

        if (
          response.data?.RELATORIO === "1" ||
          response.data?.AGENDAMENTO === "1"
        ) {
          setCredentials({ RELATORIO: true });
        }
      });
    }
    validaUsuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleCloseModalSol = () => {
    setOpenModalSol(false);
  };

  const handleCloseModalAgend = () => {
    setOpenModalAgend(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let history = useHistory();
  useEffect(() => {
    async function getToken() {
      const req = await api();
      const testeA = await req.get(`/users/usuarioLogado`);
      const companyName = localStorage.getItem("EMP_NAME");
      const companyDocument = localStorage.getItem("EMP_DOCUMENT");
      setNome(testeA.data.userName);
      setEmpresa(companyName);
      setCompanyDocument(companyDocument);
    }

    async function getNotification() {
      const req = await api();
      const response = await req.get("/agenda/notificacoes");
      setGetNotification(response.data);
    }

    getNotification();
    getToken();
  }, []);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#fff" }}
        className="testePrint"
      >
        <Toolbar
          style={{
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
            marginRight: 10,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
            style={{ marginRight: "10px" }}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <div
            onClick={() => {
              setNotification(!notification);
            }}
            style={{
              cursor: "pointer",
              height: "48px",
              paddingRight: 25,
              borderRight: "1px solid #e5e5e5",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                display: notification ? "block" : "none",
                animation: notification
                  ? "0.1s cubic-bezier(0, 0, 0.2, 1) 0s 1 normal none running popUp"
                  : "",
              }}
              className="modalNot"
            >
              {getNotification.map((element, index) => (
                <div className="notifications">
                  <Link
                    to={{
                      pathname: "/pages/examesPendentes",
                      state: { value: element.DESCRICAO },
                    }}
                    onClick={() => {
                      setNotification(false);
                    }}
                    className="linkExams"
                  >
                    <p>{element.DESCRICAO}</p>
                  </Link>
                </div>
              ))}
            </div>

            {visible && (
              <>
                <NotificationsIcon
                  color="primary"
                  style={{
                    marginLeft: "auto",
                    marginRight: "0.5%",
                    zIndex: -1,
                  }}
                />
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    background: "red",
                    borderRadius: "50%",
                    marginTop: -21,
                    marginLeft: "15px",
                    position: "absolute",
                  }}
                ></div>
              </>
            )}
          </div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <div style={{ display: "block", textAlign: "left" }}>
              <p> {nome}</p>
              <h6 style={{ display: "flex" }}>
                {localStorage.getItem("EMP_NAME")} -{" "}
                {formatCPFCNPJ(localStorage.getItem("EMP_DOCUMENT"))}
                <ArrowDropDown style={{ marginTop: "-5px" }} />
              </h6>
            </div>
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                history.push("/pages/trocarSenha");
              }}
            >
              Trocar Senha
            </MenuItem>
            <MenuItem onClick={handleOpen}>Trocar Empresa</MenuItem>
            <MenuItem
              onClick={() => {
                history.push("/");
              }}
            >
              Sair
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            color="primary"
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <DrawerDefault
              relatoriosVisible={credentials.GERALE}
              eSocialVisible={credentials.ESOCIAL}
              agendamentoVisible={credentials.AGENDAMENTO}
              financeiroVisible={credentials.FINANCEIRO}
              solicitacoesVisible={credentials.SOLICITACOES}
              relatoriosClientesVisible={credentials.RELATORIO}
              colorSideBar={colorSideBar}
              colorMenu={colorFontSidebar}
              imgLogin={imageMainURL}
              setOpenModalAgenda={setOpenModalAgend}
              setOpenModalSol={setOpenModalSol}
              abasAccess={abasAccess}
            />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            color="primary"
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerDefault
              relatoriosVisible={credentials.GERALE}
              eSocialVisible={credentials.ESOCIAL}
              agendamentoVisible={credentials.AGENDAMENTO}
              financeiroVisible={credentials.FINANCEIRO}
              solicitacoesVisible={credentials.SOLICITACOES}
              relatoriosClientesVisible={credentials.RELATORIO}
              colorSideBar={colorSideBar}
              colorMenu={colorFontSidebar}
              imgLogin={imageMainURL}
              setOpenModalAgenda={setOpenModalAgend}
              setOpenModalSol={setOpenModalSol}
              abasAccess={abasAccess}
            />
          </Drawer>
        </Hidden>
      </nav>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 style={{ width: "600px", marginBottom: "15px" }}>
              Trocar Empresa
            </h2>
            <hr
              style={{
                backgroundColor: colorSideBar,
                width: "111%",
                marginLeft: "-34px",
              }}
            />
            <div style={{ display: "flex" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "12pt",
                  marginTop: "10px",
                }}
              >
                Empresa Selecionado:{" "}
              </p>
              <p style={{ marginTop: "10px", marginLeft: "15px" }}>{empresa}</p>
            </div>
            <h3 style={{ width: "600px" }}>Selecionar outra empresa: </h3>
            <Form
              spacing={"0px 24px 0px 0px"}
              onSubmit={async () => {
                const teste = parseInt(idOption);
                const objectValues = {
                  CompanyId: teste,
                };
                const token = localStorage.getItem("Token_Key");
                const req = await api();
                const response = await req.post(
                  "/users/company/change",
                  objectValues,
                  { headers: { Authorization: `Bearer ${token}` } }
                );
                localStorage.setItem("Token_Key", response.data.token);
                localStorage.setItem("EMP_NAME", valueOptionName);
                localStorage.setItem("EMP_DOCUMENT", companyDocument);
                localStorage.setItem("EMPCODIGO", idOption);
                setOpen(false);
                handleClose();
                history.push("/pages");
              }}
              buttons={() => (
                <div
                  style={{
                    maxWidth: 1115,
                    width: "101%",
                    margin: "24px auto",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    style={{ width: "96.5%", marginTop: 10 }}
                    size="large"
                    type="submit"
                  >
                    Trocar
                  </LoadingButton>
                </div>
              )}
              schema={[
                {
                  content: [
                    {
                      lg: 12,
                      name: "DATAINICIAL",
                      type: "custom",
                      customDefValue: new Date(),
                      customComponent: () => (
                        <>
                          <div style={{ marginTop: "5%" }}>
                            <Autocomplete
                              id="country-select-demo"
                              value={valueAutoComplere}
                              onFocus={async () => {
                                const req = await api();
                                const responseGet = await req.get(
                                  "/users/company"
                                );
                                setEmpresas(responseGet.data);
                              }}
                              onChange={async (
                                event: any,
                                newValue: any | null
                              ) => {
                                setIdOption(newValue.CompanyId);
                                setValueOptionName(newValue.CompanyName);
                                setCompanyDocument(newValue.CompanyDocument);
                              }}
                              inputValue={inputAutoCompleteValue}
                              onInputChange={(event, newInputValue) => {
                                setInputAutoCompleteValue(newInputValue);
                              }}
                              options={empresas as CountryType[]}
                              autoHighlight
                              getOptionLabel={(option) =>
                                `${option.CompanyName} - ${option.CompanyDocument}`
                              }
                              renderOption={(option) => (
                                <div>
                                  <span>
                                    {option.CompanyDocument + " - "}
                                    {option.CompanyName}
                                  </span>
                                </div>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione a empresa"
                                  variant="outlined"
                                  autoComplete="new-password"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </>
                      ),
                    },
                  ],
                },
              ]}
            />
          </div>
        </Fade>
      </Modal>
      <TransitionsModalSol
        openModal={openModalSol}
        closeModal={handleCloseModalSol}
      />
      <TransitionsModal
        closeModal={handleCloseModalAgend}
      />
    </div>
  );
}

interface CountryType {
  CompanyId: string;
  CompanyName: string;
  CompanyDocument: string;
}
