import { DialogContent, styled } from "@material-ui/core";

export const Head = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const Body = styled(DialogContent)({
  fontSize: "15px",
  marginBottom: "15px",
});
