import { SelecionarItem } from "../../views/eSocial/selecionar";
import { ActionESocial } from "../actions/eSocialActions";

export interface eSocialFilterModel {
  NomeFuncionario?: string;
  eSocialTipo?: string;
  Status?: string;
  DATAINICIAL?: string;
  DATAFINAL?: string;
  tempoAtrasoDias: number;
  tipoEinvio: number;
  EXAMES: number[];
}

export interface eSocialModel {
  filter?: eSocialFilterModel;
  selecionados?: SelecionarItem[];
  EMPCODIGO?: string;
}

const eSocialReducer = (
  state: eSocialModel | null = {
    EMPCODIGO: "8634",
    selecionados: [],
  },
  action: ActionESocial
): eSocialModel | null => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "SET_EMPCODIGO":
      return {
        ...state,
        EMPCODIGO: action.payload,
      };
    case "SET_SELECIONADO":
      return {
        ...state,
        selecionados: action.payload,
      };
    default:
      return state;
  }
};

export default eSocialReducer;
