import { Box, MenuItem } from "@material-ui/core";
import { useState } from "react";
import SimpleTabs, { SelectDef } from "./components";
import { useFetchSelect } from "./controller";

export default function Cronogramas() {
  const [anaCodigo, setAnaCodigo] = useState<null | number>(null);
  const [empCodigo, setEmpCodigo] = useState<null | number>(null);

  async function handleChange(evt) {
    setAnaCodigo(evt.target.value);
    setEmpCodigo(evt.currentTarget.dataset.empcodigo);
  }

  const { options: analises } = useFetchSelect(
    `/analiserisco/empresas/analisesativas`,
    null,
    ["ANACODIGO", "EMPCODIGO"],
    (item) => `${item.ANACODIGO} - ${item.ANARAZAOSOCIAL} - ${item.POSNOME}`
  );

  const menus =
    Array.isArray(analises) &&
    analises.map((item, idx) => (
      <MenuItem key={idx} value={item.code} data-empcodigo={item.code2}>
        {item.label}
      </MenuItem>
    ));

  return (
    <Box style={{ flex: 1, width: "98%" }}>
      <Box style={{ marginBottom: 10 }}>
        <SelectDef
          value={anaCodigo}
          onChange={handleChange}
          label="Selecione uma análise"
        >
          {menus}
        </SelectDef>
      </Box>
      <SimpleTabs anaCodigo={anaCodigo} empCodigo={empCodigo} />
    </Box>
  );
}
