import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export interface IAlert {
  open: boolean,
  message: string,
  severity:  "success" | "info" | "warning" | "error"
}

export interface IAlertProps extends IAlert {
  closeItem: () => void
}

export default function AlertItem({
  open,
  severity,
  message,
  closeItem
}: IAlertProps) {

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={closeItem}
    >
      <Alert
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
