import { makeStyles, Chip, Tooltip } from "@material-ui/core";

const useStyles = makeStyles<
  undefined,
  { bgColor: string; hoverColor?: string }
>({
  root: {
    "&&:hover": {
      backgroundColor: ({ bgColor }) => `${bgColor}`,
      color: ({ hoverColor }) => (hoverColor ? hoverColor : "#FFF!important"),
    },
  },
});

const StyledChip = ({ bgColor, date, ...props }: any) => {
  const classes = useStyles({ bgColor });
  return (
    <>
      {date ? (
        <Tooltip title={date} arrow>
          <Chip {...props} className={classes.root} />
        </Tooltip>
      ) : (
        <Chip {...props} className={classes.root} />
      )}
    </>
  );
};

export default StyledChip;
