import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RootModel } from "../../redux/reducers";
import { Grid, Link, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import VerticalLinearStepperEmployee from "./Stepper";
import { useDispatch, useSelector } from "react-redux";
import CartSchedule, { CartProximo } from "./CartSchedule";
import { removeSchedule } from "../../redux/actions/scheduleActions";
import { ScheduleEventDomain } from "../../redux/reducers/scheduleReducer";
import { createContext, Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";

export enum TipoExameEnum {
  Demissional = 3,
  RetornoAoTrabalho = 5,
}

var def: {
  schedule?: ScheduleEventDomain | null;
  setSchedule?: Dispatch<SetStateAction<ScheduleEventDomain | null>>;
} = {};

const ScheduleContext = createContext(def);

export const useSchedule = () => {
  return useContext(ScheduleContext);
};

export default function Employee(props) {
  const schedule = useScheduleProvider();
  const history = useHistory();
  const {
    date,
    provider,
    schedule: schedules,
    portal,
  } = useSelector<RootModel, RootModel>((state) => state);
  const dispatch = useDispatch();

  if (!provider) {
    history.push("/pages");
  }

  useEffect(() => {
    schedules.forEach((item) => {
      deleteItemEffect(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteItemEffect = (item: ScheduleEventDomain) => {
    dispatch(removeSchedule(item.IdFrontEnd));
  };

  const deleteItem = (item: ScheduleEventDomain) => {
    dispatch(removeSchedule(item.IdFrontEnd));
    toast.success("Removido");
  };
  return (
    <ScheduleContext.Provider value={schedule}>
      <Typography variant="h4">Relacionar Exame</Typography>
      <br />
      <Typography>Agendamento: {moment(date).format("DD/MM/YYYY")}</Typography>
      <br />
      {portal?.agendamentoRapido && (
        <Fragment>
          <Typography>
            Agendamento rápido para:{" "}
            <b>
              {portal?.agendamentoRapido.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4"
              )}
            </b>
          </Typography>
          <br />{" "}
        </Fragment>
      )}
      <Typography>
        Prestador: {provider?.PRONOME}
        {" - "}
        <Link
          href="#"
          onClick={() => {
            history.goBack();
          }}
        >
          Alterar
        </Link>
      </Typography>
      <br />
      {schedules && schedules.length > 0 && (
        <Grid container spacing={3}>
          {schedules.map((item) => (
            <Grid item xs={12} sm={6} lg={3}>
              <CartSchedule onDeleteItem={deleteItem} item={item} />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3}>
            <CartProximo />
          </Grid>
        </Grid>
      )}

      <br />
      <Paper>
        <VerticalLinearStepperEmployee />
      </Paper>
    </ScheduleContext.Provider>
  );
}

export const useScheduleProvider = () => {
  const [schedule, setSchedule] = useState<ScheduleEventDomain | null>();

  return {
    schedule,
    setSchedule,
  };
};
