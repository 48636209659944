import { useLayout } from "../hooks/auth";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { ptBR } from "@material-ui/core/locale";

function ThemeContext({ children }) {
  const { colorPrimary, colorFontSidebar } = useLayout();
  const theme = createTheme(
    {
      palette: {
        primary: {
          main: colorPrimary ? colorPrimary : "#174B7A",
        },
        secondary: {
          main: colorFontSidebar ? colorFontSidebar : "#103454",
        },
        success: {
          main: "#4DBD74",
        },
        background: {
          default: "#fcfcfc",
        },
        error: {
          main: "#cc1010",
        },
      },
    },
    ptBR
  );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ThemeContext;
