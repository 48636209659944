import {
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  createStyles,
  Theme,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EsocialDashboardContainer } from "./EsocialDashboardContainer";
import { api } from "../../shared/services/api";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";

export interface eSocialTiposModel {
  label: string;
  code: number;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1336,
      margin: "0 auto",
      padding: "0px 30px",
      display: "flex",
      gap: 30,
    },
    separator: { height: 50 },
    separatorSmall: { height: 30 },
    data: { flex: 4, paddingBottom: "50px" },
    history: {
      position: "relative",
      paddingBottom: 50,
      flex: 2,
      paddingLeft: 14,
      borderLeft: "1px dashed lightgrey",
      "& li": {
        listStyle: "none",
      },
      "& ul": {
        paddingLeft: 0,
      },
      transition: theme.transitions.create(["flex", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    historyShift: {
      flex: 0,
      transition: theme.transitions.create(["flex", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    label: {
      marginTop: 15,
    },
    listItem: {
      borderRadius: 10,
    },
    icon: {
      left: -20,
      bottom: 0,
      position: "absolute",
      background: "white",
    },
  })
);

export default function EsocialDashboard(props) {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams<{ tipo?: string }>();

  const [valueRadio, setValueRadio] = useState(params.tipo ?? "1");

  useEffect(() => {
    async function loadSchedule() {
      try {
        const teste = localStorage.getItem('Token_Key');
        if (teste === props.match.params.token) {
          const req = await api();
          await req.get(`/users/token`)
        } else {
          history.push('/')
        }
      } catch {
        history.push('/')
      }
    }

    loadSchedule();
  }, [history, props.match.params.token]);

  const handleRadioTypoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => setValueRadio(value);
  const tipos: eSocialTiposModel[] = [
    {
      code: 1,
      label: "S2220 - Monitoramento da Saúde do Trabalhador",
    },
    {
      code: 2,
      label: "S2240 - Condições Ambientais do Trabalho - Fatores de risco",
    },
  ];

  const redirectFilters = () => {
    history.push(`/esocial/dashboard/${valueRadio}/filtros`);
  };

  const redirectNext = () =>
    history.push(`/esocial/dashboard/${params.tipo}/items`);

  return (
    <EsocialDashboardContainer subTitle="Selecione o tipo">
      <>
        <FormControl>
          <RadioGroup value={valueRadio} onChange={handleRadioTypoChange}>
            {tipos.map((tipo) => (
              <FormControlLabel
                className={classes.label}
                key={tipo.code}
                value={tipo.code.toString()}
                control={<Radio />}
                label={tipo.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <div className={classes.separator}></div>
        <DialogActions>
          <Button
            startIcon={<FilterListRoundedIcon />}
            onClick={redirectFilters}
            variant="outlined"
            color="primary"
          >
            Filtros
          </Button>
          <Button
            endIcon={<NavigateNextRoundedIcon />}
            onClick={redirectNext}
            variant="contained"
            color="primary"
          >
            Continuar
          </Button>
        </DialogActions>
      </>
    </EsocialDashboardContainer>
  );
}
