import { Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from 'react-router';

export default function Header({
  title,
}: {
  title?: string;
}) {
  const history = useHistory();

  return (
    <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} lg={12}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Grid container direction="row" alignItems="center">
          <IconButton
            onClick={() => history.push('/pages/funcionarios')}
            size="medium"
            color="primary"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            style={{ margin: "0px 0px 0px 10px", padding: 0 }}
            variant="h6"
          >
            {title}
          </Typography>
        </Grid>
      </div>
    </Grid>
  );
}
