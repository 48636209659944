import { useState } from "react";
import { Form } from "../../shared";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";
import { DataGrid } from '@mui/x-data-grid';
import { useLayout } from "../../hooks/auth";
import { api } from "../../shared/services/api";
import { InputDef } from "../../shared/Form/Fields";
import { removerAcentos } from "../../utils/formats";
import VisibilityIcon from '@material-ui/icons/Visibility';
import RadioButtons from "../../shared/Form/Fields/RadioButtons";
import { Typography, makeStyles, IconButton, CircularProgress } from "@material-ui/core";

interface docs {
  URL: string;
  ARQGUID: string;
  POSNOME: string;
  ARQDESCRICAO: string;
  ANADATAVALIDADE: string;
  ARQTIPODOCUMENTO: string;
  ANADATAREALIZACAO: string;
}

export default function Documentos() {

  const layout = useLayout()

  const useStyles = makeStyles({
    dataGridContainer: {
      borderRadius: '5px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12)',
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: layout?.colorPrimary, // Cor de fundo do cabeçalho
      },
      '& .MuiDataGrid-columnHeader': {
        color: '#fff', // Cor do texto do cabeçalho
        fontWeight: 'bold', // Peso da fonte do cabeçalho
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '16px', // Tamanho da fonte do cabeçalho
      },
      '& .MuiDataGrid-sortIcon': {
        color: '#fff', // Cor dos ícones de ordenação
      }
    },
  });

  const classes = useStyles();

  const [filter, setFilter] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<docs[]>([]);

  const submit = async (data) => {
    try {
      setLoading(true)
      const req = await api();
      const response = await req.get<docs[]>('/relatorio/documentos', {
        params: data
      })
      setResponseData(response.data)
    } catch (e) {
      toast.error(e?.response?.data?.message || e?.response?.data || e?.response || e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingRight: "60px",
      }}
    >
      <Typography
        variant="h4"
        color="primary"
        style={{
          marginBottom: "30px"
        }}
      >
        Buscar Documentos
      </Typography>
      <Form
        // spacing={"0px 4px"}
        onSubmit={(data) => {
          console.log(data)
        }}
        buttons={() => <></>}
        schema={[
          {
            content: [
              {
                lg: 3,
                md: 4,
                sm: 12,
                type: "custom",
                name: "analises-ativas",
                styles: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                },
                customComponent: ({ control, name, error }) => (
                  <RadioButtons
                    name={name}
                    error={error}
                    formControlStyle={{
                      width: "auto"
                    }}
                    schama={[
                      {
                        value: 1,
                        label: "Ativo",
                      },
                      {
                        value: 2,
                        label: "Todos",
                      },
                    ]}
                    control={control}
                    label="Filtrar Por"
                    rules={{
                      required: {
                        value: true,
                        message: "Necessário status"
                      }
                    }}
                  />
                ),
              },
              {
                lg: 4,
                md: 4,
                sm: 12,
                type: "custom",
                name: "TIPODOCUMENTO",
                styles: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                },
                customComponent: ({ control, name, error }) => (
                  <RadioButtons
                    name={name}
                    error={error}
                    formControlStyle={{
                      width: "auto"
                    }}
                    schama={[
                      {
                        value: 1,
                        label: "PGR",
                      },
                      {
                        value: 2,
                        label: "PCMSO",
                      },
                      {
                        value: 3,
                        label: "Laudos",
                      },
                    ]}
                    control={control}
                    label="Filtrar Por"
                    rules={{
                      required: {
                        value: true,
                        message: "Necessário tipo de documento"
                      }
                    }}
                  />
                ),
              },
              {
                lg: 4,
                md: 3,
                sm: 12,
                type: "custom",
                styles: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                },
                customComponent: () => (
                  <InputDef
                    style={{
                      width: "90%"
                    }}
                    label="Pesquisar"
                    variant="outlined"
                    onChange={(e) => {
                      setFilter(e?.target?.value.length > 0 ? e?.target?.value : null)
                    }}
                  />
                )
              },
              {
                lg: 1,
                md: 1,
                sm: 1,
                type: "custom",
                styles: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start"
                },
                customComponent: ({ handleSubmit }) => (
                  <IconButton
                    onClick={handleSubmit(submit)}
                  >
                    {
                      loading
                        ? <CircularProgress />
                        : <Search />
                    }
                  </IconButton>
                )
              }
            ],
          },
        ]}
      />
      {
        responseData.length > 0 && (
          <>
            <div style={{ width: '100%', marginTop: "10px" }} className={classes.dataGridContainer}>
              <DataGrid
                autoHeight
                pageSize={10}
                columns={[
                  {
                    width: 200,
                    field: 'POSNOME',
                    headerName: 'Posto de Trabalho',
                    headerClassName: 'MuiDataGrid-columnHeader'
                  },
                  {
                    flex: 1,
                    field: 'ARQDESCRICAO',
                    headerName: 'Nome do Arquivo',
                    headerClassName: 'MuiDataGrid-columnHeader'
                  },
                  {
                    width: 250,
                    type: "date",
                    field: 'ANADATAREALIZACAO',
                    headerName: 'Data de Realização ',
                    headerClassName: 'MuiDataGrid-columnHeader',
                  },
                  {
                    width: 250,
                    type: "date",
                    field: 'ANADATAVALIDADE',
                    headerName: 'Data de Validade',
                    headerClassName: 'MuiDataGrid-columnHeader',
                  },
                  {
                    width: 100,
                    sortable: false,
                    align: "center",
                    headerName: ' ',
                    field: 'actions',
                    headerAlign: "center",
                    renderCell: (params) => (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          window.open(params.row.URL, "_blank")
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ),
                    disableColumnMenu: true
                  },
                ]}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                rows={
                  responseData
                    .map(item => (
                      {
                        ...item,
                        id: item.ARQGUID,
                        ANADATAVALIDADE: item.ANADATAVALIDADE
                          ? new Date(item.ANADATAVALIDADE)
                          : null,
                        ANADATAREALIZACAO: item.ANADATAREALIZACAO
                          ? new Date(item.ANADATAREALIZACAO)
                          : null,
                        dateRealizacaoFormated: item.ANADATAREALIZACAO
                          ? new Date(item.ANADATAREALIZACAO).toLocaleDateString()
                          : null,
                        dateValidadeFormated: item.ANADATAVALIDADE
                          ? new Date(item.ANADATAVALIDADE).toLocaleDateString()
                          : null
                      }
                    ))
                    .filter(item => {
                      let filterStatus = false
                      if (filter?.length === 0 || !filter) return true
                      Object.keys(item).forEach(item2 => {
                        if (typeof item[item2] != "string") return
                        if (removerAcentos(item[item2]?.replace(/ /g, '')?.toLowerCase())?.includes(removerAcentos(filter?.replace(/ /g, '')?.toLowerCase()))) filterStatus = true
                      })
                      return filterStatus
                    })
                }
              />
            </div>
          </>
        )
      }
    </div>
  );
}

Documentos.access = "03004002-V";
