import Employee from "../../../../views/employee";
import Schedules from "../../../../views/finalizar";
//import HomeScreen from "../../../../views/home";
import Prestador from "../../../../views/prestador";
import TrocarSenha from "../../../../views/trocarSenha";
import examesRealizados from "../../../../views/examesRealizados";
import Funcionarios from "../../../../views/funcionarios";
import FuncionariosAdicionar from "../../../../views/funcionariosAdicionar";
import FuncionariosEditar from "../../../../views/funcionariosEditar";
import Relatorios from "../../../../views/relatorios";
import examesPendentes from "../../../../views/examesPendentes";
import verAgendamentos from "../../../../views/verAgendamentos";
import GerienciarSolicitacoes from "../../../../views/GerenciarSolicitações/index";
//import ESocial from "../../../../views/eSocial/index";
import Documentos from "../../../../views/Documentos/index";
//import novaSenha from "../../../../views/novaSenha";
import ConfigESocial from "../../../../views/eSocial/ConfigESocial";
import EsocialDashboard from "../../../../views/esocialDashboard";
import EsocialDashboardFiltros from "../../../../views/esocialDashboard/filtros";
import EsocialDashboardProcessos from "../../../../views/esocialDashboard/processos";
import EsocialDashboardProcessosItem from "../../../../views/esocialDashboard/processos/item";
import EsocialDashboardProcessosItemLog from "../../../../views/esocialDashboard/processos/item/log";
import centralNotificacoes from "../../../../views/centralNotificacoes";
import termosUso from "../../../../views/termosUso";
import Inicio from "../../../../views/Inicio";
import Financeiro from "../../../../views/financeiro";
//import PlanosDeAcao from '../../../../views/planosDeAcao';
import PlanoEditar from "../../../../views/planosDeAcao/editar";
import PlanoEditarEmpresa from "../../../../views/Cronogramas/empresa";
import PlanoEditarFator from "../../../../views/Cronogramas/fator";
import HistoricoAdicionar from "../../../../views/historicoAdicionar";
import HistoricoEditar from "../../../../views/historicoEditar";
import ESocialDashboard from "../../../../views/esocialDashboardNew/index";
import Cronogramas from "../../../../views/Cronogramas";
import GerenciadorArquivos from "../../../../views/gerenciadorArquivos";
import CatItem from "../../../../views/cat/item";
import ClinicoFuncionarioAtestadoItem from "../../../../views/atestado/item";

let all = "/pages";

interface IComponent extends JSX.Element {
  access?: string;
}

interface IRoutes {
  path?: string;
  exact?: boolean;
  name?: string;
  component?: (props?: any) => IComponent;
  privateRoute?: boolean;
  credentiaisRequired?: string[];
  type?: string;
  access?: string;
}

const routes: IRoutes[] = [
  // {
  //   path: "/:token/:date/:ambient/:host/:companyDocument/:companyName/:companyUserName/:destination/:agendamentoRapido?",
  //   exact: true,
  //   name: "home",
  //   component: HomeScreen,
  //   privateRoute: false,
  // },
  //{
  // path: all + "/eSocial",
  //exact: true,
  //name: "eSocial",
  //component: ESocial,
  //privateRoute: false,
  //},
  {
    path: all + "/eSocial",
    exact: true,
    name: "eSocial",
    component: ESocialDashboard,
    privateRoute: false,
    credentiaisRequired: ["ESOCIAL"],
  },
  {
    path: "/termosUso",
    exact: true,
    name: "Termos",
    component: termosUso,
    privateRoute: false,
  },
  {
    path: all + "/esocial/dashboard/:tipo?",
    exact: true,
    name: "eSocial",
    component: EsocialDashboard,
    privateRoute: true,
  },
  {
    path: all + "/esocial/dashboard/:tipo/filtros",
    exact: true,
    name: "eSocial",
    component: EsocialDashboardFiltros,
    privateRoute: true,
  },
  {
    path: all + "/esocial/dashboard/:tipo/items",
    exact: true,
    name: "eSocial",
    component: EsocialDashboardProcessos,
    privateRoute: true,
  },
  {
    path: all + "/esocial/dashboard/:tipo/items/:processoid",
    exact: true,
    name: "eSocial",
    component: EsocialDashboardProcessosItem,
    privateRoute: true,
  },
  {
    path: all + "/esocial/dashboard/:tipo/items/:processoid/:logid",
    exact: true,
    name: "eSocial",
    component: EsocialDashboardProcessosItemLog,
    privateRoute: true,
  },
  {
    path: all + "/Configuração",
    exact: true,
    name: "eSocial",
    component: ConfigESocial,
    privateRoute: true,
    credentiaisRequired: ["ESOCIAL"],
  },
  {
    path: all + "/prestador",
    exact: true,
    name: "prestador",
    component: Prestador,
    privateRoute: true,
  },
  {
    path: all + "/funcionario",
    exact: true,
    name: "funcionario",
    component: Employee,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO"],
  },
  {
    path: all + "/finalizar",
    exact: true,
    name: "funcionario",
    component: Schedules,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO"],
  },
  {
    path: all + "/trocarSenha",
    exact: true,
    name: "funcionario",
    component: TrocarSenha,
    privateRoute: true,
  },
  {
    path: all + "/examesRealizados",
    exact: true,
    name: "funcionario",
    component: examesRealizados,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "RELATORIO"],
    type: "OR",
  },
  {
    path: all + "/GerenciarSolicitações",
    exact: true,
    name: "funcionario",
    component: GerienciarSolicitacoes,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO"],
  },
  {
    path: all + "/Documentos",
    exact: true,
    name: "funcionario",
    component: Documentos,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "RELATORIO"],
    type: "OR",
    access: "03004002-V",
  },
  {
    path: all + "/examesPendentes",
    exact: true,
    name: "funcionario",
    component: examesPendentes,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "RELATORIO"],
    type: "OR",
  },
  {
    path: all + "/VerAgendamentos",
    exact: true,
    name: "funcionario",
    component: verAgendamentos,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO"],
  },
  /*
  {
    path: all + "/RedefinirSenha/:token",
    exact: true,
    name: "funcionario",
    component: novaSenha,
    privateRoute: true,
  },
  */
  {
    path: all + "/centralNotificacoes",
    exact: true,
    name: "central de notificações",
    component: centralNotificacoes,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO"],
  },
  {
    path: all,
    exact: true,
    name: "inicio",
    component: Inicio,
    privateRoute: true,
  },
  {
    path: all + "/financeiro",
    exact: true,
    name: "Financeiro",
    component: Financeiro,
    privateRoute: true,
    credentiaisRequired: ["FINANCEIRO"],
    access: "03004001-V",
  },
  {
    path: all + "/funcionarios",
    exact: true,
    name: "Funcionários",
    component: Funcionarios,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "ESOCIAL"],
    type: "OR",
  },
  {
    path: all + "/adicionarFuncionario",
    exact: true,
    name: "Funcionários",
    component: FuncionariosAdicionar,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "ESOCIAL"],
    type: "OR",
  },
  {
    path: all + "/editarFuncionario/:id",
    exact: true,
    name: "Funcionários",
    component: FuncionariosEditar,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "ESOCIAL"],
    type: "OR",
  },
  {
    path: all + "/editarFuncionario/:id/cat/:idCat",
    exact: true,
    name: "Funcionários",
    component: CatItem,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "ESOCIAL"],
    type: "OR",
  },
  {
    path: all + "/editarFuncionario/:id/atestado/:idAtestado",
    exact: true,
    name: "Funcionários",
    component: ClinicoFuncionarioAtestadoItem,
    privateRoute: true,
    credentiaisRequired: ["AGENDAMENTO", "ESOCIAL"],
    type: "OR",
  },
  // {
  //   path: all + "/planosDeAcao",
  //   exact: true,
  //   name: "Planos de ação",
  //   component: PlanosDeAcao,
  //   privateRoute: true,
  // },
  {
    path: all + "/cronogramas/editar/:empCodigo/:anaCodigo/empresa/:plCodigo",
    exact: true,
    name: "Planos de ação",
    component: PlanoEditarEmpresa,
    privateRoute: true,
  },
  {
    path: all + "/cronogramas/editar/:empCodigo/:anaCodigo/fator/:plCodigo",
    exact: true,
    name: "Planos de ação",
    component: PlanoEditarFator,
    privateRoute: true,
  },
  // deleta em baixo
  {
    path: all + "/planosDeAcao/editar/:plCodigo",
    exact: true,
    name: "Planos de ação",
    component: PlanoEditar,
    privateRoute: true,
  },
  {
    path:
      all +
      "/planosDeAcao/:empCodigo/:anaCodigo/:plCodigo/adicionarHistorico/:type",
    exact: true,
    name: "Planos de ação",
    component: HistoricoAdicionar,
    privateRoute: true,
  },
  {
    path:
      all +
      "/planosDeAcao/:empCodigo/:anaCodigo/:plCodigo/editarHistorico/:hisCodigo/:type",
    exact: true,
    name: "Planos de ação",
    component: HistoricoEditar,
    privateRoute: true,
  },
  {
    path: all + "/cronogramas",
    exact: true,
    name: "Cronogramas",
    component: Cronogramas,
    privateRoute: true,
  },
  {
    path: all + "/relatorios",
    exact: true,
    name: "Relatórios",
    component: Relatorios,
    privateRoute: true,
    credentiaisRequired: ["GERALE"],
  },
  {
    path: all + "/gerenciadorArquivos",
    exact: true,
    name: "Gerenciador de Arquivos",
    component: GerenciadorArquivos,
    privateRoute: false,
  },
];

export default routes;
