import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";

import useCrudStyles from "./styles";

export interface SchamaCurd<T> {
  label: string;
  content: keyof T;
  formatFun?: (data: T[keyof T] | T) => string | JSX.Element;
  formatAll?: boolean;
  type?: "number" | "string" | "date";
}

export interface CrudModel<T> {
  content?: T[];
  title?: string | JSX.Element;
  schema?: SchamaCurd<T>[];
  onEditonMouseDown?: (data: T, e: any) => void;
  onEditContextMenu?: (data: T, e: any) => void;
  onEditDoubleClick?: (data: T) => void;
  onEditClickCell?: (data: T) => void;
  edit?: boolean;
  actions?: any;
  selected?: T;
  selectedProp?: keyof T;
  id?: string | number;
  showEmptyCard?: boolean;
  cellName?: string;
  initialPropertyToOrder?: string;
  selecteds?: any[];
  setSelecteds?: any;
  blockedProp?: string;
  isItemSelect?: any;
  isDisabled?: boolean;
}

export default function CrudSelect<T>({
  content,
  title,
  schema,
  edit = false,
  actions,
  selected,
  selectedProp,
  id,
  onEditClickCell,
  cellName,
  showEmptyCard = true,
  initialPropertyToOrder,
  selecteds,
  setSelecteds,
  blockedProp,
  isItemSelect,
  isDisabled = false,
}: CrudModel<T>) {
  const classes = useCrudStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>(initialPropertyToOrder || "");

  const isSelected = isItemSelect
    ? isItemSelect
    : (id) => selecteds.indexOf(id) !== -1;

  const onEditClick = (data: any) => {
    if (blockedProp && data[blockedProp]) {
      return toast.error("Não e possível selecionar novamente");
    }
    const selectedIndex = isItemSelect
      ? selecteds.findIndex((item) => item.EXACODIGO === data.EXACODIGO)
      : selecteds.indexOf(data[selectedProp]);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(
        selecteds,
        selectedProp ? data[selectedProp] : data
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selecteds.slice(1));
    } else if (selectedIndex === selecteds.length - 1) {
      newSelected = newSelected.concat(selecteds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selecteds.slice(0, selectedIndex),
        selecteds.slice(selectedIndex + 1)
      );
    }

    setSelecteds(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = content?.map((item) =>
        selectedProp ? item[selectedProp] : item
      );
      setSelecteds(newSelecteds);
      return;
    }
    setSelecteds([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event) => {
    handleRequestSort(event, property);
  };

  const rowsCount = content?.length;

  function getComparator(order, orderBy) {
    const taskIndex = schema.findIndex((item) => item.content === orderBy);
    const task = schema[taskIndex];
    if (task?.type === "date") {
      return order === "desc"
        ? (a, b) => dateComparator(a, b, orderBy)
        : (a, b) => -dateComparator(a, b, orderBy);
    } else if (task?.type === "number") {
      return order === "desc"
        ? (a, b) => numberComparator(a, b, orderBy)
        : (a, b) => -numberComparator(a, b, orderBy);
    } else {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
  }

  function numberComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString().toLowerCase() <
      a[orderBy]?.toString().toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString().toLowerCase() >
      a[orderBy]?.toString().toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  const dateComparator = (a, b, orderBy) => {
    if (moment(new Date(b[orderBy])).isBefore(new Date(a[orderBy]))) {
      return -1;
    }
    if (moment(new Date(b[orderBy])).isAfter(new Date(a[orderBy]))) {
      return 1;
    }
    return 0;
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      try {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      } catch (err) {
        return a[1] - b[1];
      }
    });
    return stabilizedThis.map((el) => el[0]);
  }

  if (!content) {
    return (
      <div
        className={classes.rootContent}
        style={{ display: "flex", flexDirection: "column", gap: 15 }}
      >
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
        <Skeleton style={{ height: 80 }} />
      </div>
    );
  }

  if (content?.length <= 0) {
    return (
      <>
        <div className={classes.header}>
          {title ? (
            typeof title === "string" ? (
              <Typography className={classes.title} variant="h5">
                {title}
              </Typography>
            ) : (
              title
            )
          ) : (
            <div></div>
          )}
          {actions && <div>{actions}</div>}
        </div>
        {showEmptyCard && (
          <div
            style={{ width: "100%", marginTop: "25px", textAlign: "center" }}
          >
            <p>Não foram encontrados resultados para sua busca.</p>
          </div>
        )}
        <br />
        <br />
      </>
    );
  }

  return (
    <div className={classes.rootContent}>
      <div
        style={{
          display: "flex",
          justifyContent: actions ? "space-around" : "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ marginTop: 12 }}>
          {typeof title === "string" ? (
            <Typography variant="h6">{title}</Typography>
          ) : (
            title
          )}
          <br />
        </div>
        {actions && <div className={classes.button}>{actions}</div>}
      </div>

      <TableContainer>
        <Table aria-label="table" size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  disabled={isDisabled}
                  color="primary"
                  indeterminate={
                    selecteds.length > 0 && selecteds.length < rowsCount
                  }
                  checked={rowsCount && selecteds.length === rowsCount}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all items",
                  }}
                />
              </TableCell>
              {schema.map((item, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sortDirection={orderBy === item.content ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === item.label}
                    direction={orderBy === item.content ? order : "asc"}
                    onClick={createSortHandler(item.content as string)}
                  >
                    <b>{item.label}</b>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-cy="table-body">
            {content &&
              stableSort(content, getComparator(order, orderBy))
                ?.slice(page * 15, page * 15 + 15)
                .map((item, indexPage) => {
                  const isItemSelected = isSelected(
                    selectedProp ? item[selectedProp] : item
                  );
                  return (
                    <TableRow
                      selected={
                        selectedProp &&
                        selected &&
                        item[selectedProp] === selected[selectedProp]
                      }
                      className={edit ? classes.tableRow : classes.empty}
                      hover={edit}
                      onClick={() => {
                        if (!isDisabled && edit && onEditClick)
                          onEditClick(item);
                      }}
                      key={id ? item[id] : indexPage}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={isDisabled}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": "labelId",
                          }}
                        />
                      </TableCell>
                      {schema.map((schemaItem: any, index) => {
                        return (
                          <TableCell
                            data-cy={`listTile-${schemaItem.content}-${indexPage}`}
                            key={index}
                            align="left"
                            onClick={() => {
                              if (
                                edit &&
                                onEditClickCell &&
                                schemaItem.content === cellName
                              )
                                onEditClickCell(item);
                            }}
                          >
                            {schemaItem.formatFun
                              ? schemaItem.formatFun(
                                  schemaItem.formatAll
                                    ? item
                                    : item[schemaItem.content]
                                )
                              : item[schemaItem.content]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={content ? content.length : 0}
        rowsPerPage={15}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={() => {}}
      />
    </div>
  );
}
