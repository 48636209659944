import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import DataShow from "../../../../shared/Item/DataShow";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import React, { useEffect } from "react";
import { api } from '../../../../shared/services/api'
import { useHistory, useParams } from "react-router-dom";
import { useStyles } from "../..";

export default function EsocialDashboardProcessosItem(props) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ tipo?: string; processoid: string }>();

  useEffect(() => {
    async function loadSchedule() {
      try {

        const teste = localStorage.getItem('Token_Key');
        if (teste === props.match.params.token) {
          const req = await api();
          await req.get(`/users/token`)
        } else {
          history.push('/')
        }
      } catch {
        history.push('/')
      }
    }

    loadSchedule();
  }, [history, props.match.params.token]);

  const redirectBack = () =>
    history.push(`/esocial/dashboard/${params.tipo}/items`);

  const redirectLog = (logId) =>
    history.push(
      `/esocial/dashboard/${params.tipo}/items/${params.processoid}/${logId}`
    );

  return (
    <div style={{ maxWidth: 1366, margin: "0px auto" }}>
      <Box display="flex">
        <IconButton onClick={redirectBack} color="primary">
          <ArrowBackIcon />
        </IconButton>
        <div style={{ width: 30 }}></div>
        <Typography variant="h4">Item</Typography>
      </Box>
      <div style={{ height: 30 }}></div>

      <div style={{ paddingLeft: 16 }}>
        <DataShow<any>
          data={{
            CPF: "508.762.948-24",
            Nome: "Carlos Eduardo Alves do Nascimento",
            Tipo: "S2220 - Monitoramento da Saúde do Trabalhador",
          }}
          schema={[
            [
              {
                content: "CPF",
                label: "CPF",
                size: 4,
              },
              {
                content: "Nome",
                label: "Nome",
                size: 6,
              },
            ],
            [
              {
                content: "Tipo",
                label: "Tipo",
                size: 12,
              },
            ],
            [
              {
                content: "Status",
                label: "Status",
                size: 12,
              },
            ],
          ]}
        />
      </div>
      <div style={{ height: 30 }}></div>
      <Divider />
      <div style={{ height: 30 }}></div>
      <Typography variant="h5" style={{ paddingLeft: 16 }}>
        Historico
      </Typography>
      <div style={{ height: 5 }}></div>
      <Typography
        style={{ paddingLeft: 16 }}
        color="textSecondary"
        variant="caption"
      >
        Atividades realizadas nesse registro
      </Typography>
      <div style={{ height: 30 }}></div>
      <List>
        <ListSubheader component="div" id="nested-list-subheader">
          Março 2021
        </ListSubheader>
        {[1, 2, 3, 4].map((log) => (
          <ListItem
            onClick={() => redirectLog(log)}
            className={classes.listItem}
            button
          >
            <ListItemText
              primary={
                <span style={{ fontSize: 14 }}>
                  Consulta do processo <b>X</b> retornou statatus{" "}
                  <b style={{ color: "#667700" }}>Processando</b>
                  no dia <b>dd/MM/yyyy</b>
                </span>
              }
            />
          </ListItem>
        ))}
        <br />
        <ListSubheader component="div" id="nested-list-subheader">
          Fevereiro 2021
        </ListSubheader>
        {[1, 2, 3, 4].map((log) => (
          <ListItem
            onClick={() => redirectLog(log)}
            className={classes.listItem}
            button
          >
            <ListItemText
              primary={
                <span style={{ fontSize: 14 }}>
                  Consulta do processo <b>X</b> retornou statatus{" "}
                  <b style={{ color: "#667700" }}>Processando</b>
                  no dia <b>dd/MM/yyyy</b>
                </span>
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
