import { Box, MenuItem, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "../../../shared";
import InputDate from "../../../shared/Form/Fields/Date";
import { api } from "../../../shared/services/api";
import { useFetchSelect } from "../controller";
import { Loading } from "../components";

type Params = {
  empCodigo: string;
  anaCodigo: string;
  plCodigo: string;
};

export default function EditarDados() {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const params = useParams<Params>();

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(
          `/analiserisco/empresas/${params.empCodigo}/analises/${params.anaCodigo}/planosacao/${params.plCodigo}`
        );
        setData(res.data);
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { options: riscos } = useFetchSelect(
    `/analiserisco/empresas/${params.empCodigo}/analises/${params.anaCodigo}/planosacao/riscos`,
    "",
    "ID",
    (item) => `${item.RISDESCRICAO} - ${item.AGTDESCRICAO}`
  );

  const { options: status } = useFetchSelect(
    `/tecnico/${params.empCodigo}/tarefas`,
    "TARDESCRICAO",
    "TARCODIGO"
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box className="no-print" style={{ margin: 0, padding: 0 }}>
        <Typography
          className="no-print"
          align="left"
          style={{
            marginBottom: "35px",
            marginLeft: "10px",
            fontWeight: "bold",
            fontSize: "15pt",
          }}
          color="primary"
        >
          Dados
        </Typography>
        <Form
          defaultValues={data}
          spacing={"8px 8px"}
          onSubmit={(data) => {}}
          buttons={() => {}}
          schema={[
            {
              title: "Riscos",
              content: [
                {
                  lg: 12,
                  label: "Risco",
                  name: "IDRISCO",
                  type: "select",
                  rules: { required: "Selecione um risco" },
                  disabled: true,
                  options: riscos.map((item) => (
                    <MenuItem value={item.code}>{item.label}</MenuItem>
                  )),
                },
              ],
            },
            {
              title: "Dados",
              content: [
                {
                  lg: 12,
                  label: "Título",
                  name: "PLTITULO",
                  disabled: true,
                },
                {
                  lg: 6,
                  label: "Setor",
                  name: "SETNOME",
                  disabled: true,
                },
                {
                  lg: 6,
                  label: "GHE",
                  name: "GHENOME",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Passo",
                  name: "PLSTATUS",
                  type: "select",
                  disabled: true,
                  options: status.map((item) => (
                    <MenuItem value={item.code}>{item.label}</MenuItem>
                  )),
                  rules: { required: "Selecione um status" },
                },
                {
                  lg: 12,
                  label: "Intervenção",
                  name: "PLMEDPREVENCAO",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Email",
                  name: "PLEMAIL",
                  disabled: true,
                },
                {
                  lg: 6,
                  name: "PLDTLEVANTAMENTO",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Levantamento"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data Inicial"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDTPREVISTA",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Prevista"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 6,
                  name: "PLDTMAXIMA",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Máxima"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  lg: 12,
                  label: "Responsável",
                  name: "PLRESPONSAVEL",
                  disabled: true,
                },
                {
                  lg: 12,
                  label: "Agente Nocivo",
                  name: "PLAGTNOVICO",
                  disabled: true,
                  inputPropsAdittional: {
                    multiline: true,
                    rows: 6,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  );
}
