import {
  Typography,
  Box,
  IconButton,
  Divider,
  ListItem,
  List,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Crud } from "../../../shared";
import LoadingButton from "../../../shared/Button/LoadingButton";
import { api } from "../../../shared/services/api";
import { formatDate } from "../../../utils/formats";
import { Loading } from "../components";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";

type Params = {
  empCodigo: string;
  anaCodigo: string;
  plCodigo: string;
};

interface IDialogArquivos {
  open: boolean;
  onClose(): void;
  files: null | any[];
  deleteFile: any;
}

function DialogArquivos({ open, onClose, files, deleteFile }: IDialogArquivos) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        margin: "auto",
      }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        style={{ padding: "10px 0px 10px 10px" }}
      >
        Anexos
      </DialogTitle>
      <Divider />
      <List style={{ width: "100%", minWidth: "300px" }}>
        {files.map((file) => {
          return (
            <>
              <ListItem
                key={file.ARQGUID}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0px",
                  padding: "0px 10px 0px 15px",
                }}
              >
                <div>
                  <a href={file.URL} download style={{ fontSize: "14px" }}>
                    <strong>{file.ARQDESCRICAO}</strong>
                  </a>
                </div>
                <IconButton
                  color="primary"
                  onClick={deleteFile(file.ARQCODIGO)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Dialog>
  );
}

export default function HistoricoDeAcoes() {
  const history = useHistory();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  let {
    empCodigo: idempresa,
    anaCodigo: idanalise,
    plCodigo: idplanoacao,
  } = useParams<Params>();
  idplanoacao = idplanoacao.split("?")[0];
  const selectedRef = useRef<number | null>(null);
  const filesRef = useRef<any>(null);

  const deleteFile = (idcodigoarquivo) => async () => {
    const req = await api();
    try {
      await req.delete(
        `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico/${selectedRef.current}/arquivos/${idcodigoarquivo}`
      );
      toast.success("Anexo deletado com sucesso");
    } catch (err) {
      toast.error(err.response?.data?.message || "Erro ao deletar o anexo");
    } finally {
      onClose();
    }
  };

  const onOpen = async (idhistorico) => {
    selectedRef.current = idhistorico;
    const req = await api();
    const { data } = await req.get(
      `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico/${idhistorico}/arquivos`
    );
    filesRef.current = data;
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const res = await req.get(
        `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico`
      );
      setData(res.data);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: 1, margin: "0 10px" }}>
      <Box style={{ margin: 0, padding: 0 }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            align="left"
            style={{
              marginBottom: "20px",
              fontWeight: "bold",
              fontSize: "15pt",
            }}
            color="primary"
          >
            Histórico de ações
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            type="button"
            style={{ marginBottom: "10px", width: "150px" }}
            onClick={() =>
              history.push(
                `/pages/planosDeAcao/${idempresa}/${idanalise}/${idplanoacao}/adicionarHistorico/fator`
              )
            }
          >
            Novo
          </LoadingButton>
        </Box>
      </Box>
      {filesRef.current && (
        <DialogArquivos
          open={open}
          onClose={onClose}
          files={filesRef.current}
          deleteFile={deleteFile}
        />
      )}
      {Array.isArray(data) && data.length > 0 ? (
        <Crud
          content={data}
          edit
          onEditClick={(data) =>
            history.push(
              `/pages/planosDeAcao/${idempresa}/${idanalise}/${idplanoacao}/editarHistorico/${data.HISCODIGO}/fator`
            )
          }
          schema={[
            {
              content: "HISTITULO",
              label: "Título",
            },
            {
              content: "HISRESPONSAVEL",
              label: "Responsável",
            },
            {
              content: "HISDTINICIAL",
              label: "Data inicial",
              formatFun: formatDate,
            },
            {
              content: "HISDTFINAL",
              label: "Data final",
              formatFun: formatDate,
            },
            {
              content: "ANEXOS",
              label: "Anexos",
              formatAll: true,
              formatFun: (data: any) => {
                return data.ANEXOS ? (
                  <IconButton
                    style={{
                      padding: "10px",
                      display: "block",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onOpen(data.HISCODIGO);
                    }}
                  >
                    <AttachFileIcon fontSize="small" />
                  </IconButton>
                ) : null;
              },
            },
          ]}
        />
      ) : (
        <h3 style={{ textAlign: "center", marginTop: 25 }}>
          Não existem históricos de ações pra essa plano de ação.
        </h3>
      )}
    </Box>
  );
}
