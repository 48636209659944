import { useMemo } from "react";

export const useQuery = () => {
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export function valida_cpf(cpf: string) {
  let numeros, digitos, soma, i, resultado, digitos_iguais: any;
  digitos_iguais = 1;
  if (cpf.length < 11) return false;
  for (i = 0; i < cpf.length - 1; i++)
    if (cpf.charAt(i) != cpf.charAt(i + 1)) {
      digitos_iguais = 0;
      break;
    }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9);
    digitos = cpf.substring(9);
    soma = 0;

    for (i = 10; i > 1; i--) soma += numeros.charAt(10 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    numeros = cpf.substring(0, 10);
    soma = 0;
    for (i = 11; i > 1; i--) soma += numeros.charAt(11 - i) * i;
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  } else return false;
}

export const b64toBlob = (base64) => fetch(base64).then((res) => res.blob());

export const getDateTime = (value: string) => {
  const date = new Date();
  if (!value) return null;
  const [hours, minutes] = value.split(":");
  date.setHours(Number(hours), Number(minutes));
  return date;
};

export const isValidCnpjOrCpf = (v: any) => {
  if (!v) return "Campo obrigatório";
  const newitem = v.toString();
  if (!valida_cpf(newitem)) return "CPF inválido";
  return undefined;
};
