import { MenuItem } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { api } from "../../../shared/services/api";

interface CompanyDepartmentDomain {
  SETCODIGO: number;
  SETNOME: string;
  POSNOME: string;
}

interface CompanyFunctionDomain {
  FCOCODIGO: number;
  FCONOME: string;
  GHENOME: string;
}

export const useSelect = (reverse = false, solicitations = [], openFunAdd?) => {
  //Setor
  const [categeSocial, setCategeSocial] = React.useState<
    JSX.Element[] | null
  >();
  const [tipoEnderecoOptions, settipoEnderecoOptions] = React.useState<
    JSX.Element | JSX.Element[]
  >();
  const [options, setOptions] = React.useState<{label: string; value: number}[]>([])
  const [setores, setSetores] = React.useState<CompanyDepartmentDomain[]>([]);
  const [func, setFunc] = React.useState<CompanyFunctionDomain[]>([]);
  const [funcao, setFuncao] = React.useState<any>();
  const funcRef = useRef<any>();

  useEffect(() => {
    async function getApi() {
      const req = await api();
      await req.get("/esocial/categorias").then((response) => {
        let fTtemp = response.data.map((item, index) => (
          <MenuItem key={index} value={item.ESOCCATEGCODIGO}>
            {item.ESOCCATEGDESCRICAO}
          </MenuItem>
        ));
        setCategeSocial(fTtemp);
      });
    }
    getApi();
  }, []);

  useEffect(() => {
    if (solicitations.length > 0) {
      let solicitationsFormated = []
      solicitations.forEach(( item ) => {
        if(!funcao.find(item2 => item.IdTemp == item2.value)) {
          solicitationsFormated.push({
            label: item?.Title,
            ghenome: item.Title,
            fconome: item.Title,
            value: item.IdTemp
          })
        }
      })
      const fTtemp = [
        ...funcao,
        ...solicitationsFormated
      ];
      setFuncao(fTtemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solicitations]);

  const getSetores = async () => {
    const req = await api();
    await req
      .get<CompanyDepartmentDomain[]>(`/agenda/setores`)
      .then((response) => {
        setSetores(response.data);
        settipoEnderecoOptions(
          response.data.map((setorItem, index) => (
            <MenuItem
              key={index}
              data-other={setorItem.SETNOME}
              value={
                reverse
                  ? `${setorItem.SETNOME}|${setorItem.SETCODIGO}|${setorItem.POSNOME}`
                  : setorItem.SETCODIGO
              }
            >
              {setorItem.POSNOME + " / " + setorItem.SETNOME}
            </MenuItem>
          ))
        );
        setOptions(response.data.map(item => (
          {
            label: `${item.POSNOME}/${item.SETNOME}`,
            posnome: item.POSNOME,
            setnome: item.SETNOME,
            value: item.SETCODIGO
          }
        )))
      });
  };

  useEffect(() => {
    settipoEnderecoOptions(<MenuItem value="loading">Carregando...</MenuItem>);    

    getSetores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeFun = async (id: string, done: () => void = () => {}) => {
    funcRef.current = id;
    const req = await api();
    await req
      .get<CompanyFunctionDomain[]>(`/agenda/setores/${id}/funcoes`)
      .then((response) => {
        setFunc(response.data);
        setFuncao(response.data.map(item => (
          {
            label: `${item.GHENOME}/${item.FCONOME}`,
            ghenome: item.GHENOME,
            fconome: item.FCONOME,
            value: item.FCOCODIGO
          }
        )));
        done();
      });
  };

  return {
    func,
    funcao,
    setores,
    options,
    funcRef,
    changeFun,
    categeSocial,
    tipoEnderecoOptions
  };
};
