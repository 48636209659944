export interface Funcionario {}

export const addFunc = (data: any) => {
  return {
    type: "ADD_FUNC",
    payload: data
  }
}

export const removeFunc = (data: any) => {
  return { type: "REMOVE_FUNC", payload: data };
}

export default function funcionarioReducer (state: any = {}, action: any) {
  switch (action.type) {
    case "ADD_FUNC":
      return { cpf: action.payload };
    case "REMOVE_FUNC":
      return { cpf: action.payload };
    default:
      return state;
  }
}
