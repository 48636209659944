import moment from "moment";
import { useSchedule } from "..";
import DialogExam from "./DialogExam";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import { isBefore, sub } from "date-fns";
import { useSetepper } from "../Stepper";
import { useHistory } from 'react-router-dom';
import CloseIcon from "@material-ui/icons/Close";
import { api } from "../../../shared/services/api";
import CrudSelect from "../../../shared/CrudSelect";
import { Fragment, useEffect, useState } from "react";
import { useStatePersistent } from "../../../contexts/statesPersistent";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Paper } from "@material-ui/core";

export interface ExamDomain {
  EXACODIGO: number;
  EXAATETIPO: number | null; //EXAATETIPO
  EXANOMECOMERCIAL: string;
  EXAPERIODICIDADE: number | null;
  FEADTENCAMINHADO: string;
  EMPCODIGO: number | null;
  EXAADMISSIONAL: number | null;
  EXAPERIODICO: number | null;
  EXADEMISSIONAL: number | null;
  EXAVALORCLIENTE: number | null;
  NUCEXAMESPORTAL: number | null;
  //UI
  checked: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    formControl: {
      padding: theme.spacing(2),
    },
    moreExames: {
      padding: theme.spacing(2),
    },
    alertExames: {
      margin: theme.spacing(2),
      marginTop: 0,
    },
  })
);

export default function Exams() {

  const classes = useStyles();
  const { setActiveStep } = useSetepper();
  const { isComplementar, isClinico } = useStatePersistent();
  const { schedule, setSchedule } = useSchedule();

  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState<ExamDomain[] | null>();
  const [examsDisabled, setExamsDisabled] = useState(false);
  const [description, setDescription] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [examsAdd, setExamsAdd] = useState<ExamDomain[] | null>();

  const history = useHistory()

  useEffect(() => {
    const getExams = async () => {
      const fcoCodigoOld = localStorage.getItem("FCOCODIGO")
      const req = await api();
      const params = {
        params: {
          TIPOPESQUISA: "P",
          TIPOEXAME: schedule.ExamType.CODIGO,
          "somente-clinico": isClinico ? 1 : 0,
          FUNCODIGO: schedule.Employee.FUNCODIGO,
          "somente-complementares": isComplementar ? 1 : 0,
        }
      }

      const exemesAntigos = await req
        .get<ExamDomain[]>(
          `/agenda/setores/${schedule.Employee.SETCODIGO}/funcoes/${fcoCodigoOld}/exames`,
          params
        ).then((response) => {

          return response.data
        })
        .catch((error) => {
          console.log(error)
        });
      await req
        .get<ExamDomain[]>(
          `/agenda/setores/${schedule.Employee.SETCODIGO}/funcoes/${schedule.Employee.FCOCODIGO}/exames`,
          params
        )
        .then((response) => {
          setExams(response.data);
          const clinico = response.data.find((exam) => exam.EXAATETIPO === 3);

          if (
            schedule?.ExamType?.CODIGO === "3" &&
            clinico &&
            clinico.FEADTENCAMINHADO &&
            !isBefore(
              new Date(clinico.FEADTENCAMINHADO),
              sub(new Date(), { days: 90 })
            )
          ) {
            req
              .get(
                `/administrativo/empresas/${localStorage.getItem("EMPCODIGO")}`
              )
              .then((response) => {
                setShowAlert(true);
                setDescription(
                  `Empresa: ${response.data.EMPRAZAOSOCIAL} - Grau de risco ${response.data.EMPGRAURISCO}`
                );
              })
              .catch((error) => {
                toast.error(error.response?.data?.message);
              });
          }
          setSelected(response.data);
          setLoading(false);

          if (response.data[0].NUCEXAMESPORTAL === 0) {
            setExamsDisabled(true);
          }
          if (Array.isArray(exemesAntigos)) {
            const result = examesIguais(exemesAntigos, response.data)
            if (result && schedule.ExamType.CODIGO === '4' && schedule.ExamType.MUDARFUNCAO && schedule.Solicitation.length === 0) {

              setOpenModal(true)
            }
          }
        })
        .catch(() => {
          setLoading(true);
        });
    };
    getExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  function examesIguais(lista1: Array<{ EXACODIGO: number }>, lista2: Array<{ EXACODIGO: number }>): boolean {
    if (lista1.length !== lista2.length) {
      return false;
    }

    const codigos1: number[] = [];
    const codigos2: number[] = [];

    for (const exame of lista1) {
      codigos1.push(exame.EXACODIGO);
    }

    for (const exame of lista2) {
      codigos2.push(exame.EXACODIGO);
    }

    return codigos1.every(codigo => codigos2.includes(codigo));
  }


  const [selected, setSelected] = useState<ExamDomain[] | null>([]);

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const onOpen = async () => {
    setOpen(true);
    const req = await api();

    if (!examsAdd)
      await req
        .get<ExamDomain[]>(
          `/agenda/setores/${schedule.Employee.SETCODIGO}/funcoes/${schedule.Employee.FCOCODIGO}/exames`,
          {
            params: {
              TIPOPESQUISA: "T",
              TIPOEXAME: schedule.ExamType.CODIGO,
              "somente-clinico": isClinico ? 1 : 0,
              "somente-complementares": isComplementar ? 1 : 0
            }
          }
        )
        .then((response) => {
          setExamsAdd(response.data);
        })
        .catch(() => {
          setOpen(false);
          toast.info("Nenhum exame adicional");
        });
  };

  const addExam = (item) => {
    if (!selected.find((a) => a.EXACODIGO === item.EXACODIGO)) {
      setExams([...exams, item]);
      setSelected([...selected, item]);
    }
  };

  if (!exams)
    return (
      <div>
        {loading
          ? "Não foram localizados exames pra essa função."
          : "Carregando ..."}
      </div>
    );


  const handleClose = () => {
    setOpenModal(false);
  };


  return (
    <Fragment>
      <DialogExam
        examsAdd={examsAdd}
        addExam={addExam}
        open={open}
        setOpen={setOpen}
      />
      {showAlert && (
        <Alert variant="standard" severity="warning">
          {process.env.REACT_APP_MENSAGEM_AGENDAMENTO_DEMISSIONAL}
          <span style={{ marginTop: "10px", display: "block" }}>
            {description}
          </span>
        </Alert>
      )}
      {openModal && (

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll="paper"
        >
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} style={{
                fontWeight: 600,
                fontSize: "22px",
                color: "red",
              }}>Aviso!</Box>
              <Box>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: 40 }}>

            <p style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
              textAlign: "justify"

            }}>Os exames da função antiga e da nova função são semelhantes, pode não ser necessário realizar esse agendamento, em caso de dúvidas, entre em contato com a clínica para confirmar.</p>

            <DialogActions style={{
              paddingTop: "30px",
            }}>
              <Button onClick={() => history.push('/pages')} variant="text" style={{
                color: "red"
              }}>Cancelar agendamento</Button>
              <Button onClick={handleClose} color="primary" variant="contained">
                Continuar agendamento
              </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      )

      }
      <Paper className={classes.root}>
        <CrudSelect
          content={exams}
          schema={[
            { label: "Exame", content: "EXANOMECOMERCIAL" },
            {
              label: "última realização",
              content: "FEADTENCAMINHADO",
              formatFun: (value: any) => {
                if (moment(value).isValid()) {
                  return moment(value).format("DD/MM/YYYY");
                }
                return "";
              },
            },
            { label: "Periodicidade", content: "EXAPERIODICIDADE" },
          ]}
          selecteds={selected}
          isItemSelect={(item) => {
            return (
              selected.findIndex(
                (exam) => exam.EXACODIGO === item.EXACODIGO
              ) !== -1
            );
          }}
          edit
          setSelecteds={setSelected}
          isDisabled={examsDisabled}
        />
        <Alert className={classes.alertExames} severity="info">
          {examsDisabled
            ? `Selecionamos para você os exames exigidos no PCMSO. Em caso de dúvidas, contate a clínica`
            : `Selecionamos para você os exames exigidos no PCMSO. Fique à vontade
          para dermarcá-los, e selecionar apenas o que desejar. Utilize o botão
          abaixo (Mais exames) para inserir exames adicionais.`}
        </Alert>
        <Divider style={{ width: "100%" }} />
        <div className={classes.moreExames}>
          <Button
            variant="text"
            onClick={onOpen}
            color="primary"
            disabled={examsDisabled}
          >
            Mais exames
          </Button>
        </div>
      </Paper>
      <Button
        variant="contained"
        style={{ width: "100%", marginTop: 25 }}
        color="primary"
        size="large"
        disabled={!selected || selected?.length <= 0}
        onClick={() => {
          setSchedule((v) => ({ ...v, Exam: selected }));
          setActiveStep(4);
        }}
      >
        Próximo
      </Button>
    </Fragment>
  );
}