import useSWR from "swr";

import { api } from "./api";

export function useFetch<Data = any>(
  url: string,
  format?: (data: Data) => unknown
) {
  const { data, error, mutate } = useSWR<Data>(
    url,
    async (url) => {
      const instance = await api();
      const response = await instance.get(url);
      if (format) return format(response.data);
      return response.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  return { data, error, mutate };
}
