import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";

function SelectForm({
  name,
  label,
  control,
  errors,
  FormControlProps = { variant: "outlined" },
  rules = {},
  children,
  disabled,
  onChangeItem,
  defaultValue,
}: any) {
  return (
    <FormControl {...FormControlProps} error={errors}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value, ref }) => (
          <Select
            disabled={disabled}
            labelId={`${name}-label`}
            id={name}
            label={label}
            inputRef={ref}
            onChange={(event: any) => {
              onChange(event.target.value);
              if (onChangeItem)
                onChangeItem(
                  event.target.value,
                  event.currentTarget.dataset?.other
                );
            }}
            value={value}
            defaultValue=""
          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
      />
      {errors && errors.message && (
        <FormHelperText>{errors.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export default SelectForm;
