import { formatDate } from "../../utils/formats";

export const headers = [
  {
    header: "Funcionário",
    key: "FUNNOME",
    width: 40,
  },
  {
    header: "Setor",
    key: "SETNOME",
    width: 40,
  },
  {
    header: "GHE",
    key: "GHENOME",
    width: 40,
  },
  {
    header: "Função",
    key: "FCONOME",
    width: 40,
  },
  {
    header: "Data de realização",
    key: "FEADATAREALIZADO",
    width: 40,
    type: "date",
  },
  {
    header: "Nome do arquivo",
    key: "ARQNOME",
    width: 40,
  },
];
