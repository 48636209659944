import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";

import { v4 as uuid } from "uuid";
import DropFile from "../../shared/Files/DropFile";
import { Form } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import InputDate from "../../shared/Form/Fields/Date";
import HeaderWithBack from "../../shared/Item/HeaderWithBack";
import { api } from "../../shared/services/api";
import DeleteIcon from "@material-ui/icons/Delete";
import "./styles.scss";

const ListFiles = (props) => {
  const { files, removeFile } = props;

  return (
    <List>
      <Typography variant="subtitle2">Anexos: </Typography>
      <br />
      {files.map((file, idx) => {
        return (
          <ListItem key={idx}>
            <ListItemText primary={file.file.name} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => removeFile(file.id)}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

interface FileId extends File {
  id: string;
}

export default function HistoricoAdicionar() {
  const {
    empCodigo: idempresa,
    plCodigo: idplanoacao,
    anaCodigo: idanalise,
    type,
  } = useParams<{
    empCodigo: string;
    plCodigo: string;
    anaCodigo: string;
    type: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [content, setContent] = useState(null);
  const [files, setFiles] = useState<FileId[]>([]);

  const removeFile = (id) => {
    setFiles((files) => files.filter((file) => file.id !== id));
  };

  const addFiles = (acceptedFiles) => {
    const formatFiles = acceptedFiles.map((file) => {
      return {
        id: uuid(),
        file,
      };
    });
    setFiles((files) => [...files, ...formatFiles]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    addFiles(acceptedFiles);
  };

  const uploadFiles = async (files, idhistorico) => {
    const urlArquivos =
      type === "fator"
        ? `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico/${idhistorico}/arquivos`
        : `/analiserisco/${idanalise}/recomendacoes/historicos/${idhistorico}/arquivos`;

    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    files.forEach(async ({ file }) => {
      formData.append("file", file, file.name);
    });
    const request = await api();
    await request.post(urlArquivos, formData, config);
  };

  const urlMain =
    type === "fator"
      ? `/analiserisco/${idanalise}/setores/0/ghe/0/planosacao/${idplanoacao}/historico`
      : `/analiserisco/${idanalise}/recomendacoes/historicos`;

  const linkBack = `/pages/cronogramas/editar/${idempresa}/${idanalise}/${type}/${idplanoacao}?verticalTab=1`;

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      const { data } = await req.get(
        `/analiserisco/${idanalise}/cronogramas/${idplanoacao}`
      );
      setContent(data);
    };

    if (type === "empresa") {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
      <HeaderWithBack
        title="Adicionar Histórico de ações"
        linkBack={linkBack}
      />
      <Form
        spacing={"8px 8px"}
        onSubmit={async (data) => {
          setLoading(true);
          data["HISDTREALIZACAO"] = new Date();
          if (content) {
            if (content.GHECODIGO) {
              data["GHECODIGO"] = content.GHECODIGO;
            }
            if (content.SETCODIGO) {
              data["SETCODIGO"] = content.SETCODIGO;
            }
            if (content.ANACODIGO) {
              data["ANACODIGO"] = content.ANACODIGO;
            }
            if (content.CROCODIGO) {
              data["CROCODIGO"] = content.CROCODIGO;
            }
          }

          const req = await api();
          try {
            const res = await req.post(urlMain, data);
            if (res.status === 200) {
              if (files.length) {
                await uploadFiles(files, res.data.HISCODIGO);
              }
              history.push(linkBack);
              toast.success("Histórico de ação criado com sucesso");
            }
          } catch (err) {
            toast.error(err.response.data.message);
          }
          setLoading(false);
        }}
        buttons={() => (
          <div
            style={{
              width: "100%",
              margin: "24px auto",
              padding: "7px",
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              style={{ width: "100%", marginTop: -20 }}
              size="large"
              type="submit"
            >
              {loading ? <CircularProgress color="secondary" /> : "Adicionar"}
            </LoadingButton>
          </div>
        )}
        schema={[
          {
            content: [
              {
                lg: 12,
                label: "Título",
                name: "HISTITULO",
              },
              {
                lg: 12,
                name: "HISDTINICIAL",
                customDefValue: new Date(),
                customComponent: ({ control, name }) => (
                  <InputDate
                    control={control}
                    label="Data inicial"
                    name={name}
                  />
                ),
              },
              {
                lg: 12,
                name: "HISDTFINAL",
                customDefValue: new Date(),
                customComponent: ({ control, name }) => (
                  <InputDate control={control} label="Data final" name={name} />
                ),
              },
              {
                lg: 12,
                label: "Responsável",
                name: "HISRESPONSAVEL",
              },
              {
                lg: 12,
                label: "Ação realizada",
                name: "HISACAOREALIZADA",
                inputPropsAdittional: {
                  multiline: true,
                  rows: 6,
                },
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  return (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        style={{ marginBottom: "10px" }}
                      >
                        Anexe documentos referentes a este histórico de ação
                      </Typography>
                      <DropFile
                        multiple={false}
                        onDrop={onDrop}
                        message={
                          <>
                            <b>Clique</b> ou <b>arraste arquivos aqui</b> para
                            adicionar um anexo ao histórico de ação
                          </>
                        }
                      />
                    </Box>
                  );
                },
              },
              {
                lg: 12,
                name: "",
                label: "",
                customComponent: () => {
                  if (!files.length) return <></>;
                  return <ListFiles files={files} removeFile={removeFile} />;
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
