import { Form } from "../../shared";
import { toast } from "react-toastify";
import { useSetores } from "./controller";
import { useHistory } from "react-router-dom";
import { api } from "../../shared/services/api";
import { useCache } from "../../contexts/cache";
import { InputDef } from "../../shared/Form/Fields";
import { useEffect, useRef, useState } from "react";
import InputDate from "../../shared/Form/Fields/Date";
import { useSelect } from "../employee/emp/useSelect";
import { isValidCnpjOrCpf } from "../../utils/validate";
import { Masked } from "../../shared/Form/Fields/Masked";
import LoadingButton from "../../shared/Button/LoadingButton";
import AlertItem, { IAlert } from "../funcionariosEditar/alert";
import Checkbox from "../../shared/Form/Fields/CheckboxReverse";
import { ModalEsocialInfo } from "../../shared/ModalEsocialInfo";
import { Typography, Box, CircularProgress, MenuItem, Tooltip, IconButton } from "@material-ui/core";

const genders = [
  { code: 2, label: "Feminino" },
  { code: 1, label: "Masculino" },
];

export default function FuncionarioAdicionar() {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [statusOptions, setStatusOptions] = useState([]);
  const { categeSocial } = useSelect();
  const [alert, setAlert] = useState<IAlert>({} as IAlert);
  const [idSetor, setIdSetor] = useState<string | null>(null);
  const [funcoes, setFuncoes] = useState<any[]>([]);
  const { addCache } = useCache();
  const [open, setOpen] = useState<boolean>(false);
  const funcpf = useRef<string>();
  const setValueRef = useRef<any>();

  const { setores } = useSetores();

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get<any[]>(`/agenda/setores/${idSetor}/funcoes`);
        const opts = res.data.map((item: any, idx) => {
          return (
            <MenuItem key={idx} value={item.FCOCODIGO}>
              {item.GHENOME + " / " + item.FCONOME}
            </MenuItem>
          );
        });
        setFuncoes(opts);
      } catch (err) {}
    };
    fetch();
  }, [idSetor]);

  useEffect(() => {
    const fetch = async () => {
      const req = await api();
      try {
        const res = await req.get(`/funcionarios/funcionarios/estadoscivis`);
        let opt: any[] = [];
        // eslint-disable-next-line array-callback-return
        res.data?.map((item) => {
          opt = [
            ...opt,
            {
              label: item.ESTDESCRICAO,
              code: item.ESTCODIGO,
            },
          ];
        });
        setStatusOptions(opt);
      } catch (err) {}
    };
    fetch();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {open && (
        <ModalEsocialInfo
          open={open}
          onClose={() => setOpen(false)}
          funcpf={funcpf.current}
          setValue={setValueRef.current}
        />
      )}
      <Box className="no-print" style={{ margin: 0, padding: 0 }}>
        <Typography
          className="no-print"
          align="left"
          style={{ marginBottom: "35px", fontWeight: "bold", fontSize: "15pt" }}
          color="primary"
        >
          Adicionar funcionário
        </Typography>
        <Form
          spacing={"8px 8px"}
          onSubmit={async (data) => {
            setLoading(true);
            data.FUNSTATUS = data.FUNSTATUS ? 1 : 0;
            data.USEESOCIALDATA =
              data.USEESOCIALDATA && data.USEESOCIALDATA === "1" ? 1 : 0;
            const req = await api();
            try {
              const res = await req.post(`/funcionarios/funcionarios`, data);
              await req.post(
                `/funcionarios/${res.data?.FUNCODIGO}/profissiografias`,
                {
                  SETCODIGO: data.SETCODIGO,
                  FCOCODIGO: data.FCOCODIGO,
                  FUNDTINICIO: data.FUNADMISSAO,
                }
              );
              addCache({
                url: "/pages/funcionarios",
                data: { FUNNOME: data.FUNNOME },
              });
              toast.success("Funcionário adicionado com sucesso");
              history.push("/pages/funcionarios");
            } catch (error) {
              error.response?.status === 403
                ? toast.error(
                    "Usuário sem autorização para realizar essa operação. Procure o suporte."
                  )
                : toast.error(error.response?.data?.message);
            } finally {
              setLoading(false);
            }
          }}
          buttons={() => (
            <div
              style={{
                width: "100%",
                margin: "24px auto",
                padding: "7px",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ width: "100%", marginTop: -20 }}
                size="large"
                type="submit"
              >
                {loading ? <CircularProgress color="secondary" /> : "Adicionar"}
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  label: "Nome do Funcionário",
                  name: "FUNNOME",
                  xs: 12,
                  sm: 12,
                  md: 6,
                  lg: 10,
                  rules: { required: "Campo Obrigatório" },
                  inputPropsAdittional: { type: "text" },
                },
                {
                  name: "FUNSTATUS",
                  lg: 2,
                  type: "custom",
                  customDefValue: "1",
                  customComponent: ({ name, control, setValue }) => {
                    if (!setValueRef.current) setValueRef.current = setValue;
                    return (
                      <Box style={{ marginLeft: 5, marginTop: 5 }}>
                        <Checkbox
                          name={name}
                          label="Afastado"
                          control={control}
                        />
                      </Box>
                    );
                  },
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "FUNNASCIMENTO",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name, error }) => (
                    <InputDate
                      control={control}
                      label="Nascimento"
                      name={name}
                      error={error}
                      rules={{ required: "Campo Obrigatório" }}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "FUNDTINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Ínicio"
                      name={name}
                      disabled={true}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "FUNADMISSAO",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name, error }) => (
                    <InputDate
                      control={control}
                      label="Admissão"
                      name={name}
                      error={error}
                      rules={{ required: "Campo Obrigatório" }}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  name: "FUNDEMISSAO",
                  type: "custom",
                  //customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate control={control} label="Demissão" name={name} />
                  ),
                },
                {
                  sm: 6,
                  md: 6,
                  lg: 4,
                  type: "select",
                  name: "FUNSEXO",
                  label: "Sexo",
                  options: genders.map((g) => (
                    <MenuItem value={g.code}>{g.label}</MenuItem>
                  )),
                  rules: { required: "Campo Obrigatório" },
                },
                {
                  sm: 6,
                  md: 6,
                  lg: 4,
                  type: "select",
                  name: "ESTCODIGO",
                  label: "Estado Civil",
                  options: statusOptions.map((s) => (
                    <MenuItem value={s.code}>{s.label}</MenuItem>
                  )),
                  rules: { required: "Campo Obrigatório" },
                },
                {
                  label: "RG",
                  xs: 12,
                  name: "FUNRG",
                  sm: 6,
                  md: 6,
                  lg: 4,
                  inputPropsAdittional: { type: "text" },
                },
                {
                  label: "CPF",
                  name: "FUNCPF",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 3,
                  type: "custom",
                  customComponent: ({ control, error, name, setValue }) => (
                    <Masked
                      rules={{
                        validate: { isValidCnpjOrCpf: isValidCnpjOrCpf },
                      }}
                      format="###.###.###-##"
                      mask="_"
                      control={control}
                      name={name}
                      error={error}
                      label="CPF"
                      onChangeItem={(value) => {
                        funcpf.current = value;
                      }}
                    />
                  ),
                },
                {
                  lg: 3,
                  label: "DDD",
                  name: "FUNDDD1",
                },
                {
                  lg: 3,
                  name: "FUNFONE1",
                  label: "Telefone",
                },
                {
                  lg: 3,
                  styles: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  type: "checkbox",
                  name: "FUNPERMITEMSG",
                  label: "Permitir comunicação por mensagens"
                },
                {
                  lg: 1,
                  customComponent: () => {
                    return (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            <h3>Consultar dados do funcionário no eSocial</h3>
                          }
                        >
                          <IconButton
                            onClick={() => setOpen((open) => !open)}
                            style={{ width: "60px", height: "60px" }}
                          >
                            <img
                              src="/esocial_icon.png"
                              width="78px"
                              height="78px"
                              style={{ transform: "translate(3px, 0)" }}
                              alt=""
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    );
                  },
                },
                {
                  label: "CTPS",
                  name: "FUNCTPS",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 5,
                  inputPropsAdittional: { type: "text" },
                },
                {
                  label: "NIT",
                  name: "FUNNIT",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  inputPropsAdittional: { type: "text" },
                },
                {
                  label: "Categoria",
                  name: "ESOCCATEGCODIGO",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 8,
                  type: "select",
                  options: categeSocial,
                  rules: { required: "Campo Obrigatório" },
                },
                {
                  label: "Matrícula",
                  name: "FUNMATRICULA",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4,
                  inputPropsAdittional: { type: "text" },
                  customComponent: ({ control, name }) => {
                    return (
                      <Tooltip
                        title="Matricula que é utilizada no ESocial"
                        placement="top"
                        arrow
                      >
                        <InputDef
                          name={name}
                          control={control}
                          style={{ width: "100%" }}
                          type="text"
                          variant="outlined"
                          label="Matrícula"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Tooltip>
                    );
                  },
                },
                {
                  label: "Setor",
                  rules: { required: "Campo Obrigatório" },
                  name: "SETCODIGO",
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  type: "select",
                  options: setores,
                  onChange: (data, setValue) => {
                    setValue("FCOCODIGO", null);
                    setIdSetor(data.toString());
                  },
                },
                {
                  label: "Função",
                  name: "FCOCODIGO",
                  rules: { required: "Campo Obrigatório" },
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  type: "select",
                  options: funcoes,
                },
                {
                  lg: 12,
                  name: "USEESOCIALDATA",
                  inputPropsAdittional: { style: { display: "none" } },
                },
              ],
            },
          ]}
        />
      </Box>
      <AlertItem {...alert} closeItem={() => setAlert({} as IAlert)} />
    </Box>
  );
}
