import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { OutlinedButton } from "../../../../shared/Button/LoadingButton";

interface ModalProps {
  open: boolean;
  onClose: (event: object, reason: string) => void;
}

const ModalAlertRetornoAoTrabalho = ({ open, onClose }: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Atenção</DialogTitle>
      <DialogContent>
        {process.env.REACT_APP_MENSAGEM_AGENDAMENTO_RETORNO_AO_TRABALHO}
      </DialogContent>
      <DialogActions>
        <OutlinedButton onClick={onClose}>Confirmar e continuar</OutlinedButton>
      </DialogActions>
    </Dialog>
  );
};

ModalAlertRetornoAoTrabalho.displayName = "ModalAlertRetornoAoTrabalho";

export { ModalAlertRetornoAoTrabalho };
