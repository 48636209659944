import { useState } from "react";
import { toast } from "react-toastify";
import { Paper, Typography, Box } from "@material-ui/core";
import moment from "moment";
import InputDate from "../../shared/Form/Fields/Date";

import { notificacoesModel } from "../../redux/reducers/notificationsReducer";
import { api } from "../../shared/services/api";
import { Form } from "../../shared";
import LoadingButton from "../../shared/Button/LoadingButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./styles.scss";

export default function CentralNotificacoes(props) {
  const [response, setResponse] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const send = async (e) => {
    try {
      const NOTCODIGO = e.currentTarget.value;

      const req = await api();
      await req.put(`/agenda/centralnotificacoes/${NOTCODIGO}/recebimento`);
      toast.success("Recebimento confirmado com sucesso!");
    } catch (error) {
      toast.error("Erro ao confirmar recebimento " + error);
    }
  };

  return (
    <Box>
      <Box className="teste">
        <Typography
          align="left"
          style={{ marginBottom: "20px", fontWeight: "bold", fontSize: "15pt" }}
          color="primary"
        >
          Central de Notificações
        </Typography>
        <Form<notificacoesModel>
          spacing={"0px 4px"}
          onSubmit={async (data) => {
            setLoadingButton(true);
            const req = await api();

            const responseData = await req.get(`/agenda/centralnotificacoes`, {
              params: {
                DATAINICIAL: data.DATAINICIAL
                  ? moment(data.DATAINICIAL).format("YYYY-MM-DD")
                  : null,
                DATAFINAL: data.DATAFINAL
                  ? moment(data.DATAFINAL).format("YYYY-MM-DD")
                  : null,
                NOTTITULO: data.TITULO,
              },
            });
            setLoadingButton(false);
            setResponse(responseData.data);
          }}
          buttons={() => (
            <div
              style={{
                width: "100%",
                margin: "24px auto",
              }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
              >
                {loadingButton ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "Visualizar Notificações"
                )}
              </LoadingButton>
            </div>
          )}
          schema={[
            {
              content: [
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAINICIAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <InputDate
                      control={control}
                      label="Data inicial"
                      name={name}
                      rules={{ required: "Campo Obrigatório" }}
                      setValueAs={null}
                    />
                  ),
                },
                {
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 6,
                  name: "DATAFINAL",
                  type: "custom",
                  customDefValue: new Date(),
                  customComponent: ({ control, name }) => (
                    <div className="margin">
                      <InputDate
                        control={control}
                        label="Data final"
                        name={name}
                        rules={{ required: "Campo Obrigatório" }}
                        setValueAs={null}
                      />
                      <br />
                      <br />
                    </div>
                  ),
                },
                {
                  label: "Título da notificação",
                  name: "TITULO",
                  lg: 12,
                  inputPropsAdittional: { type: "text" },
                },
              ],
            },
          ]}
        />
      </Box>
      <Paper style={{ marginTop: "15px" }} className="marginPrint">
        {response.length > 0 &&
          response.map((element) => (
            <>
              <Paper
                style={{
                  padding: "25px",
                  marginTop: "30px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Data da Notificação:{" "}
                      </span>
                      {moment(element.NOTDATA).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Titulo: </span>
                      {element.NOTTITULO}{" "}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Descrição: </span>{" "}
                      {element.NOTDESCRICAO}{" "}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        <br />
                        {element.USUCODIGORECEB > 0 ? (
                          element.DESCRECEBIMENTO
                        ) : (
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            style={{ width: "96.5%", marginTop: 10 }}
                            size="large"
                            type="submit"
                            value={element.NOTCODIGO}
                            onClick={send}
                          >
                            Confirmar Recebimento
                          </LoadingButton>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </Paper>
            </>
          ))}
      </Paper>
      {!(response.length > 0) && (
        <p style={{ textAlign: "center" }}>
          Não foram encontrados registros nesta data.
        </p>
      )}
    </Box>
  );
}
