import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Form from "../../../shared/Form";

import { Masked } from "../../../shared/Form/Fields/Masked";
import { isValidCnpjOrCpf } from "../../../utils/validate";

import "../../Login/styles.scss";

export default function DialogFunCPF({
  open,
  setOpen,
  setValueRef,
  preencherCampos,
  data,
}: {
  open: boolean;
  setOpen: any;
  setValueRef: any;
  preencherCampos: any;
  data: any;
}) {
  const addCPF = ({ FUNCPF }) => {
    setOpen(false);
    preencherCampos({ ...data, FUNCPF }, setValueRef.current, true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{}}
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography>
              Funcionário {data?.FUNNOME} está sem o CPF preenchido, favor
              inserir um CPF.
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 40, paddingTop: 20 }}>
        <Form
          spacing={"18px 0px"}
          schema={[
            {
              content: [
                {
                  lg: 12,
                  name: "FUNCPF",
                  label: "CPF",
                  type: "custom",
                  customComponent: ({ control, error, name }) => (
                    <Masked
                      rules={{
                        validate: { isValidCnpjOrCpf: isValidCnpjOrCpf },
                      }}
                      format="###.###.###-##"
                      mask="_"
                      control={control}
                      name={name}
                      error={error}
                      label="CPF"
                    />
                  ),
                },
              ],
            },
          ]}
          buttons={() => (
            <>
              <Box display="flex" justifyContent="flex-end" marginTop={2}>
                <Button
                  onClick={() => setOpen(false)}
                  type="button"
                  variant="text"
                  color="primary"
                >
                  Cancelar
                </Button>
                <div style={{ width: 18 }}></div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // style={{ backgroundColor: "" }}
                >
                  Confirmar
                </Button>
              </Box>
            </>
          )}
          onSubmit={addCPF}
        />
      </DialogContent>
    </Dialog>
  );
}
