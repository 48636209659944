import { forwardRef } from "react";
import {
  Dialog,
  Slide,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

const Transition = forwardRef(function Transition(props, ref) {
  //@ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalDeTermosDeUso({ openModal, onClose }) {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openModal}
      //@ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="termos-de-uso-titulo"
      aria-describedby="termos-de-uso"
    >
      <DialogTitle>
        <h2>
          Política de privacidade para{" "}
          <a
            style={{ fontSize: 25 }}
            href="http://www.prosesmt.com.br/"
            target="_blank" rel="noreferrer"
          >
            ProSESMT
          </a>
        </h2>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <p style={{ marginBottom: "1rem", color: "black" }}>
            Todas as suas informações pessoais recolhidas, serão usadas para o
            ajudar a tornar a sua visita no nosso site o mais produtiva e
            agradável possível.
          </p>
          <p style={{ marginBottom: "1rem", color: "black" }}>
            A garantia da confidencialidade dos dados pessoais dos utilizadores
            do nosso site é importante para o prosesmt.
          </p>
          <p style={{ marginBottom: "1rem", color: "black" }}>
            Todas as informações pessoais relativas a membros, assinantes,
            clientes ou visitantes que usem o prosesmt serão tratadas em
            concordância com a Lei da Proteção de Dados Pessoais de 26 de
            outubro de 1998 (Lei n.º 67/98).
          </p>
          <p style={{ marginBottom: "1rem", color: "black" }}>
            A informação pessoal recolhida pode incluir o seu nome, e-mail,
            número de telefone e/ou telemóvel, morada, data de nascimento e/ou
            outros.
          </p>
          <p style={{ marginBottom: "1rem", color: "black" }}>
            O uso do ProSESMT pressupõe a aceitação deste Acordo de privacidade.
            A equipe do prosesmt reserva-se ao direito de alterar este acordo
            sem aviso prévio. Deste modo, recomendamos que consulte a nossa
            política de privacidade com regularidade de forma a estar sempre
            atualizado.
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button
              variant="text"
              color="primary"
              type="button"
              onClick={onClose}
            >
              Fechar
            </Button>
      </DialogActions>
    </Dialog>
  );
}
